import React from 'react'
import {useStyles} from './Styles'
import {FormattedMessage} from 'react-intl'
import MenuItem from '@material-ui/core/MenuItem'
import {withStyles} from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {AuthConsumer} from '../../AuthContext'
import {NavLink} from 'react-router-dom'
import {NamedRoutes} from '../../routes'

const UserMenu = ({isMobile}) => {
  const classes = useStyles()
  const mobileClass = isMobile ? classes.userMenuMobile : ''

  const [anchorEl, setAnchorEl] = React.useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const menuId = 'header-account-menu'

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const StyledMenu = withStyles({
    paper: {
      border: `1px solid #7E6CA4`,
      minWidth: 120
    }
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      disableScrollLock
      {...props}
    />
  ))


  return (
    <>
      <IconButton
        edge="end"
        aria-label={<FormattedMessage id='UserMenu.label'/>}
        aria-controls={menuId}
        aria-haspopup="true"
        className={classes.userMenuButton + ' ' + mobileClass}
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <AuthConsumer>
          {({userInfo}) => (
            <>
              {!!userInfo && !!Object.keys(userInfo).length &&
              <Avatar alt={userInfo.firstName + ' ' + userInfo.lastName}
                      src={'/broken'}>{userInfo.firstName.charAt(0) + userInfo.lastName.charAt(0)}</Avatar>}
            </>
          )}
        </AuthConsumer>
        <ArrowDropDownIcon color="primary"/>
      </IconButton>
      <AuthConsumer>
        {({handleLogout}) => (
          <StyledMenu
            id={menuId}
            anchorEl={anchorEl}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
            disableScrollLock
            className={classes.mainNavigationSubMenu}
          >
            <MenuItem onClick={handleMenuClose}>
              <NavLink to={NamedRoutes.account.patientProfile} className={classes.userMenuLink}>
                <FormattedMessage id='MainNavigation.link.patientProfile'/></NavLink>
            </MenuItem>
            {/* <MenuItem onClick={handleLogout}>logout</MenuItem> */}
          </StyledMenu>
        )}
      </AuthConsumer>
    </>
  )
}

export default UserMenu
