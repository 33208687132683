import 'date-fns'
import React from 'react'
import { Formik, useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { FormControl, InputLabel, TextField, RadioGroup, FormControlLabel, Radio, Checkbox } from '@material-ui/core'
import { MedicationFormValidation } from '../../../../../utils/validationSchema'
import { FormLoader } from '../../../../Loader/FormLoader'
import { useStyles } from './Styles'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import AddReminder from './FormParts/AddReminder'
import moment from 'moment'
import ProfileServices from '../../../../../Services/Consumers/ProfileServices' 
import { StyledSelect, SelectTheme } from '../../../../StyledComponents/StyledSelect'
import { HiddenCheckbox } from '../../../../StyledComponents/HiddenCheckbox'


const MedicationForm = ({ onSubmit, editableRowId, switchToListMode, initialValues }) => {

  const [open, setOpen] = React.useState(false);
  const [medNames, setMedNames] = React.useState([]);
  const [medForms, setMedForms] = React.useState([]);
  const [medDoses, setMedDoses] = React.useState([]);
  const [chosenMedications, setMedicationChoice] = React.useState('');

  const [allMedicationsList, setMedicationList] = React.useState([]);
  const [medNameInput, setMedNameInput] = React.useState('');
  const [medSuggestions, setMedSuggestions] = React.useState([]);
  const [viewList, setViewList] = React.useState(false);
  const loading = open && medNames.length === 0;


  React.useEffect(() => {
    let active = true;
    const getData = async () => {
      const response = await ProfileServices.getMedicationsList();
      const medicationsList = await response.json();
      setMedicationList(medicationsList.medications);

      const medicationsListNames = medicationsList.medications.map((list) => {
        return `${list.generic_name} (${list.brand_name})`
      });
      // Filter out duplicates using 'Set' and convert it back to array
      const medNameSet = new Set(medicationsListNames);
      const medicationsNames = [...medNameSet];

      if(active){
        setMedNames(medicationsNames.map(name => (
          {value: `${name}`, label: `${name}`}
        )))
      }
    }

    getData();

    return () => {
      active = false;
    };
  }, [loading]);


  const searchMedList = (medList) => {
    const medSearchResult = medList.filter(med => med.value.toLowerCase().includes(medNameInput.toLowerCase()) && med);
    setMedSuggestions(medSearchResult);
  }

  React.useEffect(() => {
    medNameInput.length ? searchMedList(medNames) : setMedSuggestions([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medNameInput])


  const classes = useStyles()



  
  const medicationForm = useFormik({
    initialValues: { ...initialValues},
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: MedicationFormValidation,
    onSubmit: ((values, actions) => {
      onSubmit(editableRowId, values, actions, switchToListMode)
    }),
  })

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
      setChecked(!checked);
  };

  const [ongoingChecked, setOngoingChecked] = React.useState(medicationForm.initialValues.end_date == "2200-01-01" ? true : false)
  const [ongoingStateForEndDate, setOngoingStateForEndDate] = React.useState(null)

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setOngoingChecked(true)
      medicationForm.setFieldValue('is_ongoing', true)
    } else if (e.target.checked === false) {
      setOngoingChecked(false)
      setOngoingStateForEndDate("minDate")
      medicationForm.setFieldValue('is_ongoing', false)
      if (initialValues.end_date === "2200-01-01") {
        medicationForm.setFieldValue('end_date', (moment().add(2, 'd')).format('YYYY-MM-DD'))
      }
    }
  }




  const [numberOfPills, setNumberOfPills] = React.useState(
    (!!medicationForm.values.doses_per_day && !!medicationForm.values.dose && medicationForm.values.doses_per_day >= medicationForm.values.dose) ?
      Math.ceil(medicationForm.values.doses_per_day / medicationForm.values.dose) : 0
  )


  
  const [chosenMedicationName, setChosenMedicationName] = React.useState(null);
  const [chosenMedicationForm, setChosenMedicationForm] = React.useState(null);
  const [chosenMedicationDose, setChosenMedicationDose] = React.useState(null);
  const [customMedicationDose, setCustomMedicationDose] = React.useState("");
  const [selectedDose, setSelectedDose] = React.useState("");



  const [reminderList, setReminderList] = React.useState([...initialValues.reminders])



  const [selectedStartDate, setSelectedStartDate] = React.useState(initialValues.start_date)
  const [selectedEndDate, setSelectedEndDate] = React.useState(initialValues.end_date === "2200-01-01" && ongoingStateForEndDate == "minDate" || initialValues.end_date === "2200-01-01" ? (moment().add(2, 'd')).format('YYYY-MM-DD') : initialValues.end_date)
  const [pillsAdded, setPillsAdded] = React.useState(initialValues.doses_per_day)


  const handleStartDateChange = (date) => {
    if (!!date) {
      let dateToString = date.toDateString()
      setSelectedStartDate(dateToString)
      medicationForm.setFieldValue('start_date', moment(date).format('YYYY-MM-DD'))
    }
  }

  const handleEndDateChange = (date) => {
    if (!!date) {
      let dateToString = date.toDateString()
      setSelectedEndDate(dateToString) 
      medicationForm.setFieldValue('end_date', moment(date).format('YYYY-MM-DD'))
    }
  }

  const [measureTypeValue, setMeasureTypeValue] = React.useState("")

  React.useEffect(() => {
    medicationForm.setFieldValue('reminders', reminderList)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [reminderList])

  React.useEffect(() => {
    if (!!initialValues.reminders && !!initialValues.reminders.length) {
      let newValue = 0
      initialValues.reminders.forEach((reminder) => {
        newValue += parseFloat(reminder.num_of_doses)
      })
      setPillsAdded(newValue)
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  

  const getMedicationsDosesAndForms = (medicationsChoice) => {
      const medicationsNameSplit = medicationsChoice.split(" (");
      const medicationsGenericName = medicationsNameSplit[0];
      const medicationsBrandName = medicationsNameSplit[1].replace(")", "");

      const medicationsNameFilter = allMedicationsList.filter(function(medication){
        return medication.generic_name == medicationsGenericName && medication.brand_name == medicationsBrandName});
      setMedicationChoice(medicationsNameFilter);

      const medicationsListDoses = medicationsNameFilter.map((list) => {
        return `${list.dose}${list.dose_unit}`
      })

      const medicationsListForms = medicationsNameFilter.map((list) => {
        return `${list.form}`
      })

      const medDoseSet = new Set(medicationsListDoses);
      const medicationsDose = [...medDoseSet];

      const medFormSet = new Set(medicationsListForms);
      const medicationsForms = [...medFormSet];
      setMedDoses(medicationsDose.map(medDose => ({value: `${medDose}`, label: `${medDose}`})))
      setMedForms(medicationsForms.map(medForm => ({value: `${medForm}`, label: `${medForm}`})))
  }



  const setMedicationId = (dosageChoice) => {
    const id = chosenMedications.filter(function(medication){return (medication.dose + medication.dose_unit) == dosageChoice})[0].id
    medicationForm.setFieldValue('medication_id', id);
  }

  const setMedicationDose = (dosageChoice) => {
    const dose = chosenMedications.filter(function(medication){return (medication.dose + medication.dose_unit) == dosageChoice})[0].dose
    medicationForm.setFieldValue('dose', dose);
  }

  const setMedicationMeasureType = (dosageChoice) => {
    const measureType = chosenMedications.filter(function(medication){return (medication.dose + medication.dose_unit) == dosageChoice})[0].dose_unit
    medicationForm.setFieldValue('dose_units', measureType);
  }

  const setEditModeMedicationId = () => {
    if(editableRowId !=='new') {
      medicationForm.setFieldValue('edit', true)
    }
  }

  const setDosesPerDay = (pills) => {
    medicationForm.setFieldValue('doses_per_day', pills)
  }


  return (
    <form onSubmit={(e) => {
      if(editableRowId !== 'new') {
        setEditModeMedicationId();
      }
      medicationForm.setFieldValue('is_ongoing', ongoingChecked);
      medicationForm.handleSubmit(e);
      }
    }
    noValidate className={classes.MedicationForm}>

      

    {editableRowId === 'new' ?

      
      <FormControl>

        <FormControlLabel
            control={<Checkbox
              checked={checked}
              onChange={() => {
                handleChange();
                setChosenMedicationName(null);
                setChosenMedicationForm(null);
                setChosenMedicationDose(null);
                setReminderList([...initialValues.reminders]);
                setSelectedDose(null);
                setPillsAdded(0);
                setMedicationChoice("");
                medicationForm.setFieldValue('generic_name', '');
                medicationForm.setFieldValue('medication_id', null);
                medicationForm.setFieldValue('brand_name', null);
                medicationForm.setFieldValue('dose', 0);

              }}/>}
            label={<FormattedMessage id='medicationForm.InputLabel.checkBox'/>}
            labelPlacement="end"
          />
        
        <InputLabel htmlFor="name">
          <FormattedMessage id='medicationForm.InputLabel.name'/>
        </InputLabel>
       
        {checked === false ?
        <>
          <FormControlLabel
            control={<HiddenCheckbox
              checked={viewList}
              onChange={() => {
                setViewList(!viewList)
              }}/>}
            label={!viewList ? "Select From List" : "Enter Medication Name"}
            labelPlacement="start"
          />

        <StyledSelect 
        components={!viewList && { DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        options={viewList ? medNames : medSuggestions}
        noOptionsMessage={() => null}
        onChange={(event, newChosenMedicationName) =>
          {
            medicationForm.setFieldValue("generic_name", event.value.split(' (')[0]);
            medicationForm.setFieldValue("brand_name", event.value.split(' (')[1].split(')')[0]);
            getMedicationsDosesAndForms(event.value);
            setChosenMedicationName(newChosenMedicationName);
            setChosenMedicationForm(null);
            setChosenMedicationDose(null);
          }}


          
          onInputChange={e => setMedNameInput(e)}
          menuShouldScrollIntoView={viewList}
          placeholder={viewList ? "Select From List" : "Enter Medication Name"}
          menuPlacement={viewList ? "bottom" : "top"}
          maxMenuHeight={viewList ? 240 : 120}
          isSearchable={!viewList}
          theme={theme => SelectTheme(theme)}
          styles={{
            menu: base => ({
              ...base,
              zIndex: 100
            })
          }}
          />
          </> 
        
        : 
        <TextField
        required
          fullWidth
          margin="dense"
          disabled={editableRowId !== 'new'}
          type="text"
          id='generic_name'
          name='generic_name'
          variant="outlined"
          onChange={(e)=> {
            medicationForm.handleChange(e);
          }}
          onBlur={medicationForm.handleBlur}
          value={medicationForm.values.generic_name && medicationForm.values.brand_name}
          helperText={medicationForm.errors.generic_name && medicationForm.touched.generic_name && medicationForm.errors.generic_name}
          placeholder={"Please enter your medication name"}
          error={medicationForm.errors.generic_name && medicationForm.touched.generic_name !== undefined}
          inputProps={{
            autoComplete: 'new-name',
            form: {
              autoComplete: 'off'
            }
          }}
        />
        }
        
      </FormControl>
      :
      <div>
        <h3 className="EditModeLabels"><FormattedMessage id='medicationForm.InputLabel.name'/></h3>
        {/* {medicationForm.values.brand_name !== "" && medicationForm.values.brand_name !== medicationForm.values.medication_name ? */}
        {medicationForm.values.generic_name !== medicationForm.values.brand_name ?
          <p className="EditModeInfos">{medicationForm.values.generic_name} ({medicationForm.values.brand_name})</p>
          :
          <p className="EditModeInfos">{medicationForm.values.medication_name}</p>}
              <h3 className="EditModeLabels"><FormattedMessage id='medicationForm.InputLabel.form'/></h3>
              <p className="EditModeInfos" style={{textTransform: 'capitalize'}}>{medicationForm.values.form}</p>
              <h3 className="EditModeLabels"><FormattedMessage id='medicationForm.InputLabel.dose'/></h3>
              <p className="EditModeInfos EditModeLastInfo">{medicationForm.values.dose_value} {medicationForm.values.dose_units}</p>
          </div>}

      
    {editableRowId === 'new' ?
      <div className={classes.row}>

        <FormControl variant="outlined">
          <InputLabel htmlFor="form">
            <FormattedMessage id='medicationForm.InputLabel.form'/>
          </InputLabel>
          
          <StyledSelect
          options={checked === false ? medForms : [{value: "Pill", label: "Pill"},{value: "Nasal Spray", label: "Nasal Spray"}, {value: "Syringe", label: "Syringe"}]}
          onChange={(event) => {
            medicationForm.setFieldValue("form", event.value);
            setChosenMedicationForm(event.value);
          }}
          isSearchable={false}
          theme={theme=> SelectTheme(theme)}
          />
          
        </FormControl>


        <FormControl variant="outlined">

          <InputLabel htmlFor="dose">
          <FormattedMessage id='medicationForm.InputLabel.dose'/>
        </InputLabel>

        {checked === false ?
          <StyledSelect 
            options={medDoses}
            onChange={(event) => {
                    setChosenMedicationDose(event.value);
                    setMedicationMeasureType(event.value);
                    setMedicationDose(event.value);
                    setMedicationId(event.value);
                  }}
            isSearchable={false}
            theme={theme => SelectTheme(theme)}
          />
          :
          <TextField
            id="dose"
            disabled={editableRowId !== 'new'}
            required
            fullWidth
            margin="dense"
            type="number"
            min="1"
            name='dose'
            variant="outlined"
            onChange={(event) => {
              medicationForm.handleChange(event);
            }}
            value={medicationForm.values.dose_value}
            onBlur={medicationForm.handleBlur}
            inputProps={{
              autoComplete: 'new-dose',
              form: {
                autoComplete: 'off'
              }
            }}
          />
          }
        </FormControl>

        {checked === true ? 
        <RadioGroup id="measure_type"
                    row aria-label="dose Prefix"
                    name="measure_type"
                    value={measureTypeValue}
                    onChange={(event) => {
                      if (checked === true) {
                        const customMeasureType = event.target.value;
                        medicationForm.setFieldValue('dose_units', customMeasureType)
                        setMeasureTypeValue(customMeasureType)
                      }
                    }}
                    className={classes.formRadioGroup}>
          <FormControlLabel disabled={medicationForm.values.dose_value <= 0} value="mg" control={<Radio color="primary"/>} label="mg"/>
          <FormControlLabel disabled={medicationForm.values.dose_value <= 0} value="ml" control={<Radio color="primary"/>} label="ml"/>
        </RadioGroup>
        :
        null
        }
        
      </div>
      :null}

      
      <div className={classes.row}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={classes.dateTimePicker}
            disableToolbar
            disablePast
            // minDate={(moment().format('YYYY-MM-DD'))}
            name='start_date'
            variant="inline"
            format="dd-MM-yyyy"
            margin="normal"
            id="start_date"
            label="Start Date"
            value={selectedStartDate}
            onChange={(value) => handleStartDateChange(value)}
            KeyboardButtonProps={{
              'aria-label': 'change start date'
            }}
          />
          <KeyboardDatePicker
            className={classes.dateTimePicker}
            disabled={ongoingChecked === true}
            disableToolbar
            minDate={(moment().add(2, 'd')).format('YYYY-MM-DD')}
            name='end_date'
            variant="inline"
            format="dd-MM-yyyy"
            margin="normal"
            id="end_date"
            label="End Date"
            value={ongoingStateForEndDate == "minDate" ? (moment().add(2, 'd')).format('YYYY-MM-DD') : selectedEndDate}
            onChange={(value) => {
              setOngoingStateForEndDate(null)
              handleEndDateChange(value)}}
            KeyboardButtonProps={{
              'aria-label': 'change end date'
            }}
          />
        </MuiPickersUtilsProvider>
        <FormControlLabel
          className={classes.doseCheckBox}
          control={
            <Checkbox
              checked={ongoingChecked}
              onChange={(e) => handleCheckboxChange(e)}
              name="is_ongoing"
              color="primary"
            />
          }
          label={'Ongoing'}
        />
      </div>
      <AddReminder numberOfPills={numberOfPills}
                   reminderList={reminderList}
                   setReminderList={setReminderList}
                   pillsAdded={pillsAdded}
                   setPillsAdded={setPillsAdded}
                   chosenMedicationForm={medicationForm.values.form}
                   setSelectedDose={setSelectedDose}
                   selectedDose={selectedDose}
                   setDosesPerDay={setDosesPerDay}
                   />
      <div className={classes.formActionsWrapper}>
        <button
          disabled = {
            ((chosenMedicationForm === null || pillsAdded === 0 || checked === false ? chosenMedicationDose === null : measureTypeValue === "" || medicationForm.isSubmitting || !medicationForm.isValid ) && editableRowId === 'new') || ((pillsAdded === 0 || medicationForm.isSubmitting) && editableRowId !== 'new' || pillsAdded === 0)
          }
          type="submit"
          className={classes.formActionSubmit}>
          <FormattedMessage id='MedicationForm.button.submit'/>
        </button>
        <button className={classes.formActionCancel} onClick={switchToListMode}>
          <FormattedMessage id='MedicationForm.button.cancel'/>
        </button>
      </div>
      {medicationForm.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default MedicationForm
