import React from 'react'

const SkinRashIcon = () => {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.9 595.3" version="1.1" x="100px" y="100px"  viewBox="0 0 70 97" style={{enableBackground:"new 0 0 70 97"}} >
     <g>
	<path fill="#462d7d" d="M17.6,47.6c-0.2,0.1-0.3,0.1-0.5,0.2c0.7,2.7,1.5,5.6,2.6,8.9c0.7,2.3-0.6,4.8-2.9,5.5
		c-0.4,0.1-0.9,0.2-1.3,0.2h0c-1.4,0-2.7-0.7-3.5-1.8V93c0,3,2.4,5.4,5.4,5.4c3,0,5.4-2.4,5.4-5.4V53.4h2V93c0,3,2.4,5.4,5.4,5.4
		c3,0,5.4-2.4,5.4-5.4V37.7C31.6,41,25.7,44.3,17.6,47.6z M30.5,71.7c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3
		c0,0.7-0.6,1.3-1.3,1.3C31.1,73,30.5,72.4,30.5,71.7z M13.7,72.7c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
		C14.3,74,13.7,73.4,13.7,72.7z M20.2,77.1c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3
		C21.5,76.5,20.9,77.1,20.2,77.1z"/>
	<path fill="#462d7d" d="M15.5,61.6c-1.5,0-2.9-1-3.4-2.5C8.3,46.9,6.9,38.4,6.9,32.2c0-1,0-1.9,0.1-2.8c0.4-4.5,1.6-7.6,3.2-9.6
		c1-1.3,2.1-2,3-2.5c2,2.4,5,3.9,8.3,3.9c3.7,0,6.9-1.8,8.8-4.6h2.1c0.3,0,0.5,0,0.7,0.1c1.3,0.2,2.5,0.6,3.7,1.3
		c1.4,0.8,2.9,2.1,3.9,3.9c0.3,0.5,0.6,1.2,0.8,1.8c0.2,0.8,0.4,1.6,0.4,2.5c0,1.7-0.5,3.5-1.5,5.2c-1,1.7-2.4,3.3-4.3,5
		c-3.9,3.3-9.7,6.8-18.7,10.5c-0.4,0.2-0.9,0.3-1.4,0.3c-1.4,0-2.7-0.8-3.3-2.2c-0.7-1.8,0.1-3.8,1.9-4.6c6.4-2.7,11-5.1,14.2-7.3
		c3.1-2.1,4.8-4,5.5-5.2c0.5-0.8,0.6-1.3,0.6-1.7c0-0.1,0-0.2,0-0.3l-0.9,0.3c0,0.1,0,0.5-0.4,1.3c-0.4,0.7-1.6,2.5-5.2,4.9
		c-3.2,2.2-7.5,4.4-12.9,6.7c-0.4-2.9-0.6-5.2-0.6-6.8c0-1.6,0.1-2.9,0.3-4l-0.9,0.1c-0.2,1-0.3,2.3-0.3,3.9c0,2,0.2,4.3,0.6,7.2
		c-0.1,0-0.2,0.1-0.3,0.1c-1.1,0.4-1.9,1.3-2.4,2.4c-0.4,0.9-0.4,1.9-0.2,2.9c-0.2-0.1-0.5-0.2-0.7-0.2c-0.7,0-1.3,0.6-1.3,1.3
		s0.6,1.3,1.3,1.3c0.7,0,1.2-0.5,1.3-1.2c0.8,1.2,2.2,1.9,3.6,1.9c0.1,0,0.2,0,0.3,0c0.7,2.7,1.5,5.7,2.6,9c0.6,1.8-0.4,3.8-2.3,4.4
		C16.2,61.5,15.9,61.6,15.5,61.6z M10.6,36.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3S10.6,35.9,10.6,36.7z
		 M15.1,50.1c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3S15.1,50.8,15.1,50.1z"/>
	<ellipse fill="#462d7d" cx="21.5" cy="10.4" rx="9.9" ry="9.9"/>
	<path fill="#462d7d" d="M4.6,43.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.5-0.1-0.6c0,0,0,0-0.1-0.1
		c-0.3-0.2-0.8-0.9-0.8-1.6c0-0.4,0.1-0.9,0.6-1.6c0.1-0.2,0.1-0.5-0.1-0.6c-0.2-0.2-0.5-0.1-0.6,0.1c-0.6,0.7-0.8,1.5-0.8,2.1
		C3.3,42.6,4.5,43.4,4.6,43.5z"/>
	<path fill="#462d7d" d="M2.8,44.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.3-0.2c0.2-0.2,0.1-0.5-0.1-0.6c0,0-0.1-0.1-0.2-0.2
		c-0.4-0.4-1.2-1.4-1.2-2.6c0-0.8,0.3-1.7,1.2-2.7c0.2-0.2,0.1-0.5,0-0.6c-0.2-0.2-0.5-0.1-0.6,0c-1,1.2-1.4,2.3-1.4,3.3
		C1.1,43.3,2.7,44.5,2.8,44.6z"/>
	<path fill="#462d7d" d="M6.6,56c-0.2,0-0.5,0.1-0.5,0.3C6.1,56.6,6,56.9,6,57.2c0,1.2,0.7,2.1,1.3,2.5c0.6,0.4,1.2,0.5,1.3,0.5
		c0,0,0,0,0.1,0c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-0.5-0.4-0.5h0c0,0-0.1,0-0.3-0.1c-0.6-0.2-1.6-0.7-1.6-2c0-0.2,0-0.4,0.1-0.7
		C7,56.3,6.9,56,6.6,56z"/>
	<path fill="#462d7d" d="M5,56.3c0.1-0.2-0.1-0.5-0.3-0.5c-0.2-0.1-0.5,0.1-0.5,0.3C4,56.7,4,57.2,4,57.7c0,1.8,0.9,2.9,1.8,3.5
		C6.6,61.8,7.5,62,7.5,62c0,0,0.1,0,0.1,0C7.8,62,8,61.9,8,61.7c0.1-0.2-0.1-0.5-0.3-0.5v0c0,0-0.2,0-0.4-0.1
		c-0.8-0.3-2.4-1.2-2.4-3.3C4.9,57.3,4.9,56.8,5,56.3z"/>
</g>
<path fill="#FFFFFF" d="M27.4,76.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3S27.4,75.8,27.4,76.5z"/>
<path fill="#FFFFFF"  d="M31.8,78.2c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3C32.4,76.9,31.8,77.5,31.8,78.2z
	"/>
<path fill="#FFFFFF"  d="M15.5,80.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3S15.5,80,15.5,80.7z"/>
<path fill="#FFFFFF"  d="M9.9,41.5c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3C11.3,40.9,10.7,41.5,9.9,41.5z"/>

      </svg>
 
  )
}

export default SkinRashIcon
