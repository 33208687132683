import React from 'react'
import {Grid} from '@material-ui/core'
import {useStyles} from './Styles'
import {FormattedMessage} from 'react-intl'
import Item from './Item'
import StreakItem from './StreakItem'
import {ProfileConsumer} from '../../../../../ProfileContext'
import UpcomingMedicationsFrom from './UpcomingMedicationsFrom'
const UpcomingMedicationsTab = ({loadMedicationSchedule,medicationSchedule}) => {
  const classes = useStyles()
  
  React.useEffect(() => {
    if (!!loadMedicationSchedule) {
      loadMedicationSchedule();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])


  return (
    <ProfileConsumer>
      {({  medicationSchedule }) => (
          <UpcomingMedicationsFrom medicationSchedule={medicationSchedule}/>
      )}
    </ProfileConsumer>
  )
}

export default UpcomingMedicationsTab
