import { fade, makeStyles } from '@material-ui/core/styles'
import { Directions } from '@material-ui/icons'

export const useStyles = makeStyles((theme) => ({
  MedicationTab: {
    display: 'flex',
    flexDirection: 'column',
      justifyContent: 'flex-start',
    '& .MuiAlert-root': {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2)
    }
  },
  actionButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#7c7c7c',
    '&.delete&:hover': {
      color: theme.palette.error.main
    },
    '&.edit&:hover': {
      color: theme.palette.primary.main
    }
  },
  itemTableWrapper: {
    width: '100%',
    '& .MuiTableCell-head': {
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: `2px solid rgba(224, 224, 224, 1)`
    }
  },
  itemTableContainer: {
    // maxHeight: 440,
    '&.MuiTableCell-stickyHeader': {
      backgroundColor: 'transparent'
    },
    '& .MuiTableRow-root': {
      verticalAlign: 'top'
    },
    '& .MuiTableCell-body': {
      fontWeight: theme.typography.fontWeightLight
    },
    '& .MuiTableCell-root': {
      textAlign: 'center',
      '&:nth-of-type(7n)': {
        '& div': {
          display: 'flex',
          justifyContent: 'center'
        }
      }
    },
    '& .MuiTableCell-root.MuiTableCell-body span': {
      display: 'flex',
      marginBottom: theme.spacing(0.5),
      textTransform: 'capitalize',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
        color: '#7c7c7c'
      }
    }
  },
  listActionsWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    '& button': {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5)
    }
  },
  listActionSubmit: {
    padding: theme.spacing(0.875, 4),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    border: 'none',
    borderRadius: 0,
    textTransform: 'unset',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:disabled': {
      opacity: 0.7,
      cursor: 'not-allowed'
    }
  },
  MedicationForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    maxWidth: 1000,
    '& h3': {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(0),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightLight,
      '@media (max-width: 815px)': {
        fontSize: "1.125rem",
      },
    },
    '& p': {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(0),
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightLight,
      '@media (max-width: 815px)': {
        fontSize: "1.125rem",
      },
    },
    '& .TotalPillsPerDay': {
      marginBottom: theme.spacing(4),
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(16),
      '@media (max-width: 815px)': {
        fontSize: "1.125rem",
      },
    },
    '& .EditModeLabels': {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 600,
      '@media (max-width: 815px)': {
        fontSize: "1.2rem",
      },
    },
    '& .EditModeInfos': {
      fontSize: theme.typography.pxToRem(18),
      '@media (max-width: 815px)': {
        fontSize: "1.125rem",
      },
    },
    '& .EditModeLastInfo': {
      marginBottom: theme.spacing(5),
    },
    '& .MuiInputBase-root': {
      '& .MuiIconButton-root': {
        padding: 10
      },
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: 19,
      paddingBottom: 19,
      paddingRight: 0,
      paddingLeft: 19
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 0
    },
    '& .MuiFormControl-root': {
      width: 476,
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginRight: theme.spacing(0)
      },
      '@media (max-width: 815px)': {
        width: 530,
      },
    },
    '& .MuiInputLabel-formControl': {
      top: 0,
      left: 0,
      position: 'relative',
      transform: 'none',
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightLight,
      '@media (max-width: 815px)': {
        fontSize: "1.125rem",
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[300]
    }
  },
  MedicationsFormTitle: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.primary,
    margin: theme.spacing(0, 0, 4, 0)
  },
  formRadioGroup: {
    alignItems: 'flex-start',
    marginTop: theme.spacing(2.75),
    '& .MuiFormControlLabel-root:last-of-type': {
      margin: 0
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0)
    }
  },
  formActionsWrapper: {
    textAlign: 'center',
    paddingTop: theme.spacing(4)
  },
  formActionSubmit: {
    padding: theme.spacing(1.5, 4),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    border: 'none',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:disabled': {
      opacity: 0.7,
      cursor: 'not-allowed'
    }
  },
  formActionCancel: {
    padding: theme.spacing(1.5, 4),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    border: 'none',
    background: 'transparent',
    color: '#7c7c7c',
    textDecoration: 'underline'
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: theme.spacing(4),
    '& .MuiFormControl-root': {
      width: 170,
      marginBottom: theme.spacing(0),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: theme.spacing(2)
      },
      '@media (max-width: 815px)': {
        width: 200,
      },
    }
  },

  dateTimePicker: {
    marginTop: theme.spacing(0),
    '&.disabled': {
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 50,
        backgroundColor: fade(theme.palette.common.white, 0.5)
      }
    },
    '& .MuiIconButton-root': {
      paddingRight: 0
    },
    '& .MuiInputBase-root': {
      marginTop: theme.spacing(1),
      border: `1px solid #cecece`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1, 1),
      '&:before,&:after': {
        display: 'none'
      }
    }
  },
  doseCheckBox: {
    marginTop: theme.spacing(2.75),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0)
    },
    '@media (max-width: 815px)': {
      fontSize: "1.125rem",
    },
  },
  addReminder: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    '&.disabled:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 50,
      backgroundColor: fade(theme.palette.common.white, 0.5)
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    },
    '& $formActionSubmit': {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1.25, 4),
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
      },
      '&:disabled': {
        opacity: 0.7,
        cursor: 'not-allowed'
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  addReminderProgressWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiLinearProgress-root': {
      height: 25,
      width: 256,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[300]}`
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.primary.light
    }
  },
  addReminderProgress: {
    position: 'relative'
  },
  addReminderProgressValue: {
    position: 'absolute',
    top: '50%',
    left: '100%',
    paddingLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    transform: 'translate(0,-50%)',
    color: theme.palette.primary.light,
    zIndex: 2,
    '&.whiteColor': {
      color: theme.palette.common.white
    }
  },
  addReminderTitle: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightLight,
    '@media (max-width: 815px)': {
      fontSize: "1.125rem",
    },
  },
  reminderListRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2, 2, 2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    background: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(0, 0, 2, 0)
    }
  },
  reminderListDose: {
    display: 'flex',
    width: 160,
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    '@media (max-width: 815px)': {
      fontSize: "1.125rem",
    },
  },
  reminderListTime: {
    display: 'flex',
    alignItems: 'center',
    width: 160,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1)
    },
    '@media (max-width: 815px)': {
      fontSize: "1.125rem",
    },
  },
  deleteButtonWrapper: {
    width: 100,
    textAlign: 'right'
  },
  deleteButton: {
    background: 'transparent',
    border: 0,
    color: '#7c7c7c',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    '&:hover': {
      color: theme.palette.error.main
    },
  },
  RemindersTimeWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 4fr'
  },
  RemindersTimeIcon: {
    color: '#7c7c7c',
  },
  RemindersTime: {
    textTransform: 'capitalize',
    color: '#474747',
    textAlign: 'left',
    marginLeft: '7px'
  },
  NoMedsWarningLabelContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "16px",
    margin: "40px 0 20px 0",
    color: "#8b8b8b",
  }
}))
