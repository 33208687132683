import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  PatientProfileTabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 0px 0px 2px ${theme.palette.grey[200]}`,
    '& .MuiTabs-flexContainer': {
      borderBottom: `1px solid ${theme.palette.text.primary}`
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.text.primary,
      '&.Mui-selected': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiTab-root': {
      textTransform: 'capitalize',
      minHeight: 80
    },
    '& .tab': {
      padding: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(5)
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2)
      }
    }
  }
}))
