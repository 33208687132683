import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'inline-block',
    '& a': {
      display: 'block',
      // flex: '0 0 auto',
      width: 210,
      [theme.breakpoints.down('xs')]: {
        width: 170
      }
    }
  }
}))
