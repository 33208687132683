import React from 'react'
import SignUpForm from './Form'
import AuthLogo from '../AuthLogo'
import { AuthConsumer } from '../../../AuthContext'
import VerifyCard from '../VerifyCard'

const SignUpCard = () => {
  return (
    <AuthConsumer>
      {({ handleSignUpSubmit, registerSuccess }) => (
        !!registerSuccess ? <VerifyCard/> :
          <>
            <AuthLogo/>
            <SignUpForm onSubmit={handleSignUpSubmit}/>
          </>
      )}
    </AuthConsumer>
  )
}

export default SignUpCard
