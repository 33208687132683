import { log } from '../../utils/helpers'
import bcrypt from 'bcryptjs'

export const UserLoginPayload = (formData) => {
  log('UserLoginPayload', formData)
  return {
    'email': formData.email,
    'password': formData.password
  }
}

export const UserTokenLoginPayload = (formData) => {
  log('UserTokenLoginPayload', formData)
  return {
    'ring_sn': formData.ring_sn,
    'verification_token': formData.verification_token
  }
}

export const UserOrganizationTokenLoginPayload = (formData) => {
  log('UserOrganizationTokenLoginPayload', formData)
  return {
    'organization_token': formData.organization_token,
    'verification_token': formData.verification_token,
    'patient_id': formData.patient_id,
    'organization_id': formData.organization_id
  }
}

export const UserForgotPasswordPayload = (formData) => {
  log('UserForgotPasswordPayload', formData)
  return {
    'email': formData.email
  }
}
export const UserResetPasswordPayload = (code, formData) => {
  log('UserResetPasswordPayload', formData)
  let salt = bcrypt.genSaltSync()
  let hash = bcrypt.hashSync(formData.password, salt)
  return {
    'password': hash,
    'password_salt': salt,
    'reset_code': code
  }
}
export const UserSignUpPayload = (formData) => {
  log('UserLoginPayload', formData)
  let salt = bcrypt.genSaltSync()
  let hash = bcrypt.hashSync(formData.password, salt)

  return {
    'first_name': formData.firstName,
    'last_name': formData.lastName,
    'email': formData.email,
    'mobile_number': formData.mobileNumber ? '+' + formData.mobileNumber : '',
    'emergency_contact_name': formData.emergencyContactName,
    'emergency_contact_mobile_number': formData.emergencyContactMobileNumber ? '+' + formData.emergencyContactMobileNumber : '',
    'password': hash,
    'salt': salt,
    'registration_timestamp': Date.now()
  }
}

export const UserSeizuresPayload = (email, formData) => {
  log('UserSeizuresPayload', formData)

  return {
    'email': email,
    'custom_names': {
      'aura_1_name': !!formData.aura1 ? formData.aura1Label : '',
      'aura_2_name': !!formData.aura2 ? formData.aura2Label : '',
      'partial_1_name': !!formData.focalPartial1 ? formData.focalPartial1Label : '',
      'partial_2_name': !!formData.focalPartial2 ? formData.focalPartial2Label : '',
      'tonic_clonic_name': !!formData.tonicClonic ? formData.tonicClonicLabel : '',
      'absence_name': !!formData.absence ? formData.absenceLabel : '',
      'myoclonic_name': !!formData.myoClonic ? formData.myoClonicLabel : '',
      'tonic_name': !!formData.tonic ? formData.tonicLabel : '',
      'atonic_name': !!formData.atonic ? formData.atonicLabel : '',
      'spasm_name': !!formData.spasm ? formData.spasmLabel : ''
    },
    'seizure_types': {
      'aura_1': formData.aura1,
      'aura_2': formData.aura2,
      'partial_1': formData.focalPartial1,
      'partial_2': formData.focalPartial2,
      'tonic_clonic': formData.tonicClonic,
      'absence': formData.absence,
      'myoclonic': formData.myoClonic,
      'tonic': formData.tonic,
      'atonic': formData.atonic,
      'spasm': formData.spasm
    }
  }

}

export const UserEmergencyContactPayload = (email, formData) => {
  log('UserEmergencyContactPayload', formData)

  return {
    'email': email,
    'contact_name': formData.contact_name || '',
    'contact_mobile': formData.contact_mobile ? '+' + formData.contact_mobile : ''
  }
}

export const UserRemoveEmergencyContactPayload = (email, contactID) => {
  log('UserRemoveEmergencyContactPayload', contactID)

  return {
    'email': email,
    'contact_id': contactID
  }
}

export const UserMedicationsPayload = (email, medications) => {
  log('UserMedicationsPayload', medications)

  return {
    'email': email,
    'medications': medications
  }
}

export const UserAddMedicationPayload = (editableRowId, userId, formData) => {
  
  log('UserAddMedicationsPayload', formData)
  let userObj = { 'userId': userId }
  if (!!editableRowId && editableRowId !== 'new') {
    userObj = { 'userId': userId }
  }
  const reminders = formData.reminders.map(function(reminder){
    return {
      num_of_doses : parseFloat(reminder.num_of_doses),
      dose_time : reminder.dose_time}
  })

  const brand_name = formData.brand_name == null ? formData.generic_name : formData.brand_name

  return {
    ...userObj,
    'medication_id': formData.medication_id,
    'brand_name': brand_name,
    'doses_per_day': formData.doses_per_day,
    'start_date': formData.start_date,
    'end_date': formData.end_date,
    'is_ongoing': formData.is_ongoing,
    'generic_name': formData.generic_name,
    'dose': formData.dose,
    'dose_units': formData.dose_units,
    'form': formData.form,
    'reminders': reminders,
  }
}

export const UserSortEmergencyContactPayload = (email, contactsList) => {
  log('UserSortEmergencyContactPayload', contactsList)
  let contacts = []
  contactsList.forEach((contact, index) => {
    contacts.push([contact.contact_id, index + 1])
  })
  return {
    'email': email,
    'contacts_ids_and_order_indexes': contacts
  }
}

export const RequestHelpPayload = (userId) => {
  log('requestHelpPayload')
  return {
    'userId' : userId,
    "timestamp": "2021-07-30T14:00:00Z",
    "device_type": "browser",
    "latitude": null,
    "longitude": null
  }
}