import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { sleep } from '../../../utils/pages-meta.json'
import { NamedRoutes } from '../../../routes'
import AccountLayout from '../AccountLayout'
import SleepContent from '../../../components/Account/SleepContent'

const Sleep = () => {
  return (
    <>
      <SEO title={sleep.title}
           pathname={window.location.origin + NamedRoutes.account.sleep}
           titleTemplate='Novela'
           image={sleep.image}
           description={sleep.description}
      />
      <AccountLayout content={<SleepContent/>}/>
    </>
  )
}

export default Sleep
