import React, { useRef } from 'react'

import CanvasJSReact from './canvasjs/canvasjs.stock.react'
import ProfileServices from '../../../Services/Consumers/ProfileServices'
import { useStyles } from './Styles'
import { FormLoader } from '../../Loader/FormLoader'

const DayChartsWithDate = ({ userId, userEmail, dayChartsFilterDate }) => {
  const classes = useStyles()
  const [ringDataFailure, setRingDataFailure] = React.useState(false)
  const [hasHeartrateData, setHasHeartrateData] = React.useState(false)
  const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart
  let heartRateDataPoints = [],
    spo2DataPoints = [],
    motionDataPoints = [],
    midNightDate = new Date(dayChartsFilterDate),
    currentDayDate = new Date(),
    rangeChangedTriggered = false
  midNightDate.setHours(0, 0, 0, 0)
  heartRateDataPoints.push({ x: midNightDate.getTime(), y: 0 })
  spo2DataPoints.push({ x: midNightDate.getTime(), y: 0 })
  motionDataPoints.push({ x: midNightDate.getTime(), y: 0 })

  let heartRateChartRef = useRef(null)

  const heartRateContainerProps = {
    width: '100%',
    height: '250px',
    margin: 'auto'
  }
  let heartRateOptions = {
    theme: 'light2',
    rangeChanged: function(e) {
      rangeChangedTriggered = true
    },
    charts: [{
      axisX: {
        crosshair: {
          enabled: true,
          valueFormatString: 'MMM DD, YYYY HH:mm:ss'
        }
      },
      axisY: {
        title: 'Heart Rate',
        maximum: 200,
        minimum: 0
      },
      toolTip: {
        shared: true
      },
      data: [{
        type: 'spline',
        name: 'Heart Rate',
        lineColor: '#462d7d',
        color: '#462d7d',
        xValueFormatString: 'MMM DD, YYYY HH:mm:ss',
        xValueType: 'dateTime',
        dataPoints: heartRateDataPoints
      }]
    }],
    navigator: {
      slider: {
        minimum: new Date(currentDayDate.getTime())
      }
    },
    rangeSelector: {
      enabled: false
    }
  }

  let timeFrom = midNightDate.getTime() / 1000

  const updateChart = (tfrom, reset) => {
    let currentDate = new Date(dayChartsFilterDate)
    currentDate.setHours(23, 59, 59, 999)
    let currentTime = Math.round(currentDate.getTime() / 1000)
    let needHeartrateUpdate = false
    let needRingdataUpdate = false
    let data = {
      'timestamp_from': tfrom,
      'timestamp_to': currentTime,
      'patient_id': userId
    }


    ProfileServices.loadHeartRate(userEmail, tfrom, currentTime, null, setRingDataFailure, null).then((result) => {
      if (!!result && !!result.data) {
        if (!((!!result.status && ![200, 201].includes(result.status)) || !!result.data.exception)) {
          result.data.forEach((rData) => {
            if (!!reset) {
              heartRateDataPoints[0] = { x: Date.parse(rData.timestamp), y: rData.heartrate/1 }
              reset = false
            } else {
              heartRateDataPoints.push({ x: Date.parse(rData.timestamp), y: rData.heartrate/1 })
            }
          })
          needHeartrateUpdate = true
        }
      }
    }).then(() => {
      if (!!heartRateDataPoints.length) {
        if (!!heartRateChartRef.current) {
          heartRateChartRef.current.render()
        }
        needHeartrateUpdate = false
      }
      setHasHeartrateData(true)
    })
  }
  updateChart(timeFrom, true)


  return (
    <div>
      <div className={classes.chartWrapper}>
        {!hasHeartrateData && <FormLoader loading={true}/>}
        <CanvasJSStockChart containerProps={heartRateContainerProps} options={heartRateOptions}
                            onRef={ref => heartRateChartRef.current = ref}
        />
      </div>
    </div>

  )
}
export default DayChartsWithDate
