import React from 'react'
import UserServices from './Services/Consumers/UserServices'
import {
  UserForgotPasswordPayload,
  UserLoginPayload,
  UserTokenLoginPayload,
  UserOrganizationTokenLoginPayload,
  UserResetPasswordPayload,
  UserSignUpPayload
} from './Services/Payloads/UserPayload'
import { LocalStorageService } from './Services/LocalStorageService'
import Configuration from './Services/Api/Configuration'
import { NamedRoutes } from './routes'
import moment from 'moment';
const AuthContext = React.createContext({})

const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = React.useState(Configuration.isAuth())

  const [userInfo, setUserInfo] = React.useState({})
  const [userInfoLoading, setUserInfoLoading] = React.useState(false)
  const [userInfoFailure, setUserInfoFailure] = React.useState(false)

  const [patientInfo, setPatientInfo] = React.useState({})
  const [patientInfoLoading, setPatientLoading] = React.useState(false)
  const [patientInfoFailure, setPatientFailure] = React.useState(false)

  const [watchData, setWatchData] = React.useState({})
  const [watchDataLoading, setWatchDataLoading] = React.useState(false)
  const [watchDataFailure, setWatchDataFailure] = React.useState(false)

  const [registerEndPointFailure, setRegisterEndPointFailure] = React.useState(false)
  const [registerFailure, setRegisterFailure] = React.useState(false)
  const [registerSuccess, setRegisterSuccess] = React.useState(false)

  const [loginEndPointFailure, setLoginEndPointFailure] = React.useState(false)
  const [loginFailure, setLoginFailure] = React.useState(false)
  const [loginSuccess, setLoginSuccess] = React.useState(false)
  const [loginSuccessRedirect, setLoginSuccessRedirect] = React.useState(false)

  const [tokenLoginEndPointFailure, setTokenLoginEndPointFailure] = React.useState(false)
  const [tokenLoginFailure, setTokenLoginFailure] = React.useState(false)
  const [tokenLoginSuccess, setTokenLoginSuccess] = React.useState(false)
  const [tokenLoginSuccessRedirect, setTokenLoginSuccessRedirect] = React.useState(false)


  const [organizationTokenLoginEndPointFailure, setOrganizationTokenLoginEndPointFailure] = React.useState(false)
  const [organizationTokenLoginFailure, setOrganizationTokenLoginFailure] = React.useState(false)
  const [organizationTokenLoginSuccess, setOrganizationTokenLoginSuccess] = React.useState(false)
  const [organizationTokenLoginSuccessRedirect, setOrganizationTokenLoginSuccessRedirect] = React.useState(false)

  React.useEffect(() => {
      if (!!isAuth) {
        LocalStorageService.get('PatientID').then((result) => {
          if (!!result) {
            getPatientInfo(result)
          }
        })
        LocalStorageService.get('UserEmail').then((result) => {
          if (!!result) {
            getUserPersonalInfo(result)
          }
        })
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuth])

  const getWatchData = () => {
    LocalStorageService.get('UserEmail').then((email) => {
        if (!!email) {
          setWatchDataFailure(false)
          setWatchDataLoading(true)
          UserServices.watchData(email, setWatchDataLoading, setWatchDataFailure, null).then((result) => {
            if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
              setWatchDataFailure(true)
            } else {
              if (!!result && !!result.data) {
                if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
                  setWatchDataFailure(true)
                } else {
                  setWatchData(result.data)
                }
              }
            }
          }).then(() => {
            setWatchDataLoading(false)
          })
        }
      }
    )
  }
  
  const getUserPersonalInfo = (email) => {
    if (!Object.keys(userInfo).length) {
      setUserInfoFailure(false)
      setUserInfoLoading(true)
      UserServices.userInfo(email, setUserInfoLoading, setLoginFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setUserInfoFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.patient) {
            setUserInfo({
              'id': result.data.patient[0] || 0,
              'firstName': result.data.patient[1] || '',
              'lastName': result.data.patient[2] || '',
              'email': result.data.patient[3] || '',
              'mobileNumber': result.data.patient[4] || '',
              'password': result.data.patient[5] || '',
              'salt': result.data.patient[6] || '',
              'isActive': result.data.patient[7] || false,
              'registration_date': result.data.patient[8] || null,
              'emergencyContactMobileNumber': result.data.patient[9] || ''
            })
          } else {
            setUserInfoFailure(true)
          }
        }
      }).then(() => {
        setUserInfoLoading(false)
      })
    }
  }


  
  const getPatientInfo = (userID) => {
    if (!Object.keys(userInfo).length) {
      setPatientFailure(false)
      setPatientLoading(true)
      UserServices.getUserInfo(userID, setPatientLoading, setPatientFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setPatientFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.Personal_info) {
            const timestamp = moment(result.data.Personal_info[10], 'ddd, DD MMM YYYY HH:mm:ss [GMT]');
            setPatientInfo({
              'firstName': result.data.Personal_info[0] || '',
              'lastName': result.data.Personal_info[1] || '',
              'email': result.data.Personal_info[2] || '',
              'mobileNumber': result.data.Personal_info[3] || '',
              'gender': result.data.Personal_info[5] || '',
              'weight': result.data.Personal_info[6] || '',
              'weightUnit': result.data.Personal_info[7] || '',
              'height': result.data.Personal_info[8] || '',
              'heightUnit': result.data.Personal_info[9] || '',
              'birthday': timestamp.format("YYYY-MM-DD") || '',
              'month': timestamp.format("MMM") || '',
              'day': timestamp.format("DD") || '',
              'year': timestamp.format("YYYY") || '',
              'timezone': result.data.Personal_info[11] || '',
              'bedtime':moment.utc(moment.duration(+result.data.Personal_info[4], "minutes").asMilliseconds()).format("HH:mm")   
              // 'bedtime':bedtime ||  result.data.Personal_info[4] || ''                 
            })
         
          } else {
            setPatientFailure(true)
          }
        }
      }).then(() => {
        setPatientLoading(false)
      })
    }
  }

  const getPatientAccountInfo = (userID) => {
      if(userID != null){
        setPatientFailure(false)
        setPatientLoading(true)
        UserServices.getUserInfo(userID, setPatientLoading, setPatientFailure, null).then((result) => {
          if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
            setPatientFailure(true)
          } else {
            if (!!result && !!result.data && !!result.data.Personal_info) {
              const timestamp = moment(result.data.Personal_info[10], 'ddd, DD MMM YYYY HH:mm:ss [GMT]');
              setPatientInfo({
                'firstName': result.data.Personal_info[0] || '',
                'lastName': result.data.Personal_info[1] || '',
                'email': result.data.Personal_info[2] || '',
                'mobileNumber': result.data.Personal_info[3] || '',
                'gender': result.data.Personal_info[5] || '',
                'weight': result.data.Personal_info[6] || '',
                'weightUnit': result.data.Personal_info[7] || '',
                'height': result.data.Personal_info[8] || '',
                'heightUnit': result.data.Personal_info[9] || '',
                'birthday': timestamp.format("YYYY-MM-DD") || '',
                'month': timestamp.format("MMM") || '',
                'day': timestamp.format("DD") || '',
                'year': timestamp.format("YYYY") || '',
                'timezone': result.data.Personal_info[11] || '',
                'bedtime':moment.utc(moment.duration(+result.data.Personal_info[4], "minutes").asMilliseconds()).format("HH:mm")   
                // 'bedtime':bedtime ||  result.data.Personal_info[4] || ''                 
              })
          
            } else {
              setPatientFailure(true)
            }
          }
        }).then(() => {
          setPatientLoading(false)
        })
    }
  }

  const handleLoginSubmit = (values, FormikActions) => {
    setLoginEndPointFailure(false)
    setLoginFailure(false)
    setLoginSuccess(false)
    setLoginSuccessRedirect(false)
    FormikActions.setSubmitting(true)

    UserServices.login(UserLoginPayload(values), null, setLoginFailure, FormikActions).then((result) => {
      if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
        setLoginFailure(false)
        setLoginSuccess(false)
        setLoginEndPointFailure(true)
      } else {
        if (!!result && !!result.data) {
          if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
            setLoginEndPointFailure(false)
            setLoginSuccess(false)
            setLoginFailure(true)
          } else {
            setLoginEndPointFailure(false)
            setLoginFailure(false)
            setLoginSuccess(true)
            LocalStorageService.set('IsAuthenticated', true)
            LocalStorageService.set('PatientID', result.data.patient_id)
            LocalStorageService.set('UserEmail', result.data.Email).then(() => {
              setTimeout(() => setLoginSuccessRedirect(true), 300)
              setIsAuth(true)
            })
            
          }
        }
      }
    }).then(() => {
      FormikActions.setSubmitting(false)
    })
  }

  const handleTokenLoginSubmit = (values, FormikActions) => {
    setTokenLoginEndPointFailure(false)
    setTokenLoginFailure(false)
    setTokenLoginSuccess(false)
    setTokenLoginSuccessRedirect(false)
    FormikActions.setSubmitting(true)

    UserServices.tokenLogin(UserTokenLoginPayload(values), null, setTokenLoginFailure, FormikActions).then((result) => {
      if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
        setTokenLoginFailure(false)
        setTokenLoginSuccess(false)
        setTokenLoginEndPointFailure(true)
      } else {
        if (!!result && !!result.data) {
          if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
            setTokenLoginEndPointFailure(false)
            setTokenLoginSuccess(false)
            setTokenLoginFailure(true)
          } else {
            setTokenLoginEndPointFailure(false)
            setTokenLoginFailure(false)
            setTokenLoginSuccess(true)
            LocalStorageService.set('IsAuthenticated', true)
            LocalStorageService.set('PatientID', result.data.patient_id)
            LocalStorageService.set('UserEmail', result.data.email).then(() => {
              setTimeout(() => setTokenLoginSuccessRedirect(true), 300)
              setIsAuth(true)
            })
          }
        }
      }
    }).then(() => {
      FormikActions.setSubmitting(false)
    })
  }


  const handleOrganizationTokenLoginSubmit = (values, FormikActions) => {
    setOrganizationTokenLoginEndPointFailure(false)
    setOrganizationTokenLoginFailure(false)
    setOrganizationTokenLoginSuccess(false)
    setOrganizationTokenLoginSuccessRedirect(false)
    FormikActions.setSubmitting(true)

    

    UserServices.organizationTokenLogin(UserOrganizationTokenLoginPayload(values), null, setOrganizationTokenLoginFailure, FormikActions).then((result) => {
      if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
        setOrganizationTokenLoginFailure(false)
        setOrganizationTokenLoginSuccess(false)
        setOrganizationTokenLoginEndPointFailure(true)
      } else {
        if (!!result && !!result.data) {
          if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
            setOrganizationTokenLoginEndPointFailure(false)
            setOrganizationTokenLoginSuccess(false)
            setOrganizationTokenLoginFailure(true)
          } else {
            setOrganizationTokenLoginEndPointFailure(false)
            setOrganizationTokenLoginFailure(false)
            setOrganizationTokenLoginSuccess(true)
            LocalStorageService.set('IsAuthenticated', true)
            LocalStorageService.set('PatientID', result.data.patient_id)
            LocalStorageService.set('UserEmail', result.data.email).then(() => {
              setTimeout(() => setOrganizationTokenLoginSuccessRedirect(true), 300)
              setIsAuth(true)
            })
          }
        }
      }
    }).then(() => {
      FormikActions.setSubmitting(false)
    })
  }


  const [forgotPasswordFailure, setForgotPasswordFailure] = React.useState(false)
  const [forgotPasswordFailureMessage, setForgotPasswordFailureMessage] = React.useState(false)
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = React.useState(false)

  const handleForgotPasswordSubmit = (values, FormikActions) => {
    setForgotPasswordFailure(false)
    setForgotPasswordSuccess(false)
    FormikActions.setSubmitting(true)

    UserServices.forgotPassword(UserForgotPasswordPayload(values), null, setLoginFailure, FormikActions).then((result) => {
      if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
        if (result.response.status === 402) {
          setForgotPasswordFailureMessage(result.response.data)
        }
        setForgotPasswordFailure(true)
        setForgotPasswordSuccess(false)
      } else {
        if (!!result && !!result.data) {
          if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
            setForgotPasswordFailure(true)
            setForgotPasswordSuccess(false)

          } else {
            setForgotPasswordFailure(false)
            setForgotPasswordSuccess(true)
          }
        }
      }
    }).then(() => {
      FormikActions.setSubmitting(false)
    })
  }

  const [resetPasswordFailure, setResetPasswordFailure] = React.useState(false)
  const [resetPasswordFailureMessage, setResetPasswordFailureMessage] = React.useState(false)
  const [resetPasswordSuccess, setResetPasswordSuccess] = React.useState(false)
  const [resetPasswordSuccessRedirect, setResetPasswordSuccessRedirect] = React.useState(false)

  const handleResetPasswordSubmit = (code, values, FormikActions) => {
    setResetPasswordFailure(false)
    setResetPasswordSuccess(false)
    FormikActions.setSubmitting(true)

    UserServices.resetPassword(values.email, UserResetPasswordPayload(code, values), null, setLoginFailure, FormikActions).then((result) => {
      if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
        if (result.response.status === 402) {
          setResetPasswordFailureMessage(result.response.data)
        }
        setResetPasswordFailure(true)
        setResetPasswordSuccess(false)
      } else {
        if (!!result && !!result.data) {
          if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
            setResetPasswordFailure(true)
            setResetPasswordSuccess(false)

          } else {
            setResetPasswordFailure(false)
            setResetPasswordSuccess(true)
            setTimeout(() => setResetPasswordSuccessRedirect(true), 1000)
          }
        }
      }
    }).then(() => {
      FormikActions.setSubmitting(false)
    })
  }

  const handleSignUpSubmit = (values, FormikActions) => {
    setRegisterEndPointFailure(false)
    setRegisterFailure(false)
    setRegisterSuccess(false)
    FormikActions.setSubmitting(true)

    UserServices.register(UserSignUpPayload(values), null, setRegisterFailure, FormikActions).then((result) => {
      if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
        setRegisterEndPointFailure(true)
      } else {
        if (!!result && !!result.data) {
          if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
            setRegisterFailure(false)
          } else {
            setRegisterSuccess(true)
          }
        }
      }
    }).then(() => {
      FormikActions.setSubmitting(false)
    })
  }

  const handleLogout = () => {
    LocalStorageService.removeItem('IsAuthenticated')
    LocalStorageService.removeItem('PatientID')
    LocalStorageService.removeItem('UserEmail').then(() => {
      window.location = NamedRoutes.home
    })
  }


  return (
    <AuthContext.Provider value={{
      loginEndPointFailure: loginEndPointFailure,
      loginFailure: loginFailure,
      loginSuccess: loginSuccess,
      loginSuccessRedirect: loginSuccessRedirect,
      handleLoginSubmit,

      tokenLoginEndPointFailure: tokenLoginEndPointFailure,
      tokenLoginFailure: tokenLoginFailure,
      tokenLoginSuccess: tokenLoginSuccess,
      tokenLoginSuccessRedirect: tokenLoginSuccessRedirect,
      handleTokenLoginSubmit,

      organizationTokenLoginEndPointFailure: organizationTokenLoginEndPointFailure,
      organizationTokenLoginFailure: organizationTokenLoginFailure,
      organizationTokenLoginSuccess: organizationTokenLoginSuccess,
      organizationTokenLoginSuccessRedirect: organizationTokenLoginSuccessRedirect,
      handleOrganizationTokenLoginSubmit,

      registerEndPointFailure: registerEndPointFailure,
      registerFailure: registerFailure,
      registerSuccess: registerSuccess,
      handleSignUpSubmit,
      
      getPatientInfo,
      patientInfo:patientInfo,
      patientInfoLoading:patientInfoLoading,
      patientInfoFailure:patientInfoFailure,
      setPatientInfo,
      getPatientAccountInfo,
   

      userInfoLoading: userInfoLoading,
      userInfoFailure: userInfoFailure,
      userInfo: userInfo,

      getWatchData,
      watchDataLoading: watchDataLoading,
      watchDataFailure: watchDataFailure,
      watchData: watchData,
      handleLogout,

      forgotPasswordFailureMessage: forgotPasswordFailureMessage,
      forgotPasswordFailure: forgotPasswordFailure,
      forgotPasswordSuccess: forgotPasswordSuccess,
      handleForgotPasswordSubmit,

      resetPasswordFailure: resetPasswordFailure,
      resetPasswordFailureMessage: resetPasswordFailureMessage,
      resetPasswordSuccess: resetPasswordSuccess,
      resetPasswordSuccessRedirect: resetPasswordSuccessRedirect,
      handleResetPasswordSubmit
    }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
