import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  rowItemWrapper: {
    padding: theme.spacing(3, 0, 1),
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`
    }
  },
  rowItemContentWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 1 auto'
    }
  },
  rowItemCircleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& i': {
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(30),
      '&.icon-day': {
        color: '#FFC107'
      },
      '&.icon-night': {
        color: "#32478e",
      }
    }
  },
  rowItemCircle: {
    width: 112,
    height: 112,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rowItemTitle: {
    fontSize: theme.typography.pxToRem(17),
    color: theme.palette.primary.main
  },
  rowItemSubTitle: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[500]
  },
  rowItemContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      width: '100%'
    }
  },
  rowItemContentTitle: {
    margin: 0,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(22),
    '@media (max-width:815px)': {
        fontSize: theme.typography.pxToRem(18),
      },
  },
  sideEffectDiv:{
    width: 255,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft:25,
  },
  sideEffectGradeDiv:{
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 15,
    marginRight: 15,
    '@media (max-width:815px)': {
      marginRight: 10,
    },
  },
  rowItemAudioWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f1f3f4',
    borderRadius: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    height: 'fit-content',
    marginTop: 35,
    marginRight: 20
  },
  rowItemAudio: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  deleteAudioIcon: {
    '&.MuiButton-root': {
      minWidth: 0
    }
  },
  rowItemLocation: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(2)
  },
  rowItemNote: {
    padding: theme.spacing(1, 0)
  },
  addNote: {
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      textDecoration: 'underline',
      color: theme.palette.primary.main
    }
  },
  actionWrapper: {},
  deleteIcon: {
    '&.MuiButton-root': {
      color: theme.palette.grey[500],
      marginLeft: theme.spacing(0),
      minWidth: 0,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        right: 0,
        top: 16,
        '&:last-of-type': {
          right: 40
        }
      }
    }
  },
  rowItemContentWithIcon: {
    width: '100%',
    display: 'flex',
    '& i': {
      fontSize: theme.typography.pxToRem(90),
      color: theme.palette.primary.main,
      '@media (max-width:815px)': {
        fontSize: theme.typography.pxToRem(60),
      },
    }
  },
  rowItemTranscription: {
    width: "800px",
    maxWidth: "800px",
    maxHeight: '275px',
    padding: "15px",
    marginTop: "15px",
    marginBottom: "15px",
    backgroundColor: "#F1F3F4",
    borderRadius: "30px",
    overflowX: 'hidden',
    overflowY: 'auto',
    '@media (max-width:815px)': {
      width: "650px",
      maxWidth: "650px",
    },
  },
}))
