import React from 'react'
import LazyImage from '../../LazyImage'
import authLogo from '../../../images/neureka-logo.svg'
import {useStyles} from '../Styles'

const AuthLogo = () => {
  let classes = useStyles()
  return (
    <div className={classes.authLogoWrapper}>
      <LazyImage width={216} src={authLogo}/>
    </div>
  )
}

export default AuthLogo
