import 'date-fns'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { FormLoader } from '../../../../Loader/FormLoader'
import TimerIcon from '@material-ui/icons/Timer';
import ShortSleepSession from '../../../../../images/Icons/short_sleep_session_icon.png'
import triangle from '../../../../../images/Icons/triangle.png'
import endOfWeek from 'date-fns/endOfWeek';
import startOfWeek from 'date-fns/startOfWeek';
import { makeJSDateObject } from "../../../../../../src/utils/helpers.ts"
import ProfileServices from '../../../../../Services/Consumers/ProfileServices'
import {ProfileConsumer}  from '../../../../../ProfileContext'
import { useStyles } from './Styles'
import SleepCharts from './SleepCharts.js'
import moment from "moment"
import SleepAlerts from "./SleepAlerts"
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';


const DailySleepStats = ({ isDailyStats, setIsDailystats, selectedDailyStats, setSelectedWeek, selectedWeek }) => {

  let classes = useStyles()

  const [loading, setLoading] = React.useState(true)

  const sleepQualityScore = selectedDailyStats.sleep_quality_score
  const sleepQualityScoreString = selectedDailyStats.sleep_quality_score_string
  const duration = selectedDailyStats.sleep_duration_minutes
  const startTime = selectedDailyStats.sleep_start_time
  const endTime = selectedDailyStats.sleep_end_time

  const switchToSummaryPage = (date) => {
    setIsDailystats(false)
  }


  const timeConvert = (num) => {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    const minutesRounded = Math.round(minutes)
    return `${hours} h ${minutesRounded} min`
  }

  const formatDate = (date) => {
    const formattedDate = moment(date)
      return formattedDate.format('dddd, MMMM DD, YYYY');
  }

  const formatStartTime = (time) => {
    if (time == "" || time == null) {
      return "Not available"
    } else {
      let startHours24 = parseInt(time.substring(0, 2))
      let startHours = ((startHours24 + 11) % 12) + 1
      let startAmPm = startHours24 > 11 ? 'PM' : 'AM'
      let startMinutes = time.substring(3, 5)
      let displaySleepStartTime = `${startHours}:${startMinutes} ${startAmPm}`
      return displaySleepStartTime
    }
  }

  const formatEndTime = (time) => {
    if (time == "" || time == null) {
      return "Not available"
    } else {
      let endHours24 = parseInt(time.substring(0, 2))
      let endHours = ((endHours24 + 11) % 12) + 1
      let endAmPm = endHours24 > 11 ? 'PM' : 'AM'
      let endMinutes = time.substring(3, 5)
      let displaySleepEndTime = `${endHours}:${endMinutes} ${endAmPm}`
      return displaySleepEndTime
    }
  }


  

  const sleepScoreSeries = [`${sleepQualityScore}`]
    const sleepScoreOptions = {
      chart: {
        type: 'radialBar',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 400,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            },
          },
        },
      fill: {
        colors: (sleepQualityScore == 100 ? '#007e52' : (sleepQualityScore <= 25 ? '#F75959' : (sleepQualityScore <= 50 ? '#ff9034' : (sleepQualityScore <= 75 ? '#f0b530' : '#3ea883')))),
      },
      labels: [sleepQualityScoreString],
      plotOptions: {
        radialBar: {
          hollow: {
            size: 60
          },
          track: {
            dropShadow: {
              enabled: false,
            },
          },
          dataLabels: {
            show: true,
            name: {
                offsetY: 26,
                show: true,
                color: (sleepQualityScore == 100 ? '#007e52' : (sleepQualityScore <= 25 ? '#F75959' : (sleepQualityScore <= 50 ? '#ff9034' : (sleepQualityScore <= 75 ? '#f0b530' : '#3ea883')))),
                fontSize: '16px'
              },
              value: {
                formatter: function (val) {
                  return sleepQualityScore
                },
                offsetY: -10,
                color: (sleepQualityScore == 100 ? '#007e52' : (sleepQualityScore <= 25 ? '#F75959' : (sleepQualityScore <= 50 ? '#ff9034' : (sleepQualityScore <= 75 ? '#f0b530' : '#3ea883')))),
                fontSize: '36px',
                show: true,
              }
          }
        }
      },
      stroke: {
        lineCap: 'round',
      },
      states: {
        active: {
          filter: {
            type: 'none',
          },
        },
        hover: {
          filter: {
              type: 'none',
          }
        },
      },
    }


  return (
    <>
    <div className={classes.DailySleepStatsPage}>
      <section className={classes.DailyStatsHeader}>
        <h1 className={classes.DailyStatsDate}>{formatDate(selectedDailyStats.sleep_session_date)}</h1>
        <button
          className={classes.BackButton}
          onClick={switchToSummaryPage}><CloseRoundedIcon fontSize="medium" /></button>
      </section>

      <div className={classes.DailySleepStatsSection}>
        <section className={classes.DetailStatsContainer}>

          <SleepCharts
            selectedDailyStats={selectedDailyStats}
            setLoading={setLoading}
            loading={loading} />

        </section>

        {loading == true ?
        <></>  
        :
        <section className={classes.BasicStatsContainer}>
          <h2 className={classes.HeaderLabels}>Sleep Score</h2>
          <div className={classes.Underline} />
          <div className={classes.ScoreChartContainer}>
            {sleepQualityScore !== undefined && sleepQualityScore == null ?
              <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{display: "flex", border: "8px solid rgb(217,217,217)", borderRadius: "50%", width: "140px", height: "140px", marginTop: '20px', marginBottom: '15px', justifyContent: 'center', alignItems: 'center'}}>
                  <img src={ShortSleepSession} style={{width: 60, height: 60}} alt="icon indicating not enough sleep"/>
                </div>
                <h2 style={{color: '#47337E', fontSize: 20, marginBottom: 0, marginTop: 5}}>Sleep Session<br />Too Short!</h2>
                <p style={{color: '#47337E', fontSize: 16, fontWeight: 500, margin: 0}}> Try to get more than<br/>4 hours of sleep.</p>
              </div>
              : 
              <div>
                <div className={classes.ScoreChart}>
                  <ReactApexChart
                  options={sleepScoreOptions}
                  series={sleepScoreSeries}
                  type="radialBar"
                  height={190} />
                </div>
                <h3 className={classes.SleepDurationLabel}>Time Asleep</h3>
                <p className={classes.DailySleepDuration}>{timeConvert(selectedDailyStats.sleep_duration_minutes)}</p>
                <div className={classes.StartEndTimes}>
                  <div className={classes.DailyStartTime}>
                    <h4 className={classes.SleepTimeLabel}>Sleep Time</h4>
                    <p className={classes.SleepTime}>{formatStartTime(selectedDailyStats.sleep_start_time)}</p>
                  </div>
                  <div className={classes.DailyEndTime}>
                    <h4 className={classes.SleepTimeLabel}>Wake Time</h4>
                    <p className={classes.SleepTime}>{formatEndTime(selectedDailyStats.sleep_end_time)}</p>
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
        }
      </div>

      {loading == true ?
        <></>  
      :
      <section className={classes.SleepAlertTableContainer}>
        <SleepAlerts
        selectedDailyStats={selectedDailyStats} />
      </section>
      }
    </div>
      
    </>

  )
}
export default DailySleepStats
