import React from 'react'
import { FormattedMessage } from 'react-intl'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useStyles } from '../Styles'
import AuthLogo from '../AuthLogo'
import { NavLink } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'

const EmailVerifiedCard = () => {
  let classes = useStyles()
  return (
    <>
      <AuthLogo/>
      <div className={classes.authCard + ' ' + classes.authCardVerify}>
        <CheckCircleIcon className={classes.emailVerifiedIcon}/>
        <h1 className={classes.authCardTitle}><FormattedMessage id='EmailVerifiedCard.title'/></h1>
        <NavLink to={NamedRoutes.auth.login} className={classes.authCardLink}>
          <FormattedMessage id='EmailVerifiedCard.Link.login'/>
        </NavLink>
      </div>
    </>
  )
}

export default EmailVerifiedCard
