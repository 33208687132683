import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { Redirect, useParams } from 'react-router-dom'
import { forgotPassword } from '../../../utils/pages-meta.json'
import { NamedRoutes } from '../../../routes'
import ResetPasswordCard from '../../../components/Auth/ResetPasswordCard'

const ResetPassword = () => {
  let { code } = useParams()
  return (
    <>
      {!!code && !!parseInt(code) ? <>
          <SEO title={forgotPassword.title}
               pathname={window.location.origin + NamedRoutes.auth.forgotPassword}
               titleTemplate='Novela'
               image={forgotPassword.image}
               description={forgotPassword.description}
          />
          <ResetPasswordCard code={code}/>
        </> :
        <Redirect to={NamedRoutes.auth.forgotPassword}/>
      }
    </>
  )
}

export default ResetPassword
