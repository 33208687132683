/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, Form, Field } from "formik";
import { useState, useEffect } from "react";
import { StyledInput, StyledTextArea } from "../../../../../StyledComponents/StyledInput";
import { StyledSelect, SelectTheme } from "../../../../../StyledComponents/StyledSelect";
import EditIcon from '@material-ui/icons/Edit';
import './styles.scss'
import { StopwatchIcon } from "../../../../../StyledComponents/StyledTimePicker";
import { HiddenCheckbox, HiddenRadio } from "../../../../../StyledComponents/HiddenCheckbox";
import { LocalStorageService } from "../../../../../../Services/LocalStorageService";
import ProfileServices from "../../../../../../Services/Consumers/ProfileServices";

const SeizureTypesForm = ({seizureID, seizureTypes, triggers, isAura, editSeizureType, closeForm, flag, setFlag}) => {

    const [seizureData, setSeizureData] = useState(null);
    const [isNewType, setIsNewType] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [ilaeSeizureList, setIlaeSeizureList] = useState([]);
    const [awarenessFilteredList, setAwarenessFilteredList] = useState([]);
    const [onsetFilteredList, setOnsetFilteredList] = useState([]);
    const [onsetOptions, setOnsetOptions] = useState(null);
    const [motorOptions, setMotorOptions] = useState(null);
    const [subtypeOptions, setSubtypeOptions] = useState(null);
    
    const [patientID, setPatientID] = useState(null);
    LocalStorageService.get("PatientID").then(res => setPatientID(parseInt(res)));

    const getSeizureData = () => {
        seizureTypes.forEach(type => {
            if (type.seizure_type_id === seizureID) {
                setSeizureData(type);
                setDataLoaded(true);
            }
        });
    }

    const getIlaeOptions = async () => {
        const res = await ProfileServices.getIlaeSeizureTypes();
        if (res.data && res.data.data) {
            setIlaeSeizureList(res.data.data);
        }
    }

    useEffect(() => {
        getIlaeOptions();

        if(seizureID === "new"){
            setDataLoaded(true);
            setIsNewType(true);
        } else {
            getSeizureData();
        }
    }, [])

    useEffect(() => {
        if(!isNewType && seizureData){
            getOnsetOptions(seizureData.aware_unaware)
        }
    }, [ilaeSeizureList])
    
    useEffect(() => {
        if(!isNewType && seizureData){
            getMotorOptions(seizureData.focal_generalized)
        }
    }, [awarenessFilteredList])

    useEffect(() => {
        if(!isNewType && seizureData) {
            getSubtypeOptions(seizureData.motor_nonmotor)
        }
    }, [onsetFilteredList])

    const getOnsetOptions = value => {
        const ilaeList = [...ilaeSeizureList];
        const filteredList = ilaeList.filter(item => item.awareness === value);
        setAwarenessFilteredList(filteredList);
        const optionsSet  = new Set();
        filteredList.forEach(item => optionsSet.add(item.onset_type));
        setOnsetOptions(optionsSet);
    }
    
    const getMotorOptions = value => {
        const list = [...awarenessFilteredList];
        const filteredList = list.filter(item => item.onset_type === value);
        setOnsetFilteredList(filteredList);
        const optionsSet = new Set();
        filteredList.forEach(item => optionsSet.add(item.motor_nonmotor));
        setMotorOptions(optionsSet);
    }
    
    const getSubtypeOptions = value => {
        const list = [...onsetFilteredList];
        const filteredList = list.filter(item => item.motor_nonmotor === value);
        const options = filteredList.map(item => ({ value: item.sub_type, label: item.sub_type, ilae_seizure_type_id: item.ilae_seizure_type_id }));
        setSubtypeOptions(options);
    }

    const frequencyOptions = ["daily", "weekly", "monthly", "yearly"];

    const awareUnaware = [
        {value: "Aware", label: "Yes", name: "aware_unaware"},
        {value: "Unaware", label: "No", name: "aware_unaware"},
        {value: "Not Sure", label: "Not Sure", name: "aware_unaware"}
    ];
    
    const focalGeneralized = [
        {value: "Focal Onset", label: "Focal", name: "focal_generalized"},
        {value: "Generalized Onset", label: "Generalized", name: "focal_generalized"},
        {value: "Not Sure", label: "Not Sure", name: "focal_generalized"}
    ];
    
    const motorNonmotor = [
        {value: "Motor", label: "Yes", name: "motor_nonmotor"},
        {value: "Non Motor", label: "No", name: "motor_nonmotor"},
        {value: "Not Sure", label: "Not Sure", name: "motor_nonmotor"}
    ]

    const handleCheckboxChange = (e, props, id) => {
        if (e.target.checked) {
            props.values.known_triggers.push(id)
        } else if (e.target.checked === false) {
            props.values.known_triggers.splice(props.values.known_triggers.indexOf(id), 1)
        }
    }

    const handleSubmit = (formikValues, formikActions) => {
        const values = {
            ...formikValues,
        }
        values.is_aura = isAura;
        values.focal_generalized = onsetOptions && onsetOptions.has(values.focal_generalized) ? values.focal_generalized : null;
        values.motor_nonmotor = motorOptions && motorOptions.has(values.motor_nonmotor) ? values.motor_nonmotor : null;

        const postValues = {
            seizure_types: [{...values}]
        };

        editSeizureType(seizureID, patientID, isNewType ? postValues : values, formikActions, flag, setFlag);
        closeForm();

    }

    return (
        <div className="SeizureTypesForm">
            <h3 className="formTitle">{isNewType ? "Add" : "Edit"} {isAura ? "Aura" : "Seizure"} Type</h3>
            {dataLoaded && 
            <Formik
                initialValues={{
                    custom_name: seizureData && seizureData.custom_name,
                    typical_frequency: seizureData && frequencyOptions.filter(option => option === seizureData.typical_frequency)[0],
                    typical_duration_seconds: seizureData && seizureData.typical_duration_seconds,
                    description: seizureData && seizureData.description,
                    known_triggers: seizureData ? seizureData.known_triggers : [],
                    nocturnal: seizureData && seizureData.nocturnal,
                    aware_unaware: seizureData && seizureData.aware_unaware,
                    focal_generalized: seizureData && seizureData.focal_generalized,
                    motor_nonmotor: seizureData && seizureData.motor_nonmotor,
                    specific_subtype: seizureData && seizureData.specific_subtype,
                    ilae_seizure_type_id: seizureData && seizureData.ilae_seizure_type_id
                }}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions)
                }}
            >
                {(props) => {
                    return (
                    <Form className="formContainer">

                            <label className="required">Custom Seizure Name</label>
                            <StyledInput
                                className="customNameInput" 
                                name="custom_name"
                                onChange={props.handleChange}
                                suffix={<EditIcon style={{color: "#ddd"}}/>}
                                placeholder="Enter Custom Seizure Name"
                                value={props.values.custom_name}
                            />


                        <div className="formRow">


                            <div className="frequencySelect">
                                <label className="required">How often do you get this seizure?</label>
                                <StyledSelect 
                                    theme={theme => SelectTheme(theme)}
                                    options={frequencyOptions.map(option => (
                                        {value: option, label: option}
                                    ))}
                                    placeholder={"Select Typical Frequency"}
                                    defaultValue={props.values.typical_frequency ? {value: props.values.typical_frequency, label: props.values.typical_frequency} : null}
                                    onChange={(e) => props.setFieldValue("typical_frequency", e.value)}
                                    isSearchable={false}
                                />
                            </div>

                            <div className="typicalDuration">
                                <label className="required">Typical Duration (seconds)</label>
                                <StyledInput 
                                    name="typical_duration_seconds"
                                    onChange={props.handleChange}
                                    placeholder="Enter Duration"
                                    type="number"
                                    min="1"
                                    suffix={<StopwatchIcon />}
                                    defaultValue={props.values.typical_duration_seconds}
                                />
                            </div>
                        </div>

                            <label className="descriptionHeader">Describe how you feel during this seizure:</label>
                        <StyledTextArea
                            className="seizureTypeDescription" 
                            name="description"
                            placeholder="Enter Descripton..."
                            onChange={props.handleChange}
                            autoSize={{minRows: 5}}
                            defaultValue={props.values.description}
                        />

                        <div className="knownTriggersContainer">
                            <label className="knownTriggersHeader">Known Triggers</label>
                            <div className="knownTriggers">
                                {triggers && triggers.map(trigger => {
                                    const triggerID = trigger.seizure_trigger_id;
                                    return (
                                        <div className="triggerOption" key={triggerID}>
                                            <label>
                                                <Field
                                                    className="triggerCheckbox"
                                                    as={HiddenCheckbox}
                                                    name="known_triggers"
                                                    value={triggerID}
                                                    defaultChecked={props.values.known_triggers.includes(triggerID)}
                                                    onChange={(e) => handleCheckboxChange(e, props, triggerID)}
                                                />
                                                <span>{trigger.seizure_trigger_name}</span>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="seizureTypeQuestions">
                            <p className="questionText required">Do these seizures only occur during sleep?</p>
                            <label>
                                <Field
                                    className="answerRadio" 
                                    as={HiddenRadio}
                                    name="nocturnal"
                                    value="Yes"
                                    checked={props.values.nocturnal === "Yes"}
                                />
                                <div className="answerOption">Yes</div>
                            </label>
                            <label>
                                <Field
                                    className="answerRadio" 
                                    as={HiddenRadio}
                                    name="nocturnal"
                                    value="No"
                                    checked={props.values.nocturnal === "No"}
                                />
                                <div className="answerOption">No</div>
                            </label>
                            <label>
                                <Field
                                    className="answerRadio" 
                                    as={HiddenRadio}
                                    name="nocturnal"
                                    value="Not Sure"
                                    checked={props.values.nocturnal === "Not Sure"}
                                />
                                <div className="answerOption">Not Sure</div>
                            </label>
                            {!isAura &&
                            <>
                                <p className="questionText required">Are you aware during these seizures?</p>

                                {awareUnaware.map(option => (
                                    <label key={option.value}>
                                        <Field 
                                            className="answerRadio"
                                            as={HiddenRadio}
                                            name={option.name}
                                            value={option.value}
                                            checked={props.values.aware_unaware === option.value}
                                            onChange={e => {
                                                props.setFieldValue(option.name, e.target.value)
                                                props.setFieldValue("focal_generalized", null);
                                                props.setFieldValue("motor_nonmotor", null);
                                                props.setFieldValue("ilae_seizure_type_id", null); 
                                                getOnsetOptions(e.target.value);
                                                setSubtypeOptions(null);
                                            }}
                                        />
                                        <div className="answerOption">{option.label}</div>
                                    </label>
                                ))}
    
                                   
                                <p className="questionText required">Is this seizure focal onset or generalized onset?</p>

                                {focalGeneralized.map(option => (
                                    <label key={option.value}>
                                        <Field 
                                            className="answerRadio"
                                            as={HiddenRadio}
                                            name={option.name}
                                            value={option.value}
                                            checked={props.values[option.name] === option.value && (onsetOptions && onsetOptions.has(option.value))}
                                            disabled={(!onsetOptions || !onsetOptions.has(option.value))}
                                            
                                            onChange={e => {
                                                props.setFieldValue(option.name, e.target.value);
                                                props.setFieldValue("motor_nonmotor", null);
                                                props.setFieldValue("ilae_seizure_type_id", null);
                                                setSubtypeOptions(null);
                                                getMotorOptions(e.target.value);
                                            }}
                                        />
                                        <div className="answerOption">{option.label}</div>
                                    </label>
                                ))}

                                <p className="questionText required">Does your body move during these seizures?</p>
    
                                {motorNonmotor.map(option => (
                                    <label key={option.value}>
                                        <Field 
                                            className="answerRadio"
                                            as={HiddenRadio}
                                            name={option.name}
                                            value={option.value}
                                            checked={props.values[option.name] === option.value && (motorOptions && motorOptions.has(option.value))}
                                            disabled={!props.values.focal_generalized || (motorOptions && !motorOptions.has(option.value))}
                                            onChange={e => {
                                                props.setFieldValue(option.name, e.target.value);
                                                props.setFieldValue("ilae_seizure_type_id", null);
                                                getSubtypeOptions(e.target.value);
                                            }}
                                        />
                                        <div className="answerOption">{option.label}</div>
                                    </label>
                                ))}

                                <p className="questionText required">Select Seizure Subtype: </p>
                                <StyledSelect
                                    key={JSON.stringify(subtypeOptions)} 
                                    theme={theme => SelectTheme(theme)}
                                    options={subtypeOptions ? subtypeOptions : []}
                                    isSearchable={false}
                                    menuPlacement="top"
                                    isDisabled={!props.values.motor_nonmotor || (motorOptions && !motorOptions.has(props.values.motor_nonmotor))}
                                    defaultValue={subtypeOptions && props.values.motor_nonmotor && props.values.ilae_seizure_type_id ? subtypeOptions.filter(item => item.ilae_seizure_type_id === props.values.ilae_seizure_type_id) : null}
                                    onChange={e => {
                                        props.setFieldValue("specific_subtype", e.value);
                                        props.setFieldValue("ilae_seizure_type_id", e.ilae_seizure_type_id);
                                    }}
                                />
                            </>
                            }

                        </div>
                        
                        <button disabled={!props.values.custom_name || !props.values.typical_duration_seconds || !props.values.typical_frequency || !props.values.nocturnal || (!isAura && (!props.values.aware_unaware || (onsetOptions && !onsetOptions.has(props.values.focal_generalized)) || !props.values.motor_nonmotor || !props.values.ilae_seizure_type_id))} type="submit" className="saveButton">Save</button>
                        <button className="cancelButton" onClick={closeForm}>Cancel</button>
                    </Form>
                )
                }
            }
            </Formik>}

        </div>
    )
}

export default SeizureTypesForm
