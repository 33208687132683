import React from 'react'
import { ProfileProvider } from '../../../ProfileContext'
import { AuthConsumer } from '../../../AuthContext'
import MedicationsTabs from './Tabs'

  // @refresh reset
const MedicationsContent = () => {
  return (
    <AuthConsumer>
      {({ userInfo }) => (
        !!Object.keys(userInfo).length &&
        <ProfileProvider userId={userInfo.id}>
          <MedicationsTabs/>
        </ProfileProvider>
      )}
    </AuthConsumer>
  )
}

export default MedicationsContent
