import React from 'react'
import { useEffect, useState } from "react"
import {NavLink,Link} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import {lg} from '../../utils/ResponsiveUtility'
import {NamedRoutes} from '../../routes'
import {useStyles} from './Styles'
import "./styles.scss"
import { useMediaQuery } from '@material-ui/core'


const MainNavigation = ({toggleStatus, setToggleStatus}) => {

  let menu = [
    {
      key: 1,
      label: <FormattedMessage id='MainNavigation.link.dashboard'/>,
      link: NamedRoutes.home,
      icon: 'icon-home'
    },
    {
      key: 2,
      label: <FormattedMessage id='MainNavigation.link.seizures'/>,
      link: NamedRoutes.account.seizures,
      icon: 'icon-seizure'
    },
    {
      key: 3,
      label: <FormattedMessage id='MainNavigation.link.medications'/>,
      link: NamedRoutes.account.medications,
      icon: 'icon-medication'
    },
    {
      key: 4,
      label: <FormattedMessage id='MainNavigation.link.sleep' /> ,
      link: NamedRoutes.account.sleep,
      icon: 'icon-night'
    },
    {
      key: 5,
      label: <FormattedMessage id='MainNavigation.link.sideEffects'/>,
      link: NamedRoutes.account.sideEffects,
      icon: 'icon-side-effects'
    },
    {
      key: 6,
      label: <FormattedMessage id='MainNavigation.link.voiceMemos'/>,
      link: NamedRoutes.account.voiceMemos,
      icon: 'icon-voice-memo'
    }
  ]
  const classes = useStyles()
  let [width, setWidth] = React.useState(document.body.clientWidth)
  let Mobile = (width < lg)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])
  return (
    <>
      <div className="burgerMenu" onClick = {() => {
        setToggleStatus(!toggleStatus)
      }}>

        <div className={toggleStatus ? "barOne toggle" : "barOne"}></div>
        <div className={toggleStatus ? "barTwo toggle" : "barTwo"}></div>
        <div className={toggleStatus ? "barThree toggle" : "barThree"}></div>

      </div>
      <nav className={toggleStatus ? "mainNavigation active" : "mainNavigation"}>
        {menu.map((item, index) => (
          <NavLink
            key={item.key}
            exact
            activeClassName="active"
            to={item.link}
          >
            <i className={item.icon}/>
            {item.label}
          </NavLink>
        ))}
      </nav>
    </>
  )
}

export default MainNavigation
