import React, { useState } from 'react'
import ProfileServices from './../../../../Services/Consumers/ProfileServices'
import { ProfileConsumer }  from '../../../../../src/ProfileContext'
import Modal from 'react-modal';
import ReactApexChart from 'react-apexcharts'
import { Container, Grid } from '@material-ui/core'
import ApexCharts from 'apexcharts'
import { Gradient } from '@material-ui/icons'
import moment from "moment"
import ItemFilter from './ItemFilter'
import { useStyles } from './Styles'
import RowItem from './RowItem'
import DailySleepStats from './DailySleepStats'
import ReactCSSTransitionGroup, { CSSTransition } from 'react-transition-group';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'
import './styles.css'

const modalStyles = {
  content: {
    zoom: "80%",
    marginTop: "50px",
    marginLeft: 0,
    marginRight: 0,
    height: "105vh",
  }
}

const WeeklySleepScore = ({ sleepStats }) => {

  let classes = useStyles()

  React.useEffect(() => {
    window.scrollTo(0, 0);
  });


  const [isDailyStats, setIsDailyStats] = React.useState(false)
  const [selectedDailyStats, setSelectedDailyStats] = React.useState()
  const [selectedWeek, setSelectedWeek] = React.useState([])
  const [sleepQualityData, setSleepQualityData] = React.useState([])

  const [selectedDate, setSelectedDate] = React.useState(moment().format("ddd MMM DD YYYY"))

  const selectedDateFormat = moment().format("ddd MMM DD, YYYY")


  const sleepScoreSeriesData = [
  {
    name: "Sleep Scores",
    data: sleepScoreSeries
  }];

  const [sleepScoreSeries, setSleepScoreSeries] = useState(sleepScoreSeriesData);


  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      width: '100%',
      toolbar: {
        show: false
      },
    },
    fill: {
      colors: ['#ABA4FF'],
      type: 'gradient',
      opacity: 1,
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        gradientToColors: ['#462d7d'],
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0],
      }
    },
    markers: {
      size: 0
    },
    legend: {
      show: false
    },
    tooltip: {
      show: false,
      enabled: false
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '90%',
        barWidth: '90%'
      }
    },
    xaxis: {
      categories: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
      labels: {
        show: true,
         style: {
           colors: "#999999",
           fontSize: '14px',
           fontWeight: 400,
         },
      },
      axisBorder: {
        show: true,
        color: '#4E4AA2',
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: 0
      },
    },
    dataLabels: {
      enabled: true,
      style: {
      fontSize: '16px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 'light',
      colors: ['#FFFFFF']
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      labels: {
        show: true,
        style: {
          colors: "#797979",
          fontSize: '12px',
          fontWeight: 400,
        },
      },
    }
  });


  return (
    <>
      <Modal closeTimeoutMS={200} isOpen={isDailyStats} style={modalStyles}>
        <DailySleepStats
        selectedDailyStats={selectedDailyStats}
        setIsDailystats={setIsDailyStats}
        isDailyStats={isDailyStats}
        selectedWeek={selectedWeek}
        setSelectedWeek={setSelectedWeek}
        />
      </Modal>

      <div className={classes.SummaryPage}>
        <section className={classes.WeeklyScores} style={!isDailyStats ? {position: "fixed"}: {position: "static"}}>
          <h2 className={classes.WeeklyScoresHeader}>Weekly Sleep Scores</h2>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ItemFilter
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedWeek={selectedWeek}
              setSelectedWeek={setSelectedWeek}
              sleepScoreSeries={sleepScoreSeries}
              setSleepScoreSeries={setSleepScoreSeries}
              sleepQualityData={sleepQualityData}
              setSleepQualityData={setSleepQualityData} />
          </MuiPickersUtilsProvider>
          <div className={classes.ChartWrapper}>
            <ReactApexChart
            options={options}
            series={sleepScoreSeries}
            type="bar"
            height={350}
            width={500}
          />
          </div>
        </section>

        <div className={classes.rowItemContainer}>
          <RowItem
            isDailyStats={isDailyStats}
            sleepQualityData={sleepQualityData}
            setIsDailystats={setIsDailyStats}
            setSelectedDailyStats={setSelectedDailyStats}
            />
        </div>
      </div>
    
    </>
  );
}

export default WeeklySleepScore