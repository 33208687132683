import React from 'react'
import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { useStyles } from './Styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { FormattedMessage } from 'react-intl'
import { ProfileConsumer } from '../../../../../ProfileContext'
import Alert from '@material-ui/lab/Alert'
import { FormLoader } from '../../../../Loader/FormLoader'
import moment from 'moment'

const ItemTable = ({ rows, columns, handleDeleteRow, switchToEditMode, switchToRescueForm, loadMedications }) => {
  const classes = useStyles()

  function convertUTCToLocal(utcDt, utcDtFormat) {
    var toDt = moment.utc(utcDt, utcDtFormat).toDate();
    return moment(toDt);
  }

  return (
    <div className={classes.itemTableWrapper}>
      {!!rows ?
        <TableContainer className={classes.itemTableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {rows.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        let measure_type = row['dose_units']
                        let form = row.form.toLowerCase() === 'pill' ? 'Pill' : 'Dose';
                        return (
                          <TableCell key={column.id}>
                            {column.id === 'Actions' ? 
                              <>
                                <div>
                                  <button
                                  type='button'
                                  className={classes.actionButton + ' edit'}
                                  onClick={() => switchToEditMode(row.id)}>
                                    <EditIcon/>
                                  </button>
                                  <button
                                  type='button'
                                  className={classes.actionButton + ' delete'}
                                  onClick={() => {
                                    handleDeleteRow(row.id);
                                  }}>
                                    <DeleteIcon/>
                                  </button>
                                </div>
                              </>
                              :
                              (column.id === 'medication_name') ? value
                              :
                              (column.id === 'form') ? (row['form'][0] === row['form'][0].toLowerCase() ? row['form'][0].toUpperCase() + row['form'].slice(1) : row['form'])
                              :
                              (column.id === 'dose') ? value + ' ' + measure_type
                              :
                              (column.id === 'reminders' && !!value.length ?
                                value.map((reminder, index) => (
                                  <div className={classes.RemindersTimeWrapper}>
                                    <div></div>
                                    <div className={classes.RemindersTimeIcon} key={index}><AccessTimeIcon/></div>
                                    <div className={classes.RemindersTime}>{moment(reminder.dose_time, 'HH:mm:ss').format('hh:mm A')} - {reminder.num_of_doses} {reminder.dose > 1 ? `${form}s` : form}</div>
                                  </div>))
                                :
                                (column.id === 'start_date' ?
                                  moment(value, 'YYYY-MM-DD').format('MMM DD YYYY')
                                :
                                (column.id === 'end_date' ? (value == "2200-01-01" ? 'Ongoing' : moment(value, 'YYYY-MM-DD').format('MMM DD YYYY'))
                                :
                                value
                                )))}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </>
              
            </TableBody>
          </Table>
        </TableContainer>
      :
      <>
      </> 
      }
      
      <ProfileConsumer>
        {({
            handleMedicationSendNotification, medicationSendNotificationLoading, medicationSendNotificationFailure,
            medicationSendNotificationSuccess
          }) => (
          <>
            {!!medicationSendNotificationLoading && <FormLoader loading={true}/>}
            <div className={classes.listActionsWrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => switchToEditMode('new')}
                className={classes.listActionSubmit}>
                  <FormattedMessage id='MedicationTab.button.Add'/>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => switchToRescueForm('new')}
                className={classes.listActionSubmit}>
                  Add Rescue Medication
              </Button>
            </div>
            {!!medicationSendNotificationSuccess &&
            <div className={classes.listActionsWrapper}>
              <Alert severity="success"><FormattedMessage id='EndPoint.MedicationSendNotification.Success'/></Alert>
            </div>}
            {!!medicationSendNotificationFailure &&
            <div className={classes.listActionsWrapper}>
              <Alert severity="error"><FormattedMessage id='EndPoint.MedicationSendNotification.Failure'/></Alert>
            </div>}
          </>
        )}
      </ProfileConsumer>
    </div>
  )
}
export default ItemTable
