import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  UpcomingMedicationsTab: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(3)
    }
  },
  UpcomingMedicationsTabGridItem: {
    // borderRight: `1px solid ${theme.palette.grey[100]}`
  },
  UpcomingMedicationsTabTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightLight,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    paddingBottom: theme.spacing(2)
  }
}))
