import axios from 'axios'
import {log} from '../utils/helpers'
import {LocalStorageService} from './LocalStorageService'


axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
/**
 *  Add a request interceptor
 */
axios.interceptors.request.use((config) => {
  LocalStorageService.get('NovelaAppLanguage').then((appLanguage) => {
    config.headers['Accept-Language'] = appLanguage
  })
  return config
}, (error) => {
  log(error)
  // return new Promise((resolve, reject) => {
  //   if (error.response && error.response.status === 401) {
  //     console.log(error.response.status)
  //     // window.location = NamedRoutes.unAuthorized
  //   }
  //   // if (error.response && error.response.status === 404) {
  //   //   console.log(error.response.status)
  //   //   window.location = NamedRoutes.home
  //   // }
  //   return reject(error)
  // })
})
