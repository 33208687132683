import React from 'react'
import { Paper } from '@material-ui/core';
import { useStyles } from './../DBStyles'
import { AuthConsumer } from '../../../../AuthContext'
import UpcomingMedications from './UpcomingMedications/index'
import { ProfileProvider } from '../../../../ProfileContext'

const DashboardLeftCell = () => {
  let classes = useStyles()
  return (
    <Paper className="DBGridCellOne">
                
    <AuthConsumer>
    {({ userInfo }) => (
      !!Object.keys(userInfo).length &&
      <ProfileProvider userID={userInfo.id}>
        <UpcomingMedications/>
      </ProfileProvider>
      )}
    </AuthConsumer>

</Paper>
  )
}

export default DashboardLeftCell