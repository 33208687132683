/**
 * Configuration Class
 * Responsible for All The Api Configuration for the Application
 */
import { EnglishFont } from '../../utils/Fonts'
import arMessages from './../../translations/ar.json'
import { LocalStorageService } from '../LocalStorageService'

class Configuration {
  static User_EndPoints = {
    register: process.env.REACT_APP_REGISTER_BASE_URL + '/save/',
    login: process.env.REACT_APP_REGISTER_BASE_URL + '/login/',
    tokenLogin: process.env.REACT_APP_REGISTER_BASE_URL + '/login/token/',
    organizationTokenLogin: process.env.REACT_APP_REGISTRATION_API_BASE_URL + '/organization/patient/login',
    verify: process.env.REACT_APP_REGISTER_BASE_URL + '/verify/',
    forgotPassword: process.env.REACT_APP_REGISTER_BASE_URL + '/password/reset/',
    resetPassword: process.env.REACT_APP_REGISTER_BASE_URL + '/password/set',

    userInfo: process.env.REACT_APP_DASHBOARD_BASE_URL + '/get_patient_info/',
    getUserInfo: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/personal/info/',
    watchData: process.env.REACT_APP_DASHBOARD_BASE_URL + '/get_watch_data/'
  }
  static Profile_EndPoints = {


    loadSeizures: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/read_seizures',
    loadSeizuresTypes: process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + '/seizures/types/${userID}',
    saveSeizures: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/seizures',

    ilaeSeizureTypes: process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + `/seizures/types/ilae`,

    medicationHistory:process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + `/medications/history`,
    
    
    patient: process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + '/patient',
    seizures: process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + `/seizures`,
    
    loadSeizuresHistory:process.env.REACT_APP_DASHBOARD_BASE_URL + '/patient/event-logs/email/${email}/type?type-id=3',
    
    loadSeizureFreeDays:process.env.REACT_APP_USER_PROFILE_BASE_URL + '/seizures/streak/${userID}',
    
    loadSleepQuality:process.env.REACT_APP_USER_SLEEP_QUALITY_URL + '/details/${userID}?current_time=${currentTime}',

    loadVoiceMemos:process.env.REACT_APP_DASHBOARD_BASE_URL + '/patient/event-logs/email/${email}/type?type-id=2',

    voiceMemos:process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + '/voice-memos',
    
    loadEmergencyContacts: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/emergency-contact/load',
    saveEmergencyContact: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/emergency-contact/save',
    removeEmergencyContact: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/emergency-contact/remove',
    sortEmergencyContacts: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/emergency-contacts/update-order',

    loadHeartRate: process.env.REACT_APP_HEARTRATE_BASE_URL + '/n4e-heartrate-api/patient',

    loadRingData: process.env.REACT_APP_USER_PROFILE_RING_BASE_URL + '/fetch',

    loadEventsLog: process.env.REACT_APP_DASHBOARD_BASE_URL + '/eventlog/fetch/range/',

    sendMedicationNotification: process.env.REACT_APP_USER_PROFILE_WATCH_BASE_URL + '/push',

    requestHelp: process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + '/request-help',

    loadAllMedications: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/medications',

    medication: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/medication',
    medications: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/medications',
    
    loadMedicationSchedule: process.env.REACT_APP_MEDICATION_SCHEDILE_BASE_URL + '/medication/email/${email}',
    loadUpcomingMedications: process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + '/medications/upcoming',
    savePatientProfileInfo: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/personal/info',
    loadUserData: process.env.REACT_APP_USER_PROFILE_BASE_URL + '/personal/info/',
    
    sleepHistory: process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + '/sleep/history/',
    sleepStatistics: process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + '/sleep/session/statistics/',
    sleepAlerts: process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + '/patient/',

  }

  static Global_EndPoints = {
    medicationsList: process.env.REACT_APP_BACKEND_API + '/medications',

    triggersList: process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL + `/seizures/triggers`
  }

  static Local = LocalStorageService.get('NovelaAppLanguage')
  static DefaultLocale = 'en'
  static DefaultDirection = 'ltr'
  static DefaultFont = EnglishFont
  static DefaultMessage = arMessages
  static DefaultPageSize = 7

  /**
   * Function To Check if the user is Authenticated or not
   * @returns {boolean}
   */
  static isAuth = () => {
    return !!localStorage.getItem('UserEmail') && !!localStorage.getItem('IsAuthenticated')
  }

  static logout = () => {
    localStorage.removeItem('UserEmail')
    localStorage.removeItem('PatientID')
    localStorage.removeItem('IsAuthenticated')
  }
  
  static isIOS = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }
}

export default Configuration
