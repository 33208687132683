import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { seizures } from '../../../utils/pages-meta.json'
import { NamedRoutes } from '../../../routes'
import AccountLayout from '../AccountLayout'
import SeizuresContent from '../../../components/Account/SeizuresContent'

const Seizures = () => {
  return (
    <>
      <SEO title={seizures.title}
           pathname={window.location.origin + NamedRoutes.account.seizures}
           titleTemplate='Novela'
           image={seizures.image}
           description={seizures.description}
      />
      <AccountLayout content={<SeizuresContent/>}/>
    </>
  )
}

export default Seizures
