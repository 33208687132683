import React from 'react'
import { ProfileProvider } from '../../../ProfileContext'
import { AuthConsumer } from '../../../AuthContext'
import {ProfileConsumer}  from '../../../ProfileContext'
import WeeklySleepScore from './WeeklySleepScore'

const SleepContent = () => {
  return (
    <AuthConsumer>
      {({ userInfo }) => (
        !!Object.keys(userInfo).length &&
        <ProfileProvider userId={userInfo.id}>
          <WeeklySleepScore />
        </ProfileProvider>
      )}
    </AuthConsumer>
  )
}
export default SleepContent
