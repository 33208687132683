import { DatePicker, TimePicker } from "antd"
import "antd/dist/antd.css"
import styled from 'styled-components'
import './StyledTimePicker.css'


const StyledTimePicker = styled(TimePicker)`
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(224,224,224,1);

    input {
        padding: 11px 0;
        font-size: 1rem;
    }
`

const StyledDatePicker = styled(DatePicker)`

    border-radius: 4px;
    border: 1px solid rgba(224,224,224,1);
    width: 100%;

    input {
        padding: 11px 0;
        font-size: 1rem;
    }

`

const StopwatchIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill='#cecece' width="20" height="20" viewBox="0 0 159 159">
            <path id="Rounded_Rectangle_2" data-name="Rounded Rectangle 2" className="cls-1" d="M79.5,159A65.5,65.5,0,0,1,70,28.694V23a2,2,0,0,1,2-2H86a2,2,0,0,1,2,2v5.567A65.493,65.493,0,0,1,79.5,159Zm0-121A55.5,55.5,0,1,0,135,93.5,55.5,55.5,0,0,0,79.5,38ZM96,17H61a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2H96a2,2,0,0,1,2,2V15A2,2,0,0,1,96,17Z"/>
            <path className="cls-1" d="M79.5,49A44.5,44.5,0,0,1,124,93.5c0,24.577-24.007,45.575-44.5,44.5C53.48,136.636,45,120,45,120L80,94V49H79.5Z"/>
            <path id="Rounded_Rectangle_1_copy" data-name="Rounded Rectangle 1 copy" className="cls-1" d="M149.878,40.631l-14-14c-1.405-1.405-3.3-1.785-4.241-.848l-9.331,9.331c-0.937.937-.557,2.836,0.848,4.241l14,14c1.405,1.405,3.3,1.785,4.241.848l9.331-9.331C151.663,43.936,151.283,42.037,149.878,40.631Z"/>
            <path id="Rounded_Rectangle_1_copy_2" data-name="Rounded Rectangle 1 copy 2" className="cls-1" d="M9.122,40.631l14-14c1.405-1.405,3.3-1.785,4.241-.848l9.331,9.331c0.937,0.937.557,2.836-.848,4.241l-14,14c-1.405,1.405-3.3,1.785-4.241.848L8.274,44.873C7.337,43.936,7.717,42.037,9.122,40.631Z"/>
        </svg>
    )
}



export { StyledTimePicker, StyledDatePicker, StopwatchIcon }