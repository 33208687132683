import React from 'react'

const VisionProblemsIcon = () => {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.9 595.3" version="1.1" x="100px" y="100px"  viewBox="0 0 70 97" style={{enableBackground:"new 0 0 70 97"}} >
     <g>
        <g>
          <path fill="#462D7D" class="st0" d="M43.2,22.9h-0.8c-0.5,1.6-1.9,2.7-3.6,2.9L35,30.4l-5.1-2.4l-11.5,9.1c-0.7,0.6-1.7,0.9-2.6,0.9
            c-1.1,0-2.1-0.4-2.8-1.1l-8.3-7.7C3.6,37,4.1,43.6,5.8,51.5c0,0.3,0.1,0.7,0.2,1c0.1,0.2,5,18-4.4,38c-1.2,2.5-0.1,5.6,2.4,6.8
            c0.7,0.3,1.4,0.5,2.2,0.5c1.9,0,3.7-1.1,4.6-2.9c6.9-14.8,7.2-28.5,6.4-36.9c0.2,0,0.5,0.1,0.7,0.1l-0.4,1l-0.1,0
            c0.5,0.2,13.1,6.3,6.3,32.3c-0.7,2.7,0.9,5.5,3.6,6.2c0.4,0.1,0.9,0.2,1.3,0.2c2.3,0,4.3-1.5,4.9-3.8c7-26.7-3.2-38.2-8.9-42.3
            c-2.5-8.2-1-12.8,2-18.2l7.8,3.7c0.4,0.2,0.9,0.3,1.4,0.3c0.9,0,1.9-0.4,2.5-1.2l8.9-10.6C45.4,25.8,43.8,24.6,43.2,22.9z"/>
          <path fill="#462D7D" d="M25.7,16.9l3,1c1-1.5,1.6-3.2,1.6-5.2c0-5.1-4.1-9.2-9.2-9.2c-5.1,0-9.2,4.1-9.2,9.2c0,5.1,4.1,9.2,9.2,9.2
            c1.1,0,2.1-0.2,3.1-0.5l-0.7-0.4L25.7,16.9z M25.7,10.8c1,0,1.8,0.8,1.8,1.8s-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8
            S24.7,10.8,25.7,10.8z"/>
          <path fill="#462D7D" d="M42.1,19.3l1.2,0.4c0.1-0.3,0.3-0.6,0.5-0.8L41,18l0,0C41.4,18.4,41.8,18.8,42.1,19.3z"/>
          <path fill="#462D7D" d="M47.3,18c-1.7,0-3.2,1.3-3.4,3h-2.2c-0.2-1.7-1.7-3-3.4-3c-1.6,0-2.9,1.1-3.3,2.5c0.2,0.5,0.2,1.1,0.2,1.7
            c0,0.1,0,0.3-0.1,0.4c0.5,1.3,1.8,2.2,3.2,2.2c1.7,0,3.2-1.3,3.4-3h2.2c0.2,1.7,1.7,3,3.4,3c1.9,0,3.4-1.5,3.4-3.4
            C50.8,19.6,49.2,18,47.3,18z M35.8,21.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5
            C36.9,24,35.8,22.8,35.8,21.5z M44.8,21.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5
            C45.9,18.9,44.8,20.1,44.8,21.5z"/>
          <path fill="#462D7D" d="M26.2,18l-1.4,2.5l0.6,0.3l0.6-0.5l0.7-1.3l0.7,0.2l0.9-0.7L26.2,18z"/>
          <path fill="#462D7D" d="M13.6,36.2c0.6,0.6,1.4,0.9,2.2,0.9c0.7,0,1.4-0.2,2-0.7l15.1-12c1.4-1.1,1.6-3.2,0.5-4.6
            c-1.1-1.4-3.2-1.6-4.6-0.5L16,29.5l-4.7-4.4l1-0.4l3.8,3.5l4.3-3.4c-4.4-0.9-9.5-2-13-2.8C7.2,22,7.1,22,7,21.9
            c-0.2,0-0.4-0.1-0.6,0c-0.8,0-1.7,0.4-2.3,1c-1.2,1.3-1.1,3.4,0.2,4.6l0.3,0.3L13.6,36.2z"/>
          <path fill="#462D7D" d="M38.1,2.1c0.4-0.4,0-1.3-0.4-0.9c-1,1-1.7,2.3-1.9,4c-0.2,1.8,1.1,2.9,1.3,4.6c0.1,1.1-0.4,2.3-1,3.1
            c-0.4,0.4,0.2,1.2,0.5,0.7c1-1.2,1.4-3,1.1-4.8c-0.3-1.8-1.6-2.6-0.9-4.6C37,3.3,37.6,2.6,38.1,2.1z"/>
          <path fill="#462D7D" d="M42.5,2.1c0.4-0.4,0-1.3-0.4-0.9c-1,1-1.7,2.3-1.9,4c-0.2,1.8,1.1,2.9,1.3,4.6c0.1,1.1-0.4,2.3-1,3.1
            c-0.4,0.4,0.2,1.2,0.5,0.7c1-1.2,1.4-3,1.1-4.8c-0.3-1.8-1.6-2.6-0.9-4.6C41.5,3.3,42,2.6,42.5,2.1z"/>
          <path fill="#462D7D" d="M47,2.1c0.4-0.4,0-1.3-0.4-0.9c-1,1-1.7,2.3-1.9,4C44.5,6.9,45.8,8,46,9.7c0.1,1.1-0.4,2.3-1,3.1
            c-0.4,0.4,0.2,1.2,0.5,0.7c1-1.2,1.4-3,1.1-4.8c-0.3-1.8-1.6-2.6-0.9-4.6C45.9,3.3,46.5,2.6,47,2.1z"/>
        </g>
        <circle fill="#FFFFFF" cx="19.3" cy="11.8" r="2.6"/>
      </g>
      </svg>
 
  )
}

export default VisionProblemsIcon
