/**
 * General Services class
 */
import RestClient from '../Client/RestClient'
import Configuration from '../Api/Configuration'

const { User_EndPoints } = Configuration

class UserServices {
  static login (data, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(User_EndPoints.login, data, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static tokenLogin (data, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(User_EndPoints.tokenLogin, data, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static organizationTokenLogin (data, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(User_EndPoints.organizationTokenLogin, data, {headers:{"n4e-patient-id": data.patient_id, "n4e-organization-id": data.organization_id}}, setFormLoading, setFailure, FormikActions, true)
  }

  static forgotPassword (data, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(User_EndPoints.forgotPassword, data, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static resetPassword (email, data, setFormLoading, setFailure, FormikActions) {
    return RestClient.putRequest(User_EndPoints.resetPassword + `/${email}`, data, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static register (data, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(User_EndPoints.register, data, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static userInfo (email, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(User_EndPoints.userInfo, { 'email': email }, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static getUserInfo (userID, setFormLoading, setFailure, FormikActions) {
    return RestClient.getRequest(User_EndPoints.getUserInfo + userID, { }, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static watchData (email, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(User_EndPoints.watchData, { 'email': email }, {}, setFormLoading, setFailure, FormikActions, true)
  }
}

export default UserServices
