import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { patientProfile } from '../../../utils/pages-meta.json'
import { NamedRoutes } from '../../../routes'
import AccountLayout from '../AccountLayout'
import PatientProfileContent from '../../../components/Account/PatientProfileContent'

const PatientProfile = () => {
  return (
    <>
      <SEO title={patientProfile.title}
           pathname={window.location.origin + NamedRoutes.account.patientProfile}
           titleTemplate='Novela'
           image={patientProfile.image}
           description={patientProfile.description}
      />
      <AccountLayout content={<PatientProfileContent/>}/>
    </>
  )
}

export default PatientProfile
