import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { LanguageProvider } from './LanguageContext'
import ThemeProvider from './ThemeProvider'
import Layout from './components/Layout'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './AuthContext'

// @refresh reset

ReactDOM.render(
  <>
    <Router forceRefresh={false}>
      <LanguageProvider>
        <AuthProvider>
          <ThemeProvider>
            <Layout/>
          </ThemeProvider>
        </AuthProvider>
      </LanguageProvider>
    </Router>
  </>,
  document.getElementById('root')
  )
  
serviceWorker.unregister()
