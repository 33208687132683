import Select from 'react-select'
import styled from 'styled-components'

const ProfileDropdowns = styled(Select)`
    font-size: 1rem;
    & div[class$="-control"] {
        border: 1px solid rgba(224, 224, 224, 1);
        padding: 9.5px 0;
        text-transform: capitalize;
        &:hover{
            border-color: #222;
        }
    }
    & div[class$="-placeholder"]{
        opacity: 0.5;
    }
    & div[class$="-option"]{
        text-transform: capitalize;
    }
    `

const SelectTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: "#432f7a",
        primary50: "#a190ce",
        primary25: "#e0d5fb"
    }
})

export {ProfileDropdowns, SelectTheme}
