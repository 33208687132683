import React from 'react'
import {useStyles} from './Styles'
import PersonalInfoForm from './Form'
// import { AuthConsumer } from '../../../../../AuthContext'
import {ProfileConsumer} from '../../../../../ProfileContext'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'

const PersonalInfoTab = ({loadUserData, userData, setUserData}) => {
  const classes = useStyles();


  const isFormSave = (
    userData.firstName == null || userData.firstName.length == 0 ||
    userData.lastName == null || userData.lastName.length == 0 ||
    userData.email == null || userData.email.length == 0 ||
    userData.mobileNumber == null || userData.mobileNumber.length == 0 ||
    userData.gender == null || userData.gender.length == 0 ||
    userData.weight == null || userData.weight.length == 0 ||
    userData.weightUnit == null || userData.weightUnit.length == 0 ||
    userData.heightUnit == null || userData.heightUnit.length == 0 ||
    userData.birthday == null || userData.birthday.length == 0 ||
    userData.month == null || userData.month.length == 0 ||
    userData.weightUnit == null || userData.weightUnit.length == 0 ||
    userData.day == null || userData.day.length == 0 ||
    userData.year == null || userData.year.length == 0 ||
    userData.timezone == null || userData.timezone.length == 0 ||
    userData.bedtime == null || userData.bedtime.length == 0 
    );


  React.useEffect(() => {
      loadUserData(true);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])


  return (
    <div className={classes.PersonalInfoTab}>
            <ProfileConsumer>
             {({ patientProfileInfoSaveSuccess, patientProfileInfoSaveFailure, handlePatientProfileInfoSave }) => (
               <>
                 {!!patientProfileInfoSaveSuccess &&
                 <Alert severity="success"><FormattedMessage id='EndPoint.PersonalInfoForm.Success'/></Alert>}
                 {!!patientProfileInfoSaveFailure && !patientProfileInfoSaveSuccess &&
                 <Alert severity="error">
                   {/* <FormattedMessage id='EndPoint.PersonalInfoForm.Failure'/> */}
                   <FormattedMessage id='EndPoint.PersonalInfoForm.ContactUsForChanges'/> 
                   </Alert>}


                { !!Object.keys(userData).length  && 
                <PersonalInfoForm
                  userData={userData}
                  setUserData={setUserData}
                  onSubmit={handlePatientProfileInfoSave}
                  isFormSave={isFormSave}/>}
               </>
             )}
           </ProfileConsumer>
    </div>
  )
}

export default PersonalInfoTab