import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  rowItemWrapper: {
    width: '100%',
    padding: theme.spacing(1, 0, 1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
  },
  rowItemContentWrapper: {
    display: 'flex',
    width: '50%',
  },
  rowItemCircleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& i': {
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(30),
      '&.icon-day': {
        color: '#FFC107'
      },
      '&.icon-night': {
        color: "#32478e",
      }
    }
  },
  rowItemCircle: {
    width: 112,
    height: 112,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rowItemBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& i': {
      margin: '0 15px',
      fontSize: theme.typography.pxToRem(24)
    },
    '& .icon-day': {
      color: '#ffc107'
    },
    '& .icon-night': {
      color: "#32478e",
    },
  },
  rowItemTitle: {
    fontSize: theme.typography.pxToRem(18),
    '&:first-child': {
      fontSize: theme.typography.pxToRem(26),
    }
  },
  rowItemSubTitle: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey[500],
  },
  rowItemContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      width: '100%'
    }
  },
  rowItemContentTitle: {
    margin: 0,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    paddingBottom: theme.spacing(0.5),
    '@media (max-width: 815px)': {
      fontSize: "1.125rem",
    },
  },
  rowItemLocation: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(0.5)
  },
  rowItemNote: {
    padding: theme.spacing(1, 0)
  },
  addNote: {
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      textDecoration: 'underline',
      color: theme.palette.primary.main
    }
  },
  EditIcon: {
    margin: 'auto 25px'
  },
  success: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.success.main,
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(48)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(28)
      }
    }
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(16),
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(48)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(28)
      }
    }
  },
  editIcon: {
    '&.MuiButton-root': {
      color: theme.palette.grey[500],
      marginLeft: theme.spacing(0),
      minWidth: 0,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        right: 0,
        top: 16
      }
    }
  }
}))
