import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import {SEO} from '../../../components/Seo/seo'
import {tokenLogin} from '../../../utils/pages-meta.json'
import TokenLoginCard from '../../../components/Auth/TokenLoginCard'
import {NamedRoutes} from '../../../routes'

const TokenLogin = () => {
    let { ring_sn, verification_token } = useParams()
    return (
        <>
        {!!ring_sn && !!verification_token ? <>
                <SEO title={tokenLogin.title}
                    pathname={window.location.origin + NamedRoutes.auth.tokenLogin}
                    titleTemplate='Novela'
                    image={tokenLogin.image}
                    description={tokenLogin.description}
                />
                <TokenLoginCard ring_sn={ring_sn} verification_token={verification_token} /> 
            </>:
                <Redirect to={NamedRoutes.auth.login}/>
        }
        </>
    )
}

export default TokenLogin
