import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  medicationHistoryContainer: {
    display: 'flex',
    height: '85vh',
    overflow: 'auto',
    justifyContent: 'space-between',
    '@media (max-width: 815px)': {
      height: '100vh'
    },
  },
  medicationHistoryRows: {
    width: '63%',
  },
  NoMedHistoryRows: {
    width: '63%',
    display: "flex",
  },
  calendarContainer: {
    width: '35%',
    justifyContent: 'center',
    marginTop: 0,
  },
  NoMedHistoryContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }, 
  NoMedHistoryLabel: {
    marginBottom: "47px",
    fontWeight: 400,
    fontSize: '22px',
    fontStyle: 'italic',
    color: '#929292',
  },
  MedicationsTabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 0px 0px 2px ${theme.palette.grey[200]}`,
    '& .MuiTabs-flexContainer': {
      borderBottom: `1px solid ${theme.palette.text.primary}`
    },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.text.primary,
      '&.Mui-selected': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiTab-root': {
      textTransform: 'capitalize',
      minHeight: 80
    },
    '& .tab': {
      padding: theme.spacing(2)
    },
  },
  unscheduledMedicationRows: {
    marginBottom: theme.spacing(2)
  },
  buttonsRow: {
    marginRight: theme.spacing(2),
  }
}))
