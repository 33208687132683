import React from 'react'
import { useStyles } from './../DBStyles'
import { AuthConsumer } from '../../../../AuthContext'
import { ProfileProvider } from '../../../../ProfileContext'
import NotFeelingWell from './NotFeelingWell'
import SleepQuality from './SleepQuality/'
import { Paper } from '@material-ui/core';


const DashboardRightCell = () => {
  let classes = useStyles()
  return (
    <Paper className="DBGridCellThree">
                
    <AuthConsumer >
    {({ userInfo }) => (
      !!Object.keys(userInfo).length &&
      <ProfileProvider userId={userInfo.id}>
        <SleepQuality />
      </ProfileProvider>
    )}
  </AuthConsumer>

</Paper>
  )
}

export default DashboardRightCell