import React from 'react'
import {useStyles} from './Styles'
import {Button} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import {FormattedMessage} from 'react-intl'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ringImage from '../../../../images/ring.png';
import DizzinessIcon from './icons/DizzinessIcon'
import ShakyIcon from './icons/ShakyIcon'
import HairLossIcon from './icons/HairLossIcon'
import InsomniaIcon from './icons/InsomniaIcon'
import PoorConcentrationIcon from './icons/PoorConcentrationIcon'
import SkinRashIcon from './icons/SkinRashIcon'
import VisionProblemsIcon from './icons/VisionProblemsIcon'
import OthersIcon from './icons/OthersIcon'
import effectGradeOne from './iconEffectGrades/sideEffectGrade1.png'
import effectGradeTwo from './iconEffectGrades/sideEffectGrade2.png'
import effectGradeThree from './iconEffectGrades/sideEffectGrade3.png'
import effectGradeFour from './iconEffectGrades/sideEffectGrade4.png'
import './styles.scss'


const RowItem = ({ day, icon, time, voiceMemo, transcription, sideEffectType, sideEffectIcon, sideEffectGrade }) => {

  let iconStyle = {height:"50px",width:"50px",marginLeft:"10px "};
  let sideEffectIconStyle = {height:"70px",width:"70px", marginTop:"10px"};
  let iconPoorStyle = {height:"70px",width:"100px",marginLeft:"10px "};
  const classes = useStyles()
  /**
   * listen to the play event in the capturing phase
   * and then pause all audio files, but not the target one.
   */
  React.useEffect(() => {
    document.addEventListener('play', function (e) {
      let audios = document.getElementsByTagName('audio')
      for (let i = 0, len = audios.length; i < len; i++) {
        if (audios[i] !== e.target) {
          audios[i].pause()
        }
      }
    }, true)
  }, [])

  return (
    <section className={classes.rowItemWrapper}>
        <div className={classes.rowItemCircleWrapper}>
          <div className={classes.rowItemCircle}>
            <span className={classes.rowItemTitle}>{day}</span>
            <span className={classes.rowItemSubTitle}>{time}</span>
          </div>
          <i className={icon}/>
        </div>
      <div className={classes.rowItemContentWrapper}>
        <div className={classes.rowItemContentWithIcon}>
          <div className={classes.sideEffectDiv}>
            <h2 className={classes.rowItemContentTitle}>{sideEffectType}</h2>
          
          
          {sideEffectIcon =="icon-shaky" ?
              (<div style={iconStyle}><ShakyIcon/></div>)
            :((sideEffectIcon =="icon-hair-loss" || sideEffectIcon =="icon-hair loss") ?
              (<div style={iconStyle}><HairLossIcon/></div>) 
            :(sideEffectIcon =="icon-insomnia" ?
              (<div style={iconStyle}><InsomniaIcon/></div>) 
            :(sideEffectIcon =="icon-poor concentration" ?
              (<div style={iconPoorStyle}><PoorConcentrationIcon/></div>) 
            :(sideEffectIcon =="icon-skin rash" ?
              (<div style={iconStyle}><SkinRashIcon/></div>) 
            :((sideEffectIcon =="icon-vision-problems" ||  sideEffectIcon =="icon-vision problems") ?
              (<div style={iconStyle}><VisionProblemsIcon/></div>) 
            :(sideEffectIcon =="icon-dizziness" ?
              (<div style={iconStyle}><DizzinessIcon/></div>) 
            :((sideEffectIcon =="icon-others" || sideEffectIcon =="icon-other") ?
              (<div style={iconStyle}><OthersIcon/></div>) 
              : (<i className={sideEffectIcon}/>))))))))
          }
          </div>
          
          {sideEffectGrade === 1 ? 
              (<div className={classes.sideEffectGradeDiv}>
                <h2 className={classes.rowItemContentTitle}>Minimal</h2>
                <img className='sideEffectGradeImg' alt="side effect grade of 1" src={effectGradeOne}/>
              </div>)
            : ((sideEffectGrade === 2) ?
              (<div className={classes.sideEffectGradeDiv}>
                <h2 className={classes.rowItemContentTitle}>Mild</h2>
                <img className='sideEffectGradeImg' alt="side effect grade of 2" src={effectGradeTwo}/>
              </div>)
            : ((sideEffectGrade === 3) ?
              (<div className={classes.sideEffectGradeDiv}>
                <h2 className={classes.rowItemContentTitle}>Moderate</h2>
                <img className='sideEffectGradeImg' alt="side effect grade of 3" src={effectGradeThree}/>
              </div>)
            : ((sideEffectGrade === 4) ?
              (<div className={classes.sideEffectGradeDiv}>
                <h2 className={classes.rowItemContentTitle}>Severe</h2>
                <img className='sideEffectGradeImg' alt="side effect grade of 4" src={effectGradeFour}/>
              </div>)
            : null )))
          }

        </div>
          {voiceMemo && 
          <div className={classes.rowItemAudioWrapper}>
            <audio src={voiceMemo} controls className={classes.rowItemAudio}/>
          </div>
          }
          {transcription && <div className={classes.rowItemTranscription}>
            <p style={{fontStyle: "italic", textDecoration: "underline", marginBottom: "10px"}}>Audio Transcription</p> 
            <p>{transcription}</p>
            </div>}
      </div>
    </section>
  )
}

export default RowItem
