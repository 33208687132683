import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { eBook } from '../../../utils/pages-meta.json'
import { NamedRoutes } from '../../../routes'
import AccountLayout from '../AccountLayout'
import EBookContent from '../../../components/Account/EBookContent'

const EBook = () => {
  return (
    <>
      <SEO title={eBook.title}
           pathname={window.location.origin + NamedRoutes.account.eBook}
           titleTemplate='Novela'
           image={eBook.image}
           description={eBook.description}
      />
      <AccountLayout content={<EBookContent/>}/>
    </>
  )
}

export default EBook
