import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { sideEffects } from '../../../utils/pages-meta.json'
import { NamedRoutes } from '../../../routes'
import AccountLayout from '../AccountLayout'
import SideEffectsContent from '../../../components/Account/SideEffectsContent'

const SideEffects = () => {
  return (
    <>
      <SEO title={sideEffects.title}
           pathname={window.location.origin + NamedRoutes.account.sideEffects}
           titleTemplate='Novela'
           image={sideEffects.image}
           description={sideEffects.description}
      />
      <AccountLayout content={<SideEffectsContent/>}/>
    </>
  )
}

export default SideEffects
