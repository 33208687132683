import React from 'react'
import {useStyles} from './Styles'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

const Item = ({date, title}) => {
  let classes = useStyles()

  return (
    <div className={classes.itemWrapper}>
      <AccessTimeIcon/>
      <span className={classes.itemDate}>{date}</span>
      <h3 className={classes.itemTitle}>{title}</h3>
    </div>
  )
}

export default Item
