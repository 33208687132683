import React from 'react'
import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { useStyles } from './Styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { FormattedMessage } from 'react-intl'
import { ProfileConsumer } from '../../../../../ProfileContext'
import Alert from '@material-ui/lab/Alert'
import { FormLoader } from '../../../../Loader/FormLoader'
import moment from 'moment'

const RescueTable = ({ meds, handleDeleteMed, switchToRescueEditForm, columns, getUnscheduledMeds }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.itemTableWrapper}>
        {!!meds ?
        <TableContainer className={classes.itemTableContainer}>
           <Table stickyHeader aria-label="sticky table">
             <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
              <>
              {meds.map((med) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={med.id}>
                    {columns.map((column) => {
                      const value = med[column.id];
                      let measure_type = med['dose_units']

                      return (
                        <TableCell key={column.id}>
                          {column.id === 'Actions' ?
                          <>
                            <div>
                              <button
                              type='button'
                              className={classes.actionButton + ' delete'}
                              onClick={() => {
                                handleDeleteMed(med.id);
                              }}>
                                <DeleteIcon/>
                              </button>
                            </div>
                          </>
                          :
                          (column.id === 'medication_name') ? value
                          :
                          (column.id === 'form') ? value != "" ? value[0].toUpperCase() + value.slice(1): value
                          :
                          (column.id === 'dose') ? value + ' ' + measure_type
                          : <></>
                        }
                        </TableCell>
                      )
                    })}

                  </TableRow>
                )
              })}
              </>
            
          </TableBody>

           </Table>
         </TableContainer>
        :
        <>
        </> 
        }
         
      </div>
    </div>
  )
}

export default RescueTable