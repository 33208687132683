import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  voiceMemosContent: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 0px 0px 2px ${theme.palette.grey[200]}`,
    padding: theme.spacing(3, 3, 0, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  voiceMemosContentTitle: {
    margin: theme.spacing(0),
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(34),
    fontWeight: theme.typography.fontWeightLight
  }
}))
