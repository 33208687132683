import React from 'react'
import ForgotPasswordForm from './Form'
import AuthLogo from '../AuthLogo'
import { AuthConsumer } from '../../../AuthContext'

const ForgotPasswordCard = () => {

  return (
    <>
      <AuthLogo/>
      <AuthConsumer>
        {({ handleForgotPasswordSubmit }) => (
          <ForgotPasswordForm onSubmit={handleForgotPasswordSubmit}/>
        )}
      </AuthConsumer>
    </>
  )
}

export default ForgotPasswordCard
