import { Modal } from "antd";
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
    & .ant-modal-content {
        border-radius: 20px;
        text-align: center;

        & .mainText {
            font-size: 1.3rem;
            font-weight: 400;
            width: 80%;
            margin: 10px auto;
        }

        & .note {
            width: 80%;
            margin: 10px auto;
            font-size: 0.9rem;
        }

    }
    & .ant-modal-footer {
        border-radius: 0 0 20px 20px;
        padding: 0;
        height: 50px;
        display: flex;
        justify-content: space-around;
        & button {
            width: 50%;
            margin: 0 !important;
            height: 100%;
            border-radius: 0 0 0 20px;

            & span {
                text-transform: uppercase;
                letter-spacing: 0.5px;
                font-size: 1rem;
            }

        }

        & .ant-btn-dangerous.ant-btn-primary,
        & .ant-btn-primary {
            border-radius: 0 0 20px 0;
        }

    }
`