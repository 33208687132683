import React from 'react'
import { Button } from '@material-ui/core'
import { useStyles } from './DBStyles'
import { StyledHelpModal } from '../../StyledComponents/StyledHelpModal'
import moment from 'moment'

const HelpButton = ({ handleRequestHelp, requestHelpLoading, requestHelpFailure }) => {
  let classes = useStyles()

  const [isHelpModalOpen, setIsHelpModalOpen] = React.useState(false);

  const showHelpModal = () => {
    setIsHelpModalOpen(true);
  }
  const closeHelpModal = () => {
    setIsHelpModalOpen(false);
  }

  const requestHelp = (data) => {
    handleRequestHelp(data);
  }

  const handleHelpClick = () => {
    const helpParams = {
      timestamp: moment().utc().format(),
      device_type: "browser",
      latitude: null,
      longitude: null
    }

    requestHelp(helpParams);
    showHelpModal();
  }
  
  return (
    <div>
      <button className={"HelpButton"} onClick={handleHelpClick}>
        <div className={classes.HelpButtonInnerCircle}>
          <p className={classes.HelpButtonString}>Help</p>
        </div>
      </button>
      {!!isHelpModalOpen && !requestHelpLoading &&
        (!requestHelpFailure ?
        <div className={classes.RHNotifications}>
            <StyledHelpModal
              okButtonProps={{ danger: true }}
              okText="OK"
              visible={isHelpModalOpen}
              onOk={closeHelpModal}
              onCancel={closeHelpModal}
              width={350}
              footer={[
                <Button key="close" onClick={closeHelpModal}>
                  Ok
                </Button>
              ]}
            >
              <p className="mainText">A text message has been sent to your emergency contact.</p>
            </StyledHelpModal>
        </div>
        : <div className={classes.RHNotifications}>
        <StyledHelpModal
              okButtonProps={{ danger: true }}
              okText="OK"
              visible={isHelpModalOpen}
              onOk={closeHelpModal}
              onCancel={closeHelpModal}
              width={350}
              footer={[
                <Button style={{backgroundColor: "#f75959"}}key="close" onClick={closeHelpModal}>
                  Ok
                </Button>
              ]}
            >
              <p className="mainText">Something went wrong.</p>
              <p className="note">Please try again.</p>
            </StyledHelpModal>
      </div>)
      }

    </div>
  )
}

export default HelpButton
