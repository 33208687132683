import React from 'react'
import OrganizationTokenLoginForm from './Form'
import AuthLogo from '../AuthLogo'
import { AuthConsumer } from '../../../AuthContext'

const OrganizationTokenLoginCard = ({organization_token, verification_token, patientID, organizationID}) => {

    return (
        <>
        <AuthLogo/>
        <AuthConsumer>
            {({ handleOrganizationTokenLoginSubmit }) => (
                <OrganizationTokenLoginForm organization_token={organization_token} verification_token={verification_token} patientID={patientID} organizationID={organizationID} onSubmit={handleOrganizationTokenLoginSubmit}/>
            )}
        </AuthConsumer>
        </>
    )
}

export default OrganizationTokenLoginCard