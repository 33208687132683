import React from 'react'
import { Container, Grid } from '@material-ui/core'
import { md,sm } from '../../utils/ResponsiveUtility'
import MobileMenu from '../MobileMenu'
import { useStyles } from './Styles'
import Logo from '../Logo'
import UserMenu from '../UserMenu'
import { FormattedMessage } from 'react-intl'
import TimeWeather from '../../components/Account/DashboardContent/TimeWeather'


const Header = () => {
  const classes = useStyles()
  let [width, setWidth] = React.useState(document.body.clientWidth)
  // let Mobile = false;// (width < sm)
  // let Tablet = (width < ipad)
  let Mobile = false;
  let DesktopAndUp = (width >= sm)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])


  return (
    <header className={classes.header} id='header'>
      <Container maxWidth={false}>
        <Grid container justify='space-between' alignItems='center'>
          <div className={classes.logoContainer}>
            {Mobile && <MobileMenu/>}
            <Logo/>
          </div>
          <div style={{display:"flex"}}>
            <TimeWeather/>
            {DesktopAndUp &&
            <>
              {/* <a className={classes.reportBugLink} href='https://airtable.com/shr9futTRMDH1GFEN' target='_blank'
                rel="noopener noreferrer">
                <FormattedMessage id='link.reportBug'/>
              </a> */}
              <UserMenu/>
              </>
            }
           </div>
        </Grid>
      </Container>
    </header>
  )
}
export default Header
