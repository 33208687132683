/** It checks if the user is authenticated, if they are,
 it renders the "component" prop. If not, it redirects
 the user to /login.
 **/
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { log } from '../utils/helpers'
import { NamedRoutes } from '../routes'
import Configuration from '../Services/Api/Configuration'

const AnonymousRoute = ({ component: Component, ...rest }) => {
  log('Access Token is set:', Configuration.isAuth())
  return (
    <>
      <Route {...rest} render={(props) => (
        !Configuration.isAuth() ? <Component {...props} /> :
          <Redirect to={{
            pathname: NamedRoutes.home,
            state: { from: props.location }
          }}/>
      )}/>
    </>
  )
}
export default AnonymousRoute
