import { useState, useEffect } from 'react'
import {useStyles} from './Styles'
import RowItem from './RowItem/RowItem'
import ItemFilter from './ItemFilter'
import {ProfileConsumer} from '../../../../../ProfileContext'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import { SearchLoader } from '../../../../Loader/SearchLoader'
import moment from "moment";
import SeizureHistoryForm from './SeizureHistoryForm/SeizureHistoryForm'
import { StyledModal } from '../../../../StyledComponents/StyledModal'
import { useLocation } from 'react-router-dom'
import "./styles.scss"

const SeizuresHistoryTab = ({ location, loadSeizuresHistory, loadSeizureTypes, editSeizureEvent, deleteSeizureEvent, getSeizureEvent, seizures }) => {
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"))
  const [editSeizure, setEditSeizure] = useState(false);
  const [seizureEventID, setSeizureEventID] = useState(null);
  const [eventsUpdated, setEventsUpdated] = useState(false);

  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [addSeizureWarning, setAddSeizureWarning] = useState(false);

  const {state} = useLocation();

  useEffect(() => {
    if(state && state.editSeizure){
      handleEditItem("new");
    }
  },[state])
  
  useEffect(() => {
    
    let query = '';
    const startDate = moment(selectedDate).startOf('d').utc().format("YYYY-MM-DD HH:mm:ssZ");
    const endDate = moment(selectedDate).endOf('d').utc().format("YYYY-MM-DD HH:mm:ssZ");
    
    if(selectedDate !== null) {
      query += `start_timestamp=${startDate}&end_timestamp=${endDate}`
    }
    loadSeizuresHistory(query)
    loadSeizureTypes();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedDate, eventsUpdated])

  useEffect(() => {
    if(seizureEventID !== "new") {
      getSeizureEvent(seizureEventID);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSeizure])

  const showDeleteModal = (id) => {
    setItemToDelete(id);
    setIsDeleteModalOpen(true);
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  }
  
  const handleDeleteItem = () => {
    deleteSeizureEvent(itemToDelete, eventsUpdated, setEventsUpdated);
    closeDeleteModal();
  }
  
  const handleEditItem = (id) => {
    setSeizureEventID(id);
    setEditSeizure(true);
  }
  
  const handleCloseEditForm = () => {
    setEditSeizure(false);
    setSeizureEventID(null);
    window.history.replaceState({}, document.title);
  }

  return (
    <div className="SeizuresHistoryTab">
      

      <ProfileConsumer>
        {({ seizuresHistoryLoading, seizuresHistoryFailure, seizuresHistory, seizuresHistorySuccess, seizures, seizureEventData, seizureEventDataLoading }) => (
          <>
            {editSeizure ?
              !!seizureEventDataLoading ? <SearchLoader loading={true} /> :
              <SeizureHistoryForm
                selectedDate={selectedDate}
                seizureTypes={seizures}
                seizureEventID={seizureEventID} 
                closeForm={handleCloseEditForm}
                editSeizureEvent={editSeizureEvent}
                eventsUpdated={eventsUpdated}
                setEventsUpdated={setEventsUpdated}
                seizureEventData={seizureEventID !== "new" ? seizureEventData : null}
                />

                 :
              <>
            {!!seizuresHistoryFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}
            {!!seizuresHistorySuccess && <Alert severity="success"><FormattedMessage id='EndPoint.Seizures.Success'/></Alert>}
            {!!seizuresHistoryLoading ? <SearchLoader loading={true}/> :

              (!!seizuresHistory && !!seizuresHistory.length) ?
      
              <>
                <div className="SeizureHistoryContainer">
                  <div className="ItemFilterColumn">
                    <ItemFilter selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
                  </div>
                   <div className="SeizureRowsColumn">
                    <h2 className="SelectedDate">
                      {moment(selectedDate).format("MMMM DD, YYYY")}
                    </h2>
                  {seizuresHistory.map((item) => {
                    const itemData = {
                      ...item,
                      day: moment(item.seizure_timestamp).format("D"),
                      month: moment(item.seizure_timestamp).format("MMM"),
                      time: moment(item.seizure_timestamp).format("h:mm A"),
                      duration: moment.utc(moment.duration(item.duration, "seconds").asMilliseconds()).format(item.duration >= 60 ? "m [min] s [sec]" : "s [sec]"),
                      icon: moment(item.seizure_timestamp).format("a") === 'am' ? 'icon-day' : 'icon-night'
                    }

                    itemData.type = item.seizure_custom_name
                    itemData.intensity = item.intensity
                    itemData.intensity_string = item.intensity_string
                    
                    
                    return (
                      <div className={classes.rowItemContainer}key={itemData.id}>
                        <RowItem
                          seizureData={itemData}
                          handleDeleteItem={showDeleteModal}
                          handleEditItem={handleEditItem}
                        />
                      </div>
                  )})}
                  <StyledModal
                    okButtonProps={{danger: true}}
                    cancelButtonProps={{style: {background: "#8b8b8b", color: "white", border: "none"}}}
                    okText="DELETE"
                    cancelText="CANCEL"
                    visible={isDeleteModalOpen}
                    onOk={handleDeleteItem}
                    onCancel={closeDeleteModal}>
                      <p className="mainText">Are you sure you want to delete this seizure?</p>
                      <span className="note">This action cannot be undone.</span>
                  </StyledModal>
              </div>
            </div>
                  
                  </>
              :
              <div className="SeizureHistoryContainer">

                  <div className={classes.ItemFilterColumn}>
                    <ItemFilter selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
                  </div>
                  <div className={classes.NoSeizureLabelContainer}>
                    <p className={classes.NoSeizureLabel}>No Seizure History for <span>{moment(selectedDate).format("MMMM DD, YYYY")}</span></p>
                  </div>
              </div>
            }
          </>
          }
          </>
        )}
      </ProfileConsumer>
      <div className="buttonsRow">
        {!editSeizure && 
        <>
        <section class="addSeizureButtonContainer">
          {addSeizureWarning &&
            <p className="addSeizureWarning">No seizure types are available.</p>
          }
          <button
            className="addSeizureButton"
            style={{cursor: (!seizures ? 'not-allowed' : ('pointer')), opacity: (!seizures ? 0.5 : 1)}}
            onClick={() => {
              if (!seizures) {
                setAddSeizureWarning(true);
              } else if (!!seizures) {
                setAddSeizureWarning(false);
                handleEditItem("new")
              }
            }}>
              <span>+</span>Log New Seizure Event
          </button>
        </section>
          
        </>
        }
      </div>
    </div>
    )
}

export default SeizuresHistoryTab
