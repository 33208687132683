import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { useStyles } from '../Styles'
import DeleteIcon from '@material-ui/icons/Delete'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import moment from 'moment'
import { StyledTimePicker } from '../../../../../StyledComponents/StyledTimePicker'

const Item = ({ data, index, handleDeleteReminderRow }) => {
  let classes = useStyles()
  return (
    <div className={classes.reminderListRow}>
      <span className={classes.reminderListDose}>{data.num_of_doses > 0 || data.num_of_doses !== "" ? data.num_of_doses : data.num_of_doses[0] == '1' ? data.dose + 'Dose' : data.num_of_doses + 'Doses'}</span>
      <div className={classes.reminderListTime}>
        <AccessTimeIcon/>
        <span>{moment(data.dose_time, 'HH:mm:ss').format('hh:mm A')}</span>
      </div>
      <div className={classes.deleteButtonWrapper}>
        <button type='button' className={classes.deleteButton} onClick={() => handleDeleteReminderRow(index)}>
          <DeleteIcon/>
        </button>
      </div>
    </div>
  )
}
const AddReminder = ({ numberOfPills, reminderList, setReminderList, pillsAdded, setPillsAdded, chosenMedicationForm, setDosesPerDay}) => {
  let classes = useStyles()

  const [selectedDose, setSelectedDose] = React.useState(0);
  const [selectedTime, handleTimeChange] = React.useState("")
  const pillsPerReminder = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]
  const handleChange = (event) => {
    setSelectedDose(event.target.value)
  }
  const addNewReminderRow = () => {
    let newNumberOfPillsAdded = pillsAdded + selectedDose
      setPillsAdded(newNumberOfPillsAdded)
      setReminderList((prevList) => [...prevList,
        {
          'dose_time': `${selectedTime}`,
          'num_of_doses': `${selectedDose}`
        }
      ])
      setDosesPerDay(newNumberOfPillsAdded)
  }

  const handleDeleteReminderRow = (index) => {
    let newList = reminderList
    let deletedItem = newList.splice(index, 1)
    setReminderList(newList)
    setPillsAdded(pillsAdded - parseFloat(deletedItem[0].num_of_doses))
  }
  React.useEffect(() => {
    setPillsAdded(0)
  }, [numberOfPills, setPillsAdded])


  return (
    <>
      <div className={classes.addReminderProgressWrapper}>
        <h3 className={classes.addReminderTitle}>
          <FormattedMessage id='addReminder.title'/>
          </h3>
      </div>
      <div
        >
        <div className={classes.row}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="reminderDose">
              <FormattedMessage id='formActions.InputLabel.reminderDose'/>
            </InputLabel>
            <FormControl variant="outlined" margin="dense">
              <Select
                id="reminderDose"
                required
                fullWidth
                margin="dense"
                type="text"
                name='reminderDose'
                variant="outlined"
                value={selectedDose}
                onChange={handleChange}
              >
                {  
                  pillsPerReminder.map((item) => (
                    <MenuItem key={item} value={item}>{item} {(chosenMedicationForm != "Pill" ? "Dose" : "Pill")}{(item > 1) ? 's' : ''}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </FormControl>


          <FormControl>
            <InputLabel htmlFor="reminderTime">
              <FormattedMessage id='formActions.InputLabel.reminderTime'/>
            </InputLabel>
            <StyledTimePicker
              allowClear={false}
              id="reminderTime"
              name="reminderTime"
              use12Hours
              inputReadOnly={true}
              placeholder=""
              format="h:mm A"
              onChange={(time) => {
                handleTimeChange(time.format("HH:mm:ss"))
              }}
            />

          </FormControl>

          <div className={classes.formActionsWrapper}>
            <button type='button' className={classes.formActionSubmit}
                    disabled={selectedDose !== 1 && selectedDose !== 2 && selectedDose !== 3 && selectedDose !== 4 && selectedDose !== 5 && selectedDose !== 1.5 && selectedDose !== 2.5 && selectedDose !== 3.5 && selectedDose !== 4.5 && selectedDose !== .5 || selectedTime == ""} 
                    onClick={addNewReminderRow}>
              <FormattedMessage id='AddReminder.button.submit'/>
            </button>
          </div>
        </div>
      </div>
      <p className="TotalPillsPerDay">Total {(chosenMedicationForm != "Pill" ? "dose" : "pill")}{(pillsAdded > 1) ? 's' : ''} per day: {pillsAdded}</p>
      {!!reminderList && reminderList.map((item, index) => (
        <Item data={item} key={index} index={index} handleDeleteReminderRow={handleDeleteReminderRow}/>
        ))}
    </>
  )
}

export default AddReminder
