import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  sideEffectsContent: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 0px 0px 2px ${theme.palette.grey[200]}`,
    padding: theme.spacing(3, 3, 0, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  sideEffectsContentHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  sideEffectsContentTitle: {
    margin: theme.spacing(0),
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(34),
    fontWeight: theme.typography.fontWeightLight
  },
  sideEffectsAddNewButton: {
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      color: theme.palette.common.white
    }
  }
}))
