import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  userMenuButton: {
    borderRadius: 0,
    color: theme.palette.common.white
  },
  userMenuLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  }
}))
