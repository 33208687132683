import React from 'react'
import PropTypes from 'prop-types'
import {Tabs, Tab} from '@material-ui/core'
import {ProfileConsumer} from '../../../../ProfileContext'
import SeizuresHistoryTab from './SeizuresHistory'
import SeizureTypesTab from './SeizureTypes'
import {useStyles} from './Styles'
// import {ProfileConsumer,ProfileProvider} from '../../../../ProfileContext'

function TabPanel (props) {
  const {children, value, index, ...other} = props
  

  return (
    <div
      role="tabpanel"
      className='tab'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (children)}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `seizures-tab-${index}`,
    'aria-controls': `seizures-tabpanel-${index}`
  }
}

export default function SeizuresTabs () {
  let classes = useStyles()
  const [value, setValue] = React.useState(0)



  const handleSeizuresTabsChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.SeizuresTabs}>
      <Tabs
        value={value}
        onChange={handleSeizuresTabsChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        scrollButtons="auto"
        aria-label="Seizures Tabs"
      >
        <Tab label="Seizures History" {...a11yProps(0)} />
        <Tab label="My Seizures Types" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ProfileConsumer>
          {({ getLoadSeizuresHistory, getUserSeizures, handleEditSeizureEvent, handleDeleteSeizureEvent, handleGetSeizureEventData, seizures }) => (
            <SeizuresHistoryTab
            loadSeizuresHistory={getLoadSeizuresHistory}
            loadSeizureTypes={getUserSeizures}
            editSeizureEvent={handleEditSeizureEvent}
            deleteSeizureEvent={handleDeleteSeizureEvent}
            getSeizureEvent={handleGetSeizureEventData}
            seizures={seizures}/>
          )}
        </ProfileConsumer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProfileConsumer>
          {({ getUserSeizures, handleEditSeizureType, handleDeleteSeizureType }) => (
            <SeizureTypesTab loadSeizures={getUserSeizures} editSeizureType={handleEditSeizureType} deleteSeizureType={handleDeleteSeizureType}/>
          )}
        </ProfileConsumer>
      </TabPanel>
    </div>
  )
}
