import React from 'react'
import LoginForm from './Form'
import AuthLogo from '../AuthLogo'
import { AuthConsumer } from '../../../AuthContext'

const LoginCard = () => {

  return (
    <>
      <AuthLogo/>
      <AuthConsumer>
        {({ handleLoginSubmit }) => (
          <LoginForm onSubmit={handleLoginSubmit}/>
        )}
      </AuthConsumer>
    </>
  )
}

export default LoginCard
