import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  SeizureTab: {
    '& .MuiTableCell-root': {
      color: '#462D7D',
      textAlign: 'center',
      padding: '10px 8px',
    },
    '& .MuiTableCell-head': {
      color: '#585858',
      fontSize: '16px',
      fontWeight: 400,
      backgroundColor: '#f2f2f2',
    },
    '& .MuiTableCell-body': {
      color: '#585858',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textTransform: 'capitalize',
      textAlign: 'center',
      fontSize: '16px',
      padding: '5px',
      fontWeight: 200,
    },
    '& .MuiAlert-root': {
      marginBottom: theme.spacing(2)
    },
    "& .addButtonsRow": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginRight: "25px",
      "& .addButtons": {
        margin: "20px 10px",
        padding: theme.spacing(1.2, 3.5),
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
        border: 'none',
        borderRadius: 0,
        textTransform: 'unset',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        "&:disabled": {
          backgroundColor: "lightgrey",
          boxShadow: 'none',
        },
        "& span": {
          fontSize: theme.typography.pxToRem(16),
          marginRight: "8px"
        }
      }
    }
    
  },
  SeizureTypesFormTitle: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.primary,
    margin: theme.spacing(0, 0, 4, 0)
  },
  SeizureTypesForm: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiCheckbox-root': {
      padding: theme.spacing(1)
    },
    '& .MuiFormControlLabel-root': {
      minWidth: 150
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    '& .MuiFormControl-marginDense': {
      marginTop: 0
    }
  },
  SeizureTypesFormRow: {
    display: 'flex',
    alignItems: 'center'
  },
  formActionsWrapper: {
    marginTop: theme.spacing(4)
  },
  formActionSubmit: {
    padding: theme.spacing(1.5, 4),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    border: 'none',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:disabled': {
      opacity: 0.7,
      cursor: 'not-allowed'
    }
  },
  colorInput: {
    marginLeft: theme.spacing(1),
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: 0,
      height: 35,
      width: 35
    }
  },
  'bannerText': {
    color: '#A0A083',
    textAlign: 'center',
    padding: '7px 100px',
  },
  SeizureNameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '55px',
    borderRadius: '5px',
    color: '#4b2c7a',
    fontWeight: 400,
    fontSize: '16px'
  },
  editDeleteIcons: {
    '&.MuiButton-root': {
      color: theme.palette.grey[500],
      marginLeft: theme.spacing(0),
      minWidth: 0,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        right: 0,
        top: 16,
        '&:last-of-type': {
          right: 40
        }
      }
    }
  },
  seizureTypesContainer: {
    "& .seizureFullRow": {
      borderBottom: "1px solid lightgrey",
      position: "relative",
    },
    "& .seizureTypesRow": {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "0.5fr 1.5fr repeat(5, 1fr)",
      textAlign: "center",
      alignItems: "center",
      "& p": {
        margin: "14px auto",
        fontSize: "1rem"
      },
      "& .seizureNameCol": {
        textTransform: "capitalize",
        "& .seizureName": {
          background: "#462d7d",
          color: "white",
          padding: "10px 20px",
          borderRadius: "25px",
        }
      },
      "& .seizureFrequencyCol": {
        textTransform: "capitalize"
      },
      "& .seizureDescCol p": {
        whiteSpace: "nowrap",
        width: "120px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& .actionsCol": {
        position: "relative",
        "& .buttons": {
          position: "absolute",
          left: "20px",
          top: "-20px"
        }
      }
    },
    "& .seizureTypesHeader": {
      backgroundColor: "rgb(224, 224, 224)"
    },
    "& .seizureDetails": {
      display: "none"
    },
    "& .seizureChevron": {
      position: "absolute",
      top: "24px",
      left: "20px"
    },
    "& input[type=checkbox]:checked + .seizureDetails": {
      display: "grid",
      alignItems: "normal",
      textAlign: "left",
      paddingBottom: "20px",
      "& ul": {
        margin: "0"
      },
      "& .knownTriggers": {
        gridColumn: "2 / 3",
        marginLeft: "-15px"
      },
      "& .answeredQuestions": {
        gridColumn: "3 / 6",
        marginLeft: "-45px",
        paddingRight: "75px"
      },
      "& .seizureQuestion": {
        margin: "8px 0 0 0",
        fontWeight: "600"
      },
      "& .seizureAnswer": {
        fontSize: "1rem",
        margin: "0",
        textTransform: "capitalize"
      },
      "& .fullSeizureDescription": {
        gridColumn: "6 / 8",
        paddingRight: "25px",
        paddingTop: '8px',
        paddingLeft: '25px',
        marginLeft: "-75px",
        " & p": {
          margin: "0"
        }
      }
    },
    "& input[type=checkbox]:checked ~ label .seizureChevron": {
      transform: "rotate(90deg)"
    }
  },
  NoSeizureWarningLabelContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "16px",
    margin: "40px 0 20px 0",
    color: "#8b8b8b",
  }
  
}))
