import React from 'react'
import * as yup from 'yup'
import {FormattedMessage} from 'react-intl'

export const LoginFormValidation = yup.object().shape({
  email: yup.string()
    .email(<FormattedMessage id='validationSchema.validEmail'/>)
    .required(<FormattedMessage id='validationSchema.required'/>),
  password: yup.string().required(<FormattedMessage id='validationSchema.required'/>)
})
export const ForgotPasswordFormValidation = yup.object().shape({
  email: yup.string()
    .email(<FormattedMessage id='validationSchema.validEmail'/>)
    .required(<FormattedMessage id='validationSchema.required'/>)
})
export const ResetPasswordFormValidation = yup.object().shape({
  email: yup.string()
    .email(<FormattedMessage id='validationSchema.validEmail'/>)
    .required(<FormattedMessage id='validationSchema.required'/>),
  password: yup.string()
    .min(8, <FormattedMessage id='validationSchema.passwordMinimum'/>)
    .required(<FormattedMessage id='validationSchema.required'/>),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], <FormattedMessage id='validationSchema.passwordMatch'/>)
    .required(<FormattedMessage id='validationSchema.required'/>)
})
export const SignUpFormValidation = yup.object().shape({
  firstName: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  lastName: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  email: yup.string()
    .email(<FormattedMessage id='validationSchema.validEmail'/>)
    .required(<FormattedMessage id='validationSchema.required'/>),
  mobileNumber: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .min(8, <FormattedMessage id='validationSchema.required'/>),
  emergencyContactName: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  password: yup.string()
    .min(8, <FormattedMessage id='validationSchema.passwordMinimum'/>)
    .required(<FormattedMessage id='validationSchema.required'/>),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], <FormattedMessage id='validationSchema.passwordMatch'/>)
    .required(<FormattedMessage id='validationSchema.required'/>)
})
export const SeizureTypesFormValidation = yup.object().shape({
  aura1: yup.bool(),
  aura2: yup.bool(),
  focalPartial1: yup.bool(),
  focalPartial2: yup.bool(),
  tonicClonic: yup.bool(),
  absence: yup.bool(),
  myoClonic: yup.bool(),
  tonic: yup.bool(),
  atonic: yup.bool(),
  spasm: yup.bool(),
  aura1Label: yup.string().trim(),
  aura2Label: yup.string().trim(),
  focalPartial1Label: yup.string().trim(),
  focalPartial2Label: yup.string().trim(),
  tonicClonicLabel: yup.string().trim(),
  absenceLabel: yup.string().trim(),
  myoClonicLabel: yup.string().trim(),
  tonicLabel: yup.string().trim(),
  atonicLabel: yup.string().trim(),
  spasmLabel: yup.string().trim(),
  aura1Color: yup.string().trim(),
  aura2Color: yup.string().trim(),
  focalPartial1Color: yup.string().trim(),
  focalPartial2Color: yup.string().trim(),
  tonicClonicColor: yup.string().trim(),
  absenceColor: yup.string().trim(),
  myoClonicColor: yup.string().trim(),
  tonicColor: yup.string().trim(),
  atonicColor: yup.string().trim(),
  spasmColor: yup.string().trim()
}).test('SeizureTypesCheckbox', null, (obj) => {
  if ([obj.aura1, obj.aura2, obj.focalPartial1, obj.focalPartial2, obj.tonicClonic, obj.absence, obj.myoClonic, obj.tonic, obj.atonic, obj.spasm].filter(value => value).length === 4) {
    return true // everything is fine
  }
  return new yup.ValidationError(<FormattedMessage
    id='validationSchema.SeizureTypes.only4'/>, null, 'SeizureTypesCheckbox')
})
export const EmergencyContactsFormValidation = yup.object().shape({
  contact_name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  contact_mobile: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .min(8, <FormattedMessage id='validationSchema.required'/>)
})

export const MedicationFormValidation = yup.object().shape({
  generic_name: yup.string()
    .required(<FormattedMessage id='validationSchema.required'/>),
  form: yup.string()
    .required(<FormattedMessage id='validationSchema.required'/>),
  dose: yup.string()
    .required(<FormattedMessage id='validationSchema.required'/>),
  is_ongoing: yup.bool()
})


