import { Modal } from "antd";
import styled from 'styled-components'

export const StyledHelpModal = styled(Modal)`
    & .ant-modal-content {
      border-radius: 20px;
      text-align: center;
      @media (max-width: 815px){
        zoom: 80%;
        top: -30px;
      }
      & .ant-modal-body {
        padding: 50px;
      }
      & .mainText {
        font-size: 1.3rem;
        font-weight: 400;
        width: 100%;
        margin: 10px auto;
      }

    }
    & .ant-modal-footer {
      padding: 0;
      height: 50px;
      display: flex;
      justify-content: space-around;
      & button {
        background-color: #3ea883;
        color: #FFFFFF;
        width: 100%;
        margin: 0 !important;
        height: 100%;
        border-radius: 0 0 20px 20px;
        & span {
          text-transform: uppercase;
          letter-spacing: 0.5px;
          font-size: 1rem;
        }
      }
    }
`