import React from 'react'
import {useStyles} from './Styles'
import moment from "moment"
import ShortSleepSession from '../../../../images/Icons/short_sleep_session_icon.png'

const RowItem = ({sleepQualityData, setIsDailystats, setSelectedDailyStats, isDailyStats }) => {

  const classes = useStyles()



  const timeConvert = (num) => {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    const minutesRounded = Math.round(minutes)
    return `${hours} h ${minutesRounded} min`
  }

  const formatDate = (date) => {
    const formattedDate = moment(date)
    if (date === "") {
      return "No sleep Date"
    } else {
      return formattedDate.format('ddd MMM DD, YYYY');
    }
  }

  const formatStartTime = (time) => {
    if (time == "" || time == null) {
      return "No time"
    } else {
      let startHours24 = parseInt(time.substring(0, 2))
      let startHours = ((startHours24 + 11) % 12) + 1
      let startAmPm = startHours24 > 11 ? 'PM' : 'AM'
      let startMinutes = time.substring(3, 5)
      let displaySleepStartTime = `${startHours}:${startMinutes} ${startAmPm}`
      return displaySleepStartTime
    }
  }

  const formatEndTime = (time) => {
    if (time == "" || time == null) {
      return "No time"
    } else {
      let endHours24 = parseInt(time.substring(0, 2))
      let endHours = ((endHours24 + 11) % 12) + 1
      let endAmPm = endHours24 > 11 ? 'PM' : 'AM'
      let endMinutes = time.substring(3, 5)
      let displaySleepEndTime = `${endHours}:${endMinutes} ${endAmPm}`
      return displaySleepEndTime
    }
  }

  const switchToDailyPage = (day) => {
    setSelectedDailyStats(day)
    setIsDailystats(true)
  }



  function Rows({data}){
    return (
      <>
        {data.map((day)=>{
          
          if (day != null){
            return (
              <>
                <div className={classes.DayList} onClick={()=>{switchToDailyPage(day)}}>
                  <section className={classes.DateTimeContainer}>
                    <p className={classes.SleepDate}>{formatDate(day.sleep_session_date)}</p>
                    <p className={classes.SleepInterval}>
                      {formatStartTime(day.sleep_start_time)} - {formatEndTime(day.sleep_end_time)}
                    </p>
                    <p className={classes.SleepDuration}>{day.sleep_duration_minutes == 0 ? "No duration": timeConvert(day.sleep_duration_minutes)}</p>
                  </section>

                  <section className={classes.ScoreContainer}>
                    <div className={classes.Score}>
                      {day.sleep_quality_score == undefined ?
                      <span>
                        <img src={ShortSleepSession} style={{width: 40, height: 40}} alt="icon indicating not enough sleep"/>
                      </span>
                      :
                      <span className={classes.ScoreCircle}
                      style={{backgroundColor: (day.sleep_quality_score == undefined ? '#dedede' : (day.sleep_quality_score <= 25 ? '#f76565' : (day.sleep_quality_score <= 50 ? '#ff9034' : (day.sleep_quality_score <= 75 ? '#F0B530' : (day.sleep_quality_score == 100 ? '#007e52': "#3ea883")))))}}>
                        {day.sleep_quality_score == undefined ? "" : day.sleep_quality_score == 0 ? 0 : day.sleep_quality_score}
                        </span>
                    }
                        <p className={classes.ScoreString} style={{color: (day.sleep_quality_score == undefined ? '#939393' : (day.sleep_quality_score <= 25 ? '#f76565' : (day.sleep_quality_score <= 50 ? '#ff9034' : (day.sleep_quality_score <= 75 ? '#F0B530' : (day.sleep_quality_score == 100 ? '#007e52': "#3ea883")))))}}>{day.sleep_quality_score_string == "" ? "No data": day.sleep_quality_score_string == "Not Enough Data" ? "Sleep too short" : day.sleep_quality_score_string}</p>
                    </div>
                  </section>
                </div>
              </>
            )
          }
        })}
      </>
    )
  }


  return (
    <>
      <section className={classes.RowWeekList} style={{maxHeight: (isDailyStats == true ? "100vh" : "none")}}>
        <div className={classes.RowWeekListPosition}>
          <div className={classes.WeekList}>
            {sleepQualityData  && <Rows data={sleepQualityData}/>}
          </div>
        </div>
        
      </section>
      
    </>   
  );
}

export default RowItem
