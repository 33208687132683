import React from 'react'
import { useEffect, useState } from "react"
import {useStyles} from './Styles'
import MainNavigation from '../../../components/MainNavigation'
import MobileMenu from '../../../components/MobileMenu'
import {Container, Grid} from '@material-ui/core'
import {md,sm} from '../../../utils/ResponsiveUtility'
import "./styles.scss"

const AccountLayout = ({content}) => {

  const [toggleStatus, setToggleStatus] = useState(false);

  let classes = useStyles()
  let [width, setWidth] = React.useState(document.body.clientWidth)
  let DesktopAndUp = (width >= sm)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])
  return (
      <div className="contentWrapper">
        <Container maxWidth={false} className="accountLayoutContainer">
          <Grid container className="accountLayoutGridContainer">
            
            {DesktopAndUp && 
            <MainNavigation
              toggleStatus={toggleStatus}
              setToggleStatus={setToggleStatus}
            />
            }
            <div className="accountLayoutContent">
              <div className={toggleStatus ? "accountDarkOverlay active" : "accountDarkOverlay"}></div>
              {content}
            </div>
          </Grid>
        </Container>
      </div>
  )
}

export default AccountLayout
