import React from 'react'
import { useFormik } from 'formik'
import { Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { TextField, InputLabel, IconButton, InputAdornment, FormControl } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { ResetPasswordFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../Styles'
import { AuthConsumer } from '../../../AuthContext'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { NamedRoutes } from '../../../routes'

const ResetPasswordForm = ({ code, onSubmit }) => {
  let classes = useStyles()
  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const handleChangeVisibility = (confirm = false) => {
    if (!!confirm) {
      setShowConfirmPassword(!showConfirmPassword)
    } else {
      setShowPassword(!showPassword)
    }
  }
  const resetPasswordForm = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: ResetPasswordFormValidation,
    onSubmit: ((values, actions) => {
      onSubmit(code, values, actions)
    })
  })
  return (
    <AuthConsumer>
      {({ resetPasswordFailure, resetPasswordFailureMessage, resetPasswordSuccess, resetPasswordSuccessRedirect }) => (
        <div className={classes.authCard}>
          <h1 className={classes.authCardTitle}><FormattedMessage id='ForgotPasswordForm.title'/></h1>
          <form onSubmit={resetPasswordForm.handleSubmit} noValidate autoComplete="off" className={classes.authForm}>
            {!!resetPasswordFailure &&
            <Alert severity="error">
              {!!resetPasswordFailureMessage ? resetPasswordFailureMessage :
                <FormattedMessage id='EndPoint.Error'/>}
            </Alert>}
            {!!resetPasswordSuccess &&
            <Alert severity="success"><FormattedMessage id='EndPoint.ResetPassword.Success'/></Alert>}
            {!!resetPasswordSuccessRedirect && <Redirect to={{
              pathname: NamedRoutes.auth.login,
              state: { from: NamedRoutes.auth.forgotPassword }
            }}/>}

            <FormControl className={classes.authCardFormControl}>
              <InputLabel className={classes.authCardFormLabel} htmlFor="email">
                <FormattedMessage id='SignUpForm.InputLabel.email'/>
              </InputLabel>
              <TextField
                id="email"
                required
                fullWidth
                margin="dense"
                type="email"
                name='email'
                variant="outlined"
                onChange={resetPasswordForm.handleChange}
                onBlur={resetPasswordForm.handleBlur}
                value={resetPasswordForm.values.email}
                helperText={resetPasswordForm.errors.email && resetPasswordForm.touched.email && resetPasswordForm.errors.email}
                error={resetPasswordForm.errors.email && resetPasswordForm.touched.email !== undefined}
                inputProps={{
                  autoComplete: 'new-email',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
            <FormControl className={classes.authCardFormControl}>
              <InputLabel htmlFor="password">
                <FormattedMessage id='SignUpForm.InputLabel.newPassword'/>
              </InputLabel>
              <TextField
                id='password'
                required
                fullWidth
                margin="dense"
                type={showPassword ? 'text' : 'password'}
                name='password'
                variant="outlined"
                onChange={resetPasswordForm.handleChange}
                onBlur={resetPasswordForm.handleBlur}
                value={resetPasswordForm.values.password}
                helperText={resetPasswordForm.errors.password && resetPasswordForm.touched.password && resetPasswordForm.errors.password}
                error={resetPasswordForm.errors.password && resetPasswordForm.touched.password !== undefined}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={<FormattedMessage id='Fields.Labels.passwordVisibility'/>}
                        onClick={() => handleChangeVisibility()}
                        onMouseDown={() => handleChangeVisibility()}
                      >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
            <FormControl className={classes.authCardFormControl}>
              <InputLabel htmlFor="confirmPassword">
                <FormattedMessage id='SignUpForm.InputLabel.newConfirmPassword'/>
              </InputLabel>
              <TextField
                id='confirmPassword'
                required
                fullWidth
                margin="dense"
                type={showConfirmPassword ? 'text' : 'password'}
                name='confirmPassword'
                variant="outlined"
                onChange={resetPasswordForm.handleChange}
                onBlur={resetPasswordForm.handleBlur}
                value={resetPasswordForm.values.confirmPassword}
                helperText={resetPasswordForm.errors.confirmPassword && resetPasswordForm.touched.confirmPassword && resetPasswordForm.errors.confirmPassword}
                error={resetPasswordForm.errors.confirmPassword && resetPasswordForm.touched.confirmPassword !== undefined}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={<FormattedMessage id='Fields.Labels.passwordVisibility'/>}
                        onClick={() => handleChangeVisibility(true)}
                        onMouseDown={() => handleChangeVisibility(true)}
                      >
                        {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: 'new-confirmPassword',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
            <div className={classes.formActionsWrapper}>
              <button disabled={resetPasswordForm.isSubmitting || !resetPasswordForm.isValid}
                      className={classes.formActionSubmit}>
                <FormattedMessage id='ResetPasswordForm.button.submit'/>
              </button>
            </div>
            {resetPasswordForm.isSubmitting && <FormLoader loading={true}/>}
          </form>
        </div>
      )}
    </AuthConsumer>
  )
}

export default ResetPasswordForm
