import moment from "moment"
import { useEffect, useState } from "react"
import "./MedHistoryForm.scss"
import { useFormik } from 'formik'
import { StyledSelect, SelectTheme } from "../../../../StyledComponents/StyledSelect"
import { StyledTimePicker } from "../../../../StyledComponents/StyledTimePicker"

const MedHistoryForm = ({ handleCloseEditForm, medicationHistory, medLogID, editMedicationHistory, setPutFlag, putFlag }) => {
    const [medication, setMedication] = useState('')
    const [medicationTimeSelected, setMedicationTimeSelected] = useState(false)
    const [takenStatus, setTakenStatus] = useState(null)
    const [takenTimeState, settakenTimeState] = useState(null)
    const [skippedReasonState, setskippedReasonState] = useState(null)
    
    const statusOptions = [
        {value: "taken", label: 'Taken'},
        {value: "not_taken", label: 'Not Taken'}
    ];
    
    const skippedOptions = [
        {value: 'out_of_meds', label: 'Out of Medication'},
        {value: 'not_near_meds', label: 'Medication Not Near Me'}
    ];

    useEffect(() => {
    let med;
    // eslint-disable-next-line array-callback-return
        medicationHistory.data.filter(item => {
        
        const itemID = item.scheduled_medication_log_id;
        
        if(itemID === medLogID){
            med = item;
        }

        });
        setMedication(med);
        setTakenStatus(med.status)
    },[medicationHistory, medLogID])


    const currentTime = moment().utc().format("YYYY-MM-DD HH:mm:ss");
    const defaultValues = {
        current_timestamp: currentTime,
        device_type: "Browser"
    }

    const itemID = medication.scheduled_medication_log_id;

    const validate = values => {
        const errors = {}

        if (!values.medication_missed_reason && takenStatus === 'not_taken') {
            errors.medication_missed_reason = 'Please select a reason!'
        }

        return errors
    }
    
    const medHistoryForm = useFormik({
        initialValues: {
            ...defaultValues,
            action: null,
            medication_taken_timestamp: null,
            medication_missed_reason: null
        },
        validate,
        onSubmit: ((values, actions) => {
            editMedicationHistory(itemID, values, actions, putFlag, setPutFlag);
            handleCloseEditForm();
        })

    })

    return (
        <div className='medHistoryFormContainer'>
            <div className="formHeaderContainer">
                <h2>Edit Medication</h2>
                <h3 style={{color: '#432f7a'}}>{moment(medication.dose_timestamp).format('MMMM DD, YYYY')}</h3>
            </div>
            <form onSubmit={(e) => {
                medHistoryForm.setFieldValue("action", takenStatus)
                medHistoryForm.handleSubmit(e)
                }}>
                <div className="formRow">
                    <div className="formRowItem">
                        <div className="formRowHeader">
                            <h4>Medication Name</h4>
                        </div>
                        <p>{medication.medication_name}</p>
                    </div>
                    <div className="formRowItem">
                        <div className="formRowHeader">
                            <h4>Dose Amount</h4>
                        </div>
                        <p>{medication.dose}</p>
                    </div>
                    <div className="formRowItem">
                        <div className="formRowHeader">
                            <h4>Dose Time</h4>
                        </div>
                        <p>{moment(medication.dose_timestamp).format('h:mm A')}</p>
                    </div>
                    {
                        medication.reply_reason ?
                        <div className="formRowItem">
                            <div className="formRowHeader">
                                <h4>Reason Skipped</h4>
                            </div>
                            <p style={{textTransform: "capitalize"}}>{medication.reply_reason}</p>
                        </div>
                        : medication.reply_timestamp && medication.reply_timestamp !== medication.dose_timestamp &&
                        <div className="formRowItem">
                            <div className="formRowHeader">
                                <h4>Taken Time</h4>
                            </div>
                            <p>{moment(medication.reply_timestamp).format("h:mm A")}</p>
                        </div>
                        
                    }
                </div>
                
                <div className="formRow">
                    {takenStatus === 'no_response' || takenStatus === null && medication.status == 'no_response' || medication.status === null ? 
                        <div className="formRowSelectItem">
                            <h4>Status</h4>
                            <StyledSelect
                                isSearchable={false}
                                id={'action'}
                                name={'action'}
                                options={statusOptions}
                                theme={theme => SelectTheme(theme)}
                                onChange={event => {
                                    medHistoryForm.setFieldValue('action', event.value)
                                    setTakenStatus(event.value);
                                    medHistoryForm.setFieldValue('medication_taken_timestamp', `${moment(medication.dose_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}`)
                                    setMedicationTimeSelected(true)
                                    medHistoryForm.setFieldValue('medication_missed_reason', null)
                                    setskippedReasonState(null)
                                }}
                            />
                        </div>
                        : takenStatus === 'taken' ?
                        <>
                        <div className="formRowSelectItem">
                            <div className="formRowHeader">
                                <h4>Status</h4>
                            </div>
                            <StyledSelect
                                isSearchable={false}
                                id={'action'}
                                name={'action'}
                                options={statusOptions}
                                defaultValue={{value: "taken", label: 'Taken'}}
                                theme={theme => SelectTheme(theme)}
                                onChange={event => {
                                    medHistoryForm.setFieldValue('action', event.value)
                                    setTakenStatus(event.value);
                                    medHistoryForm.setFieldValue('medication_taken_timestamp', `${moment(medication.dose_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}`)
                                    setMedicationTimeSelected(true)
                                    medHistoryForm.setFieldValue('medication_missed_reason', null)
                                    setskippedReasonState(null)
                                    
                                }}
                            />
                        </div>
                        <div className="formRowSelectItem">
                            <div className="formRowHeader">
                                <h4>Time Taken</h4>
                            </div>
                            <StyledTimePicker
                                allowClear={false}
                                defaultValue={moment(medication.dose_timestamp)}
                                id={'medication_taken_timestamp'}
                                name={'medication_taken_timestamp'}
                                inputReadOnly={true} 
                                format="h:mm a"
                                use12Hours 
                                onChange={(time, timeString) => {
                                    medHistoryForm.setFieldValue('medication_taken_timestamp', `${moment(medication.dose_timestamp).format('YYYY-MM-DD')} ${moment(time).utc().format("HH:mm:ss")}`)
                                    settakenTimeState(timeString)
                                }}
                            />
                        </div>
                        </>
                        : takenStatus === 'not_taken' ?
                        <>
                        <div className="formRowSelectItem">
                            <div className="formRowHeader">
                                <h4>Status</h4>
                            </div>
                            <StyledSelect
                                isSearchable={false}
                                id={'action'}
                                name={'action'}
                                options={statusOptions}
                                defaultValue={{value: "not_taken", label: 'Not Taken'}}
                                theme={theme => SelectTheme(theme)}
                                onChange={event => {
                                    medHistoryForm.setFieldValue('action', event.value)
                                    setTakenStatus(event.value);
                                    medHistoryForm.setFieldValue('medication_taken_timestamp', `${moment(medication.dose_timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}`)
                                    setMedicationTimeSelected(true)
                                    medHistoryForm.setFieldValue('medication_missed_reason', null)
                                    setskippedReasonState(null)
                                }}
                            />
                        </div>
                        <div className="formRowSelectItem">
                            <div className="formRowHeader">
                                <h4>Reason Skipped</h4>
                            </div>
                            <StyledSelect
                                isSearchable={false}
                                id={'medication_missed_reason'}
                                name={'medication_missed_reason'}
                                options={skippedOptions}
                                theme={theme => SelectTheme(theme)}
                                onChange={event => {
                                    medHistoryForm.setFieldValue('medication_missed_reason', event.value)
                                    setskippedReasonState(event.value)
                                }}
                            />
                            {medHistoryForm.errors.medication_missed_reason ? <div className='error'>{medHistoryForm.errors.medication_missed_reason}</div> : null}
                        </div>
                        </>
                        : null
                    }
                </div>

                <div className="submitRow">
                    <button 
                        disabled={takenStatus === 'not_taken' && skippedReasonState === null}
                        type='submit'>
                            Save
                    </button>
                    <button
                        className='cancel formRowItem'
                        onClick={() => handleCloseEditForm()}>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    )
}

export default MedHistoryForm
