import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.common.white,
    // padding: theme.spacing(2, 0),
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1000,
 
    boxShadow: `0px 0px 0px 2px ${theme.palette.grey[200]}`,
    '& .MuiAvatar-colorDefault': {
      backgroundColor: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(15),
    },
    [theme.breakpoints.up(0)]: {
      // padding: theme.spacing(0, 0)
    }
    // [theme.breakpoints.up('md')]: {
    //   // border:'20px solid blue',
    //   width:'calc(100% - 0px)',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   padding: theme.spacing(1, 0)
    // }
  },
  reportBugLink: {
    textDecoration: 'none',
    paddingRight: theme.spacing(1)
  },

  logoContainer: {
    '@media (max-width: 815px)': {
      marginLeft: 30,
      },
  },
}))
