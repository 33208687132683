import React from 'react'
import {SEO} from '../../../components/Seo/seo'
import {forgotPassword} from '../../../utils/pages-meta.json'
import {NamedRoutes} from '../../../routes'
import ForgotPasswordCard from '../../../components/Auth/ForgotPasswordCard'

const ForgotPassword = () => {
  return (
    <>
      <SEO title={forgotPassword.title}
           pathname={window.location.origin + NamedRoutes.auth.forgotPassword}
           titleTemplate='Novela'
           image={forgotPassword.image}
           description={forgotPassword.description}
      />
      <ForgotPasswordCard/>
    </>
  )
}

export default ForgotPassword
