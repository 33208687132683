import React from 'react'
import MedicationForm from './MedicationForm'
import ItemTable from './ItemTable'
import { useStyles } from './Styles'
import { ProfileConsumer } from '../../../../../ProfileContext'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import Alert from '@material-ui/lab/Alert'
import { FormLoader } from '../../../../Loader/FormLoader'

import RescueForm from './RescueForm'
import RescueTable from './RescueTable'

const MedicationTab = ({ loadMedications, medications, resetMedicationStatus, getUnscheduledMeds, unscheduledMeds, resetUnscheduledMedicationStatus }) => {
  let classes = useStyles()
  React.useEffect(() => {
    if (!!loadMedications || !!getUnscheduledMeds) {
      loadMedications();
      getUnscheduledMeds();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [putFlag])

  const columns = [
    { id: 'medication_name', label: <FormattedMessage id='MedicationTab.ListHeader.MedicationName'/>, minWidth: 100 },
    { id: 'form', label: <FormattedMessage id='MedicationTab.ListHeader.Form'/>, minWidth: 100 },
    { id: 'dose', label: <FormattedMessage id='MedicationTab.ListHeader.DosePerPill'/>, minWidth: 100 },
    { id: 'start_date', label: <FormattedMessage id='MedicationTab.ListHeader.StartDate'/>, minWidth: 100 },
    { id: 'end_date', label: <FormattedMessage id='MedicationTab.ListHeader.EndDate'/>, minWidth: 100 },
    { id: 'reminders', label: <FormattedMessage id='MedicationTab.ListHeader.Reminders'/>, minWidth: 100 },
    { id: 'Actions', label: '', minwidth: 100 }
  ];

  const rescueColumns = [
    {id: 'medication_name', label: 'Rescue Medication Name', minWidth: 100},
    { id: 'form', label: <FormattedMessage id='MedicationTab.ListHeader.Form'/>, minWidth: 100 },
    { id: 'dose', label: <FormattedMessage id='MedicationTab.ListHeader.DosePerPill'/>, minWidth: 100 },
    { id: 'space', label: "", minWidth: 200 },
    { id: 'Actions', label: '', minwidth: 100 }
  ];

  const [isEditable, setIsEditable] = React.useState(false)
  const [isRescueForm, setIsRescueForm] = React.useState(false)
  const [editableRow, setEditableRow] = React.useState('new')
  const initialValues = {
    medication_id: null,
    brand_name: null,
    doses_per_day: 0,
    start_date: moment().add("days", 1).format('YYYY-MM-DD'),
    end_date: moment().add("days", 7).format('YYYY-MM-DD'),
    is_ongoing: false,
    generic_name: null,
    dose: '',
    dose_units: '',
    form: '',
    reminders: []
  }
  const [initialValuesPassed, setInitialValuesPassed] = React.useState([])


  const switchToEditMode = (rowId) => {
    resetUnscheduledMedicationStatus()
    resetMedicationStatus()
    setEditableRow(rowId)
    if (rowId != 'new') {
      if (!!medications.filter((medication) => (medication.id === rowId)).length) {
      let currentMedication = medications.filter((medication) => (medication.id === rowId))[0]
      currentMedication.reminders.forEach((reminder) => {
        delete reminder.id
        delete reminder.end_date
        delete reminder.start_date
      })
      if (currentMedication.is_ongoing === true) {
        currentMedication.end_date = initialValues.end_date
      }
      setInitialValuesPassed(currentMedication)
    }
    } else {
    setInitialValuesPassed(initialValues)
    }
    setIsEditable(true)
    setIsRescueForm(false)
  }


  const switchToListMode = () => {
    setEditableRow('new')
    setIsEditable(false)
    setIsRescueForm(false)
    window.history.replaceState({}, document.title)
  }

  const [putFlag, setPutFlag] = React.useState(false);
  const [medicationID, setMedicationID] = React.useState(null)

  const switchToRescueForm = (medID) => {
    resetUnscheduledMedicationStatus()
    resetMedicationStatus()
    setIsEditable(false)
    setIsRescueForm(true)
    setMedicationID(medID)
  }


  return (
    <div className={classes.MedicationTab}>
      <ProfileConsumer>
        {({ medicationsLoading, medicationsFailure, handleAddMedication, medicationsAddSuccess, medicationsAddFailure, handleRemoveMedication, medicationsRemoveLoading, medicationsRemoveSuccess, medicationsRemoveFailure,

          handleRemoveUnscheduledMedication, handleAddUnscheduledMedication, unscheduledMedicationsLoading, unscheduledMedicationsAddSuccess, unscheduledMedicationsAddFailure, unscheduledMedicationsRemoveSuccess, unscheduledMedicationsRemoveFailure
        }) => (
          <>
            {(!!medicationsLoading || !!medicationsRemoveLoading || !!unscheduledMedicationsLoading) && <FormLoader loading={true}/>}
            {!!medicationsFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}

            {!!medicationsAddSuccess &&
            <Alert severity="success"><FormattedMessage id='EndPoint.AddMedication.Success'/></Alert>}
            {!!unscheduledMedicationsAddSuccess &&
            <Alert severity="success"><FormattedMessage id='EndPoint.AddRescueMedication.Success'/></Alert>}
            
            {!!medicationsAddFailure &&
            <Alert severity="error"><FormattedMessage id='EndPoint.AddMedication.Failure'/></Alert>}
            {!!unscheduledMedicationsAddFailure &&
            <Alert severity="error"><FormattedMessage id='EndPoint.AddMedication.Failure'/></Alert>}

            {!!medicationsRemoveSuccess &&
            <Alert severity="success"><FormattedMessage id='EndPoint.RemoveMedication.Success'/></Alert>}
            {!!unscheduledMedicationsRemoveSuccess &&
            <Alert severity="success"><FormattedMessage id='EndPoint.RemoveMedication.Success'/></Alert>}
            {!!medicationsRemoveFailure &&
            <Alert severity="error"><FormattedMessage id='EndPoint.RemoveMedication.Failure'/></Alert>}
            {!!unscheduledMedicationsRemoveFailure &&
            <Alert severity="error"><FormattedMessage id='EndPoint.RemoveMedication.Failure'/></Alert>}


            {!!isEditable && !isRescueForm ?
              <MedicationForm
                initialValues={initialValuesPassed}
                onSubmit={handleAddMedication}
                switchToListMode={switchToListMode}
                editableRowId={editableRow}
              /> 
              :!isEditable && isRescueForm !== false ?
                <>
                  <RescueForm
                    onSubmit={handleAddUnscheduledMedication}
                    switchToListMode={switchToListMode}
                    medicationID={medicationID}
                    putFlag={putFlag}
                    setPutFlag={setPutFlag}
                    unscheduledMeds={unscheduledMeds}
                  />
                </>
              :
              <>
                {!unscheduledMeds && !medications &&
                <>
                  <div className={classes.NoMedsWarningLabelContainer}>
                    <p className={classes.NoMedsWarningLabel}>No medications are available.
                    </p>
                    <p className = {classes.NoMedsWarningLabel}>Please use one of two buttons below to add one.</p>
                  </div>
                </> 
                }
                <RescueTable
                  meds={unscheduledMeds}
                  columns={rescueColumns}
                  handleDeleteMed={handleRemoveUnscheduledMedication}
                  switchToRescueEditForm={switchToRescueForm}
                  setMedicationID={setMedicationID}
                  getUnscheduledMeds={getUnscheduledMeds}
                />
                <ItemTable
                  rows={medications}
                  columns={columns}
                  handleDeleteRow={handleRemoveMedication}
                  switchToEditMode={switchToEditMode}
                  switchToRescueForm={switchToRescueForm}
                  loadMedications={loadMedications}
                />
              </>
            }
          </>)
        }
      </ProfileConsumer>
    </div>
  )
}

export default MedicationTab
