import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  menuButton: {
    float: 'left',
    marginRight: theme.spacing(1.25),
    padding: theme.spacing(1.25),
    paddingLeft: theme.spacing(0),
    '& svg': {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600
    },
    '&:hover, &:focus': {
      cursor: 'pointer',
      outline: 'none'
    }
  },
  navigationDrawerWrapper: {
    '& .MuiPaper-root': {
      background: theme.palette.common.white
    }
  },
  navigationDrawer: {
    width: 300,
    minHeight: 560,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(10, 0),
    height: '100%',
    position: 'relative',
    '& nav': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(1),
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      '& a': {
        fontSize: theme.typography.pxToRem(20),
        margin: theme.spacing(0)
      }
    }
  },
  menuCloseButton: {
    marginTop: theme.spacing(1) + 'px !important',
    position: 'absolute !important',
    top: 0,
    right: theme.spacing(2),
    '& svg': {
      fontSize: theme.typography.pxToRem(42),
      fontWeight: 600
    },
    '&:hover, &:focus': {
      cursor: 'pointer',
      color: theme.palette.common.white,
      outline: 'none'
    }
  },
  userLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiAvatar-colorDefault': {
      backgroundColor: theme.palette.primary.main,
      width: 80,
      height: 80,
      fontSize: theme.typography.pxToRem(32),
      marginBottom: theme.spacing(3)
    },
    '& a': {
      textDecoration: 'none',
    }
  },
  logout: {
    color: theme.palette.error.main,
    background: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(3, 0, 2)
  }
}))
