import React from 'react'

const ShakyIcon = () => {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.9 595.3" version="1.1" x="100px" y="100px"  viewBox="0 0 70 97" style={{enableBackground:"new 0 0 70 97"}} >
      <g>
        <path fill="#462d7d" d="M2.4,41.4c-0.3,0.6-0.5,1-0.7,1.4c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2
          c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.3-0.1,0.6c0,1.7,1.4,3.2,3.2,3.2c1.4,0,2.6-0.9,3-2.1c0.1-0.1,0.2-0.4,0.4-0.8
          c0.9-1.8,3.2-5.6,6.4-8.7c1.6-1.6,3.5-3,5.5-4c0.5-0.3,1.1-0.5,1.6-0.7C20.1,40,21.7,46.1,24,55.5c0.1,0.3,0.2,0.6,0.3,0.8
          c0.1,0.7,0.3,1.3,0.6,1.9h0c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.4,2.3,4.5,2.3,10.4c0,5.2-1.5,11.8-7.4,19.4c-1.7,2.1-1.3,5.2,0.8,6.9
          c0.9,0.7,2,1.1,3,1.1c1.5,0,2.9-0.6,3.9-1.9c7.3-9.3,9.6-18.4,9.5-25.5c0-3.1-0.4-5.8-0.9-8.1c1.7,1,4.2,2.8,6.6,5.3
          c1.7,1.9,3.4,4.1,4.6,6.7c1.2,2.6,2,5.7,2,9.3c0,2.4-0.3,5-1.2,7.9c-0.7,2.6,0.8,5.3,3.4,6.1c0.4,0.1,0.9,0.2,1.3,0.2
          c2.2,0,4.1-1.4,4.7-3.6c1-3.7,1.5-7.3,1.5-10.6c0-5.5-1.3-10.3-3.3-14.3c-3-6-7.3-10.2-10.8-12.9c-1.3-1-2.5-1.8-3.6-2.5
          c-2.4-8.1-0.8-12.6,2.4-18c4.4,1.4,8.3,4.4,11.1,7.4c1.7,1.8,3,3.5,3.9,4.8c0.4,0.6,0.8,1.2,1,1.5c0.1,0.2,0.2,0.3,0.2,0.4
          c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0.6,1,1.7,1.6,2.8,1.6c0.5,0,1.1-0.1,1.5-0.4c1.5-0.9,2.1-2.8,1.2-4.3c-0.1-0.1-2.2-4-6.2-8.1
          c-3.4-3.5-8.1-7.4-14.1-9.2c-1.6,3.3-4.9,5.5-8.8,5.5c-5.3,0-9.7-4.3-9.7-9.6c-0.2,0-0.4,0-0.6,0c-4.2,0-7.9,1.3-11,3.1
          c-4.6,2.8-8,6.7-10.3,10C3.8,38.8,3,40.3,2.4,41.4z M24.9,58.3L24.9,58.3C24.9,58.3,24.9,58.3,24.9,58.3L24.9,58.3z"/>
        <circle fill="#462d7d" cx="36.7" cy="23.9" r="8.9"/>
        <path fill="#462d7d" d="M10.3,27.2c-1.4,0-2.2,0.7-2.5,1.4c-0.4,0.7-0.4,1.3-0.4,1.4c0,0.1,0,0.2,0.1,0.3l0,0c0.1,0.1,0.2,0.1,0.3,0.1
          c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.3c0-0.3,0.2-0.7,0.5-1C9,28.3,9.4,28,10.3,28c0.1,0,0.1,0,0.2,0
          c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4C10.4,27.2,10.3,27.2,10.3,27.2z"/>
        <path fill="#462d7d" d="M10.4,25.5c-0.2,0-0.4,0-0.5,0c-1.9,0-3.1,1-3.6,1.9c-0.5,0.9-0.6,1.9-0.6,1.9c0,0.2,0.2,0.4,0.4,0.4
          c0,0,0,0,0,0c0.2,0,0.4-0.2,0.4-0.4c0,0,0-0.2,0.1-0.5c0.2-0.9,0.8-2.6,3.3-2.6c0.2,0,0.3,0,0.5,0c0.2,0,0.4-0.1,0.4-0.4
          C10.7,25.7,10.6,25.5,10.4,25.5z"/>
        <path fill="#462d7d" d="M22.3,58.6c-0.2-0.1-0.4,0-0.5,0.2c-0.3,0.5-0.4,1-0.4,1.5c0,1.8,1.7,2.4,1.7,2.4c0.1,0,0.1,0,0.2,0
          c0.2,0,0.3-0.1,0.4-0.2c0.1-0.2,0-0.4-0.2-0.5c0,0-0.1,0-0.2-0.1c-0.4-0.2-1-0.7-1-1.6c0-0.3,0.1-0.7,0.3-1.1
          C22.6,58.9,22.5,58.6,22.3,58.6z"/>
        <path fill="#462d7d" d="M20.7,58c-0.2-0.1-0.4,0-0.5,0.2c-0.5,0.9-0.6,1.6-0.6,2.3c0,2.4,2.2,3.5,2.2,3.5c0.1,0,0.1,0,0.2,0
          c0.1,0,0.3-0.1,0.4-0.2c0.1-0.2,0-0.4-0.2-0.5c0,0-0.1,0-0.3-0.2c-0.5-0.4-1.5-1.2-1.5-2.6c0-0.5,0.1-1.2,0.6-2
          C21,58.3,20.9,58.1,20.7,58z"/>
        <path fill="#462d7d" d="M58.6,69.2c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.2c0.2-0.5,0.3-0.9,0.3-1.3c0-0.9-0.5-1.6-0.9-1.9
          c-0.4-0.4-0.9-0.5-0.9-0.5c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.2,0,0.4,0.2,0.5h0c0,0,0.1,0,0.2,0.1c0.4,0.2,1.1,0.7,1.1,1.7
          c0,0.3-0.1,0.6-0.2,1C58.3,68.9,58.4,69.1,58.6,69.2z"/>
        <path fill="#462d7d" d="M60.3,69.7c0.1,0,0.1,0,0.2,0c0.1,0,0.3-0.1,0.4-0.2c0.4-0.8,0.5-1.5,0.5-2.1c0-2.6-2.4-3.6-2.4-3.6
          c-0.2-0.1-0.4,0-0.5,0.2c-0.1,0.2,0,0.4,0.2,0.5c0,0,0.1,0,0.3,0.2c0.6,0.3,1.7,1.2,1.7,2.7c0,0.5-0.1,1.1-0.4,1.8
          C60,69.3,60.1,69.6,60.3,69.7z"/>
        <path fill="#462d7d" d="M46.4,18.5c-0.1,0.2,0,0.4,0.2,0.5c0,0,0.1,0,0.2,0.1c0.4,0.2,1.1,0.7,1.1,1.7c0,0.3-0.1,0.6-0.2,1
          c-0.1,0.2,0,0.4,0.2,0.5c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.2c0.2-0.5,0.3-0.9,0.3-1.3c0-0.9-0.5-1.6-0.9-1.9
          c-0.4-0.4-0.9-0.5-0.9-0.6C46.7,18.2,46.5,18.3,46.4,18.5z"/>
        <path fill="#462d7d" d="M47.7,17.1c-0.1,0.2,0,0.4,0.2,0.5v0c0,0,0.1,0,0.3,0.2c0.6,0.3,1.7,1.2,1.7,2.7c0,0.5-0.1,1.1-0.4,1.8
          c-0.1,0.2,0,0.4,0.2,0.5c0.1,0,0.1,0,0.2,0c0.1,0,0.3-0.1,0.4-0.2c0.4-0.8,0.5-1.5,0.5-2.1c0-2.6-2.4-3.6-2.4-3.6
          C48,16.8,47.8,16.9,47.7,17.1z"/>
        <path fill="#462d7d" d="M61.4,36c0,0.2,0.1,0.4,0.3,0.4c0,0,0.1,0,0.3,0.1c0.5,0.2,1.4,0.6,1.4,1.9c0,0.2,0,0.4-0.1,0.6
          c0,0.2,0.1,0.4,0.3,0.5c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.4-0.3c0-0.3,0.1-0.5,0.1-0.7c0-1.1-0.6-1.9-1.2-2.2
          c-0.6-0.4-1.1-0.4-1.2-0.5C61.6,35.6,61.4,35.8,61.4,36z"/>
        <path fill="#462d7d" d="M65.4,39.5C65.4,39.5,65.5,39.5,65.4,39.5c0.3,0,0.4-0.1,0.5-0.3c0.1-0.5,0.2-0.9,0.2-1.3
          c0-1.6-0.8-2.6-1.6-3.2c-0.8-0.6-1.6-0.7-1.6-0.7c-0.2,0-0.4,0.1-0.5,0.3c0,0.2,0.1,0.4,0.3,0.5v0c0,0,0.1,0,0.4,0.1
          c0.8,0.3,2.2,1,2.2,3c0,0.3,0,0.7-0.1,1.1C65.1,39.3,65.2,39.5,65.4,39.5z"/>
        <path fill="#462d7d" d="M16.9,17.4c0.1,0.3,0.3,0.4,0.5,0.5l5.5,0.5c0,0,0,0,0.1,0c0.3,0,0.6-0.2,0.6-0.5c0-0.3-0.2-0.6-0.5-0.6
          l-3.6-0.3l3.7-2.2c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2-0.1-0.4-0.3-0.5l-2.7-1.7l3.8-0.6c0.2,0,0.4-0.2,0.4-0.3c0.1-0.2,0.1-0.4,0-0.6
          l-2.8-4.3l4.6,1.7c0.2,0.1,0.4,0,0.5-0.1c0.2-0.1,0.3-0.3,0.3-0.5l0-4.5l3.2,3.8c0.1,0.2,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.3
          l1.8-3.8L34.2,8c0,0.2,0.2,0.3,0.3,0.4c0.2,0.1,0.4,0.1,0.5,0l2.8-1.4l-1,2.9c-0.1,0.3,0.1,0.6,0.4,0.8c0.3,0.1,0.6-0.1,0.8-0.4
          l1.5-4.3c0.1-0.2,0-0.5-0.2-0.6c-0.2-0.2-0.4-0.2-0.7-0.1l-3.4,1.7l-1.3-6.3c-0.1-0.2-0.3-0.4-0.5-0.5c-0.3,0-0.5,0.1-0.6,0.3
          l-2.2,4.5L27,0.8c-0.2-0.2-0.4-0.3-0.7-0.2C26.1,0.7,26,0.9,26,1.2l0,5.3l-5.5-2.1c-0.2-0.1-0.5,0-0.7,0.2c-0.2,0.2-0.2,0.5,0,0.7
          l3.3,5L18.6,11c-0.2,0-0.4,0.2-0.5,0.5c0,0.2,0.1,0.5,0.3,0.6l3.3,2l-4.4,2.6C17,16.9,16.8,17.1,16.9,17.4z"/>
      </g>
      </svg>
  )
}

export default ShakyIcon
