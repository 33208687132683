import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  formActionSubmit: {
    padding: theme.spacing(1.5, 4),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    border: 'none',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:disabled': {
      opacity: 0.7,
      cursor: 'not-allowed'
    }
  },
  formDropdown:{
    margin:"8px",marginBottom:"14px",
  },
  dateOfBirthDropdown:{
    marginRight:"20px",width:"100px",
  },
  InlineWidth:{
    width:"auto!important",
    flexDirection: "row",
    display: "flex"
  },
  PatientInfoForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    maxWidth: 500,
    '& .MuiFormControl-root': {
      width: '100%',
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginRight: theme.spacing(0)
      }
    },
    '& .MuiInputLabel-formControl': {
      top: 0,
      left: 0,
      position: 'relative',
      transform: 'none',
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightLight
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[300]
    }
  },
  profileSectionContainer: {
    width: '50%',
  },
  MarginRight: {
    marginRight: '20px',
  },
  UnitDropdown: {
    width: '70%'
  },
  BirthdayDropdown: {
    marginRight: '20px',
    width: '100%'
  },
  BirthdayYearDropdown: {
    width: '100%'
  },
  Header: {
    margin: "30px 0 6px 0"
  },
  FirstHeader: {
    marginBottom: "6px"
  },
  TimezoneDropdown: {
    height: '57px',
    outline: 'none',
    borderColor: 'red'
  },
  formActionsWrapper: {
    marginBottom: '60px',
  }
}))
