import React from 'react'
import {SEO} from '../../../components/Seo/seo'
import {emailVerified} from '../../../utils/pages-meta.json'
import {NamedRoutes} from '../../../routes'
import EmailVerifiedCard from '../../../components/Auth/EmailVerifiedCard'

const EmailVerified = () => {
  return (
    <>
      <SEO title={emailVerified.title}
           pathname={window.location.origin + NamedRoutes.auth.emailVerified}
           titleTemplate='Novela'
           image={emailVerified.image}
           description={emailVerified.description}
      />
      <EmailVerifiedCard/>
    </>
  )
}

export default EmailVerified
