import moment from "moment"
import { useEffect, useState } from "react"
import "./MedHistoryForm.scss"
import { useFormik } from 'formik'
import { StyledSelect, SelectTheme } from "../../../../StyledComponents/StyledSelect"
import { StyledTimePicker, StopwatchIcon, StyledDatePicker } from "../../../../StyledComponents/StyledTimePicker"
import { CompareSharp } from "@material-ui/icons"

const UnscheduledHistoryForm = ({ handleCloseEditForm, unscheduledMedicationHistory, unscheduledMedID, saveUnscheduledMedicationHistory, unscheduledMedicationList, handleCloseUnscheduledForm, setPutFlag, putFlag, selectedDate, selectedDateForRescue, setSelectedDateForRescue, setselectedDate }) => {


  const [medicationOptions, setMedicationOptions] = useState(null)

  const handleSetMedicationOptions = () => {
    if (unscheduledMedicationList) {
      setMedicationOptions(unscheduledMedicationList.map((options) => ({
        value: options.id,
        label: options.medication_name
      })))
    } else if (!handleSetMedicationOptions) {
      setMedicationOptions([{
        value: "No Medications available",
        lable: "No medications available"
      }]
      )
    }
  }



  const doseAmountList = [
    {value: "0.5", label: "0.5"},
    {value: "1", label: "1"},
    {value: "1.5", label: "1.5"},
    {value: "2", label: "2"},
    {value: "2.5", label: "2.5"},
    {value: "3", label: "3"},
    {value: "3.5", label: "3.5"},
    {value: "4", label: "4"},
    {value: "4.5", label: "4.5"},
    {value: "5", label: "5"},
  ];


  const [medication, setMedication] = useState('')
  const [skippedReasonState, setskippedReasonState] = useState(null)
  const [medicationLogID, setMedicationLogID] = useState(null)

  const [medicationSelected, setMedicationSelected] = useState(null)
  const [dose, setDose] = useState(null)
  const [doseUnits, setDoseUnits] = useState(null)
  const [timeTaken, setTimeTaken] = useState("")
  const [timeValueToPass, setTimeValueToPass] = useState("")
  const [form, setForm] = useState(null)



  const [selectedTimeIsValid, setSelectedTimeIsValid] = useState(null)
  const [medicationDateStamp, setMedicationDateStamp] = useState(moment(selectedDateForRescue).format("YYYY-MM-DD"))
  const [medicationTimeStamp, setMedicationTimeStamp] = useState(null)

  
  useEffect(() => {

  handleSetMedicationOptions();
  if (unscheduledMedID !== null) {
    let med;
    // eslint-disable-next-line array-callback-return
    unscheduledMedicationHistory.data.filter(item => {
    const itemID = item.unscheduled_medication_log_id;
    setMedicationLogID(itemID)
    if(itemID === unscheduledMedID){
        med = item;
    }});
    let timeStamp = moment(med.dose_timestamp)
    setTimeTaken(timeStamp.format("MMMM DD, YYYY, h:mm A"))
    setTimeValueToPass(timeStamp.format())
    setMedication(med);
    unscheduledMedHistoryForm.setFieldValue('patient_medication_id', med.patient_medications_id)
    unscheduledMedHistoryForm.setFieldValue('num_of_doses', med.num_of_doses)
    unscheduledMedHistoryForm.setFieldValue('medication_taken_timestamp', timeStamp.format())
  } else {
    setMedication("new");
  }
  }, [unscheduledMedicationHistory, unscheduledMedID])


  const defaultValues = {
    patient_medication_id: unscheduledMedID !== null ? medication.patient_medications_id : null,
    medication_taken_timestamp: unscheduledMedID !== null ? timeValueToPass : "",
    device_type: "Browser",
    num_of_doses: unscheduledMedID !== null ? medication.num_of_doses : ""
  }

  const unscheduledMedHistoryForm = useFormik({
    initialValues: {
      ...defaultValues,
    },
    onSubmit: ((values, actions) => {
      saveUnscheduledMedicationHistory(medicationLogID, values, actions, putFlag, setPutFlag);
      handleCloseUnscheduledForm();
      setSelectedDateOnSubmit();
    })
  })

  const setSelectedDateOnSubmit = () => {
    setselectedDate(moment(selectedDateForRescue).format("ddd MMM DD YYYY"))
  }

  const [medicationTimeSelected, setMedicationTimeSelected] = useState(null)

  let monthNow = parseInt(moment().format("M"))
  let dayNow = parseInt(moment().format("D"))
  let hourNow = parseInt(moment().format("k"))
  let minuteNow = parseInt(moment().format("m"))

  const handlePreventFutureTime = (time) => {
    let monthSelected = parseInt(moment(selectedDateForRescue).format("M"))
    let daySelected = parseInt(moment(selectedDateForRescue).format("D"))
    let hourSelected = parseInt(moment(time).format("k"))
    let minuteSelected = parseInt(moment(time).format("m"))

    if (monthNow > monthSelected) {
      setSelectedTimeIsValid(true);
    }
    if (monthNow == monthSelected) {
      if (dayNow > daySelected) {
        setSelectedTimeIsValid(true);
      } else if (dayNow == daySelected) {
        if (hourNow > hourSelected) {
          setSelectedTimeIsValid(true);
        } else if (hourNow == hourSelected) {
          if (minuteNow >= minuteSelected) {
            setSelectedTimeIsValid(true);
          } else if (minuteNow < minuteSelected) {
            setSelectedTimeIsValid(false);
          }
        } else if (hourNow < hourSelected) {
          setSelectedTimeIsValid(false);
        }
      }
    }
  }


  const handlePreventFutureDate = (date) => {
    let monthSelected = parseInt(moment(date).format("M"))
    let daySelected = parseInt(moment(date).format("D"))
    let hourSelected = parseInt(moment(medicationTimeSelected).format("k"))
    let minuteSelected = parseInt(moment(medicationTimeSelected).format("m"))

    if (monthNow > monthSelected) {
      setSelectedTimeIsValid(true);
    }
    if (monthNow == monthSelected) {
      if (dayNow > daySelected) {
        setSelectedTimeIsValid(true);
      } else if (dayNow == daySelected) {
        if (hourNow > hourSelected) {
          setSelectedTimeIsValid(true);
        } else if (hourNow == hourSelected) {
          if (minuteNow >= minuteSelected) {
            setSelectedTimeIsValid(true);
          } else if (minuteNow < minuteSelected) {
            setSelectedTimeIsValid(false);
          }
        } else if (hourNow < hourSelected) {
          setSelectedTimeIsValid(false);
        }
      }
    }
  }



  const medSelected = (medicationChoice) => {
    const listFilter = unscheduledMedicationList.filter(function(medication) {
      return medication.medication_name == medicationChoice
    })
    setMedicationSelected(listFilter);
    displayDose(listFilter);
  }

  const displayDose = (listFilter) => {
    if (listFilter !== null) {
      const selectedDose = listFilter[0].dose
      const selectedDoseUnits = listFilter[0].dose_units
      const selectedForm = listFilter[0].form
      setDose(selectedDose)
      setDoseUnits(selectedDoseUnits)
      setForm(selectedForm)
    }
  }


    return (
      <>
      {medication === "new" ?
        <>
        <form onSubmit={(e) => {
          unscheduledMedHistoryForm.handleSubmit(e)}}>
            <div className='medHistoryFormContainer'>
              <div className="formHeaderContainer">
                <h2>Add Rescue Medication</h2>
              </div>
              <div className='medHistoryFormContainer'>
              <div className="formRowSelectItem">
                <div className="formRowHeader">
                  <h4>Which Medication did you take?</h4>
                </div>
                  <StyledSelect
                    isSearchable={false}
                    id={'medicationName'}
                    name={'medicationName'}
                    options={medicationOptions}
                    theme={theme => SelectTheme(theme)}
                    onChange={event => {
                      unscheduledMedHistoryForm.setFieldValue('patient_medication_id', event.value)
                      medSelected(event.label)
                    }}
                  />
                </div>

                {unscheduledMedHistoryForm.values.patient_medication_id !== null ?
                <>
                  <div className="formRowSelectItem">
                    <div className="formRowHeader">
                      <h4>How many doses did you take?</h4>
                    </div>
                    <StyledSelect
                      isSearchable={false}
                      id={'doseAmount'}
                      name={'doseAmount'}
                      options={doseAmountList}
                      theme={theme => SelectTheme(theme)}
                      onChange={event => {
                        unscheduledMedHistoryForm.setFieldValue('num_of_doses', event.value)
                      }}
                    />
                  </div>
                  <div className="medTypeContainer">
                    <div className="medType">
                      <div className="formRowHeader">
                        <h4>Form:</h4>
                      </div>
                      <p className="form">{form}</p>
                    </div>
                    <div className="medType">
                      <div className="formRowHeader">
                        <h4>Dose:</h4>
                      </div>
                      <p>{dose}{doseUnits}</p>
                    </div>
                  </div>
                  <div className="formRowSelectItem">
                    <div className="formRowHeader">
                      <h4>When did you take it?</h4>
                    </div>
                    <section className="dateAndTimeContainer">
                      <div className="datePicker">
                        <label className="required">Date</label>
                        <StyledDatePicker
                          className="datePickerComponent"
                          defaultValue={moment(selectedDate)}
                          showToday={false}
                          allowClear={false}
                          format={"ddd. MMMM D, yyyy"}
                          onChange={(date) => {
                            setSelectedDateForRescue(moment(date).format())
                            handlePreventFutureDate(moment(date).format());
                            setMedicationDateStamp(moment(date).utc().format("YYYY-MM-DD"));
                            unscheduledMedHistoryForm.setFieldValue('medication_taken_timestamp', `${moment(date).utc().format("YYYY-MM-DD")}T` + medicationTimeStamp);
                          }}
                          inputReadOnly={true}
                          disabledDate={date => date > moment()}
                          dropdownAlign={{offset: [-10, -215]}}
                          />
                      </div>
                      <div className="timePicker">
                        <label className="required">Time</label>
                          <StyledTimePicker
                          allowClear={false}
                          id={'medication_taken_timestamp'}
                          name={'medication_taken_timestamp'}
                          inputReadOnly={true} 
                          format="h:mm a"
                          use12Hours 
                          onChange={(time) => {
                            setMedicationTimeSelected(moment(time).format())
                            setMedicationTimeStamp(moment(time).utc().format("HH:mm:ss"));
                            unscheduledMedHistoryForm.setFieldValue('medication_taken_timestamp', medicationDateStamp + `T${moment(time).utc().format("HH:mm:ss")}`);
                            handlePreventFutureTime(time)
                          }} />
                          {selectedTimeIsValid === false ?
                        <p className="timeTakenWarning">Future times are invalid.</p>
                        : <></>}
                        </div>
                    </section>
                  </div>
                </>
                :
                <></>
                }
              </div>
            </div>
            <div className="submitRow">
                <button className="saveButton" 
                    disabled={unscheduledMedHistoryForm.values.num_of_doses == "" || unscheduledMedHistoryForm.values.patient_medication_id == null || unscheduledMedHistoryForm.values.medication_taken_timestamp == "" || selectedTimeIsValid == false || selectedTimeIsValid == null}
                    type='submit'>
                        Save
                </button>
                <button className='cancel formRowItem'
                    onClick={() => handleCloseEditForm()}>
                    Cancel
                </button>
            </div>
          </form>
        </>
      :
        <>
        <form onSubmit={(e) => {
          unscheduledMedHistoryForm.handleSubmit(e)}}>
            <div className='medHistoryFormContainer'>
              <div className="formHeaderContainer">
                <h2>Edit Rescue Medication</h2>
              </div>
              <div className='medHistoryFormContainer'>
              <div className="formRowSelectItem">
                <div className="formRowHeader">
                  <h4>Rescue Medication Name</h4>      
                </div>
                  <p>{medication.medication_name}</p>
                </div>

              <div>
                <div className="medTypeContainer">
                  <div className="medType">
                    <div className="formRowHeader">
                      <h4>Form:</h4>  
                    </div>
                    <p className="form">{medication.form}</p>
                  </div>
                  <div className="medType">
                    <div className="formRowHeader">
                      <h4>Dose:</h4> 
                    </div>
                    <p>{medication.num_of_doses}{medication.dose_units}</p>
                  </div>
                </div>
                <div className="medType">
                  <div className="formRowHeader">
                    <h4>Amount Taken:</h4>  
                  </div>
                  <p className="amountTaken">{medication.num_of_doses} {medication.form}</p>
                </div>
                <div className="medType">
                  <div className="formRowHeader">
                    <h4>Medication taken at:</h4>    
                  </div>
                  <p>{timeTaken}</p>
                </div>
              </div>

              <div className="formRowSelectItem">
                <div className="formRowHeader">
                  <h4>Edit the Amount Taken</h4>
                </div>
                
                  <StyledSelect
                    isSearchable={false}
                    id={'doseAmount'}
                    name={'doseAmount'}
                    options={doseAmountList}
                    theme={theme => SelectTheme(theme)}
                    onChange={event => {
                      unscheduledMedHistoryForm.setFieldValue('num_of_doses', event.value)
                    }}
                  />
                </div>

                  <div className="formRowSelectItem">
                    <div className="formRowHeader">
                      <h4>When did you take it?</h4> 
                    </div>
                    <section className="dateAndTimeContainer">
                      <div className="datePicker">
                        <label className="required">Date</label>
                        <StyledDatePicker
                          className="datePickerComponent"
                          defaultValue={moment(selectedDate)}
                          showToday={false}
                          allowClear={false}
                          format={"ddd. MMMM D, yyyy"}
                          onChange={(date) => {
                            setSelectedDateForRescue(moment(date).format())
                            handlePreventFutureDate(moment(date).format());
                            setMedicationDateStamp(moment(date).utc().format("YYYY-MM-DD"));
                            unscheduledMedHistoryForm.setFieldValue('medication_taken_timestamp', `${moment(date).utc().format("YYYY-MM-DD")}T` + medicationTimeStamp);
                          }}
                          inputReadOnly={true}
                          disabledDate={date => date > moment()}
                          dropdownAlign={{offset: [-10, -215]}}
                          />
                      </div>
                      <div className="timePicker">
                        <label className="required">Time</label>
                          <StyledTimePicker
                          allowClear={false}
                          id={'medication_taken_timestamp'}
                          name={'medication_taken_timestamp'}
                          inputReadOnly={true} 
                          format="h:mm a"
                          use12Hours 
                          onChange={(time) => {
                            setMedicationTimeSelected(moment(time).format())
                            setMedicationTimeStamp(moment(time).utc().format("HH:mm:ss"));
                            unscheduledMedHistoryForm.setFieldValue('medication_taken_timestamp', medicationDateStamp + `T${moment(time).utc().format("HH:mm:ss")}`);
                            handlePreventFutureTime(time)
                          }} />
                          {selectedTimeIsValid === false ?
                        <p className="timeTakenWarning">Future times are invalid.</p>
                        : <></>}
                        </div>
                    </section>
                  </div>
              </div>
            </div>
            <div className="submitRow">
              <button 
                  disabled={unscheduledMedHistoryForm.values.medication_taken_timestamp == defaultValues.medication_taken_timestamp && unscheduledMedHistoryForm.values.num_of_doses == defaultValues.num_of_doses || selectedTimeIsValid == false || selectedTimeIsValid == null}
                  type='submit'>
                      Save
              </button>
              <button className='cancel formRowItem'
                  onClick={() => handleCloseEditForm()}>
                  Cancel
              </button>
            </div>
          </form>
        </>
      }
        
      </>
    )
}

export default UnscheduledHistoryForm
