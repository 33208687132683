import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import Alert from '@material-ui/lab/Alert'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../Styles'
import { Redirect } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'
import { AuthConsumer } from '../../../AuthContext'
import { subMinutes } from 'date-fns'

const OrganizationTokenLoginForm = ({ organization_token, verification_token, onSubmit, patientID, organizationID }) => {

    let classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(true)
    const organizationTokenLoginForm = useFormik({
        initialValues: {
            organization_token: organization_token,
            verification_token: verification_token,
            patient_id: patientID,
            organization_id: organizationID
        },
        validateOnBlur: false,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: ((values, actions) => {
            onSubmit(values, actions)
        })
    })

    const login_and_redirect = () => {
        if(isLoading){
            organizationTokenLoginForm.handleSubmit()
            setIsLoading(false)
        }
    }
    return (
        <AuthConsumer>
        {({ organizationTokenLoginEndPointFailure, organizationTokenLoginFailure, organizationTokenLoginSuccess, organizationTokenLoginSuccessRedirect }) => (
            <div className={classes.authCard}>
                <form onLoad={login_and_redirect()} noValidate autoComplete="off" className={classes.authForm}>
                    {!!organizationTokenLoginEndPointFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}
                    {!!organizationTokenLoginFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Login.Error'/></Alert>}
                    {!!organizationTokenLoginSuccess && <Alert severity="success"><FormattedMessage id='EndPoint.Login.Success'/></Alert>}
                    {!!organizationTokenLoginSuccessRedirect && 
                    <>{window.location = NamedRoutes.home}</>
                    }
                    {organizationTokenLoginForm.isSubmitting && <FormLoader loading={true}/>}
                </form>
            </div>
        )}
        </AuthConsumer>
    )
}

export default OrganizationTokenLoginForm
