import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  RowDataContentGridContainer: {},
  RowDataContentGridItem: {
    '& .canvasjs-chart-credit': {
      display: 'none'
    }
  },
  RowDataContentBox: {
    height: '100%',
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 0px 0px 2px ${theme.palette.grey[200]}`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  itemFilter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  itemFilterButton: {
    minWidth: 110,
    padding: theme.spacing(1),
    backgroundColor: `#f9fafb`,
    border: `1px solid #e5e5e6`,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  itemFilterTitle: {
    margin: 0,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(16)
  },
  itemTableWrapper: {
    width: '100%',
    marginTop: theme.spacing(4),
    '& .MuiTableCell-head': {
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: `2px solid rgba(224, 224, 224, 1)`
    }
  },
  itemTableContainer: {
    // maxHeight: 440
  },
  chartWrapper: {
    position: 'relative',
    marginBottom: theme.spacing(4)
  },
  memoPlayer: {
    marginBottom: theme.spacing(0.5)
  }
}))
