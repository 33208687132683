import 'date-fns'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { useStyles } from './Styles'
import ProfileServices from '../../../../../Services/Consumers/ProfileServices'
import { SearchLoader } from '../../../../Loader/SearchLoader'
import moment from "moment"


const SleepCharts = ({ selectedDailyStats, loading, setLoading }) => {

  React.useEffect(() => {
    getSleepStats();
  }, []);

  let classes = useStyles()

  
  const [heartRateEnabled, setHeartRateEnabled] = React.useState(true);
  const [motionEnabled, setMotionEnabled] = React.useState(false);
  const [spoEnabled, setSpoEnabled] = React.useState(false);

  const [response, setResponse] = React.useState([])
  const [sleepStats, setSleepStats] = React.useState([])
  const [timeseriesFull, setTimeseriesFull] = React.useState([])
  const [timeseries, setTimeseries] = React.useState([])
  const [heartRateArray, setHeartRateArray] = React.useState([])
  const [spoArray, setSpoArray] = React.useState([])
  const [motionArray, setMotionArray] = React.useState([])

  const [heartRateMin, setHeartRateMin] = React.useState(0)
  const [heartRateMax, setHeartRateMax] = React.useState(0)

  const [spoMin, setSpoMin] = React.useState(0)

  const [motionMax, setMotionMax] = React.useState(0)

  const formatTime = (time) => {
    let hours24 = parseInt(time.substring(11, 13))
    let hours = ((hours24 + 11) % 12) + 1
    let amPm = hours24 > 11 ? 'PM' : 'AM'
    let minutes = time.substring(14, 16)
    let formattedTime = `${hours}:${minutes} ${amPm}`
    return formattedTime
  }

  const getSleepStats = async () => {
    let userID = localStorage.getItem('PatientID') 
    let sleepLogsID = selectedDailyStats.sleep_logs_id
    const response = await ProfileServices.sleepStatistics(userID, sleepLogsID)
    const sleepStats = response.data.data[0]
    setSleepStats(sleepStats)
    setResponse(response)
    let mappedTimeseries = []

    if (sleepStats != null) {
      const timeseriesFullNoFormat = sleepStats.timeseries
      mappedTimeseries.push(timeseriesFullNoFormat.map(formatTime))
      setTimeseriesFull(mappedTimeseries[0])
      getTimeseriesValue(mappedTimeseries[0])
      getHeartrateChartValues(mappedTimeseries[0], sleepStats.heart_rate)
      getSpoChartValues(mappedTimeseries[0], sleepStats.spo2)
      getMotionChartValues(mappedTimeseries[0], sleepStats.motion)
    }
    setLoading(false)
  }

  const getTimeseriesValue = (timeseriesFull) => {
    if (timeseriesFull.length >= 12) {
      setTimeseries(timeseriesFull)
    } else if (timeseriesFull.length < 12) {
      setTimeseries([])
    } else if (response == null) {
      setTimeseries([])
    }
  }


  const getHeartrateChartValues = (timeseriesFull, heartRateFull) => {
    if (timeseriesFull.length >= 12) {
      const heartRateChartDataNoNull = heartRateFull.filter(data => data != null)

      const heartRateMinValue = Math.min(...heartRateChartDataNoNull)
      const heartRateMaxValue = Math.max(...heartRateChartDataNoNull)
      setHeartRateMin(Math.ceil((heartRateMinValue - 10) / 10) * 10)
      setHeartRateMax(Math.ceil((heartRateMaxValue + 10) / 10) * 10)
      setHeartRateArray(heartRateChartDataNoNull)
    
    } else if (timeseriesFull.length < 12) {
      setHeartRateArray([]);
      setHeartRateMin(0)
      setHeartRateMax(100)
    } else if (response == null) {
      setHeartRateArray([]);
      setHeartRateMin(0)
      setHeartRateMax(100)
    }
  }

  const getSpoChartValues = (timeseriesFull, spoFull) => {
    if (timeseriesFull.length >= 12) {
      const spoChartDataNoNull = spoFull.filter(data => data != null)
      setSpoArray(spoChartDataNoNull)
      const spoMinValue = Math.min(...spoChartDataNoNull)
      setSpoMin(Math.ceil((spoMinValue - 10) / 10) * 10)

    } else if (timeseriesFull.length < 12) {
      setSpoArray([])
      setSpoMin(0)
    } else if (response == null) {
      setSpoArray([]);
      setSpoMin(0)
    }
  }

  const getMotionChartValues = (timeseriesFull, motionFull) => {
    if (timeseriesFull.length >= 12) {
      const motionChartDataNoNull = motionFull.filter(data => data != null)
      setMotionArray(motionChartDataNoNull)
      const motionMaxValue = Math.max(...motionChartDataNoNull)
      setMotionMax(Math.ceil((motionMaxValue + 10)/10)*10)
    } else if (timeseriesFull.length < 12) {
      setMotionArray([])
      setMotionMax(120)
    } else if (response == null) {
      setMotionArray([])
    }
  }


  const chartSeries = [{
    data: !!heartRateEnabled ? heartRateArray : !!spoEnabled ? spoArray : !!motionEnabled ? motionArray : heartRateArray,
  }]

  const motionSeries = [{
    data: motionArray
  }]

  const spoSeries = [{
    data: spoArray
  }]

  const chartOptions = {
    chart: {
      type: 'line',
      height: 330,
      width: '100%',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
    },
    markers: {
      size: 0
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    legend: {
        show: false
    },
    tooltip: {
      show: false,
      enabled: false
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: !!heartRateEnabled ? ['#ff4769'] : !!spoEnabled ? ['#12afde'] : !!motionEnabled ? ['#77c40a'] : ['#ff4769'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [100]
      },
    },
    yaxis: {
      min: !!heartRateEnabled ? heartRateMin : !!spoEnabled ? spoMin : !!motionEnabled ? 0 : heartRateMin,
      max: !!heartRateEnabled ? heartRateMax : !!spoEnabled ? 100 : !!motionEnabled ? motionMax : heartRateMax,
      tickAmount: 4,
      labels: {
        show: true,
        style: {
          colors: "#797979",
          fontSize: '12px',
          fontWeight: 400,
        },
      },
    },
    xaxis: {
      categories: timeseries,
      tickAmount: 10,
    },
  }


  return (
    <>
    {!!loading ? <SearchLoader loading={true}/> :
      <>
      {timeseriesFull.length >= 30 ? 
      <>
        <div className={classes.StatHeader}>
        <h2 className={classes.HeaderLabels}>Statistics</h2>
        <div className={classes.ChartbuttonsContainer}>
        <button
        className={classes.ChartButton}
        style={!heartRateEnabled ? {backgroundColor: "#dedede", color: "#ffffff"} : {backgroundColor: "#ff4769", color: "#FFFFFF", boxShadow: '0px 0px 3px 1px #d5cfe6'}}
        onClick={() => {
          setHeartRateEnabled(true);
          setMotionEnabled(false);
          setSpoEnabled(false);
        }}>Heart Rate</button>

        <button
        className={classes.ChartButton}
        style={!spoEnabled ? {backgroundColor: "#dedede", color: "#ffffff"} : {backgroundColor: "#12afde", color: "#FFFFFF", boxShadow: '0px 0px 3px 1px #d5cfe6'}}
        onClick={() => {
          setHeartRateEnabled(false);
          setMotionEnabled(false);
          setSpoEnabled(true);
        }}>SpO<span style={{fontSize: "12px"}}>2</span></button>

        <button
        className={classes.ChartButton}
        style={!motionEnabled ? {backgroundColor: "#dedede", color: "#ffffff"} : {backgroundColor: "#77c40a", color: "#FFFFFF", boxShadow: '0px 0px 3px 1px #d5cfe6'}}
        onClick={() => {
          setHeartRateEnabled(false);
          setMotionEnabled(true);
          setSpoEnabled(false);
        }}>Motion</button>
       </div>
        
    </div>
     
      <div className={classes.Underline} />
        <div className={classes.StatChart}>
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="line"
              height={330}
              width={670}
            />
        </div>
        </>
      :
      <>
      <div className={classes.StatHeader}>
      <h2 className={classes.HeaderLabels}>Statistics</h2>
      <div className={classes.ChartbuttonsContainer}>
        <button className={classes.ChartButton} style={{color: "#b9b9b9"}} disabled>Heart Rate</button>

        <button className={classes.ChartButton} style={{color: "#b9b9b9"}} disabled>SpO<span style={{fontSize: "12px"}}>2</span></button>

        <button className={classes.ChartButton} style={{color: "#b9b9b9"}} disabled>Motion</button>
       </div>
      </div>
      <div className={classes.Underline} />
      <p style={{position: "absolute", top: "50%", left: "32%", fontSize: "18px", fontStyle: "italic", color:"#a9a9a9" }}>Not enough data</p>
      </>}
      
      </>
    }
    </>
  )
}
export default SleepCharts
