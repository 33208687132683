import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import Alert from '@material-ui/lab/Alert'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../Styles'
import { Redirect } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'
import { AuthConsumer } from '../../../AuthContext'
import { subMinutes } from 'date-fns'

const TokenLoginForm = ({ ring_sn, verification_token, onSubmit }) => {
    let classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(true)
    const tokenLoginForm = useFormik({
        initialValues: {
            ring_sn: ring_sn,
            verification_token: verification_token
        },
        validateOnBlur: false,
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: ((values, actions) => {
            onSubmit(values, actions)
        })
    })

    const login_and_redirect = () => {
        if(isLoading){
            tokenLoginForm.handleSubmit()
            setIsLoading(false)
        }
    }
    return (
        <AuthConsumer>
        {({ tokenLoginEndPointFailure, tokenLoginFailure, tokenLoginSuccess, tokenLoginSuccessRedirect }) => (
            <div className={classes.authCard}>
                <form onLoad={login_and_redirect()} noValidate autoComplete="off" className={classes.authForm}>
                    {!!tokenLoginEndPointFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}
                    {!!tokenLoginFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Login.Error'/></Alert>}
                    {!!tokenLoginSuccess && <Alert severity="success"><FormattedMessage id='EndPoint.Login.Success'/></Alert>}
                    {!!tokenLoginSuccessRedirect && 
                    <>{window.location = NamedRoutes.home}</>
                    // toDo: temp clinic portal patient link solution
                    // <Redirect to={{
                    //     pathname: NamedRoutes.home,
                    //     state: { from: NamedRoutes.auth.tokenLogin }
                    // }}/>
                    }
                    {tokenLoginForm.isSubmitting && <FormLoader loading={true}/>}
                </form>
            </div>
        )}
        </AuthConsumer>
    )
}

export default TokenLoginForm
