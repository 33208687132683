import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { useStyles } from './Styles'
import ItemFilter from './ItemFilter'
import ItemTable from './ItemTable'
import { RawDataConsumer } from '../../../RawDataContext'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import NightCharts from './NightCharts'
import { AuthConsumer } from '../../../AuthContext'
import NightChartsWithDate from './NightChartsWithDate'
import DayCharts from './DayCharts'
import DayChartsWithDate from './DayChartsWithDate'

const isToday = (date) => {
  const today = new Date()
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
}


const RawDataContent = () => {
  let classes = useStyles()
  const [nightChartsFilterDate, setNightChartsFilterDate] = React.useState(new Date())
  const [dayChartsFilterDate, setDayChartsFilterDate] = React.useState(new Date())
  const columns = [
    { id: 2, name: 'timestamp', label: 'Timestamp', minWidth: 170 },
    { id: 3, name: 'event_type', label: 'Event Type', minWidth: 170 },
    { id: 4, name: 'content', label: 'Content', minWidth: 100 }
  ]
  return (
    <div>
      <Grid container spacing={3} className={classes.RowDataContentGridContainer}>
        <Grid item xs={12} md={8} className={classes.RowDataContentGridItem}>
          <Box p={2} className={classes.RowDataContentBox}>
            <RawDataConsumer>
              {({ eventsLog, eventsLogLoading, eventsLogFailure, setEventLogDate }) => (
                <>
                  <ItemFilter title={'Event Log'} setFilterDate={setEventLogDate}/>
                  {!!eventsLogFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}
                  <ItemTable rows={eventsLog} columns={columns} loading={eventsLogLoading}/>
                </>
              )}
            </RawDataConsumer>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} className={classes.RowDataContentGridItem}>
          <Grid container spacing={3} className={classes.RowDataContentGridContainer}>
            <Grid item xs={12} className={classes.RowDataContentGridItem}>
              <Box p={2} className={classes.RowDataContentBox}>
                <ItemFilter title={'Day Time'} setFilterDate={setDayChartsFilterDate}/>
                <AuthConsumer>
                  {({ userInfo }) => (
                    !!Object.keys(userInfo).length &&
                    (!!isToday(new Date(dayChartsFilterDate)) ?
                      <DayCharts userId={userInfo.id} userEmail={userInfo.email}/> :
                      <DayChartsWithDate userId={userInfo.id} userEmail={userInfo.email}
                                         dayChartsFilterDate={dayChartsFilterDate}/>)
                  )}
                </AuthConsumer>
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.RowDataContentGridItem}>
              <Box p={2} className={classes.RowDataContentBox}>
                <ItemFilter title={'Night Time'} setFilterDate={setNightChartsFilterDate}/>
                <AuthConsumer>
                  {({ userInfo }) => (
                    !!Object.keys(userInfo).length &&
                    (!!isToday(new Date(nightChartsFilterDate)) ?
                      <NightCharts userId={userInfo.id}/> :
                      <NightChartsWithDate userId={userInfo.id} nightChartsFilterDate={nightChartsFilterDate}/>)
                  )}
                </AuthConsumer>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>

  )
}
export default RawDataContent
