import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
      width: 500,
      position: 'relative',
      minHeight: 200,
  },
  fab: {
    position: 'absolute',
    bottom: "100",
    right: theme.spacing(2),
  },
  DashboardContentItemContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "55px",
    fontWeight: 300
  },
  dashboardContent: {
    width: "calc(100%)",
    height: "100%",
    background: "#f0f0f0"
  },
  DashboardContentGridContainer: {
    height: "100%"
  },
  DashboardContentGridItem: {
    padding: "0px"
  },
  DashboardGrid: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "33% 33.5% 33% 0.5%",
    gridTemplateRows: "100%",
  },
  DBGridHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  HeaderIcon: {
    backgroundColor: "#9d7ad2",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0px solid transparent",
    borderRadius: "50px",
    '@media (max-width: 815px)': {
      width: "38px",
      height: "38px",
    },
    '& i': {
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(22),
      color: "white",
      margin: 0,
      marginTop: "6px",
      marginLeft: "1px",
      '@media (max-width: 815px)': {
        fontSize: theme.typography.pxToRem(21),
      },
    },
  },
  DBGridHeaderString: {
    margin: 0,
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(20),
    color: "#4b2c7a",
    '@media (max-width: 815px)': {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  Divider: {
    height: "1px",
    backgroundColor: "#e7e7e7",
    margin: "0 10px"
  },

  timWeatherDate: {
    fontSize: 10,
  },



  //Grid One
  DBGridCellOne: {
    gridColumn: "1 / span 1",
    marginTop: "14px",
    marginBottom: "14px",
    marginLeft: "14px",
    marginRight: "7px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    border: "0px solid transparent",
    borderRadius: "5px",
    height: "100vh",
  },
  DBUpcomingMedicationsListWrapper: {
    paddingTop: "12px",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginLeft: "10px",
    marginRight: "10px",
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
  },
  DBUpcomingMedicationsList: {
    display: "flex",
    flexDirection: "column",
    fontSize: "20px",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  UpcomingMedicationsListNameAndDose: {
    display: "flex",
    flexDirection: "Column",
  },
  UMName: {
    fontSize: theme.typography.pxToRem(20),
    margin: 0,
    color: "#4b2c7a",
  },
  UMDose: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 200,
    color: "#707070",
    marginBottom: "0px",
    '@media (max-width: 815px)': {
      minWidth: 68,
    },
  },
  UMDoseNumber: {
    fontSize: theme.typography.pxToRem(16),
    color: "#707070",
    fontWeight: 200,
    width: '100px',
    marginLeft: 15,
    marginBottom: "0px",
    '@media (max-width: 815px)' : {
      marginLeft: 10,
      width: '85px',
    }
  },
  UMDoseTime: {
    fontSize: theme.typography.pxToRem(18),
    color: "#707070",
    fontWeight: 400,
    marginBottom: "0px",
    textAlign: 'right',
  },
  UpcomingMedicationsListDoses: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  UpcomingMedicationsDosesContainer: {
    display: "flex",
    margin: 0,
  },
  NoRemindersLabelContainer: {
    textAlign: "center",
    marginTop: "100px"
  },
  NoRemindersLabelString: {
    fontSize: theme.typography.pxToRem(18),
    fontStyle: "italic",
    color: "#808080"
  },
  MoreRemindersContainer: {
    backgroundColor: "#c2c2c2",
    marginTop: "5px",
    paddingTop: "5px",
  },
  MoreReminders: {
    textAlign: "center",
    margin: 0,
    color: "white",
    fontSize: theme.typography.pxToRem(14),
  },


  // Grid Two
  DBGridCellTwo: {
    gridColumn: "2 / span 1",
    display: "flex",
    flexDirection: "column",
    marginTop: "14px",
    marginBottom: "14px",
    marginLeft: "7px",
    marginRight: "7px",
    backgroundColor: "white",
    border: "0px solid transparent",
    borderRadius: "5px",
    height: "100vh",
  },
  DBGridCellTwoSeizureFreeStreak: {
    fontSize: theme.typography.pxToRem(60),
    color: "#12bbc1",
    marginTop: "0",
  },
  DBGridCellTwoSeizureFreeString: {
    fontSize: theme.typography.pxToRem(16),
    color: "#707070",
    margin: 0,
    fontWeight: "200",
  },
  DBGridCellTwoFreeSinceString: {
    fontSize: theme.typography.pxToRem(18),
    color: "#707070",
    marginTop: "10px",
    marginBottom: "25px"
  },
  RecentSeizureEventsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    '& .icon-night' : {
      fontSize: theme.typography.pxToRem(22),
      color: '#32478e',
    },
    '& .icon-day': {
      fontSize: theme.typography.pxToRem(22),
      color: '#f8c624',
    },
  },
  RecentSeizureEventsHeader: {
    fontSize: theme.typography.pxToRem(16),
    color: "#707070",
    fontWeight: "200",
    marginTop: "10px",
    marginBottom: "14px",
    marginLeft: "20px",
    textAlign: "left",
  },

  DBNoSeizuresContainer: {
    textAlign: "center",
    marginTop: "100px"
  },
  DBNoSeizuresString: {
    fontSize: theme.typography.pxToRem(18),
    fontStyle: "italic",
    color: "#808080"
  },

  EventsDateContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "14px",
    marginRight: "14px",
  },
  RecentSeizureDate: {
    fontSize: theme.typography.pxToRem(18),
    marginTop: 0,
    marginLeft: "5px",
  },
  RecentSeizureIconDateWrapper: {
    display: "flex",
  },

  RecentSeizureEventsTypeContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "10% 8% 30% 10% 42%",
    '& .icon-seizure': {
      gridColumn: "2 / span 1",
      color: '#4b2c7a',
      fontSize: theme.typography.pxToRem(16),
      marginTop: "4px",
      marginRight: "6px",
    },
  },
  TypeLabel: {
    gridColumn: "3 / span 1",
    fontWeight: 600,
    marginBottom: "7px",
  },
  TypeString: {
    gridColumn: "5 / span 1",
    marginBottom: "7px",
  },


  RecentSeizureEventsDurationContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "10% 8% 30% 10% 42%",
  },
  DurationLabel: {
    gridColumn: "3 / span 1",
    fontWeight: 600,
  },
  DurationString: {
    gridColumn: "5 / span 1",
  },
  NewSeizureLinkSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  NewSeizureLink: {
    border: 'none',
    padding: theme.spacing(1.3, 4),
    backgroundColor: '#4b2c7a',
    color: '#FFFFFF',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 200,
    margin: "10px 0 0 0",
    textAlign: "center",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  },
  NewSeizurePlus: {
    fontSize: theme.typography.pxToRem(16),
  },
  NoSeizureNewSeizureLink: {
    color: '#0fadb3',
    textDecoration: "underline",
    fontSize: theme.typography.pxToRem(20),
    margin: "8px 0 0 0",
    textAlign: "center",
    marginTop: "100px"
  },
  DisabledButtonAndWarningSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },





  // Grid Three
  DBGridCellThree: {
    gridColumn: "3 / span 1",
    display: "flex",
    flexDirection: "column",
    marginTop: "14px",
    marginBottom: "14px",
    marginLeft: "7px",
    marginRight: "14px",
    backgroundColor: "white",
    border: "0px solid transparent",
    borderRadius: "5px",
    height: "100vh",
  },
  SleepInformationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  SleepDurationHeader: {
    marginBottom: 0,
    marginTop: 10,
    fontWeight: 200,
    fontSize: theme.typography.pxToRem(16),
    color: "#707070",
  },
  SleepDurationString: {
    marginBottom: 10,
    fontSize: theme.typography.pxToRem(20),
  },
  BedtimeWaketime: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "13% 30% 10% 34% 13%",
    '@media (max-width: 815px)': {
      gridTemplateColumns: "11% 34% 10% 34% 11%",
    },
    '& h4' : {
      gridColumn: "2 / span 1",
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(16),
      marginBottom: "7px",
    },
  },
  BedtimeWaketimeString: {
    textAlign: "right",
    marginBottom: "7px",
  },


  DBGridCellThreeSleepQuality: {
    display: "flex",
    flexDirection: "column",
    height: "400px",
    position: 'relative',
    top: '-3px'
  },
  DBGridCellThreeSleepQualityHeader: {
    fontSize: "25px",
    width: "100%",
    textAlign: "center",
    marginBottom: "10px",
    fontWeight: "400",
    color: "rgb(23, 43, 77)"
  },
  sleepQualityScoreChart: {
    position: 'relative',
  },
  sleepQualityScoreChartParagraph: {
    position: 'absolute',
    top: '9%',
    left: 0,
    right: 0,
    margin: 0,
    textAlign: "center",
  },
  sleepQualityScoreString: {
    marginTop: "-22px",
    marginBottom: 10,
    textAlign: "center",
  },
  DBGridCellThreeSleepQualityColumn: {
    flexDirection: "column"
  },
  DBGridCellThreeSleepQualityImage: {
    height: "80%",
    maxHeight: "100px"
  },
  DBGridCellThreeSleepQualityText: {
    fontSize: "12px",
    fontWeight: "bold"
  },
  DBGridCellThreeSleepQualityEmoji: {
    width: "80%",
    maxWidth: "40px"
  },
  DashboardGridImageCell: {
    gridColumn: "1 / span 3",
  },


  HelpButton: {
    position: 'fixed',
    bottom: 10,
    right: 10,
    opacity: 1,
    width: 100,
    height: 100,
    borderRadius: '50%',
    border: '0px solid transparent',
    transition: `all 0.5s ease-in-out`,
    display: 'flex',
    cursor: 'pointer',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "#4b2c7a",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)",
    fontSize: 32,
    zIndex: 100,
    transform: 'translateY(0)',

    '&:hover': {
      color: theme.palette.background.paper,
      borderColor: theme.palette.primary.main,
      animationDuration: '0.2s',
      animationFillMode: 'both',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      animationName: 'bounce',
      backgroundColor: "#8660bf",
    },

    '&.active': {
      backgroundColor: "#8660bf",
      animationDuration: '0.2s',
      animationFillMode: 'both',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      animationName: 'bounce',
    }
  },
  HelpButtonInnerCircle: {
    backgroundColor: "white",
    width: "77px",
    height: "77px",
    border: "0px solid transparent",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  HelpButtonString: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(26),
    margin: 0,
    color: "#4b2c7a",
    textTransform: "capitalize",
    transition: `all 0.5s ease-in-out`,
    '&:hover': {
      animationDuration: '0.2s',
      animationFillMode: 'both',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      animationName: 'bounce',
      color: "#8660bf",
    },

    '&.active': {
      color: "#8660bf",
      animationDuration: '0.2s',
      animationFillMode: 'both',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      animationName: 'bounce',
    }
  },
}))