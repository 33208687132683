import {makeStyles} from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'


export const useStyles = makeStyles((theme) => ({
  mainNavigation: {
    position: 'fixed',
    '& a': {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.primary.main,
      padding: theme.spacing(2.75, 1),
      paddingTop:"12px",
      paddingBottom:"7px",
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightLight,
      textTransform: 'capitalize',
      textDecoration: 'none',
      lineHeight: 1,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      '& [class^="icon-"]': {
        marginBottom: theme.spacing(0.1),
        fontSize: theme.typography.pxToRem(34)
      },
      '&.active': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
      },
      '&:hover, &:focus': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
      }
    },
    [theme.breakpoints.up('md')]: {
      height: '100%',
      minWidth: 104,
      backgroundColor: theme.palette.common.white,
      boxShadow: `0px 0px 0px 2px ${theme.palette.grey[200]}`,
      '& a': {
        backgroundColor: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightLight
      }
    }
  }
}))
