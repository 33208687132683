import React from 'react'
// import { useStyles } from '../../Styles'
import {ProfileConsumer}  from '../../../../../ProfileContext'
import SleepQualityForm from './SleepQualityForm'


const SleepQuality = () => {
  // let classes = useStyles()
  return (
    <ProfileConsumer>
    {({getSleepQuality}) => (
      <SleepQualityForm
        loadSleepQuality={getSleepQuality}/>
    )}
  </ProfileConsumer>
  )
}

export default SleepQuality