import React from 'react'
import { useFormik } from 'formik'
import { useStyles } from './Styles'
import ProfileServices from '../../../../../Services/Consumers/ProfileServices'
import { FormLoader } from '../../../../Loader/FormLoader'
import { FormattedMessage } from 'react-intl'
import { FormControl, InputLabel, TextField, RadioGroup, FormControlLabel, Radio, Checkbox } from '@material-ui/core'
import { StyledSelect, SelectTheme } from '../../../../StyledComponents/StyledSelect'
import { HiddenCheckbox } from '../../../../StyledComponents/HiddenCheckbox'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'



const RescueForm = ({ onSubmit, switchToListMode, medicationID, putFlag, setPutFlag, unscheduledMeds }) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false);
  const [medNames, setMedNames] = React.useState([]);
  const [medForms, setMedForms] = React.useState([]);
  const [medDoses, setMedDoses] = React.useState([]);
  const [chosenMedications, setMedicationChoice] = React.useState('');

  const [allMedicationsList, setMedicationList] = React.useState([]);
  const [medNameInput, setMedNameInput] = React.useState('');
  const [medSuggestions, setMedSuggestions] = React.useState([]);
  const [viewList, setViewList] = React.useState(false);


  const defaultValues = {
    start_date: moment().add("days", 1).format('YYYY-MM-DD'),
    medication_id: null,
    generic_name: null,
    brand_name: null,
    dose: null,
    dose_units: "",
    form: ""
  }

  const unscheduledMedForm = useFormik({
    initialValues: {
      ...defaultValues,
    },
    onSubmit: ((values, actions) => {
      onSubmit(medicationID, values, actions, putFlag, setPutFlag);
      switchToListMode();
    })
  })



  const loading = open && medNames.length === 0;

  React.useEffect(() => {
    const getData = async () => {
      const response = await ProfileServices.getMedicationsList();
      const medicationsList = await response.json();
      setMedicationList(medicationsList.medications);

      const medicationsListNames = medicationsList.medications.map((list) => {
        return `${list.generic_name} (${list.brand_name})`
      });
      const medNameSet = new Set(medicationsListNames);
      const medicationsNames = [...medNameSet];

      setMedNames(medicationsNames.map(name => (
        {value: `${name}`, label: `${name}`}
      )))
    }
    getData();
  }, [loading]);


  const [medication, setMedication] = React.useState('')
  const [medicationLogID, setMedicationLogID] = React.useState(null)

  React.useEffect(() => {
    if(unscheduledMeds && medicationID !== null) {
      let med;

      unscheduledMeds.filter(item => {
      const itemID = item.id;
      setMedicationLogID(itemID)
      if(itemID === medicationID) {
        med = item;
      }});
      setMedication(med);
      unscheduledMedForm.setFieldValue('patient_medication_id')
    } else {
      setMedication('new')
    }
  }, [unscheduledMeds, medicationID])

  const searchMedList = (medList) => {
    const medSearchResult = medList.filter(med => med.value.toLowerCase().includes(medNameInput.toLowerCase()) && med);
    setMedSuggestions(medSearchResult);
  }

  React.useEffect(() => {
    medNameInput.length ? searchMedList(medNames) : setMedSuggestions([]);
  }, [medNameInput])

  const [checked, setChecked] = React.useState(false);

  const handleCheck = () => {
    setChecked(!checked);
  };

  const [chosenMedicationName, setChosenMedicationName] = React.useState(null);
  const [chosenMedicationForm, setChosenMedicationForm] = React.useState(null);
  const [chosenMedicationDose, setChosenMedicationDose] = React.useState(null);
  const [customMedicationDose, setCustomMedicationDose] = React.useState("");
  const [selectedDose, setSelectedDose] = React.useState("");



  const [selectedStartDate, setSelectedStartDate] = React.useState(defaultValues.start_date)


  const handleStartDateChange = (date) => {
    if (!!date) {
      let dateToString = date.toDateString()
      setSelectedStartDate(dateToString)
      unscheduledMedForm.setFieldValue('start_date', moment(date).format('YYYY-MM-DD'))
    }
  }

  const [measureTypeValue, setMeasureTypeValue] = React.useState("")

  

  const getMedicationsDosesAndForms = (medicationsChoice) => {
      const medicationsNameSplit = medicationsChoice.split(" (");
      const medicationsGenericName = medicationsNameSplit[0];
      const medicationsBrandName = medicationsNameSplit[1].replace(")", "");

      const medicationsNameFilter = allMedicationsList.filter(function(medication){
        return medication.generic_name == medicationsGenericName && medication.brand_name == medicationsBrandName});
      setMedicationChoice(medicationsNameFilter);

      const medicationsListDoses = medicationsNameFilter.map((list) => {
        return `${list.dose}${list.dose_unit}`
      })

      const medicationsListForms = medicationsNameFilter.map((list) => {
        return `${list.form}`
      })

      const medDoseSet = new Set(medicationsListDoses);
      const medicationsDose = [...medDoseSet];

      const medFormSet = new Set(medicationsListForms);
      const medicationsForms = [...medFormSet];
      setMedDoses(medicationsDose.map(medDose => ({value: `${medDose}`, label: `${medDose}`})))
      setMedForms(medicationsForms.map(medForm => ({value: `${medForm}`, label: `${medForm}`})))
  }

  const setMedicationId = (dosageChoice) => {
    const id = chosenMedications.filter(function(medication){return (medication.dose + medication.dose_unit) == dosageChoice})[0].id
    unscheduledMedForm.setFieldValue('medication_id', null);
  }

  const setMedicationDose = (dosageChoice) => {
    const dose = chosenMedications.filter(function(medication){return (medication.dose + medication.dose_unit) == dosageChoice})[0].dose
    unscheduledMedForm.setFieldValue('dose', dose);
  }

  const setMedicationMeasureType = (dosageChoice) => {
    const measureType = chosenMedications.filter(function(medication){return (medication.dose + medication.dose_unit) == dosageChoice})[0].dose_unit
    unscheduledMedForm.setFieldValue('dose_units', measureType);
  }

  const setDosesPerDay = (pills) => {
    unscheduledMedForm.setFieldValue('doses_per_day', pills)
  }

  return (
    <>
    <h1>Add Rescue Medication</h1>
    <form onSubmit={(e) => {
      unscheduledMedForm.handleSubmit(e)}}
      noValidate className={classes.MedicationForm}>


        <FormControl>
          <FormControlLabel
            control={<Checkbox
              checked={checked}
              onChange={() => {
                handleCheck();
                unscheduledMedForm.setFieldValue('generic_name', '');
                unscheduledMedForm.setFieldValue('medication_id', null);
                unscheduledMedForm.setFieldValue('brand_name', null);
                unscheduledMedForm.setFieldValue('dose', 0);
              }}/>}
            label={<FormattedMessage id='medicationForm.InputLabel.checkBox'/>}
            labelPlacement="end"
          />

        <h3>Medication Name</h3>

        {checked === false ?
        <>
          <FormControlLabel
            control={<HiddenCheckbox
              checked={viewList}
              onChange={() => {
                setViewList(!viewList)
              }}/>}
            label={!viewList ? "Select From List" : "Enter Medication Name"}
            labelPlacement="start"
          />

          <StyledSelect 
          components={!viewList && { DropdownIndicator:() => null, IndicatorSeparator:() => null }}
          options={viewList ? medNames : medSuggestions}
          noOptionsMessage={() => null}
          onChange={(event, newChosenMedicationName) =>
            {
              unscheduledMedForm.setFieldValue("generic_name", event.value.split(' (')[0]);
              unscheduledMedForm.setFieldValue("brand_name", event.value.split(' (')[1].split(')')[0]);
              getMedicationsDosesAndForms(event.value);
              setChosenMedicationName(newChosenMedicationName);
              setChosenMedicationForm(null);
              setChosenMedicationDose(null);
            }}
            
            onInputChange={e => setMedNameInput(e)}
            menuShouldScrollIntoView={viewList}
            placeholder={viewList ? "Select From List" : "Enter Medication Name"}
            menuPlacement={viewList ? "bottom" : "top"}
            maxMenuHeight={viewList ? 240 : 120}
            isSearchable={!viewList}
            theme={theme => SelectTheme(theme)}
            styles={{
              menu: base => ({
                ...base,
                zIndex: 100
              })
            }}
            />
          </> 
        : 
        <TextField
        required
          fullWidth
          margin="dense"
          disabled={medicationID !== 'new'}
          type="text"
          id='generic_name'
          name='generic_name'
          variant="outlined"
          onChange={(e)=> {
            unscheduledMedForm.handleChange(e);
          }}
          onBlur={unscheduledMedForm.handleBlur}
          value={unscheduledMedForm.values.generic_name && unscheduledMedForm.values.brand_name}
          helperText={unscheduledMedForm.errors.generic_name && unscheduledMedForm.touched.generic_name && unscheduledMedForm.errors.generic_name}
          placeholder={"Please enter your medication name"}
          error={unscheduledMedForm.errors.generic_name && unscheduledMedForm.touched.generic_name !== undefined}
          inputProps={{
            autoComplete: 'new-name',
            form: {
              autoComplete: 'off'
            }
          }}
        />}
      </FormControl>

      <div className={classes.row}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="form">
            <FormattedMessage id='medicationForm.InputLabel.form'/>
          </InputLabel>
          
          <StyledSelect
          options={checked === false ? medForms : [{value: "Pill", label: "Pill"},{value: "Nasal Spray", label: "Nasal Spray"}, {value: "Syringe", label: "Syringe"}]}
          onChange={(event) => {
            unscheduledMedForm.setFieldValue("form", event.value);
            setChosenMedicationForm(event.value);
          }}
          isSearchable={false}
          theme={theme=> SelectTheme(theme)}
          />
          </FormControl>

        {checked === false ?
          <FormControl variant="outlined">
            <InputLabel htmlFor="dose">
              <FormattedMessage id='medicationForm.InputLabel.dose'/>
            </InputLabel>
            <StyledSelect 
              options={medDoses}
              onChange={(event) => {
                setChosenMedicationDose(event.value);
                setMedicationMeasureType(event.value);
                setMedicationDose(event.value);
                setMedicationId(event.value);
              }}
              isSearchable={false}
              theme={theme => SelectTheme(theme)}
            />
          </FormControl>
          :
          <FormControl variant="outlined">
            <InputLabel htmlFor="dose">
              <FormattedMessage id='medicationForm.InputLabel.dose'/>
            </InputLabel>
            <TextField
              id="dose"
              disabled={medicationID !== 'new'}
              required
              fullWidth
              margin="dense"
              type="number"
              min="1"
              name='dose'
              variant="outlined"
              onChange={(event) => {
                unscheduledMedForm.handleChange(event);
                setChosenMedicationDose(event.target.value)
              }}
              value={unscheduledMedForm.values.dose_value}
              onBlur={unscheduledMedForm.handleBlur}
              inputProps={{
                autoComplete: 'new-dose',
                form: {
                  autoComplete: 'off'
                }
              }}
            />
          </FormControl>
        }
        {checked === true ? 
        <RadioGroup id="measure_type"
                    row aria-label="dose Prefix"
                    name="measure_type"
                    value={measureTypeValue}
                    onChange={(event) => {
                      if (checked === true) {
                        const customMeasureType = event.target.value;
                        unscheduledMedForm.setFieldValue('dose_units', customMeasureType)
                        setMeasureTypeValue(customMeasureType)
                      }
                    }}
                    className={classes.formRadioGroup}>
          <FormControlLabel disabled={unscheduledMedForm.values.dose_value <= 0} value="mg" control={<Radio color="primary"/>} label="mg"/>
          <FormControlLabel disabled={unscheduledMedForm.values.dose_value <= 0} value="ml" control={<Radio color="primary"/>} label="ml"/>
        </RadioGroup>
        :
        null
        }
        </div>

        <div className={classes.row}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={classes.dateTimePicker}
            disableToolbar
            name='start_date'
            variant="inline"
            format="dd-MM-yyyy"
            margin="normal"
            id="start_date"
            label="Start Date"
            value={selectedStartDate}
            onChange={(value) => handleStartDateChange(value)}
            KeyboardButtonProps={{
              'aria-label': 'change start date'
            }}
          />
        </MuiPickersUtilsProvider>
        </div>
        <div className={classes.formActionsWrapper}>
          
        <button
        disabled = {
          chosenMedicationForm === null || chosenMedicationDose === null || (checked === true && measureTypeValue === "")
        }
          type="submit"
          className={classes.formActionSubmit}>
          <FormattedMessage id='MedicationForm.button.submit'/>
        </button>

        <button className={classes.formActionCancel} onClick={switchToListMode}>
          <FormattedMessage id='MedicationForm.button.cancel'/>
        </button>

      </div>
      {unscheduledMedForm.isSubmitting && <FormLoader loading={true}/>}
    </form>
    </>
  )
}

export default RescueForm
