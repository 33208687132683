import React from 'react'
import { useStyles } from '../Styles'
import { FormattedMessage } from 'react-intl'
import Alert from '@material-ui/lab/Alert'
import { SearchLoader } from '../../../../Loader/SearchLoader'
import moment from "moment";
import RowItem from './RowItem/RowItem'
import UnscheduledRowItem from './RowItem/UnscheduledRowItem'
import {ProfileConsumer} from '../../../../../ProfileContext'
import ItemFilter from './ItemFilter'
import MedHistoryForm from './MedHistoryForm'
import UnscheduledHistoryForm from './UnscheduledHistoryForm'
import "./UnscheduledHistoryForm.scss"
import { useLocation } from 'react-router-dom'
import { StyledModal } from '../../../../StyledComponents/StyledModal'

const MedicationHistoryTab = ({ loadMedicationHistory, editMedicationHistory, loadUnscheduledMedicationHistory, saveUnscheduledMedicationHistory, deleteUnscheduledMedicationHistory, getUnscheduledMedications, unscheduledMedications }) => {

  const classes = useStyles()
  const today = new Date()
  const [selectedDate, setSelectedDate] = React.useState(moment().format("ddd MMM DD YYYY"))
  const [selectedDateForRescue, setSelectedDateForRescue] = React.useState(moment().format())
  const [editMed, setEditMed] = React.useState(false)
  const [medToEdit, setMedToEdit] = React.useState(null)

  const [openUnscheduledMedAddForm, setOpenUnscheduledMedAddForm] = React.useState(false)
  const [unscheduledMedToEdit, setUnscheduledMedToEdit] = React.useState(null)

  const [putFlag, setPutFlag] = React.useState(false);

  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [addMedicationWarning, setAddMedicationWarning] = React.useState(false);



  const status = {
    "taken":  "Taken",
    "snooze":   "Snoozed",
    "no action": "Auto Snoozed",
    "no_response":  "Not Taken",
    "dismiss":  "Not Taken",
    "not_taken":  "Not Taken",
    "not taken": "Not Taken"
  };
  const statusColor = {
    "taken":  "#3EA883",
    "snooze":   "blue",
    "no action": "blue",
    "no_response":  "#f76565",
    "dismiss":  "#f76565",
    "not_taken":  "#f76565",
    "not taken":  "#f76565"
    
  };

  const handleOpenEditForm = (id) => {
    setMedToEdit(id)
    setEditMed(true)
  }

  const handleOpenUnscheduledAddForm = (id) => {
    setOpenUnscheduledMedAddForm(true)
  }

  const handleOpenUnscheduledEditForm = (id) => {
    setUnscheduledMedToEdit(id)
    setOpenUnscheduledMedAddForm(true)
  }

  const handleCloseUnscheduledForm = () => {
    setOpenUnscheduledMedAddForm(false)
  }

  const showDeleteModal = (id) => {
    setItemToDelete(id);
    setIsDeleteModalOpen(true);
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  }

  const handleDeleteUnscheduledMedicationHistory = () => {
    deleteUnscheduledMedicationHistory(itemToDelete, putFlag, setPutFlag);
    closeDeleteModal();
  }

  const handleCloseEditForm = () => {
    setEditMed(false);
    setMedToEdit(null);
  }

  React.useEffect(() => {

    const startTimestamp = moment(selectedDate).startOf('d').utc().format();
    const endTimestamp = moment(selectedDate).endOf('d').utc().format();
    
    let queryParam = '';
    if(selectedDate !== null) {
      queryParam += `start_timestamp=${startTimestamp}&end_timestamp=${endTimestamp}`;
    }

    getUnscheduledMedications()

    loadMedicationHistory(queryParam);
    loadUnscheduledMedicationHistory(queryParam);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDate, putFlag])

    
  const unscheduledMedicationList = unscheduledMedications
    
  return (
    <div className="MedicationHistoryTab">
      <ProfileConsumer>       
        {({ medicationHistoryLoading, medicationHistoryFailure, medicationHistory, medicationHistorySuccess,unscheduledMedicationHistoryLoading, unscheduledMedicationHistoryFailure, unscheduledMedicationHistory, unscheduledMedicationHistorySuccess, unscheduledMedicationEventData, unscheduledMedicationEventDataLoading }) => (
          <>
            {}
            {!!medicationHistoryFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}
            {!!unscheduledMedicationHistoryFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}

            {!!medicationHistorySuccess && <Alert severity="success"><FormattedMessage id='EndPoint.Seizures.Success'/></Alert>}
            {!!unscheduledMedicationHistorySuccess && <Alert severity="success"><FormattedMessage id='EndPoint.Seizures.Success'/></Alert>}

            

            {!!medicationHistoryLoading && !!unscheduledMedicationHistoryLoading ? <SearchLoader loading={true}/> :
              editMed ?
              <MedHistoryForm 
                handleCloseEditForm={handleCloseEditForm}
                medicationHistory={medicationHistory}
                medLogID={medToEdit}
                editMedicationHistory={editMedicationHistory}

                loadMedicationHistory={loadMedicationHistory}

                putFlag={putFlag}
                setPutFlag={setPutFlag}
              />
              : openUnscheduledMedAddForm == true ?
              <>
              <UnscheduledHistoryForm
                handleCloseEditForm={handleCloseEditForm}
                handleCloseUnscheduledForm={handleCloseUnscheduledForm}
                unscheduledMedicationHistory={unscheduledMedicationHistory}
                unscheduledMedID={unscheduledMedToEdit}
                saveUnscheduledMedicationHistory={saveUnscheduledMedicationHistory}
                unscheduledMedicationList={unscheduledMedicationList}
                unscheduledMedicationEventData={unscheduledMedToEdit !== "new" ? unscheduledMedicationEventData : null}
                unscheduledMedicationEventDataLoading={unscheduledMedicationEventDataLoading}
                selectedDateForRescue={selectedDateForRescue}
                setSelectedDateForRescue={setSelectedDateForRescue}
                putFlag={putFlag}
                setPutFlag={setPutFlag}
                selectedDate={selectedDate}
                setselectedDate={setSelectedDate}
                />
              </>
              :
              <div className={classes.medicationHistoryContainer}>
                <div className={classes.calendarContainer}>
                  <ItemFilter className={classes.calendarPicker}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  setSelectedDateForRescue={setSelectedDateForRescue} / >
                </div>
                <div className={classes.medicationHistoryRows}>
                  <div className={classes.unscheduledMedicationRows}>
                    <h3 className="rescueMedicationHeader">Rescue Medication History
                      <span className={classes.buttonsRow}>
                    {!editMed && 
                      <button
                        className={classes.addRescueButton}
                        style={{cursor: (!unscheduledMedicationList ? 'not-allowed' : ('pointer')), opacity: (!unscheduledMedicationList ? 0.5 : 1)}}
                        onClick={() => {
                          if (!unscheduledMedicationList) {
                            setAddMedicationWarning(true);
                          } else if (!!unscheduledMedicationList) {
                            setAddMedicationWarning(false);
                          handleOpenUnscheduledAddForm();
                          }}}>
                          <span>+ </span>Log Rescue Medication
                      </button>}
                      {addMedicationWarning &&
                        <p className="addMedicationWarning">No rescue medication available.</p>
                      }
                      
                  </span></h3>
                    {unscheduledMedicationHistory.data && !unscheduledMedicationHistory.data.length ?
                      <>
                      <p className="NoRMHString">You have no rescue medication history for <span>{moment(selectedDate).format("MMMM DD, YYYY")}</span></p>
                      </>
                    :<></>}
                    {unscheduledMedicationHistory.data && unscheduledMedicationHistory.data.length ?
                      unscheduledMedicationHistory.data.map((unscheduledItem) => {
                        const unscheduledTimeStamp = moment(unscheduledItem.dose_timestamp);
                        const unscheduledItemData = {
                          medId: unscheduledItem.patient_medications_id,
                          logId: unscheduledItem.unscheduled_medication_log_id,
                          name: unscheduledItem.medication_name,
                          dose: unscheduledItem.dose,
                          time: unscheduledTimeStamp.format("h:mm A"),
                          icon: (unscheduledTimeStamp.format("a") === 'am' ? 'icon-day' : 'icon-night'),
                          day: (unscheduledTimeStamp.format("DD")),
                          month: (unscheduledTimeStamp.format("MMM")),
                      };
                      return (
                        <>
                        <UnscheduledRowItem
                          id={unscheduledItemData.medId}
                          logId={unscheduledItemData.logId}
                          day={unscheduledItemData.day}
                          month={unscheduledItemData.month}
                          time={unscheduledItemData.time}
                          dose={unscheduledItemData.dose}
                          medicationName={unscheduledItemData.name}
                          handleEditItem={handleOpenUnscheduledEditForm}
                          handleDeleteItem={showDeleteModal}
                          icon={unscheduledItemData.icon}
                        />
                        </>
                      )})
                      :
                      <></>
                    }
                    {}
                    
                  </div>
                  <h3 className="rescueMedicationHeader">Medication History</h3>
                  {medicationHistory.data && !medicationHistory.data.length ?
                      <>
                      <p className="NoRMHString">You have no medication history for <span>{moment(selectedDate).format("MMMM DD, YYYY")}</span></p>
                      </>
                    :<></>}
                  {medicationHistory.data ?
                    medicationHistory.data.map((item) => {
                      const timeStamp = item.reply_timestamp ? moment(item.reply_timestamp) : moment(item.dose_timestamp);
                      const originalTimeStamp = moment(item.dose_timestamp);
                      const itemData = {
                        id: item.scheduled_medication_log_id,
                        time : timeStamp.format("h:mm A"),
                        icon : (timeStamp.format("a") === 'am' ? 'icon-day' : 'icon-night'),
                        day : (originalTimeStamp.format("DD")),
                        month: (originalTimeStamp.format("MMM")),
                        status: item.status ? status[item.status.toLowerCase()] : status.no_response,
                        color: item.status ? statusColor[item.status.toLowerCase()] : statusColor.no_response
                      };
                    
                      return (
                      <>
                        <RowItem
                        id={itemData.id}
                        key={itemData.id}
                        day={itemData.day}
                        month={itemData.month}
                        time={itemData.time}
                        status={itemData.status}
                        color={itemData.color}
                        dose={item.dose}
                        medicationName={item.medication_name}
                        handleEditItem={handleOpenEditForm}
                        icon={itemData.icon}
                        />
                      </>
                      )
                    })
                  :
                  <></>}
                  
                  
                <StyledModal
                    okButtonProps={{danger: true}}
                    cancelButtonProps={{style: {background: "#8b8b8b", color: "white", border: "none"}}}
                    okText="DELETE"
                    cancelText="CANCEL"
                    visible={isDeleteModalOpen}
                    onOk={handleDeleteUnscheduledMedicationHistory}
                    onCancel={closeDeleteModal}>
                      <p className="mainText">Are you sure you want to delete this rescue medication?</p>
                      <span className="note">This action cannot be undone.</span>
                  </StyledModal>
                </div>
              </div>
            }
          </>
        )}
      </ProfileConsumer>
    </div>
  )
}

export default MedicationHistoryTab