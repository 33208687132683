import React from 'react'
import {useStyles} from './Styles'
import {Button} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import {FormattedMessage} from 'react-intl'
import DeleteIcon from '@material-ui/icons/Delete'

const RowItem = ({day, time, icon, voice, transcription }) => {
  const classes = useStyles()
  /**
   * listen to the play event in the capturing phase
   * and then pause all audio files, but not the target one.
   */
  React.useEffect(() => {
    document.addEventListener('play', function (e) {
      let audios = document.getElementsByTagName('audio')
      for (let i = 0, len = audios.length; i < len; i++) {
        if (audios[i] !== e.target) {
          audios[i].pause()
        }
      }
    }, true)
  }, [])

  return (
    <section className={classes.rowItemWrapper}>
      <div className={classes.rowItemContentWrapper}>
        <div className={classes.rowItemCircleWrapper}>
          <div className={classes.rowItemCircle}>
            <span className={classes.rowItemTitle}>{day}</span>
            <span className={classes.rowItemSubTitle}>{time}</span>
          </div>
          <i className={icon}/>
        </div>
        <div className={classes.rowItemContent}>
          {voice && <audio style={{marginBottom: "4px"}}src={voice} controls className={classes.rowItemAudio}  controlsList="nodownload"/>}
          <div style={{
            width: "500px",
            padding: "20px",
            marginBottom: "15px",
            background: "#F1F3F4",
            borderRadius: "30px",
          }}>
            <p style={{fontStyle: "italic", textDecoration: "underline", marginBottom: "10px"}}>Audio Transcription</p> 
            <p>{transcription}</p>
          </div>
          {/* <div className={classes.rowItemLocation}>
            <LocationOnIcon/>
            <span>{location}</span>
          </div> */}
          {/* {!!note ?
            <div className={classes.rowItemNote}>
              <span>"{note}"</span>
            </div>
            :
            <Button variant='text' className={classes.addNote}>
              <FormattedMessage id='RowItem.button.addNote'/>
            </Button>
          } */}
        </div>
      </div>
      {/* <div className={classes.actionWrapper}>
        <Button variant='text' className={classes.deleteIcon} onClick={() => handleDeleteItem(id)}>
          <DeleteIcon/>
        </Button>
      </div> */}
    </section>
  )
}

export default RowItem
