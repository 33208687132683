import 'date-fns'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers'
import {useStyles} from './Styles'
import moment from "moment";


const ItemFilter = ({selectedDate, setSelectedDate, setSelectedDateForRescue }) => {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  const classes = useStyles()

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  return (
    <div className={classes.itemFilter}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disableToolbar
              disableFuture
              variant="static"
              format="dd-MM-yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={selectedDate}
              onChange={(value) => {
                handleDateChange(value.toDateString())
                setSelectedDateForRescue(moment(value).format())
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
    </div>

  )
}
export default ItemFilter
