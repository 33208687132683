import React from 'react'
import {SEO} from '../../../components/Seo/seo'
import {signUp} from '../../../utils/pages-meta.json'
import SignUpCard from '../../../components/Auth/SignUpCard'
import {NamedRoutes} from '../../../routes'

const SignUp = () => {
  return (
    <>
      <SEO title={signUp.title}
           pathname={window.location.origin + NamedRoutes.auth.signUp}
           titleTemplate='Novela'
           image={signUp.image}
           description={signUp.description}
      />
      <SignUpCard/>
    </>
  )
}

export default SignUp
