import React from 'react'
import { useFormik, Field, Form, Formik} from 'formik'
import {FormattedMessage} from 'react-intl'
import { FormControl, FormHelperText, InputLabel, TextField , Select, MenuItem, FormControlLabel} from '@material-ui/core'
import {FormLoader} from '../../../../Loader/FormLoader'
import PhoneInput from "react-phone-input-2"
import {useStyles} from './Styles'
import { ProfileDropdowns, SelectTheme } from "../../../../StyledComponents/ProfileDropdowns"
import { ProfileBirthdayDropdowns, SelectBirthdayTheme } from "../../../../StyledComponents/ProfileBirthdayDropdowns"
import { StyledInput, StyledTextArea } from "../../../../StyledComponents/StyledInput";


import moment from 'moment'
const PersonalInfoForm = ({ onSubmit, userData , setUserData ,isFormSave }) => {
  // alert(JSON.stringify(userData));
  let classes = useStyles();

  const genderOptions = [
    {value: "male", label: 'Male'},
    {value: "female", label: 'Female'}
  ]

  const weightUnitOptions = [
    {value: "Kilograms", label: 'Kilograms'},
    {value: "Pounds", label: 'Pounds'}
  ]

  const heightUnitOptions = [
    {value: "Feet", label: 'Feet'},
    {value: "Inches", label: 'Inches'},
    {value: "Centimeters", label: 'Centimeters'}
  ]

  const dayOptions = [
    {value: "01", label: '1'},
    {value: "02", label: '2'},
    {value: "03", label: '3'},
    {value: "04", label: '4'},
    {value: "05", label: '5'},
    {value: "06", label: '6'},
    {value: "07", label: '7'},
    {value: "08", label: '8'},
    {value: "09", label: '9'},
    {value: "10", label: '10'},
    {value: "11", label: '11'},
    {value: "12", label: '12'},
    {value: "13", label: '13'},
    {value: "14", label: '14'},
    {value: "15", label: '15'},
    {value: "16", label: '16'},
    {value: "17", label: '17'},
    {value: "18", label: '18'},
    {value: "19", label: '19'},
    {value: "20", label: '20'},
    {value: "21", label: '21'},
    {value: "22", label: '22'},
    {value: "23", label: '23'},
    {value: "24", label: '24'},
    {value: "25", label: '25'},
    {value: "26", label: '26'},
    {value: "27", label: '27'},
    {value: "28", label: '28'},
    {value: "29", label: '29'},
    {value: "30", label: '30'},
    {value: "31", label: '31'}
  ];

  const monthOptions = [
    {value: "Jan", label: 'Jan'},
    {value: "Feb", label: 'Feb'},
    {value: "Mar", label: 'Mar'},
    {value: "Apr", label: 'Apr'},
    {value: "May", label: 'May'},
    {value: "Jun", label: 'Jun'},
    {value: "Jul", label: 'Jul'},
    {value: "Aug", label: 'Aug'},
    {value: "Sep", label: 'Sep'},
    {value: "Oct", label: 'Oct'},
    {value: "Nov", label: 'Nov'},
    {value: "Dec", label: 'Dec'}
  ];

  const yearOptions = [
    {value: "1931", label: '1931'},
    {value: "1932", label: '1932'},
    {value: "1933", label: '1933'},
    {value: "1934", label: '1934'},
    {value: "1935", label: '1935'},
    {value: "1936", label: '1936'},
    {value: "1937", label: '1937'},
    {value: "1938", label: '1938'},
    {value: "1939", label: '1939'},
    {value: "1940", label: '1940'},
    {value: "1941", label: '1941'},
    {value: "1942", label: '1942'},
    {value: "1943", label: '1943'},
    {value: "1944", label: '1944'},
    {value: "1945", label: '1945'},
    {value: "1946", label: '1946'},
    {value: "1947", label: '1947'},
    {value: "1948", label: '1948'},
    {value: "1949", label: '1949'},
    {value: "1950", label: '1950'},
    {value: "1951", label: '1951'},
    {value: "1952", label: '1952'},
    {value: "1953", label: '1953'},
    {value: "1954", label: '1954'},
    {value: "1955", label: '1955'},
    {value: "1956", label: '1956'},
    {value: "1957", label: '1957'},
    {value: "1958", label: '1958'},
    {value: "1959", label: '1959'},
    {value: "1960", label: '1960'},
    {value: "1961", label: '1961'},
    {value: "1962", label: '1962'},
    {value: "1963", label: '1963'},
    {value: "1964", label: '1964'},
    {value: "1965", label: '1965'},
    {value: "1966", label: '1966'},
    {value: "1967", label: '1967'},
    {value: "1968", label: '1968'},
    {value: "1969", label: '1969'},
    {value: "1970", label: '1970'},
    {value: "1971", label: '1971'},
    {value: "1972", label: '1972'},
    {value: "1973", label: '1973'},
    {value: "1974", label: '1974'},
    {value: "1975", label: '1975'},
    {value: "1976", label: '1976'},
    {value: "1977", label: '1977'},
    {value: "1978", label: '1978'},
    {value: "1979", label: '1979'},
    {value: "1980", label: '1980'},
    {value: "1981", label: '1981'},
    {value: "1982", label: '1982'},
    {value: "1983", label: '1983'},
    {value: "1984", label: '1984'},
    {value: "1985", label: '1985'},
    {value: "1986", label: '1986'},
    {value: "1987", label: '1987'},
    {value: "1988", label: '1988'},
    {value: "1989", label: '1989'},
    {value: "1990", label: '1990'},
    {value: "1991", label: '1991'},
    {value: "1992", label: '1992'},
    {value: "1993", label: '1993'},
    {value: "1994", label: '1994'},
    {value: "1995", label: '1995'},
    {value: "1996", label: '1996'},
    {value: "1997", label: '1997'},
    {value: "1998", label: '1998'},
    {value: "1999", label: '1999'},
    {value: "2000", label: '2000'},
    {value: "2001", label: '2001'},
    {value: "2002", label: '2002'},
    {value: "2003", label: '2003'},
    {value: "2004", label: '2004'},
    {value: "2005", label: '2005'},
    {value: "2006", label: '2006'},
    {value: "2007", label: '2007'},
    {value: "2008", label: '2008'},
    {value: "2009", label: '2009'},
    {value: "2000", label: '2000'},
    {value: "2011", label: '2011'},
    {value: "2012", label: '2012'},
    {value: "2013", label: '2013'},
    {value: "2014", label: '2014'},
    {value: "2015", label: '2015'},
    {value: "2016", label: '2016'},
    {value: "2017", label: '2017'},
    {value: "2018", label: '2018'},
    {value: "2019", label: '2019'},
    {value: "2020", label: '2020'},
    {value: "2021", label: '2021'},
  ];

  const [isEnvCA, setIsEnvCA] = React.useState(false);

  React.useEffect(() => {
    const envSubstring = process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL.split('.')[0].slice(-2);
    setIsEnvCA(envSubstring === "ca");
  },[])

 
  function handleChange(arg1, arg2) {
    setIsFormChanged(true);
    personalInfoForm.handleChange(arg1, arg2);
  }


  const personalInfoForm = useFormik({
    initialValues: {
      first_name: userData.first_name == null ? "" : userData.first_name,
      last_name: userData.last_name == null ? "" : userData.last_name,
      email: userData.email,
      mobile_number: userData.mobile_number == null ? "" : userData.mobile_number,
      gender: userData.gender,
      weight: userData.weight.value == null ? "" : userData.weight.value,
      weight_unit: userData.weight.unit,
      height: userData.height.value == null ? "" : userData.height.value,
      height_unit: userData.height.unit,
      birthday: userData.birth_date,
      timezone: userData.timezone,
      month: userData.birth_date == null ? "01"
        : userData.birth_date.slice(5, 7) == "01" ? "Jan"
        : userData.birth_date.slice(5, 7) == "02" ? "Feb"
        : userData.birth_date.slice(5, 7) == "03" ? "Mar"
        : userData.birth_date.slice(5, 7) == "04" ? "Apr"
        : userData.birth_date.slice(5, 7) == "05" ? "May"
        : userData.birth_date.slice(5, 7) == "06" ? "Jun"
        : userData.birth_date.slice(5, 7) == "07" ? "Jul"
        : userData.birth_date.slice(5, 7) == "08" ? "Aug"
        : userData.birth_date.slice(5, 7) == "09" ? "Sep"
        : userData.birth_date.slice(5, 7) == "10" ? "Oct"
        : userData.birth_date.slice(5, 7) == "11" ? "Nov"
        : userData.birth_date.slice(5, 7) == "12" ? "Dec"
        : "01",
      day: userData.birth_date == null ? "01" : userData.birth_date.slice(8, 10),
      year: userData.birth_date == null ? "" : userData.birth_date.slice(0, 4),
      bedtime: "Invalid date"
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: ((values, actions) => {
      onSubmit(values, actions, isFormSave);
      setIsFormChanged(false)
      window.scrollTo(0, 0)
    })
  })

  const [isFormChanged, setIsFormChanged] = React.useState(false)

  const [birthdayMonth, setBirthdayMonth] = React.useState(userData.birth_date == null ? "01" : userData.birth_date.slice(5, 7));
  const [birthdayDay, setBirthdayDay] = React.useState(userData.birth_date == null ? "01" : userData.birth_date.slice(8, 10));
  const [birthdayYear, setBirthdayYear] = React.useState(userData.birth_date == null ? "" : userData.birth_date.slice(0, 4));

  const handleSetBirthday = () => {
    personalInfoForm.setFieldValue("birthday", moment(birthdayYear + '-' + birthdayMonth + '-' + birthdayDay).format("MMM-DD-YYYY"))
  }

  const birthdayYearIndex = yearOptions.findIndex((option) => {
    return option.value == personalInfoForm.values.year
  })
  const birthdayMonthIndex = monthOptions.findIndex((option) => {
    return option.value == personalInfoForm.values.month
  })
  const birthdayDayIndex = dayOptions.findIndex((option) => {
    return option.value == personalInfoForm.values.day
  })


  return (
    <>
    <form onSubmit={personalInfoForm.handleSubmit} noValidate autoComplete="off">

      <section className={classes.profileSectionContainer}>
        <InputLabel htmlFor="first_name" className={classes.FirstHeader}>
          <FormattedMessage id='PersonalInfoForm.InputLabel.FirstName'/>
        </InputLabel>
          <StyledInput
            id="first_name"
            name="first_name"
            onChange={(e) => {
              setIsFormChanged(true);
              personalInfoForm.handleChange(e)}}
            placeholder="Enter your first name"
            value={personalInfoForm.values.first_name}
            style={{height: '50px', fontSize: "16px"}}
          />
      </section>
        
      <section className={classes.profileSectionContainer}>
        <InputLabel htmlFor="last_name" className={classes.Header}>
          <FormattedMessage id='PersonalInfoForm.InputLabel.LastName'/>
        </InputLabel>
          <StyledInput
          id="last_name"
          name="last_name"
          onChange={(e) => {
            setIsFormChanged(true);
            personalInfoForm.handleChange(e)}}
          placeholder="Enter your last name"
          value={personalInfoForm.values.last_name}
          style={{height: '50px', fontSize: "16px"}}
        />
        
      </section>

      <section className={classes.profileSectionContainer}>
        <InputLabel htmlFor="mobileNumber" className={classes.Header}>
          <FormattedMessage id='PersonalInfoForm.InputLabel.MobileNumber'/>
        </InputLabel>
          <PhoneInput
          value={personalInfoForm.values.mobile_number}
          enableSearch={true}
          country={'us'}
          specialLabel={''}
          inputProps={{
            name: 'mobile_number',
            required: true
          }}
          onChange={(phone) => {
            personalInfoForm.setFieldValue('mobile_number', phone.toString());
            personalInfoForm.handleChange(phone);
            setIsFormChanged(true);
          }}
          onBlur={personalInfoForm.handleBlur}
          isValid={(value, country) => {
            return (!!value)
          }}
        />
        
      </section>

      <section className={classes.profileSectionContainer}>
        <InputLabel htmlFor="gender" className={classes.Header}>
          <FormattedMessage id='PersonalInfoForm.InputLabel.Gender'/>
        </InputLabel>
          <ProfileDropdowns
          theme={theme => SelectTheme(theme)}
          options={genderOptions}
          placeholder={"Select your gender"}
          defaultValue={personalInfoForm.values.gender !== null ? {value: personalInfoForm.values.gender, label: personalInfoForm.values.gender}: null}
          onChange={(e) => {
            setIsFormChanged(true);
            personalInfoForm.setFieldValue("gender", e.value)}}
          isSearchable={false}
        />
        
      </section>

      <section className={classes.profileSectionContainer}>
        <InputLabel htmlFor="weight" className={classes.Header}> 
          <FormattedMessage id='PersonalInfoForm.InputLabel.Weight'/>
        </InputLabel>
        <div className={classes.HeightDropdowns} style={{display: "flex"}}>
          <StyledInput
          className={classes.MarginRight}
          id="weight"
          name="weight"
          onChange={(e) => {
            setIsFormChanged(true);
            personalInfoForm.handleChange(e)}}
          placeholder="Enter Duration"
          type="number"
          min="1"
          suffix=" "
          defaultValue={personalInfoForm.values.weight}
        />
          <ProfileDropdowns
          className={classes.UnitDropdown}
          theme={theme => SelectTheme(theme)}
          options={weightUnitOptions}
          placeholder={"Weight unit"}
          defaultValue={personalInfoForm.values.weight_unit !== null ? {value: personalInfoForm.values.weight_unit, label: personalInfoForm.values.weight_unit}: null}
          onChange={(e) => {
            setIsFormChanged(true);
            personalInfoForm.setFieldValue("weight_unit", e.value)}}
          isSearchable={false}
          width='200px'
        />
        </div>
      </section>

      <section className={classes.profileSectionContainer}>
        <InputLabel htmlFor="height" className={classes.Header}> 
          <FormattedMessage id='PersonalInfoForm.InputLabel.Height'/>
        </InputLabel>
        <div className={classes.HeightDropdowns} style={{display: "flex"}}>
          <StyledInput
            className={classes.MarginRight}
            theme={theme => SelectTheme(theme)}
            id="height"
            name="height"
            onChange={(e) => {
              setIsFormChanged(true);
              personalInfoForm.handleChange(e)}}
            placeholder="Enter Duration"
            type="number"
            min="1"
            suffix=" "
            defaultValue={personalInfoForm.values.height}
          />
          <ProfileDropdowns
            className={classes.UnitDropdown}
            theme={theme => SelectTheme(theme)}
            options={heightUnitOptions}
            placeholder={"Height unit"}
            defaultValue={personalInfoForm.values.height_unit !== null ? {value: personalInfoForm.values.height_unit, label: personalInfoForm.values.height_unit}: null}
            onChange={(e) => {
              setIsFormChanged(true);
              personalInfoForm.setFieldValue("height_unit", e.value)}}
            isSearchable={false}
          />
        </div>
          
      </section>

      <section className={classes.profileSectionContainer}>
        { isEnvCA ?
        <InputLabel htmlFor="birthday" className={classes.Header}> 
          <FormattedMessage id='PersonalInfoForm.InputLabel.YearofBirth'/>
        </InputLabel>
        :
        <InputLabel htmlFor="birthday" className={classes.Header}> 
          <FormattedMessage id='PersonalInfoForm.InputLabel.DateofBirth'/>
        </InputLabel>
        }
        { isEnvCA ?
        <>
          <div style={{display: "flex"}}>
            <ProfileBirthdayDropdowns
              className={classes.BirthdayYearDropdown}
              theme={theme => SelectTheme(theme)}
              options={yearOptions}
              placeholder={"Year"}
              value={yearOptions[birthdayYearIndex]}
              defaultValue={personalInfoForm.values.year !== null ? {value: personalInfoForm.values.year, label: personalInfoForm.values.year}: null}
              onChange={(e) => {
                setIsFormChanged(true);
                personalInfoForm.setFieldValue("year", e.value)
                setBirthdayYear(e.value)
              }}
              isSearchable={false}
            />
            </div>
          </>
        :
          <div className={classes.BirthdayDropdowns} style={{display: "flex"}}>
              <ProfileBirthdayDropdowns
                className={classes.BirthdayDropdown}
                theme={theme => SelectBirthdayTheme(theme)}
                options={monthOptions}
                placeholder={"Month"}
                value={monthOptions[birthdayMonthIndex]}
                defaultValue={personalInfoForm.values.month !== null ? {value: personalInfoForm.values.month, label: personalInfoForm.values.month}: null}
                onChange={(e) => {
                  setIsFormChanged(true);
                  personalInfoForm.setFieldValue("month", e.value)
                  setBirthdayMonth(e.value)
                }}
                isSearchable={false}
                width="200px"
              />
            <ProfileBirthdayDropdowns
              theme={theme => SelectBirthdayTheme(theme)}
              className={classes.BirthdayDropdown}
              options={dayOptions}
              placeholder={"Day"}
              value={dayOptions[birthdayDayIndex]}
              defaultValue={personalInfoForm.values.day !== null ? {value: personalInfoForm.values.day, label: personalInfoForm.values.day}: null}
              onChange={(e) => {
                setIsFormChanged(true);
                personalInfoForm.setFieldValue("day", e.value)
                setBirthdayDay(e.value)
              }}
              isSearchable={false}
            />
            <ProfileBirthdayDropdowns
              className={classes.BirthdayYearDropdown}
              theme={theme => SelectBirthdayTheme(theme)}
              options={yearOptions}
              placeholder={"Year"}
              isOpen={true}
              value={yearOptions[birthdayYearIndex]}
              menuShouldScrollIntoView={true}
              noOptionsMessage={true}
              defaultValue={personalInfoForm.values.year !== null ? {value: personalInfoForm.values.year, label: personalInfoForm.values.year}: null}
              onChange={(e) => {
                setIsFormChanged(true);
                personalInfoForm.setFieldValue("year", e.value)
                setBirthdayYear(e.value)
              }}
              isSearchable={false}
            />
          </div>
          }
        
          
      </section>

      <section className={classes.profileSectionContainer}>

        <InputLabel htmlFor="timezone" className={classes.Header}>
          <FormattedMessage id='PersonalInfoForm.InputLabel.TimeZone'/>
        </InputLabel>
        <Select
          className={classes.TimezoneDropdown}
          labelId="label" 
          id="timezone" 
          value="20"
          required
          fullWidth
          margin="dense"
          type="text"
          name='timezone'
          variant="outlined"
          onChange={handleChange}
          onBlur={personalInfoForm.handleBlur}
          value={personalInfoForm.values.timezone}
          helperText={personalInfoForm.errors.timezone && personalInfoForm.touched.timezone && personalInfoForm.errors.timezone}
          error={personalInfoForm.errors.timezone && personalInfoForm.touched.timezone !== undefined}
          inputProps={{
            autoComplete: 'new-name',
            form: {
              autoComplete: 'off'
            }
          }}
        >
          <MenuItem value="-12:00">(GMT-12:00) International Date Line West</MenuItem>
          <MenuItem value="-11:00">(GMT-11:00) Midway Island, Samoa</MenuItem>
          <MenuItem value="-10:00">(GMT-10:00) Hawaii</MenuItem>
          <MenuItem value="-09:00">(GMT-09:00) Alaska</MenuItem>
          <MenuItem value="-08:00">(GMT-08:00) Pacific Time (US and Canada); Tijuana</MenuItem>
          <MenuItem value="-07:00">(GMT-07:00) Mountain Time (US and Canada)</MenuItem>
          <MenuItem value="-07:00">(GMT-07:00) Chihuahua, La Paz, Mazatlan</MenuItem>
          <MenuItem value="-07:00">(GMT-07:00) Arizona</MenuItem>
          <MenuItem value="-06:00">(GMT-06:00) Central Time (US and Canada</MenuItem>
          <MenuItem value="-06:00">(GMT-06:00) Saskatchewan</MenuItem>
          <MenuItem value="-06:00">(GMT-06:00) Guadalajara, Mexico City, Monterrey</MenuItem>
          <MenuItem value="-06:00">(GMT-06:00) Central America</MenuItem>
          <MenuItem value="-05:00">(GMT-05:00) Eastern Time (US and Canada)</MenuItem>
          <MenuItem value="-05:00">(GMT-05:00) Indiana (East)</MenuItem>
          <MenuItem value="-05:00">(GMT-05:00) Bogota, Lima, Quito</MenuItem>
          <MenuItem value="-04:00">(GMT-04:00) Atlantic Time (Canada)</MenuItem>
          <MenuItem value="-04:00">(GMT-04:00) Caracas, La Paz</MenuItem>
          <MenuItem value="-04:00">(GMT-04:00) Santiago</MenuItem>
          <MenuItem value="-03:30">(GMT-03:30) Newfoundland and Labrador</MenuItem>
          <MenuItem value="-03:00">(GMT-03:00) Brasilia</MenuItem>
          <MenuItem value="-03:00">(GMT-03:00) Buenos Aires, Georgetown</MenuItem>
          <MenuItem value="-03:00">(GMT-03:00) Greenland</MenuItem>
          <MenuItem value="-02:00">(GMT-02:00) Mid-Atlantic</MenuItem>
          <MenuItem value="-01:00">(GMT-01:00) Azores</MenuItem>
          <MenuItem value="-01:00">(GMT-01:00) Cape Verde Islands</MenuItem>
          <MenuItem value="+00:00">(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London</MenuItem>
          <MenuItem value="+00:00">(GMT) Casablanca, Monrovia</MenuItem>
          <MenuItem value="+01:00">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</MenuItem>
          <MenuItem value="+01:00">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</MenuItem>
          <MenuItem value="+01:00">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</MenuItem>
          <MenuItem value="+01:00">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</MenuItem>
          <MenuItem value="+02:00">(GMT+01:00) West Central Africa</MenuItem>
          <MenuItem value="+02:00">(GMT+02:00) Bucharest</MenuItem>
          <MenuItem value="+02:00"></MenuItem>
          <MenuItem value="+02:00">(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius</MenuItem>
          <MenuItem value="+02:00">(GMT+02:00) Athens, Istanbul, Minsk</MenuItem>
          <MenuItem value="+02:00">(GMT+02:00) Jerusalem</MenuItem>
          <MenuItem value="+02:00">(GMT+02:00) Harare, Pretoria</MenuItem>
          <MenuItem value="+03:00">(GMT+03:00) Moscow, St. Petersburg, Volgograd</MenuItem>
          <MenuItem value="+03:00">(GMT+03:00) Kuwait, Riyadh</MenuItem>
          <MenuItem value="+03:00">(GMT+03:00) Nairobi</MenuItem>
          <MenuItem value="+03:00">(GMT+03:00) Baghdad</MenuItem>
          <MenuItem value="+03:30">(GMT+03:30) Tehran</MenuItem>
          <MenuItem value="+04:00">(GMT+04:00) Abu Dhabi, Muscat</MenuItem>
          <MenuItem value="+04:00">(GMT+04:00) Baku, Tbilisi, Yerevan</MenuItem>
          <MenuItem value="+04:30">(GMT+04:30) Kabul</MenuItem>
          <MenuItem value="+05:00">(GMT+05:00) Ekaterinburg</MenuItem>
          <MenuItem value="+05:00">(GMT+05:00) Islamabad, Karachi, Tashkent</MenuItem>
          <MenuItem value="+05:30">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</MenuItem>
          <MenuItem value="+05:45">(GMT+05:45) Kathmandu</MenuItem>
          <MenuItem value="+06:00">(GMT+06:00) Astana, Dhaka</MenuItem>
          <MenuItem value="+06:00">(GMT+06:00) Sri Jayawardenepura</MenuItem>
          <MenuItem value="+06:00">(GMT+06:00) Almaty, Novosibirsk</MenuItem>
          <MenuItem value="+06:30">(GMT+06:30) Yangon Rangoon</MenuItem>
          <MenuItem value="+07:00">(GMT+07:00) Bangkok, Hanoi, Jakarta</MenuItem>
          <MenuItem value="+07:00">(GMT+07:00) Krasnoyarsk</MenuItem>
          <MenuItem value="+08:00">(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi</MenuItem>
          <MenuItem value="+08:00">(GMT+08:00) Kuala Lumpur, Singapore</MenuItem>
          <MenuItem value="+08:00">(GMT+08:00) Taipei</MenuItem>
          <MenuItem value="+08:00">(GMT+08:00) Perth</MenuItem>
          <MenuItem value="+08:00">(GMT+08:00) Irkutsk, Ulaanbaatar</MenuItem>
          <MenuItem value="+09:00">(GMT+09:00) Seoul</MenuItem>
          <MenuItem value="+09:00">(GMT+09:00) Osaka, Sapporo, Tokyo</MenuItem>
          <MenuItem value="+09:00">(GMT+09:00) Yakutsk</MenuItem>
          <MenuItem value="+09:30">(GMT+09:30) Darwin</MenuItem>
          <MenuItem value="+09:30">(GMT+09:30) Adelaide</MenuItem>
          <MenuItem value="+10:00">(GMT+10:00) Canberra, Melbourne, Sydney</MenuItem>
          <MenuItem value="+10:00">(GMT+10:00) Brisbane</MenuItem>
          <MenuItem value="+10:00">(GMT+10:00) Hobart</MenuItem>
          <MenuItem value="+10:00">(GMT+10:00) Vladivostok</MenuItem>
          <MenuItem value="+10:00">(GMT+10:00) Guam, Port Moresby</MenuItem>
          <MenuItem value="+11:00">(GMT+11:00) Magadan, Solomon Islands, New Caledonia</MenuItem>
          <MenuItem value="+12:00">(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands</MenuItem>
          <MenuItem value="+12:00">(GMT+12:00) Auckland, Wellington</MenuItem>
          <MenuItem value="+13:00">(GMT+13:00) Nuku'alofa</MenuItem>
        </Select>
    </section>

        <div className={classes.formActionsWrapper}>
          <button
            style={{margin: "50px 0 30px 0"}}
            disabled={personalInfoForm.isSubmitting || !isFormChanged ||  !personalInfoForm.isValid}
            className={classes.formActionSubmit}
            onClick={handleSetBirthday}>
              <FormattedMessage id='PersonalInfoForm.button.Submit'/>
          </button>
        </div>
        {personalInfoForm.isSubmitting && <FormLoader loading={true}/>}

      </form>
                
    </>
  )
}

export default PersonalInfoForm