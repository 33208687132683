import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  EmergencyContactsTab: {
    '& .MuiAlert-root': {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2)
    }
  },
  EmergencyContactsForm: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '50%',
    '@media (max-width:815px)': {
      flexDirection: 'column',
    },
    '& .MuiFormControl-root': {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginRight: theme.spacing(0)
      }
    },
    '& .MuiInputLabel-formControl': {
      top: 0,
      left: 0,
      position: 'relative',
      transform: 'none',
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightLight
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[300]
    }
  },
  EmergencyContactsFormTitle: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.primary,
    margin: theme.spacing(0, 0, 4, 0)
  },
  formActionsWrapper: {
    marginTop: theme.spacing(3),
    textAlign: 'center'
  },
  formActionSubmit: {
    padding: theme.spacing(1.25, 4),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    '&:disabled': {
      opacity: 0.7,
      cursor: 'not-allowed'
    }
  },
  ContactListRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50%',
    padding: theme.spacing(3, 2),
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    background: theme.palette.common.white
  },
  ContactListRowName: {
    flex: '1 1 auto',
    display: 'flex',
    padding: theme.spacing(0, 2),
    '& span': {
      width: '50%',
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        width: '35%'
      }
    }
  },
  deleteButton: {
    background: 'transparent',
    border: 0
  },
  listActionsWrapper: {
    marginTop: theme.spacing(3),
    textAlign: 'center'
  },
  listActionSubmit: {
    padding: theme.spacing(1.5, 4),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    border: 'none',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:disabled': {
      opacity: 0.7,
      cursor: 'not-allowed'
    }
  },
  authCardFormControl: {
    '& .react-tel-input': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      '& .form-control': {
        paddingTop: theme.spacing(1.3125),
        paddingBottom: theme.spacing(1.3125),
        width: '100%'
      }
    }
  }
}))
