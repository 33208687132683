import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    paddingTop: 66,
    height: '100%',
    background:"white",
    
  },
  accountLayoutContainer: {
    height: '100%',
    padding: theme.spacing(0)
  },
  accountLayoutGridContainer: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    zoom: "80%",
  },
  accountLayoutContent: {
    padding:"1px",
    marginLeft: 104,
    width: 'calc(100% - 60px)',
    height: '100%',
    [theme.breakpoints.up(300)]: {
      height: '100%',
      width:"100%"
    }
  }
}))
