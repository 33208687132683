import React, { useState } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import ReorderIcon from '@material-ui/icons/Reorder'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useStyles } from './Styles'

const List = ({ data, handleDeleteRow, handleSort }) => {
  let classes = useStyles()
  const [state, setState] = useState(data)

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    if (result.destination.index === result.source.index) {
      return
    }
    const contacts = reorder(
      state,
      result.source.index,
      result.destination.index
    )
    setState(contacts)
    handleSort(contacts)
  }
  const Item = ({ contact, index, length }) => {
    return (
      <Draggable isDragDisabled={true} draggableId={index.toString()} index={index}>
        {(provided) => (
          <div
            className={classes.ContactListRow}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <ReorderIcon/>
            <div className={classes.ContactListRowName}>
              <span>{index + 1}. {contact.contact_name}</span>
              <span>+{contact.contact_mobile.replace(/^[+]/g, '')}</span>
            </div>
            {length > 1 &&
            <button type='button' className={classes.deleteButton} onClick={() => handleDeleteRow(contact.contact_id)}>
              <DeleteIcon/>
            </button>
            }
          </div>
        )}
      </Draggable>
    )
  }

  const ContactsList = React.memo(function ContactsList ({ contacts }) {
    return contacts.map((contact, index) => (
      <Item contact={contact} length={contacts.length} index={index} key={index}/>
    ))
  })


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list" >
        {(provided) => (
          <>
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <ContactsList contacts={state}/>
              {provided.placeholder}
            </div>
            {/*<div className={classes.listActionsWrapper}>*/}
            {/*  <button className={classes.listActionSubmit} onClick={(e) => handleSort(state)}>*/}
            {/*    <FormattedMessage id='EmergencyContactsList.button.submit'/>*/}
            {/*  </button>*/}
            {/*</div>*/}
          </>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default List
