import React from 'react'
import {useState} from 'react'
import { useStyles } from './../../DBStyles'

import { Paper } from '@material-ui/core';
import { ProfileConsumer } from '../../../../../ProfileContext'
import moment from "moment"
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import MedicationIcon from '../../../../../images/Icons/medication.svg'
import Arrow from '../../../../../images/Icons/arrow.png'
import UpcomingMedications from '.';


const UpcomingMedicaitonsFrom = ({ loadUpcomingMedications }) => {

  React.useEffect(() => {
    if (!!loadUpcomingMedications) {
      setInterval(() => {
        loadUpcomingMedications();
      }, 60000);
    }
  },
    [])

  React.useEffect(() => {
    if (!!loadUpcomingMedications) {
      loadUpcomingMedications();
    }
  },
    []);


  let classes = useStyles();

  const formatTime = (time) => {
    let hours24 = parseInt(time.substring(0,2))
    let hours = ((hours24 + 11) % 12) + 1
    let amPm = hours24 > 11 ? 'PM' : 'AM'
    let minutes = time.substring(3, 5)
    let formattedTime = `${hours}:${minutes} ${amPm}`
    return formattedTime
  }

  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1 && e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight - 1 ;
      if (bottom) { 
      setIsBottom(true);
      } else {
        setIsBottom(false)
      }
    }


  return (
    <>
      <ProfileConsumer>
        {({ upcomingMedications }) => {

          return (
            <>
              <div className='DBGridCellOneMainContianer'>
                
                <div className={classes.DBGridCellOneFooter}>
                  <div className={classes.DBGridHeader}>
                    <div className={classes.HeaderIcon}>
                      <i className="icon-medication" />
                    </div>
                    <p className={classes.DBGridHeaderString}>Upcoming Medications</p>
                  </div>

                  <div className='upcomingMedicationContainer' onScroll={handleScroll}>
                  {upcomingMedications && upcomingMedications.reminders ?
                      upcomingMedications.reminders.map(item => {
                        let medicationName = item.medication_name;

                        return (
                          <div className={classes.DBUpcomingMedicationsListWrapper} key={item.medication_reminder_id}>
                            <div className={classes.DBUpcomingMedicationsList}>

                              <div className={classes.UpcomingMedicationsListNameAndDose}>
                                {medicationName.length > 21?
                                <p className={classes.UMName}>
                                  {medicationName.slice(0,21)}<span>...</span>
                                </p>
                                : <p className={classes.UMName}>{item.medication_name}</p>}
                              </div>

                                <div className={classes.UpcomingMedicationsListDoses}>
                                  <div className={classes.UpcomingMedicationsDosesContainer}>
                                    <p className={classes.UMDose}>{item.dose} {item.dose_units}</p>
                                    <p className={classes.UMDoseNumber}>{item.num_of_doses} {item.form === 'Pill' && item.num_of_doses === 1 ? 'Pill' : item.form === 'Pill'&& item.num_of_doses != 1 ? 'Pills' : item.form != 'Pill' && item.num_of_doses === 1 ? 'Dose' : item.form != "Pill" && item.num_of_doses != 1 ? "Doses" : 'Dose(s)'}</p>
                                  </div>
                                  <div className={classes.UpcomingMedicationsTimeContainer}>
                                    <p className={classes.UMDoseTime}>{formatTime(item.dose_time)}</p>
                                  </div>  
                                </div>
                            </div>
                          </div>
                        )
                      })
                      :
                      <div className={classes.NoRemindersLabelContainer}>
                        <p className={classes.NoRemindersLabelString}>No upcoming medications<br /> for today!</p>
                      </div>
                    }
                  </div>
                </div>
                <div>
                  {upcomingMedications && upcomingMedications.reminders ?
                    <>
                    {!isBottom ?
                    upcomingMedications.reminders.length >= 5 ?
                      <div className={classes.MoreRemindersContainer}>
                        <p className={classes.MoreReminders}><img src={Arrow} style={{ width: '13px', height: '10px' }} /> Scroll down here for more reminders</p>
                      </div>
                      :
                      <div></div>
                      : <div></div>}
                    </>
                      :
                      <div></div>
                    }
                  </div>
              </div>

            </>
          )
        }}
      </ProfileConsumer>
    </>
  )
}

export default UpcomingMedicaitonsFrom