import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers'
import {useStyles} from './Styles'
import moment from 'moment'


const ItemFilter = ({ selectedDate,setSelectedDate }) => {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  const classes = useStyles()

  const handleDateChange = (date) => {
    setSelectedDate(moment(date).format("YYYY-MM-DD"))
  }

  return (
    <div className={classes.itemFilter}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disableToolbar
              disableFuture
              variant="static"
              format="dd-MM-yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={moment(selectedDate)}
              onChange={(value) => handleDateChange(value)}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
    </div>

  )
}
export default ItemFilter
