import React from 'react'
import TokenLoginForm from './Form'
import AuthLogo from '../AuthLogo'
import { AuthConsumer } from '../../../AuthContext'

const TokenLoginCard = ({ring_sn, verification_token}) => {

    return (
        <>
        <AuthLogo/>
        <AuthConsumer>
            {({ handleTokenLoginSubmit }) => (
                <TokenLoginForm ring_sn={ring_sn} verification_token={verification_token} onSubmit={handleTokenLoginSubmit}/>
            )}
        </AuthConsumer>
        </>
    )
}

export default TokenLoginCard
