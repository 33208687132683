import React from 'react'
import { useStyles } from '../RowItem/Styles'
import { FormattedMessage } from 'react-intl'
import { ProfileConsumer } from '../../../../ProfileContext'
import Alert from '@material-ui/lab/Alert'
import { SearchLoader } from '../../../Loader/SearchLoader'
import moment from "moment";
import RowItem from '../RowItem/RowItem'
import voiceRecord from '../../VoiceMemosContent/test-voice.mp3'
const SideEffectsTable = ({ getSideEffects }) => {

  const [isEnvCA, setIsEnvCA] = React.useState(false);

  let classes = useStyles()
  React.useEffect(() => {

      if(!!getSideEffects) {
        getSideEffects();
      }

      const envSubstring = process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL.split('.')[0].slice(-2);
      setIsEnvCA(envSubstring === "ca");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  return (
      <ProfileConsumer>
        {({ patientSideEffects, getSideEffectsLoading, getSideEffectsFailure }) => (
          <>
            {!!getSideEffectsFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}
            {!!getSideEffectsLoading ? <SearchLoader loading={true}/> :
              (!!patientSideEffects && !!Object.keys(patientSideEffects).length &&
                <>
                  {patientSideEffects.map(item => {
                    const timestamp = moment(item.side_effect_timestamp);

                    const itemData = {
                      day: timestamp.format("DD MMM YY"),
                      time: timestamp.format("hh:mm A"),
                      dayIcon :(timestamp.format("a") === 'am' ? 'icon-day' : 'icon-night'),
                    }

                    return(
                      <RowItem
                        key={item.id}
                        name={item.side_effect_type_name}
                        day={itemData.day}
                        time={itemData.time}
                        icon={itemData.dayIcon}
                        voiceMemo={!isEnvCA ? item.memo_url : null}
                        transcription={item.memo_transcription}
                        sideEffectType={item.side_effect_type_name}
                        sideEffectIcon={`icon-${item.side_effect_type_name}`.toLowerCase()}
                        sideEffectGrade={item.intensity}
                      />

                    )
                  })}
                  </>
              )
            }
          </>
        )}
      </ProfileConsumer>
  )
}

export default SideEffectsTable
