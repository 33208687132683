import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import {SEO} from '../../../components/Seo/seo'
import {tokenLogin} from '../../../utils/pages-meta.json'
import OrganizationTokenLoginCard from '../../../components/Auth/OrganizationTokenLoginCard'
import {NamedRoutes} from '../../../routes'

const OrganizationTokenLogin = () => {
    let { organization_token, verification_token, patientID, organizationID } = useParams()

    return (
        <>
        {!!organization_token && !!verification_token && !!patientID && !!organizationID ? <>
                <SEO title={tokenLogin.title}
                    pathname={window.location.origin + NamedRoutes.auth.organizationTokenLogin}
                    titleTemplate='Novela'
                    image={tokenLogin.image}
                    description={tokenLogin.description}
                />
                <OrganizationTokenLoginCard organization_token={organization_token} verification_token={verification_token} patientID={patientID} organizationID={organizationID} /> 
            </>:
                <Redirect to={NamedRoutes.auth.organizationTokenLogin}/>
        }
        </>
    )
}

export default OrganizationTokenLogin