import React from 'react'
import {useStyles} from './Styles'
import {FormattedMessage} from 'react-intl'
import {Button} from '@material-ui/core'
import {ProfileConsumer,ProfileProvider} from '../../../ProfileContext'
import SideEffectsTable from './SideEffectsTable'

const SideEffectsContent = () => {
  const classes = useStyles()

  const handleAddNewSideEffect = () => {
    console.log('Add New Side Effect button clicked')
  }

  const handleDeleteAudioItem = (id) => {
    console.log('handle Delete Audio Item....', id)
  }

  const handleDeleteItem = (id) => {
    console.log('handle Delete Item....', id)
  }

  const handleEditItem = (id) => {
    console.log('handle Edit Item....', id)
  }

  return (
    <section className={classes.sideEffectsContent}>
      <header className={classes.sideEffectsContentHeader}>
        <h1 className={classes.sideEffectsContentTitle}><FormattedMessage id='SideEffects.Content.Title'/></h1>
        {/* <Button
          variant='contained'
          color='primary'
          className={classes.sideEffectsAddNewButton}
          onClick={() => handleAddNewSideEffect()}
        >
          <FormattedMessage id='SideEffects.button.add'/>
        </Button> */}
      </header>

      <ProfileProvider>
          <ProfileConsumer>
            {({ handleGetSideEffects}) => (
              <SideEffectsTable 
                  getSideEffects={handleGetSideEffects}
              />
            )}
          </ProfileConsumer>
        </ProfileProvider>
    </section>
  )
}

export default SideEffectsContent
