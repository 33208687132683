import React from 'react'

const HairLossIcon = () => {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.9 595.3" version="1.1" x="100px" y="100px"  viewBox="0 0 70 97" style={{enableBackground:"new 0 0 70 97"}} >
      <g>
        <path fill="#462d7d" class="st0" d="M49.9,23.2c-0.8-4.9-3.4-9.3-7.2-12.4c-0.3-0.2-0.8-0.2-1,0.1c-0.3,0.3-0.2,0.8,0.1,1.1
          c3.5,2.9,5.9,7,6.7,11.5c0.1,0.7-0.1,1.4-0.5,1.9c-0.5,0.5-1.1,0.8-1.8,0.8c-0.4,0-0.8,0.3-0.8,0.7c0,0.4,0.3,0.8,0.7,0.8
          c1.1,0.1,2.2-0.4,3-1.3C49.8,25.5,50.1,24.3,49.9,23.2C49.9,23.2,49.9,23.2,49.9,23.2z"/>
        <path fill="#462d7d"  class="st0" d="M15.3,11.6c0.1,0.4-0.1,0.8-0.4,0.9c-0.4,0.1-0.8,0-1-0.4c-0.7-1.6-1-3.4-0.8-5.1c0.1-1.2,0.7-2.2,1.6-2.9
          c0.9-0.7,2.1-0.8,3.2-0.5c3.2,0.9,6.2,2.7,8.6,5c0.3,0.3,0.3,0.8,0,1.1c-0.3,0.3-0.8,0.3-1.1,0c-2.2-2.2-5-3.8-8-4.7
          c-0.7-0.2-1.4-0.1-1.9,0.3c-0.6,0.4-0.9,1-1,1.7C14.5,8.7,14.7,10.2,15.3,11.6C15.3,11.6,15.3,11.6,15.3,11.6z"/>
        <path fill="#462d7d"  class="st0" d="M65.3,25.9c0.7,0,1.3-0.3,1.8-0.9c0.4-0.5,0.6-1.3,0.4-1.9c-0.9-3.9-3.1-7.5-6.2-10.1C61,12.8,61,12.3,61.3,12
          c0.3-0.3,0.7-0.4,1.1-0.1c3.3,2.8,5.7,6.6,6.7,10.9c0.3,1.1,0,2.3-0.7,3.2s-1.8,1.4-2.9,1.5c-0.4,0-0.9,0-1.3-0.1
          c-0.4,0-0.7-0.4-0.7-0.8s0.4-0.7,0.8-0.7C64.6,25.9,65,25.9,65.3,25.9C65.3,25.9,65.3,25.9,65.3,25.9z"/>
        <path fill="#462d7d"  class="st0" d="M31.4,17.4c-0.2-0.2-0.2-0.5-0.1-0.8c0.1-0.3,0.4-0.4,0.7-0.4c0.3,0,0.5,0.1,0.7,0.4c2.7,4.1,6.6,7.1,11.1,8.8
          c-0.7-7.8-7.1-13.8-15-14c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.4,0.1,0.8-0.2,1.1c-0.4,0.2-0.8,0.1-1.1-0.2c-0.4-0.6-0.8-1.1-1.2-1.7
          c-8.2,0.8-14.3,8-13.8,16.2c0.5,8.2,7.4,14.6,15.7,14.5S43.6,35.3,43.8,27C38.7,25.3,34.3,21.9,31.4,17.4
          C31.4,17.4,31.4,17.4,31.4,17.4z"/>
        <path fill="#462d7d"  class="st0" d="M41,7.3l-0.8-2.2l2-1.4c0.1,0,0.1-0.1,0.1-0.2S42.1,3.4,42,3.4h-2.4l-0.7-2.3c0-0.1-0.1-0.1-0.2-0.1
          S38.6,1,38.6,1.1l-0.7,2.3h-2.4c-0.1,0-0.1,0-0.2,0.1s0,0.2,0.1,0.2l2,1.4l-0.8,2.2c0,0.1,0,0.2,0.1,0.2c0.1,0,0.1,0,0.2,0l2-1.4
          l2,1.4c0.1,0,0.1,0,0.2,0C41,7.5,41,7.4,41,7.3C41,7.3,41,7.3,41,7.3z"/>
        <path fill="#462d7d"  class="st0" d="M57,2.4l-0.7,2.3h-2.4c-0.1,0-0.1,0-0.2,0.1s0,0.2,0.1,0.2l2,1.4l-0.8,2.2c0,0.1,0,0.2,0.1,0.2s0.1,0,0.2,0
          l2-1.4l2,1.4c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.1-0.2l-0.8-2.2l2-1.4c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1H58l-0.7-2.3
          c0-0.1-0.1-0.1-0.2-0.1C57.1,2.3,57,2.3,57,2.4L57,2.4L57,2.4z"/>
        <path fill="#462d7d"  class="st0" d="M59.4,32.7l-0.8-2.2l2-1.4c0.1,0,0.1-0.1,0.1-0.2s-0.1-0.1-0.2-0.1H58l-0.7-2.3c0-0.1-0.1-0.1-0.2-0.1
          c-0.1,0-0.1,0-0.2,0.1l-0.7,2.3h-2.4c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.2,0.1,0.2l2,1.4l-0.8,2.2c0,0.1,0,0.2,0.1,0.2
          c0.1,0,0.1,0,0.2,0l2-1.4l2,1.4c0.1,0,0.1,0,0.2,0C59.4,32.9,59.4,32.8,59.4,32.7L59.4,32.7z"/>
        <path fill="#462d7d"  class="st0" d="M20.7,49.5c-1.4,4-5.3,6.5-9.6,6.2c-0.5,0-0.9-0.3-1.2-0.6s-0.4-0.8-0.4-1.2L11.9,30c0.2-2.2-1.4-4.2-3.6-4.4
          C6,25.4,4.1,27,3.9,29.2L0.9,59.3c-0.2,1.8,0.5,3.6,1.7,4.9c1.3,1.3,3,2,4.8,1.9L18,65.4c0.5,0,1.1,0.2,1.4,0.5
          c0.4,0.4,0.6,0.9,0.6,1.4v28.4c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1H50c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3V54.4
          c0-7.2-5.9-13.1-13.1-13.1H37c-3.6,2.1-7.9,2.8-12,2C23.3,45.1,21.8,47.2,20.7,49.5C20.7,49.5,20.7,49.5,20.7,49.5z"/>
      </g>
      </svg>
 
  )
}

export default HairLossIcon
