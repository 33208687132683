import React from 'react'
import {Button} from '@material-ui/core'
import {FormattedMessage} from 'react-intl'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import {useStyles} from './Styles'
import "./styles.scss"

const RowItem = ({id, day, month, dose, time, location,color, handleEditItem, medicationName, status, icon}) => {
  const classes = useStyles()


  return (
    <section className={classes.rowItemWrapper}>
      <div className="rowItemContentWrapper">
       
            <div className={classes.rowItemBox}>
              <span className={classes.rowItemTitle}>{day}</span>
              <span className={classes.rowItemTitle}>{month}</span>
            </div>
            <div className={classes.rowItemBox}>
              <i className={icon}></i>
            </div>
       
          <div className={classes.rowItemContent} style={{display:"flex",justifyContent:"center"}}>
            <h2 className={classes.rowItemContentTitle}>{medicationName}</h2>
              <span className={classes.rowItemSubTitle}>{time} | {dose}</span>
       
          </div>
      </div>
    
      <div className={classes.rescueMedWrapper} style={{display:"flex",alignSelf:"center"}}>

        
        <span 
          style={{
            fontSize:"16px",
            backgroundColor:color,
            color: 'white',
            padding: '8px 16px',
            borderRadius: '20px',
            width: '120px',
            textAlign: 'center'
          }}>
          {status}
        </span>
        <EditIcon 
          className={classes.EditIcon} 
          onClick={() => handleEditItem(id)}/>

      </div>

    </section>
  )
}

export default RowItem