import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useStyles } from './Styles'
import { FormLoader } from '../../Loader/FormLoader'
import { FormattedMessage } from 'react-intl'
import ReactAudioPlayer from 'react-audio-player'

const ItemTable = ({ rows, columns, loading }) => {
  const classes = useStyles()
  return (
    <div className={classes.itemTableWrapper}>
      {!!loading && <FormLoader loading={true}/>}
      <TableContainer className={classes.itemTableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 &&
            <TableRow><TableCell colSpan={3}>
              <h4 align={'center'}><FormattedMessage id='EventLog.NoData'/></h4>
            </TableCell></TableRow>
            }
            {rows.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row[0]}>
                  {columns.map((column) => {
                    if ('content' === column.name && !!row.content) {
                      let parsedData = JSON.parse(row.content.replace(/'/g, '"')
                        .replace(/None/g, '"None"')
                        .replace(/False/g, '"False"')
                        .replace(/True/g, '"True"'))
                      return (
                        <TableCell key={column.id}>
                          {'memo' === row.event_type && !!parsedData['url'] && parsedData['url'] !== 'None' ?
                            <>
                              <ReactAudioPlayer
                                className={classes.memoPlayer}
                                src={parsedData['url']}
                                controls
                              />
                              {!!Object.keys(parsedData).includes('label') &&
                              <div>
                                <strong>label:</strong> {parsedData['label']}
                              </div>
                              }
                            </>
                            :
                            (Object.keys(parsedData).map((item, index) => (
                              <div key={column.id + '-' + index}>
                                <strong>{item}:</strong> {parsedData[item]}
                              </div>
                            )))}

                        </TableCell>
                      )
                    } else {
                      const value = row[column.name]
                      return (
                        <TableCell key={column.id}>
                          {value}
                        </TableCell>
                      )
                    }
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
export default ItemTable
