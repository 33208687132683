import { Input, InputNumber } from "antd";
import "antd/dist/antd.css";
import styled from 'styled-components';

const { TextArea } = Input;

const StyledInput = styled(Input)`

    border-radius: 4px;
    border: 1px solid rgba(224,224,224,1);
    
    &:hover,
    &:focus {
        border: 1px solid #432f7a !important;
        box-shadow: none;
    }
    input {
        font-size: 1rem;
        padding: 11px 0 !important;
    }

    input[type=number] {
        &::-webkit-inner-spin-button, 
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
        -moz-appearance: textfield;
    }

    `

const StyledInputNumber = styled(InputNumber)`
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(224,224,224,1);
    padding: 11px 0 !important;
    
    &:hover,
    &:focus {
        border: 1px solid #432f7a !important;
        box-shadow: none;
    }
    input {
        font-size: 1rem;
    }

    .ant-input-number-handler-wrap {
        opacity: 1;
    }

    .ant-input-number-handler:hover .ant-input-number-handler-up-inner, .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
        color: #432f7a;
    }

`

const StyledTextArea = styled(TextArea)`

    border-radius: 4px;
    border: 1px solid rgba(224,224,224,1);
    font-size: 1rem;
    
    &:hover,
    &:focus {
        border: 1px solid #432f7a !important;
        box-shadow: none;
    }

`

export {StyledInput, StyledTextArea, StyledInputNumber}

