import React, { useRef } from 'react'

import CanvasJSReact from './canvasjs/canvasjs.stock.react'
import ProfileServices from '../../../Services/Consumers/ProfileServices'
import { useStyles } from './Styles'
import { FormLoader } from '../../Loader/FormLoader'

const DayCharts = ({ userId, userEmail }) => {
  const classes = useStyles()
  const [hasHeartrateData, setHasHeartrateData] = React.useState(false)
  const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart
  let midNightDate = new Date(),
    currentDayDate = new Date(),
    rangeChangedTriggered = false
  midNightDate.setHours(0, 0, 0, 0)

  const [heartRateDataPoints, setHeartRateDataPoints] = React.useState([{ x: midNightDate.getTime(), y: 0 }])
  const [heartRateStripLine, setHeartRateStripLine] = React.useState(0)

  let heartRateChartRef = useRef(null)
  const heartRateContainerProps = {
    width: '100%',
    height: '250px',
    margin: 'auto'
  }
  let heartRateOptions = {
    theme: 'light2',
    rangeChanged: function(e) {
      rangeChangedTriggered = true
    },
    charts: [{
      axisX: {
        crosshair: {
          enabled: true,
          valueFormatString: 'MMM DD, YYYY HH:mm:ss'
        }
      },
      axisY: {
        title: 'Heart Rate',
        stripLines: [{
          showOnTop: true,
          lineDashType: 'dash',
          color: '#ff4949',
          labelFontColor: '#ff4949',
          labelFontSize: 14,
          value: heartRateStripLine,
          label: heartRateStripLine
        }]
      },
      toolTip: {
        shared: true
      },
      data: [{
        type: 'spline',
        name: 'Heart Rate',
        lineColor: '#462d7d',
        color: '#462d7d',
        xValueFormatString: 'MMM DD, YYYY HH:mm:ss',
        xValueType: 'dateTime',
        dataPoints: heartRateDataPoints
      }]
    }],
    navigator: {
      slider: {
        minimum: new Date(currentDayDate.getTime() - (1 * 3600000))
      }
    },
    rangeSelector: {
      enabled: false
    }
  }

  let timeFrom = midNightDate.getTime() / 1000

  const updateChart = (tfrom) => {
    let currentDate = new Date()
    let currentTime = Math.round(currentDate.getTime() / 1000)
    let data = {
      'timestamp_from': tfrom,
      'timestamp_to': currentTime,
      'patient_id': userId
    }

    let heartRateDataPointsArr = heartRateDataPoints

    ProfileServices.loadHeartRate(userEmail, tfrom, currentTime, null, null, null).then((result) => {
      if (!!result && !!result.data) {
        if (!((!!result.status && ![200].includes(result.status)) || !!result.data.exception)) {
          if (!!result.data && !!result.data.length) {
            if (heartRateDataPointsArr.length === 1) {
              heartRateDataPointsArr.shift()
            }
          }
          result.data.forEach((rData) => {
            heartRateDataPointsArr.push({ x: Date.parse(rData.timestamp), y: rData.heartrate/1 })
          })
          setHeartRateDataPoints(heartRateDataPointsArr)

          timeFrom = currentTime
        }
      }
    }).then(() => {
      if (!rangeChangedTriggered) {
        if (!!heartRateChartRef.current) {
          heartRateChartRef.current.navigator.slider.set('minimum', heartRateDataPointsArr[heartRateDataPointsArr.length - 1].x - (1 * 3600000))
        }
        setHeartRateStripLine(heartRateDataPointsArr[heartRateDataPointsArr.length - 1].y)
      }
      setHasHeartrateData(true)
    })
  }

  // let timeIntervalPointer = null
  React.useEffect(() => {
    updateChart(timeFrom)
    let timeIntervalPointer = setInterval(() => updateChart(timeFrom), 10000)
    return () => clearInterval(timeIntervalPointer)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  return (
    <div>
      <div className={classes.chartWrapper}>
        {!hasHeartrateData && <FormLoader loading={true}/>}
        <CanvasJSStockChart containerProps={heartRateContainerProps} options={heartRateOptions}
                            onRef={ref => heartRateChartRef.current = ref}
        />
      </div>
    </div>
  )
}
export default DayCharts