import React from 'react'
import {FormattedMessage} from 'react-intl'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import {useStyles} from '../Styles'
import AuthLogo from '../AuthLogo'

const VerifyCard = () => {
  let classes = useStyles()
  return (
    <>
      <AuthLogo/>
      <div className={classes.authCard + ' '+ classes.authCardVerify} >
        <MailOutlineIcon/>
        <h1 className={classes.authCardTitle}><FormattedMessage id='VerifyCard.title'/></h1>
        <p className={classes.authCardDescription}><FormattedMessage id='VerifyCard.Description'/></p>
      </div>
    </>
  )
}

export default VerifyCard
