import 'date-fns'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {IconButton, fade, FormControl, Menu, withStyles} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'

import clsx from 'clsx';
import format from 'date-fns/format'
import endOfWeek from 'date-fns/endOfWeek';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfWeek from 'date-fns/startOfWeek';
import ProfileServices  from '../../../../Services/Consumers/ProfileServices'
import { makeJSDateObject } from "../../../../../src/utils/helpers.ts"
import { log } from '../../../../../src/utils/helpers.js'
import { useStyles } from './Styles'
import moment from "moment"

const StyledMenu = withStyles((theme) => ({
  paper: {
    minWidth: 192,
    borderRadius: 12,
    marginTop: 13,
    left: 500,
    boxShadow: `0 8px 16px -4px ${fade(theme.palette.common.black, 0.16)}`,
    border: `solid 1px #eaeaea`,
    backgroundColor: theme.palette.common.white,
    '& .MuiMenuItem-root': {
      fontSize: theme.typography.pxToRem(14),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      borderBottom: `1px solid #e5e5e5`
    }
  }
}))((props) => (<Menu elevation={0} getContentAnchorEl={null} anchorOrigin={{
  vertical: 'bottom',
  horizontal: 'center'
}} transformOrigin={{
  vertical: 'top',
  horizontal: 'center'
}} {...props}/>))


const ItemFilter = ({ selectedDate, setSelectedDate, selectedWeek, setSelectedWeek, sleepScoreSeries, setSleepScoreSeries, setSleepQualityData }) => {

  const today = new Date()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    log('Date Changed', date)
    setAnchorEl(null)
  }


   const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    let dateClone = makeJSDateObject(date);
    let selectedDateClone = makeJSDateObject(selectedDate);

    const start = startOfWeek(selectedDateClone, {weekStartsOn: 0});
    const end = endOfWeek(selectedDateClone, {weekStartsOn: 0});

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);


    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    const dateArray = [];
    const currentDate = start;
    while (currentDate <= end) {
      dateArray.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1);
    };
    
    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    );
  };

  const setChosenWeek = (date) => {
    const dateClone = makeJSDateObject(date);
    const start = startOfWeek(dateClone, {weekStartsOn: 0});
    const end = new Date(start);
    end.setDate(start.getDate() + 7);
    
    const dates = [];
    const currentDate = start;
    while (currentDate <= end) {
      dates.push(new Date(currentDate))

      currentDate.setDate(currentDate.getDate() + 1);
    };
    setSelectedWeek(dates);
    getSleepQuality(dates)
  }


  const isThisWeek = (date) => {
    const start = startOfWeek(today, {weekStartsOn: 0});
    const end = new Date(start);
    end.setDate(start.getDate() + 7);
    const day = new Date(date)
    return (start <= day && day <= end)
  }

  const getSleepQuality = async (week) => {
    let sleepScore = [0,0,0,0,0,0,0]

    const userID = localStorage.getItem('PatientID');


    let currentTime = ""
    let start = new Date(week[0])
    start.setDate(start.getDate())


    let end = new Date(week[7])
    end.setDate(end.getDate())
    

    const startTimestamp = moment(start).utc().format();
    const endTimestamp = moment(end).utc().format();


    const response = await ProfileServices.sleepHistory(userID, startTimestamp, endTimestamp)
    let counter = 0
    let sleepQualityData = response.data.data


    if (!!response && !!response.data) {
      if (response.data.data) {
        setSleepQualityData(response.data.data.reverse())
      }

      for (let i = 0 ; i < 7 ; i++){
        currentTime = moment(week[i]).utc().format("YYYY-MM-DD")

        for(let day in response.data.data){
          if (currentTime === response.data.data[day].sleep_session_date){
            if(response.data.data[day].sleep_quality_score != null){
              sleepScore[i] += response.data.data[day].sleep_quality_score
              counter += 1
            }
          }
        }
        if(sleepScore[i]!==0){
          sleepScore[i] = Math.round(sleepScore[i] / counter)
        }
        counter = 0
      }
      const sleepScoreSeriesData = [{
        name: "Sleep Scores",
        data: sleepScore
      }];
      if (sleepQualityData != null) {
        sleepQualityData.sort(function (a, b) {
          return new Date(a.sleep_start_date + "T" + a.sleep_start_time) - new Date(b.sleep_start_date + "T" + b.sleep_start_time)
        })
      }
      setSleepScoreSeries(sleepScoreSeriesData)
      setSleepQualityData(sleepQualityData)
    }
  }

  React.useEffect(() => {
      if (sleepScoreSeries.data == null) {
        setChosenWeek(today);
      }
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);


  return (
    <div className={classes.ItemFilter}>
      <FormControl variant="filled" className={classes.formControl}>
        <button
        className={classes.itemFilterButton}
        onClick={handleClick}>

          {isThisWeek(selectedDate) ? 'This week' : moment(selectedWeek[0]).format("ddd MMM DD, YYYY") + " - " + moment(selectedWeek[6]).format("ddd MMM DD, YYYY")}

          <ArrowDropDownIcon/>

        </button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="static"
              format="dd-MM-yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={selectedDate}
              renderDay={renderWrappedWeekDay}
              onChange={(value) => {
                handleDateChange(value.toDateString())
                setChosenWeek(value)
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
        </StyledMenu>
      </FormControl>
    </div>

  )
}
export default ItemFilter
