import React, { useState } from 'react'
import { useStyles } from '../RowItem/Styles'
import { FormattedMessage } from 'react-intl'
import { ProfileConsumer } from '../../../../ProfileContext'
import Alert from '@material-ui/lab/Alert'
import { SearchLoader } from '../../../Loader/SearchLoader'
import moment from "moment";
import RowItem from '../RowItem/RowItem'
const VoiceMemosTable = ({ loadVoiceMemos, getVoiceMemos }) => {

  const [isEnvCA, setIsEnvCA] = useState(false);

  React.useEffect(() => {
      if (!!loadVoiceMemos) {
        loadVoiceMemos()
      }

      const query = {
        start_timestamp: moment().subtract(5, 'y').utc().format(),
        end_timestamp: moment().utc().format()
      }

      if(!!getVoiceMemos){
        getVoiceMemos(query);
      }

      const envSubstring = process.env.REACT_APP_PATIENT_PORTAL_API_BASE_URL.split('.')[0].slice(-2);
      setIsEnvCA(envSubstring === "ca");

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  return (
      <ProfileConsumer>
      {({ voiceMemoDataLoading, voiceMemoDataFailure, voiceMemoData, voiceMemoDataSuccess }) => (
          <>
            {!!voiceMemoDataFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}
            {!!voiceMemoDataSuccess && <Alert severity="success"><FormattedMessage id='EndPoint.Seizures.Success'/></Alert>}
            {!!voiceMemoDataLoading ? <SearchLoader loading={true}/> : 
              (!!voiceMemoData && !!voiceMemoData.length ?
                <>
                   {voiceMemoData.map((item) => {

                    const timestamp = moment(item.date);
                    const itemData = {
                      time : timestamp.format("hh:mm a"),
                      icon : (timestamp.format("a") === 'am' ? 'icon-day' : 'icon-night'),
                      day  : (timestamp.format("DD MMM YY") === moment().format("DD MMM YY")) ?  "Today" :  
                      (timestamp.format("DD MMM YY") === moment().subtract(1, "days").format("DD MMM YY") ? 
                      "Yesterday" : timestamp.format("DD MMM YY"))
                    };

                    return (
                        <RowItem
                          key={item.id}
                          day={itemData.day}
                          time={itemData.time}
                          icon={itemData.icon}
                          voice={!isEnvCA ? item.url : null}
                          transcription={item.transcription}
                        />
                  )
                  })}
                </>

                : 
                
                  <div style={{
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <p style={{
                      color: "#bbb", fontSize: "1.3rem", fontStyle: "italic"
                    }}>
                      No Voice Memos Found
                    </p>
                  </div>
              )
            }
          </>
        )}
      </ProfileConsumer>
  )
}

export default VoiceMemosTable