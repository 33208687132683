import React from 'react'
import {SwapSpinner} from 'react-spinners-kit'
import {useStyles} from './Styles'

export const FormLoader = ({loading}) => {
  let classes = useStyles()
  return (
    <div className={classes.formLoaderWrapper}>
      <SwapSpinner
        size={50}
        color="#462d7d"
        loading={loading}
      />
    </div>
  )
}
