import React, { useState } from 'react'
import {
  UserEmergencyContactPayload,
  UserRemoveEmergencyContactPayload,
  UserSeizuresPayload, UserSortEmergencyContactPayload,
  UserAddMedicationPayload, RequestHelpPayload
} from './Services/Payloads/UserPayload'
import moment from "moment"
import ProfileServices from './Services/Consumers/ProfileServices'
import { set } from 'js-cookie'

const ProfileContext = React.createContext({})

const ProfileProvider = ({ children, userId }) => {
  const [authEmail, setAuthEmail] = React.useState(localStorage.getItem('UserEmail'))

  const [userID, setUserID] = React.useState(+localStorage.getItem('PatientID'))

  const [seizures, setSeizures] = React.useState([])
  const [seizuresLoading, setSeizuresLoading] = React.useState(false)
  const [seizuresFailure, setSeizuresFailure] = React.useState(false)
  const [seizuresSuccess, setSeizuresSuccess] = React.useState(false)

  const today = new Date(),
    currentTime = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  React.useEffect(() => {
    if (!authEmail) {
      setAuthEmail(localStorage.getItem('UserEmail'))
      setUserID(+localStorage.getItem('PatientID'))
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const getUserSeizures = () => {
    if (!!userID) {
      setSeizuresSuccess(false)
      setSeizuresFailure(false)
      setSeizuresLoading(true)
      ProfileServices.loadSeizureTypes(userID, setSeizuresLoading, setSeizuresFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setSeizuresFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setSeizuresFailure(true)
            } else {
              setSeizures(result.data.data)
            }
          }
        }
      }).then(() => {
        setSeizuresLoading(false)
      })
    }
  }
  const handleSeizuresUpdate = (values, FormikActions) => {
    if (!!authEmail) {
      setSeizuresSuccess(false)
      setSeizuresFailure(false)
      FormikActions.setSubmitting(true)
      ProfileServices.saveSeizures(UserSeizuresPayload(authEmail, values), null, setSeizuresFailure, FormikActions).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setSeizuresFailure(true)
          setSeizuresSuccess(false)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setSeizuresSuccess(false)
              setSeizuresFailure(true)
            } else {
              setSeizuresFailure(false)
              setSeizuresSuccess(true)
            }
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }


  const [voiceMemos, setVoiceMemos] = React.useState({})
  const [voiceMemosLoading, setVoiceMemosLoading] = React.useState(false)
  const [voiceMemosFailure, setVoiceMemosFailure] = React.useState(false)
  const [voiceMemosSuccess, setVoiceMemosSuccess] = React.useState(false)

  const getLoadVoiceMemos = (query) => {
    if (!!authEmail) {
      setVoiceMemosSuccess(false)
      setVoiceMemosFailure(false)
      setVoiceMemosLoading(true)


      ProfileServices.getVoiceMemos(userID, query).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setVoiceMemosFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setVoiceMemosFailure(true)
            } else if (!!result.data) {
              setVoiceMemos(result.data)
            }
          }
        }
      }).then(() => {
        setVoiceMemosLoading(false)
      })
    }
  }

  const [voiceMemoData, setVoiceMemoData] = React.useState({})
  const [voiceMemoDataLoading, setVoiceMemoDataLoading] = React.useState(false)
  const [voiceMemoDataFailure, setVoiceMemoDataFailure] = React.useState(false)
  const [voiceMemoDataSuccess, setVoiceMemoDataSuccess] = React.useState(false)

  const handleGetVoiceMemos = (queryParams) => {
    if (!!authEmail) {
      setVoiceMemoDataSuccess(false)
      setVoiceMemoDataFailure(false)
      setVoiceMemoDataLoading(true)
      ProfileServices.getVoiceMemos(userID, queryParams).then((response) => {

        if ((!!response && ![200, 201].includes(response.status))) {
          setVoiceMemoDataFailure(true)
        } else {
          if (!!response && !!response.data) {
            setVoiceMemoData(response.data.data.voice_memos)
          }
        }
      }).then(() => {
        setVoiceMemoDataLoading(false)
      })
    }
  }


  const [seizuresHistory, setSeizuresHistory] = React.useState({})
  const [seizuresHistoryLoading, setSeizuresHistoryLoading] = React.useState(false)
  const [seizuresHistoryFailure, setSeizuresHistoryFailure] = React.useState(false)
  const [seizuresHistorySuccess, setSeizuresHistorySuccess] = React.useState(false)

  const getLoadSeizuresHistory = queryParam => {
    if (!!userID) {
      setSeizuresHistoryFailure(false)
      setSeizuresHistorySuccess(false)
      setSeizuresHistoryLoading(true)
      ProfileServices.loadSeizuresHistory(userID, queryParam, setSeizuresHistoryLoading, setSeizuresHistoryFailure)
        .then(result => {
          if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
            setSeizuresHistoryFailure(true)
          } else {
            if (!!result && !!result.data) {
              if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
                setSeizuresHistoryFailure(true);
              } else {
                setSeizuresHistory(result.data.data);
              }
            }
          }
        }).then(() => {
          setSeizuresHistoryLoading(false);
        })
    }
  }


  const [recentSeizure, setRecentSeizure] = React.useState({})
  const [recentSeizureLoading, setRecentSeizureLoading] = React.useState(false)
  const [recentSeizureFailure, setRecentSeizureFailure] = React.useState(false)
  const [recentSeizureSuccess, setRecentSeizureSuccess] = React.useState(false)

  const getRecentSeizure = () => {
    if (!!userID) {
      setRecentSeizureSuccess(false)
      setRecentSeizureFailure(false)
      setRecentSeizureLoading(true)
      ProfileServices.loadRecentSeizure(userID, setRecentSeizureLoading, setRecentSeizureFailure, null).then((result) => {
        if ((!result || (!!result.response && ![200, 201].includes(result.response.status)))) {
          setRecentSeizureFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setRecentSeizureFailure(true)
            } else {
              setRecentSeizure(result.data.data[0])
            }
          }
        }
      }).then(() => {
        setRecentSeizureLoading(false)
      })
    }
  }


  const [medicationHistory, setMedicationHistory] = React.useState({});
  const [medicationHistoryLoading, setMedicationHistoryLoading] = React.useState(false);
  const [medicationHistoryFailure, setMedicationHistoryFailure] = React.useState(false);
  const [medicationHistorySuccess, setMedicationHistorySuccess] = React.useState(false);

  const getLoadMedicationHistory = queryParam => {
    if (!!userID) {
      setMedicationHistorySuccess(false);
      setMedicationHistoryFailure(false);
      setMedicationHistoryLoading(true);
      ProfileServices.loadMedicationHistory(userID, queryParam, setMedicationHistoryLoading, setMedicationHistoryFailure)
        .then(result => {
          if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
            setMedicationHistoryFailure(true)
          } else {
            if (!!result && !!result.data) {
              if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
                setMedicationHistoryFailure(true);
              } else {
                setMedicationHistory(result.data);
              }
            }
          }
        }).then(() => {
          setMedicationHistoryLoading(false);
        })
    }
  }

  const [editMedicationHistoryFailure, setEditMedicationHistoryFailure] = React.useState(false);
  const [editMedicationHistorySuccess, setEditMedicationHistorySuccess] = React.useState(false);

  const handleEditMedicationHistory = (medicationID, queryParam, FormikActions, putFlag, setPutFlag) => {
    if (!!userID) {
      setEditMedicationHistorySuccess(false);
      setEditMedicationHistoryFailure(false);

      FormikActions.setSubmitting(true);

      ProfileServices.editMedicationHistory(userID, medicationID, queryParam, null, setEditMedicationHistoryFailure, FormikActions)
        .then(res => {
          if ((!!res.request.response && ![200, 201].includes(res.request.response.status)) || !res) {
            setEditMedicationHistoryFailure(true)
          } else {
            if (!!res && !!res.data) {
              if ((!!res.data.RETURN_CODE && ![200, 201].includes(res.data.RETURN_CODE)) || !!res.data.exception) {
                setEditMedicationHistoryFailure(true);
              } else {
                setEditMedicationHistorySuccess(true);
              }
            }
          }
        }).then(() => {
          setPutFlag(!putFlag);
          FormikActions.setSubmitting(false);
        })
    }
  }


  const [medicationSchedule, setMedicationSchedule] = React.useState({})
  const [medicationScheduleLoading, setMedicationScheduleLoading] = React.useState(false)
  const [medicationScheduleFailure, setMedicationScheduleFailure] = React.useState(false)
  const [medicationScheduleSuccess, setMedicationScheduleSuccess] = React.useState(false)

  const getLoadMedicationSchedule = () => {
    if (!!authEmail) {

      setMedicationScheduleSuccess(false)
      setMedicationScheduleFailure(false)
      setMedicationScheduleLoading(true)

      ProfileServices.loadMedicationSchedule(authEmail, userID, setMedicationScheduleLoading, setMedicationScheduleFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setMedicationScheduleFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setMedicationScheduleFailure(true)
            } else {
              setMedicationSchedule(result.data.Medications)
            }
          }
        }
      }).then(() => {
        setMedicationScheduleLoading(false)
      })
    }
  }


  const [upcomingMedications, setUpcomingMedications] = React.useState({})
  const [upcomingMedicationsLoading, setUpcomingMedicationsLoading] = React.useState(false)
  const [upcomingMedicationsFailure, setUpcomingMedicationsFailure] = React.useState(false)
  const [upcomingMedicationsSuccess, setUpcomingMedicationsSuccess] = React.useState(false)

  const loadUpcomingMedications = () => {
    if (!!userID) {

      setUpcomingMedicationsSuccess(false)
      setUpcomingMedicationsFailure(false)
      setUpcomingMedicationsLoading(true)

      ProfileServices.loadUpcomingMedications(userID, setUpcomingMedicationsLoading, setUpcomingMedicationsFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setUpcomingMedicationsFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setUpcomingMedicationsFailure(true)
            } else {
              setUpcomingMedications(result.data.data)
            }
          }
        }
      }).then(() => {
        setUpcomingMedicationsLoading(false)
      })
    }
  }



  const [seizureFreeDays, setSeizureFreeDays] = React.useState({})
  const [seizureFreeDaysLoading, setSeizureFreeDaysLoading] = React.useState(false)
  const [seizureFreeDaysFailure, setSeizureFreeDaysFailure] = React.useState(false)
  const [seizureFreeDaysSuccess, setSeizureFreeDaysSuccess] = React.useState(false)

  const getSeizureFreeDays = () => {
    if (!!authEmail) {

      setSeizureFreeDaysSuccess(false)
      setSeizureFreeDaysFailure(false)
      setSeizureFreeDaysLoading(true)
      ProfileServices.loadSeizureFreeDays(userID, setSeizureFreeDaysLoading, setSeizureFreeDaysFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setSeizureFreeDaysFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setSeizureFreeDaysFailure(true)
            } else {
              setSeizureFreeDays(result.data)
            }
          }
        }
      }).then(() => {
        setSeizureFreeDaysLoading(false)
      })
    }
  }



  const [sleepQuality, setSleepQuality] = React.useState([])
  const [sleepQualityLoading, setSleepQualityLoading] = React.useState(false)
  const [sleepQualityFailure, setSleepQualityFailure] = React.useState(false)
  const [sleepQualitySuccess, setSleepQualitySuccess] = React.useState(false)

  const getSleepQuality = () => {
    if (!!userID) {
      setSleepQualitySuccess(false)
      setSleepQualityFailure(false)
      setSleepQualityLoading(true)
      ProfileServices.loadSleepQuality(userID, currentTime, setSleepQualityLoading, setSleepQualityFailure, null).then((result) => {

        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setSleepQualityFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setSleepQualityFailure(true)
            } else {
              setSleepQuality(result.data)
            }
          }
        }
      }).then(() => {
        setSleepQualityLoading(false)
      })
    }
  }

  const [sleepStats, setSleepStats] = React.useState([])
  const [sleepStatsLoading, setSleepStatsLoading] = React.useState(false)
  const [sleepStatsFailure, setSleepStatsFailure] = React.useState(false)
  const [sleepStatsSuccess, setSleepStatsSuccess] = React.useState(false)

  const getSleepStats = () => {
    if (!!userID) {
      setSleepStatsSuccess(false)
      setSleepStatsFailure(false)
      setSleepStatsLoading(true)
      ProfileServices.sleepStatistics(userID).then((result) => {

        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setSleepStatsFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setSleepStatsFailure(true)
            } else {
              setSleepStats(result.data)
            }
          }
        }
      }).then(() => {
        setSleepStatsLoading(false)
      })
    }
  }



  const [emergencyContacts, setEmergencyContacts] = React.useState([])
  const [emergencyContactsLoading, setEmergencyContactsLoading] = React.useState(false)
  const [emergencyContactsFailure, setEmergencyContactsFailure] = React.useState(false)

  const [emergencyContactsAddSuccess, setEmergencyContactsAddSuccess] = React.useState(false)
  const [emergencyContactsAddFailure, setEmergencyContactsAddFailure] = React.useState(false)

  const [emergencyContactsRemoveLoading, setEmergencyContactsRemoveLoading] = React.useState(false)
  const [emergencyContactsRemoveSuccess, setEmergencyContactsRemoveSuccess] = React.useState(false)
  const [emergencyContactsRemoveFailure, setEmergencyContactsRemoveFailure] = React.useState(false)
  const getEmergencyContacts = () => {
    if (!!authEmail) {
      setEmergencyContacts([])
      setEmergencyContactsLoading(true)
      setEmergencyContactsFailure(false)

      setEmergencyContactsAddSuccess(false)
      setEmergencyContactsAddFailure(false)

      setEmergencyContactsRemoveLoading(false)
      setEmergencyContactsRemoveSuccess(false)
      setEmergencyContactsRemoveFailure(false)

      setEmergencyContactsSortLoading(false)
      setEmergencyContactsSortSuccess(false)
      setEmergencyContactsSortFailure(false)

      ProfileServices.loadEmergencyContacts(authEmail, setSeizuresLoading, setSeizuresFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setEmergencyContactsFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setEmergencyContactsFailure(true)
            } else {
              setEmergencyContacts(result.data.emergency_contacts)
            }
          }
        }
      }).then(() => {
        setEmergencyContactsLoading(false)
      })
    }
  }


  const handleAddEmergencyContact = (values, FormikActions) => {
    if (!!authEmail) {
      setEmergencyContactsAddSuccess(false)
      setEmergencyContactsAddFailure(false)

      setEmergencyContactsRemoveSuccess(false)
      setEmergencyContactsRemoveFailure(false)

      setEmergencyContactsSortSuccess(false)
      setEmergencyContactsSortFailure(false)

      FormikActions.setSubmitting(true)

      ProfileServices.saveEmergencyContact(UserEmergencyContactPayload(authEmail, values), null, setEmergencyContactsAddFailure, FormikActions).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setEmergencyContactsAddFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setEmergencyContactsAddFailure(true)
            } else {
              getEmergencyContacts()
              setEmergencyContactsAddSuccess(true)
            }
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }


  const handleRemoveEmergencyContact = (contactID) => {
    if (!!authEmail) {
      setEmergencyContactsRemoveSuccess(false)
      setEmergencyContactsRemoveFailure(false)

      setEmergencyContactsAddSuccess(false)
      setEmergencyContactsAddFailure(false)

      setEmergencyContactsSortSuccess(false)
      setEmergencyContactsSortFailure(false)

      setEmergencyContactsRemoveLoading(true)

      ProfileServices.removeEmergencyContact(UserRemoveEmergencyContactPayload(authEmail, contactID), setEmergencyContactsRemoveLoading, setEmergencyContactsAddFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setEmergencyContactsAddFailure(true)
        } else {
          if (!!result && !!result.data) {
            if (![200, 201].includes(result.data.RETURN_CODE) || !!result.data.exception) {
              setEmergencyContactsRemoveFailure(true)
            } else {
              getEmergencyContacts()
              setEmergencyContactsRemoveSuccess(true)
            }
          }
        }
      }).then(() => {
        setEmergencyContactsRemoveLoading(false)
      })
    }
  }

  const [emergencyContactsSortLoading, setEmergencyContactsSortLoading] = React.useState(false)
  const [emergencyContactsSortSuccess, setEmergencyContactsSortSuccess] = React.useState(false)
  const [emergencyContactsSortFailure, setEmergencyContactsSortFailure] = React.useState(false)
  const handleSortEmergencyContacts = (contactsList) => {
    setEmergencyContactsSortLoading(true)
    setEmergencyContactsSortSuccess(false)
    setEmergencyContactsSortFailure(false)

    setEmergencyContactsRemoveSuccess(false)
    setEmergencyContactsRemoveFailure(false)

    setEmergencyContactsAddSuccess(false)
    setEmergencyContactsAddFailure(false)


    ProfileServices.sortEmergencyContacts(UserSortEmergencyContactPayload(authEmail, contactsList), setEmergencyContactsSortLoading, setEmergencyContactsSortFailure, null).then((result) => {
      if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
        setEmergencyContactsSortFailure(true)
      } else {
        if (!!result && !!result.data) {
          if (![200, 201].includes(result.data.RETURN_CODE) || !!result.data.exception) {
            setEmergencyContactsSortFailure(true)
          } else {
            setEmergencyContactsSortSuccess(true)
          }
        }
      }
    }).then(() => {
      setEmergencyContactsSortLoading(false)
    })
  }


  const [medicationSendNotificationLoading, setMedicationSendNotificationLoading] = React.useState(false)
  const [medicationSendNotificationSuccess, setMedicationSendNotificationSuccess] = React.useState(false)
  const [medicationSendNotificationFailure, setMedicationSendNotificationFailure] = React.useState(false)
  const handleMedicationSendNotification = () => {
    setMedicationSendNotificationLoading(true)
    setMedicationSendNotificationSuccess(false)
    setMedicationSendNotificationFailure(false)

    ProfileServices.sendMedicationNotification(userId, setMedicationSendNotificationLoading, setMedicationSendNotificationFailure, null).then((result) => {
      if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
        setMedicationSendNotificationFailure(true)
      } else {
        if (!!result && !!result.data) {
          if (![200, 201].includes(result.status) || !!result.data.exception) {
            setMedicationSendNotificationFailure(true)
          } else {
            setMedicationSendNotificationSuccess(true)
          }
        }
      }
    }).then(() => {
      setMedicationSendNotificationLoading(false)
    })
  }

  const [medications, setMedications] = React.useState([])
  const [medicationsLoading, setMedicationsLoading] = React.useState(false)
  const [medicationsFailure, setMedicationsFailure] = React.useState(false)

  const [medicationsAddSuccess, setMedicationsAddSuccess] = React.useState(false)
  const [medicationsAddFailure, setMedicationsAddFailure] = React.useState(false)

  const [medicationsRemoveLoading, setMedicationsRemoveLoading] = React.useState(false)
  const [medicationsRemoveSuccess, setMedicationsRemoveSuccess] = React.useState(false)
  const [medicationsRemoveFailure, setMedicationsRemoveFailure] = React.useState(false)



  const getMedications = () => {
    if (!!userID) {
      setMedicationsLoading(true)
      setMedicationsFailure(false)
      setMedicationsRemoveLoading(false)

      ProfileServices.loadMedications(userID, setMedicationsLoading, setMedicationsFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setMedicationsFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setMedicationsFailure(true)
            } else {
              if (!!result && !!result.data && result.data.status.code == 204) {
                setMedications(null)
              } else if (!!result && !!result.data && !!result.data.data) {
                setMedications(result.data.data.medications)
              }
            }
          }
        }
      }).then(() => {
        setMedicationsLoading(false)
      })
    }
  }

  const handleRemoveMedication = (editableRowId) => {
    if (!!userID) {
      setMedicationsRemoveSuccess(false)
      setMedicationsRemoveFailure(false)

      setMedicationsAddSuccess(false)
      setMedicationsAddFailure(false)

      setMedicationsRemoveLoading(true)

      ProfileServices.deleteMedication(userID, editableRowId, setMedicationsRemoveLoading, setMedicationsAddFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setMedicationsAddFailure(true)
        } else {
          if (!!result && !!result.data) {
            if (![200, 201].includes(result.status) || !!result.data.exception) {
              setMedicationsRemoveFailure(true)
            } else {
              getMedications()
              setMedicationsRemoveSuccess(true)
            }
          }
        }
      }).then(() => {
        setMedicationsRemoveLoading(false)
      })
    }
  }

  const handleAddMedication = (editableRowId, values, FormikActions, switchToListMode) => {
    if (!!userID) {
      setMedicationsAddSuccess(false)
      setMedicationsAddFailure(false)

      setMedicationsRemoveSuccess(false)
      setMedicationsRemoveFailure(false)
      
      
      FormikActions.setSubmitting(true)

      ProfileServices.saveMedication(userId, editableRowId, UserAddMedicationPayload(editableRowId, userId, values), null, setMedicationsAddFailure, FormikActions, switchToListMode).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setMedicationsAddFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.status.RETURN_CODE && ![200, 201].includes(result.data.status.RETURN_CODE)) || !!result.data.exception) {
              setMedicationsAddFailure(true)
            } else {
              getMedications()
              setMedicationsAddSuccess(true)
              switchToListMode()
            }
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }

  

  const resetMedicationStatus = () => {
    setMedicationsFailure(false)
    setMedicationsRemoveSuccess(false)
    setMedicationsRemoveFailure(false)
    setMedicationsAddSuccess(false)
    setMedicationsAddFailure(false)
  }


  //start
  const [patientProfileInfo, setPatientProfileInfo] = React.useState([])
  const [patientProfileInfoLoading, setPatientProfileInfoLoading] = React.useState(false)
  const [patientProfileInfoFailure, setPatientProfileInfoFailure] = React.useState(false)

  const [patientProfileInfoSaveSuccess, setPatientProfileInfoSaveSuccess] = React.useState(false)
  const [patientProfileInfoSaveFailure, setPatientProfileInfoSaveFailure] = React.useState(false)
  // savePatientProfileInfo
  const handlePatientProfileInfoSave = (values, FormikActions, isFormSave) => {

    setPatientProfileInfoSaveFailure(true);
    FormikActions.setSubmitting(false);
    // setPatientProfileInfoLoading(false)
    if (isFormSave == false)
      return;

    if (!!authEmail) {
      setPatientProfileInfoSaveSuccess(false)
      setPatientProfileInfoSaveFailure(false)

      setPatientProfileInfoSaveFailure(true)

      FormikActions.setSubmitting(true)

      //ProfileServices.savePatientProfileInfo(UserEmergencyContactPayload(authEmail, values), null, setPatientProfileInfoSaveFailure, FormikActions).then((result) => {
      ProfileServices.savePatientProfileInfo(values, null, setPatientProfileInfoSaveFailure, FormikActions).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setPatientProfileInfoSaveFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setPatientProfileInfoSaveFailure(true)
            } else {
              setPatientProfileInfoSaveSuccess(true)
            }
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false);
      })
    }
  }

  //end


  const [userData, setUserData] = React.useState({})
  const [userDataLoading, setUserDataLoading] = React.useState(false)
  const [userDataFailure, setUserDataFailure] = React.useState(false)


  const getUserData = (refresh) => {
    if (refresh) {
      setUserData({})
    }
    if (!Object.keys(userData).length || refresh == true) {
      setUserDataFailure(false)
      setUserDataLoading(true)
      ProfileServices.getUserData(userID, setUserDataLoading, setUserDataFailure, null).then((result) => {
        // ProfileServices
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setUserDataFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.patient_personal_info) {
            setUserData(result.data.patient_personal_info)

          } else {
            setUserDataFailure(true)
          }
        }
      }).then(() => {
        setUserDataLoading(false)
      })
    }
  }

  const [editSeizureEventFailure, setEditSeizureEventFailure] = React.useState(false);
  const [editSeizureEventSuccess, setEditSeizureEventSuccess] = React.useState(false);

  const [deleteSeizureEventFailure, setDeleteSeizureEventFailure] = React.useState(false);
  const [deleteSeizureEventSuccess, setDeleteSeizureEventSuccess] = React.useState(false);
  const [deleteSeizureEventLoading, setDeleteSeizureEventLoading] = React.useState(false);

  const handleDeleteSeizureEvent = (eventID, flag, setFlag) => {
    if (!!eventID) {
      setDeleteSeizureEventSuccess(false);
      setDeleteSeizureEventFailure(false);

      setDeleteSeizureEventLoading(true);

      ProfileServices.deleteSeizureEvent(userID, eventID, setDeleteSeizureEventLoading, setDeleteSeizureEventFailure, null, true)
        .then(res => {
          if ((!!res.request.response && ![200, 201].includes(res.request.response.status)) || !res) {
            setDeleteSeizureEventFailure(true)
          } else {
            if (!!res && !!res.data) {
              if ((!!res.data.RETURN_CODE && ![200, 201].includes(res.data.RETURN_CODE)) || !!res.data.exception) {
                setDeleteSeizureEventFailure(true);
              } else {
                setDeleteSeizureEventSuccess(true);
              }
            }
          }
        }).then(() => {
          setFlag(!flag);
          setDeleteSeizureEventLoading(false);
        })
    }
  }


  const handleEditSeizureEvent = (eventID, queryParam, FormikActions, flag, setFlag) => {
    if (!!eventID) {
      setEditSeizureEventSuccess(false);
      setEditSeizureEventFailure(false);

      FormikActions.setSubmitting(true);

      ProfileServices.editSeizureEvent(eventID, userID, queryParam, setEditSeizureEventFailure, FormikActions)
        .then(res => {
          if ((!!res.request.response && ![200, 201].includes(res.request.response.status)) || !res) {
            setEditSeizureEventFailure(true)
          } else {
            if (!!res && !!res.data) {
              if ((!!res.data.RETURN_CODE && ![200, 201].includes(res.data.RETURN_CODE)) || !!res.data.exception) {
                setEditSeizureEventFailure(true);
              } else {
                setEditSeizureEventSuccess(true);
              }
            }
          }
        }).then(() => {
          setFlag(!flag);
          FormikActions.setSubmitting(false);
        })
    }
  }

  const [editSeizureTypeFailure, setEditSeizureTypeFailure] = React.useState(false);
  const [editSeizureTypeSuccess, setEditSeizureTypeSuccess] = React.useState(false);

  const [deleteSeizureTypeFailure, setDeleteSeizureTypeFailure] = React.useState(false);
  const [deleteSeizureTypeSuccess, setDeleteSeizureTypeSuccess] = React.useState(false);
  const [deleteSeizureTypeLoading, setDeleteSeizureTypeLoading] = React.useState(false);

  const handleDeleteSeizureType = (typeID, flag, setFlag) => {
    if (!!typeID) {
      setDeleteSeizureTypeSuccess(false);
      setDeleteSeizureTypeFailure(false);

      setDeleteSeizureTypeLoading(true);

      ProfileServices.deleteSeizureType(userID, typeID, setDeleteSeizureTypeLoading, setDeleteSeizureTypeFailure, null, true)
        .then(res => {
          if ((!!res.request.response && ![200, 201].includes(res.request.response.status)) || !res) {
            setDeleteSeizureTypeFailure(true)
          } else {
            if (!!res && !!res.data) {
              if ((!!res.data.RETURN_CODE && ![200, 201].includes(res.data.RETURN_CODE)) || !!res.data.exception) {
                setDeleteSeizureTypeFailure(true);
              } else {
                setDeleteSeizureTypeSuccess(true);
              }
            }
          }
        }).then(() => {
          setFlag(!flag);
          setDeleteSeizureTypeLoading(false);
        })
    }
  }


  const handleEditSeizureType = (typeID, patientID, queryParam, FormikActions, flag, setFlag) => {
    if (!!typeID) {
      setEditSeizureTypeSuccess(false);
      setEditSeizureTypeFailure(false);

      FormikActions.setSubmitting(true);

      ProfileServices.editSeizureType(typeID, patientID, queryParam, setEditSeizureTypeFailure, FormikActions)
        .then(res => {
          if ((!!res.request.response && ![200, 201].includes(res.request.response.status)) || !res) {
            setEditSeizureTypeFailure(true)
          } else {
            if (!!res && !!res.data) {
              if ((!!res.data.RETURN_CODE && ![200, 201].includes(res.data.RETURN_CODE)) || !!res.data.exception) {
                setEditSeizureTypeFailure(true);
              } else {
                setEditSeizureTypeSuccess(true);
              }
            }
          }
        }).then(() => {
          setFlag(!flag);
          FormikActions.setSubmitting(false);
        })
    }
  }

  const [seizureEventData, setSeizureEventData] = useState(null);
  const [seizureEventDataLoading, setSeizureEventDataLoading] = useState(false);
  const [seizureEventDataFailure, setSeizureEventDataFailure] = useState(false);
  const [seizureEventDataSuccess, setSeizureEventDataSuccess] = useState(false);

  const handleGetSeizureEventData = (eventID) => {
    if (!!userID && !!eventID) {
      setSeizureEventData(null);
      setSeizureEventDataLoading(true);
      setSeizureEventDataFailure(false);
      setSeizureEventDataSuccess(false);

      ProfileServices.getSeizureEventData(userID, eventID).then(result => {
        if (!!result && !!result.data) {
          if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
            setSeizureEventDataFailure(true)
          } else {
            setSeizureEventData(result.data.data)
            setSeizureEventDataSuccess(true)
          }
        }
      }).then(() => setSeizureEventDataLoading(false))
    }
  }

  const [requestHelpLoading, setRequestHelpLoading] = React.useState(true)
  const [requestHelpFailure, setRequestHelpFailure] = React.useState(true)


  const handleRequestHelp = (data) => {
    setRequestHelpLoading(true)
    setRequestHelpFailure(true)

    ProfileServices.requestHelp(data, userID).then((result) => {
      
        if (!!result && !!result.data) {
          if (![200, 201].includes(result.status) || !!result.data.exception) {
            setRequestHelpFailure(true)
          } else {
            setRequestHelpFailure(false)
          }
        }
      
    })
    .then(() => {
      setRequestHelpLoading(false)
    })
  }

  const [getSideEffectsLoading, setGetSideEffectsLoading] = useState(false);
  const [getSideEffectsFailure, setGetSideEffectsFailure] = useState(false);
  const [patientSideEffects, setPatientSideEffects] = useState(null)

  const handleGetSideEffects = () => {
    if(!!userID){

      setGetSideEffectsLoading(true);

      ProfileServices.getSideEffects(userID).then(result => {
        if(!!result && !!result.data) {
          if(![200, 201].includes(result.status)){
            setGetSideEffectsFailure(true);
          } else {
            setPatientSideEffects(result.data.data.side_effects);
          }
        }
      }).then(() => {
        setGetSideEffectsLoading(false);
      })
    }
  }





  const [unscheduledMedications, setUnscheduledMedications] = React.useState([])
  const [unscheduledMedicationsLoading, setUnscheduledMedicationsLoading] = React.useState(false)
  const [unscheduledMedicationsFailure, setUnscheduledMedicationsFailure] = React.useState(false)

  const [unscheduledMedicationsAddSuccess, setUnscheduledMedicationsAddSuccess] = React.useState(false)
  const [unscheduledMedicationsAddFailure, setUnscheduledMedicationsAddFailure] = React.useState(false)

  const [unscheduledMedicationsRemoveLoading, setUnscheduledMedicationsRemoveLoading] = React.useState(false)
  const [unscheduledMedicationsRemoveSuccess, setUnscheduledMedicationsRemoveSuccess] = React.useState(false)
  const [unscheduledMedicationsRemoveFailure, setUnscheduledMedicationsRemoveFailure] = React.useState(false)



  const getUnscheduledMedications = () => {
    if (!!userID) {
      setUnscheduledMedicationsLoading(true)
      setUnscheduledMedicationsFailure(false)
      setUnscheduledMedicationsRemoveLoading(false)

      ProfileServices.loadUnscheduledMedication(userID, setMedicationsLoading, setMedicationsFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setUnscheduledMedicationsFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setUnscheduledMedicationsFailure(true)
            } else {
              if (!!result && !!result.data && result.data.status.code == 204) {
                setUnscheduledMedications(null)
              } else if (!!result && !!result.data && !!result.data.data) {
                setUnscheduledMedications(result.data.data.medications)
              }
            }
          }
        }
      }).then(() => {
        setUnscheduledMedicationsLoading(false)
      })
    }
  }

  const handleRemoveUnscheduledMedication = (editableRowId) => {
    if (!!editableRowId) {
      setUnscheduledMedicationsRemoveSuccess(false)
      setUnscheduledMedicationsRemoveFailure(false)

      setUnscheduledMedicationsAddSuccess(false)
      setUnscheduledMedicationsAddFailure(false)

      setUnscheduledMedicationsRemoveLoading(true)

      ProfileServices.deleteUnscheduledMedication(userID, editableRowId, setMedicationsRemoveLoading, setMedicationsAddFailure, null, true).then((result) => {
        if ((!!result.request.response && ![200, 201].includes(result.status)) || !result) {
          setUnscheduledMedicationsAddFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setUnscheduledMedicationsRemoveFailure(true)
            } else {
              getUnscheduledMedications();
              setUnscheduledMedicationsRemoveSuccess(true)
            }
          }
        }
      }).then(() => {
        setUnscheduledMedicationsRemoveLoading(false)
      })
    }
  }

  const handleAddUnscheduledMedication = (editableRowId, values, FormikActions, flag, setFlag) => {
    if (!!userID) {
      setUnscheduledMedicationsAddSuccess(false)
      setUnscheduledMedicationsAddFailure(false)

      setUnscheduledMedicationsRemoveSuccess(false)
      setUnscheduledMedicationsRemoveFailure(false)
      
      FormikActions.setSubmitting(true)

      ProfileServices.saveUnscheduledMedication(userID, editableRowId, values, setUnscheduledMedicationsAddFailure, FormikActions).then((result) => {
        if ((!!result.request.response && ![200, 201].includes(result.status)) || !result) {
          setUnscheduledMedicationsAddFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
              setUnscheduledMedicationsAddFailure(true)
            } else {
              getUnscheduledMedications()
              setUnscheduledMedicationsAddSuccess(true)
            }
          }
        }
      }).then(() => {
        setFlag(!flag);
        FormikActions.setSubmitting(false)
      })
    }
  }

  const resetUnscheduledMedicationStatus = () => {
    setUnscheduledMedicationsFailure(false)
    setUnscheduledMedicationsRemoveSuccess(false)
    setUnscheduledMedicationsRemoveFailure(false)
    setUnscheduledMedicationsAddSuccess(false)
    setUnscheduledMedicationsAddFailure(false)
  }



  const [unscheduledMedicationHistory, setUnscheduledMedicationHistory] = React.useState({});
  const [unscheduledMedicationHistoryLoading, setUnscheduledMedicationHistoryLoading] = React.useState(false);
  const [unscheduledMedicationHistoryFailure, setUnscheduledMedicationHistoryFailure] = React.useState(false);
  const [unscheduledMedicationHistorySuccess, setUnscheduledMedicationHistorySuccess] = React.useState(false);

  const getLoadUnscheduledMedicationHistory = (queryParam) => {
    if (!!userID && !!queryParam) {
      setUnscheduledMedicationHistorySuccess(false);
      setUnscheduledMedicationHistoryFailure(false);
      setUnscheduledMedicationHistoryLoading(true);
      ProfileServices.loadUnscheduledMedicationHistory(userID, queryParam, setMedicationHistoryLoading, setMedicationHistoryFailure)
        .then(result => {
          if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
            setUnscheduledMedicationHistoryFailure(true)
          } else {
            if (!!result && !!result.data) {
              if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
                setUnscheduledMedicationHistoryFailure(true);
              } else {
                setUnscheduledMedicationHistory(result.data);
              }
            }
          }
        }).then(() => {
          setUnscheduledMedicationHistoryLoading(false);
        })
    }
  }

  const [unscheduledMedicationEventData, setUnscheduledMedicationEventData] = useState(null);
  const [unscheduledMedicationEventDataLoading, setUnscheduledMedicationEventDataLoading] = useState(false);
  const [unscheduledMedicationEventDataFailure, setUnscheduledMedicationEventDataFailure] = useState(false);
  const [unscheduledMedicationEventDataSuccess, setUnscheduledMedicationEventDataSuccess] = useState(false);

  const handleGetUnscheduledMedicationEventData = (eventID) => {
    if (!!userID && !!eventID) {
      setUnscheduledMedicationEventData(null);
      setUnscheduledMedicationEventDataLoading(true);
      setUnscheduledMedicationEventDataFailure(false);
      setUnscheduledMedicationEventDataSuccess(false);

      ProfileServices.getUnscheduledMedicationEventData(userID, eventID).then(result => {
        if (!!result && !!result.data) {
          if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
            setUnscheduledMedicationEventDataFailure(true)
          } else {
            setUnscheduledMedicationEventData(result.data.data)
            setUnscheduledMedicationEventDataSuccess(true)
          }
        }
      }).then(() => setUnscheduledMedicationEventDataLoading(false))
    }
  }



  const [saveUnscheduledMedicationHistorySuccess, setSaveUnscheduledMedicationHistorySuccess] = React.useState(false);
  const [saveUnscheduledMedicationHistoryFailure, setSaveUnscheduledMedicationHistoryFailure] = React.useState(false);


  const handleSaveUnscheduledMedicationHistory = (medicationID, values, FormikActions, flag, setFlag) => {
    if (!!userID) {
      setSaveUnscheduledMedicationHistorySuccess(false);
      setSaveUnscheduledMedicationHistoryFailure(false);

      setRemoveUnscheduledMedicationHistorySuccess(false);
      setRemoveUnscheduledMedicationHistoryFailure(false);

      FormikActions.setSubmitting(true);

      ProfileServices.saveUnscheduledMedicationHistory(userID, medicationID, values, null, setEditMedicationHistoryFailure, FormikActions)
        .then((result) => {
          if ((!!result.request.response && ![200, 201].includes(result.status)) || !result) {
            setSaveUnscheduledMedicationHistorySuccess(true)
          } else {
            if (!!result && !!result.data) {
              if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
                setSaveUnscheduledMedicationHistoryFailure(true);
              } else {
                getLoadUnscheduledMedicationHistory();
                setSaveUnscheduledMedicationHistorySuccess(true);
              }
            }
          }
        }).then(() => {
          setFlag(!flag);
          FormikActions.setSubmitting(false);
        })
    }
  }


  const [removeUnscheduledMedicationHistoryLoading, setRemoveUnscheduledMedicationHistoryLoading] = React.useState(false);
  const [removeUnscheduledMedicationHistorySuccess, setRemoveUnscheduledMedicationHistorySuccess] = React.useState(false);
  const [removeUnscheduledMedicationHistoryFailure, setRemoveUnscheduledMedicationHistoryFailure] = React.useState(false);

  const handleDeleteUnscheduledMedicationHistory = (medicationID, flag, setFlag) => {
    if (!!medicationID) {
      setRemoveUnscheduledMedicationHistorySuccess(false);
      setRemoveUnscheduledMedicationHistoryFailure(false);

      setSaveUnscheduledMedicationHistorySuccess(false);
      setSaveUnscheduledMedicationHistoryFailure(false);

      setRemoveUnscheduledMedicationHistoryLoading(true);

      ProfileServices.deleteUnscheduledMedicationHistory(userID, medicationID, setRemoveUnscheduledMedicationHistoryLoading, setRemoveUnscheduledMedicationHistoryFailure, null, true)
        .then((result) => {
          if ((!!result.request.response && ![200, 201].includes(result.status)) || !result) {
            setSaveUnscheduledMedicationHistoryFailure(true)
          } else {
            if (!!result && !!result.data) {
              if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception) {
                setRemoveUnscheduledMedicationHistoryFailure(true);
              } else {
                setRemoveUnscheduledMedicationHistorySuccess(true);
              }
            }
          }
        }).then(() => {
          setFlag(!flag);
          setRemoveUnscheduledMedicationHistoryLoading(false);
        })
    }
  }





  return (
    <ProfileContext.Provider value={{
      seizuresLoading: seizuresLoading,
      seizuresFailure: seizuresFailure,
      seizures: seizures,
      getUserSeizures,

      seizuresSuccess: seizuresSuccess,
      handleSeizuresUpdate,
      
      voiceMemosLoading: voiceMemosLoading,
      voiceMemosFailure: voiceMemosFailure,
      voiceMemos: voiceMemos,
      voiceMemosSuccess: voiceMemosSuccess,
      getLoadVoiceMemos,

      voiceMemoDataLoading: voiceMemoDataLoading,
      voiceMemoDataFailure: voiceMemoDataFailure,
      voiceMemoData: voiceMemoData,
      voiceMemoDataSuccess: voiceMemoDataSuccess,
      handleGetVoiceMemos,

      medicationHistory: medicationHistory,
      medicationHistoryLoading: medicationHistoryLoading,
      medicationHistoryFailure: medicationHistoryFailure,
      medicationHistorySuccess: medicationHistorySuccess,

      getLoadMedicationHistory,

      editMedicationHistoryFailure: editMedicationHistoryFailure,
      editMedicationHistorySuccess: editMedicationHistorySuccess,

      handleEditMedicationHistory,

      editSeizureEventFailure: editSeizureEventFailure,
      editSeizureEventSuccess: editSeizureEventSuccess,

      handleEditSeizureEvent,

      deleteSeizureEventFailure: deleteSeizureEventFailure,
      deleteSeizureEventSuccess: deleteSeizureEventSuccess,
      deleteSeizureEventLoading: deleteSeizureEventLoading,

      handleDeleteSeizureEvent,

      editSeizureTypeFailure: editSeizureTypeFailure,
      editSeizureTypeSuccess: editSeizureTypeSuccess,

      handleEditSeizureType,

      deleteSeizureTypeFailure: deleteSeizureTypeFailure,
      deleteSeizureTypeSuccess: deleteSeizureTypeSuccess,
      deleteSeizureTypeLoading: deleteSeizureTypeLoading,

      handleDeleteSeizureType,

      medicationSchedule: medicationSchedule,
      medicationScheduleLoading: medicationScheduleLoading,
      medicationScheduleFailure: medicationScheduleFailure,
      medicationScheduleSuccess: medicationScheduleSuccess,

      getLoadMedicationSchedule,


      upcomingMedications: upcomingMedications,
      upcomingMedicationsLoading: upcomingMedicationsLoading,
      upcomingMedicationsFailure: upcomingMedicationsFailure,
      upcomingMedicationsSuccess: upcomingMedicationsSuccess,
      loadUpcomingMedications,


      seizuresHistoryLoading: seizuresHistoryLoading,
      seizuresHistoryFailure: seizuresHistoryFailure,
      seizuresHistory: seizuresHistory,
      seizuresHistorySuccess: seizuresHistorySuccess,
      getLoadSeizuresHistory,


      getRecentSeizure,

      recentSeizure: recentSeizure,
      recentSeizureLoading: recentSeizureLoading,
      recentSeizureFailure: recentSeizureFailure,
      recentSeizureSuccess: recentSeizureSuccess,

      getEmergencyContacts,
      emergencyContacts: emergencyContacts,
      emergencyContactsLoading: emergencyContactsLoading,
      emergencyContactsFailure: emergencyContactsFailure,
      emergencyContactsAddSuccess: emergencyContactsAddSuccess,
      emergencyContactsAddFailure: emergencyContactsAddFailure,
      handleAddEmergencyContact,

      emergencyContactsRemoveLoading: emergencyContactsRemoveLoading,
      emergencyContactsRemoveSuccess: emergencyContactsRemoveSuccess,
      emergencyContactsRemoveFailure: emergencyContactsRemoveFailure,
      handleRemoveEmergencyContact,

      emergencyContactsSortLoading: emergencyContactsSortLoading,
      emergencyContactsSortSuccess: emergencyContactsSortSuccess,
      emergencyContactsSortFailure: emergencyContactsSortFailure,
      handleSortEmergencyContacts,

      medicationSendNotificationLoading: medicationSendNotificationLoading,
      medicationSendNotificationFailure: medicationSendNotificationFailure,
      medicationSendNotificationSuccess: medicationSendNotificationSuccess,
      handleMedicationSendNotification,

      medications: medications,
      medicationsLoading: medicationsLoading,
      medicationsFailure: medicationsFailure,
      getMedications,

      medicationsAddSuccess: medicationsAddSuccess,
      medicationsAddFailure: medicationsAddFailure,
      handleAddMedication,

      medicationsRemoveLoading: medicationsRemoveLoading,
      medicationsRemoveSuccess: medicationsRemoveSuccess,
      medicationsRemoveFailure: medicationsRemoveFailure,
      handleRemoveMedication,
      resetMedicationStatus,

      patientProfileInfo: patientProfileInfo,
      patientProfileInfoLoading: patientProfileInfoLoading,
      patientProfileInfoFailure: patientProfileInfoFailure,
      patientProfileInfoSaveSuccess: patientProfileInfoSaveSuccess,
      patientProfileInfoSaveFailure: patientProfileInfoSaveFailure,
      handlePatientProfileInfoSave,

      seizureFreeDays: seizureFreeDays,
      seizureFreeDaysLoading: seizureFreeDaysLoading,
      seizureFreeDaysFailure: seizureFreeDaysFailure,
      seizureFreeDaysSuccess: seizureFreeDaysSuccess,
      getSeizureFreeDays,


      sleepQuality: sleepQuality,
      sleepQualityLoading: sleepQualityLoading,
      sleepQualityFailure: sleepQualityFailure,
      sleepQualitySuccess: sleepQualitySuccess,
      getSleepQuality,

      sleepStats: sleepStats,
      sleepStatsLoading: sleepStatsLoading,
      sleepStatsFailure: sleepStatsFailure,
      sleepStatsSuccess: sleepStatsSuccess,
      getSleepStats,


      userData: userData,
      setUserData: setUserData,
      userDataLoading: userDataLoading,
      userDataFailure: userDataFailure,
      getUserData,

      seizureEventData: seizureEventData,
      seizureEventDataLoading: seizureEventDataLoading,
      seizureEventDataFailure: seizureEventDataFailure,
      seizureEventDataSuccess: seizureEventDataSuccess,
      handleGetSeizureEventData,

      requestHelpLoading: requestHelpLoading,
      requestHelpFailure: requestHelpFailure,
      handleRequestHelp,

      getSideEffectsFailure: getSideEffectsFailure,
      getSideEffectsLoading: getSideEffectsLoading,
      patientSideEffects: patientSideEffects,
      handleGetSideEffects,


      unscheduledMedications: unscheduledMedications,
      unscheduledMedicationsLoading: unscheduledMedicationsLoading,
      unscheduledMedicationsFailure: unscheduledMedicationsFailure,
      
      unscheduledMedicationsAddSuccess: unscheduledMedicationsAddSuccess,
      unscheduledMedicationsAddFailure: unscheduledMedicationsAddFailure,

      unscheduledMedicationsRemoveLoading: unscheduledMedicationsRemoveLoading,
      unscheduledMedicationsRemoveSuccess: unscheduledMedicationsRemoveSuccess,
      unscheduledMedicationsRemoveFailure: unscheduledMedicationsRemoveFailure,
      getUnscheduledMedications,
      handleRemoveUnscheduledMedication,
      handleAddUnscheduledMedication,
      resetUnscheduledMedicationStatus,


      unscheduledMedicationHistory: unscheduledMedicationHistory,
      unscheduledMedicationHistoryLoading: unscheduledMedicationHistoryLoading,
      unscheduledMedicationHistoryFailure: unscheduledMedicationHistoryFailure,
      unscheduledMedicationHistorySuccess: unscheduledMedicationHistorySuccess,
      getLoadUnscheduledMedicationHistory,

      unscheduledMedicationEventData: unscheduledMedicationEventData,
      unscheduledMedicationEventDataLoading: unscheduledMedicationEventDataLoading,
      unscheduledMedicationEventDataFailure: unscheduledMedicationEventDataFailure,
      unscheduledMedicationEventDataSuccess: unscheduledMedicationEventDataSuccess,
      handleGetUnscheduledMedicationEventData,

      saveUnscheduledMedicationHistorySuccess: saveUnscheduledMedicationHistorySuccess,
      saveUnscheduledMedicationHistoryFailure: saveUnscheduledMedicationHistoryFailure,
      handleSaveUnscheduledMedicationHistory,

      removeUnscheduledMedicationHistorySuccess: removeUnscheduledMedicationHistorySuccess,
      removeUnscheduledMedicationHistoryFailure: removeUnscheduledMedicationHistoryFailure,
      removeUnscheduledMedicationHistoryLoading: removeUnscheduledMedicationHistoryLoading,
      handleDeleteUnscheduledMedicationHistory,


    }}>
      {children}
    </ProfileContext.Provider>
  )
}



const ProfileConsumer = ProfileContext.Consumer

export { ProfileProvider, ProfileConsumer }
