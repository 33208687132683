import React from 'react'
import { useStyles } from './../DBStyles'
import { AuthConsumer } from '../../../../AuthContext'
import { ProfileProvider } from '../../../../ProfileContext'
import KeepingTrack from './KeepingTrack'
import SeizureFreeDays from './SeizureFreeDays'
import { Paper } from '@material-ui/core';

import ringImage from '../../../../images/ring.png';
import watchImage from '../../../../images/watch.png';

const DashboardLeftCell = () => {
  let classes = useStyles()
  return (
    <Paper className="DBGridCellTwo">
                
    <AuthConsumer >
    {({ userInfo }) => (
      !!Object.keys(userInfo).length &&
      <ProfileProvider userId={userInfo.id}>
        <SeizureFreeDays/>
      </ProfileProvider>
    )}
  </AuthConsumer>

</Paper>
  )
}

export default DashboardLeftCell