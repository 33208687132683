import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  authLogoWrapper: {
    margin: theme.spacing(4, 'auto'),
    textAlign: 'center'
  },
  authCard: {
    position: 'relative',
    width: '100%',
    maxWidth: 568,
    margin: 'auto',
    marginBottom: theme.spacing(5),
    padding: theme.spacing(5, 2),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 0px 0px 2px ${theme.palette.grey[200]}`,
    '& .MuiAlert-root': {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2),
      width: 'auto'
    }
  },
  authCardVerify: {
    '& .MuiSvgIcon-root': {
      textAlign: 'center',
      display: 'block',
      margin: 'auto',
      fontSize: theme.typography.pxToRem(64),
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2),
      '&$emailVerifiedIcon': {
        color: theme.palette.success.main
      }
    }
  },
  authCardLink: {
    lineHeight: 1,
    display: 'block',
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightLight
  },
  emailVerifiedIcon: {
    color: theme.palette.success.main
  },
  authForm: {
    margin: 'auto',
    width: '100%',
    maxWidth: 344,
    '& .MuiInputLabel-formControl': {
      top: 0,
      left: 0,
      position: 'relative',
      transform: 'none',
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightLight
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[300]
    }
  },
  authCardTitle: {
    fontSize: theme.typography.pxToRem(36),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(0),
    color: theme.palette.primary.main,
    textAlign: 'center',
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightLight
  },
  authCardDescription: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightLight,
    margin: theme.spacing(0)
  },
  formActionsWrapper: {
    textAlign: 'center',
    paddingTop: theme.spacing(1)
  },
  formActionSubmit: {
    padding: theme.spacing(1.5, 4),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    border: 'none',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:disabled': {
      opacity: 0.7,
      cursor: 'not-allowed'
    }
  },
  authCardFormControl: {
    '&.MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3)
    },
    '& .react-tel-input': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      '& .form-control': {
        paddingTop: theme.spacing(1.3125),
        paddingBottom: theme.spacing(1.3125),
        width: '100%'
      }
    }
  }

}))
