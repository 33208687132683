import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { voiceMemos } from '../../../utils/pages-meta.json'
import { NamedRoutes } from '../../../routes'
import AccountLayout from '../AccountLayout'
import VoiceMemosContent from '../../../components/Account/VoiceMemosContent'

const VoiceMemos = () => {
  return (
    <>
      <SEO title={voiceMemos.title}
           pathname={window.location.origin + NamedRoutes.account.voiceMemos}
           titleTemplate='Novela'
           image={voiceMemos.image}
           description={voiceMemos.description}
      />
      <AccountLayout content={<VoiceMemosContent/>}/>
    </>
  )
}

export default VoiceMemos
