import React from 'react'
import { useStyles } from './../../DBStyles'
import {ProfileConsumer}  from '../../../../../ProfileContext'
import UpcomingMedicaitonsFrom from './UpcomingMedicaitonsFrom'
import HelpButton from '../../HelpButton'

const UpcomingMedications = () => {
  let classes = useStyles()

  return (
    <ProfileConsumer>
    {({ loadUpcomingMedications, handleRequestHelp, requestHelpFailure, requestHelpLoading }) => (
      <>
        <UpcomingMedicaitonsFrom
          loadUpcomingMedications={loadUpcomingMedications}
          />
        <HelpButton handleRequestHelp={handleRequestHelp} requestHelpFailure={requestHelpFailure} requestHelpLoading={requestHelpLoading} />

      </>
    )}
  </ProfileConsumer>
  )
}

export default UpcomingMedications