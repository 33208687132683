import 'date-fns'
import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { fade, FormControl, Menu, MenuItem, withStyles } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { log } from '../../../utils/helpers'
import { useStyles } from './Styles'

const StyledMenu = withStyles((theme) => ({
  paper: {
    minWidth: 192,
    borderRadius: 12,
    marginTop: 13,
    boxShadow: `0 8px 16px -4px ${fade(theme.palette.common.black, 0.16)}`,
    border: `solid 1px #eaeaea`,
    backgroundColor: theme.palette.common.white,
    '& .MuiMenuItem-root': {
      fontSize: theme.typography.pxToRem(14),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      borderBottom: `1px solid #e5e5e5`
    }
  }
}))((props) => (<Menu elevation={0} getContentAnchorEl={null} anchorOrigin={{
  vertical: 'bottom',
  horizontal: 'right'
}} transformOrigin={{
  vertical: 'top',
  horizontal: 'right'
}} {...props}/>))

const ItemFilter = ({ title, setFilterDate }) => {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  let classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    console.log(anchorEl)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [selectedDate, setSelectedDate] = React.useState(today.toDateString())
  const handleDateChange = (date) => {
    setSelectedDate(date)
    log('Date Changed', date)
    setFilterDate(date)
    setAnchorEl(null)
  }
  return (
    <div className={classes.itemFilter}>
      <h2 className={classes.itemFilterTitle}>{title}</h2>
      <FormControl variant="filled" className={classes.formControl}>
        <button className={classes.itemFilterButton} onClick={handleClick}>
          {selectedDate === today.toDateString() ? 'Today' : selectedDate === yesterday.toDateString() ? 'Yesterday' : selectedDate}
          <ArrowDropDownIcon/>
        </button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem value={today.toDateString()} onClick={() => handleDateChange(today.toDateString())}>Today</MenuItem>
          <MenuItem value={yesterday.toDateString()}
                    onClick={() => handleDateChange(yesterday.toDateString())}>Yesterday</MenuItem>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="static"
              format="dd-MM-yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={selectedDate}
              onChange={(value) => handleDateChange(value.toDateString())}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
        </StyledMenu>
      </FormControl>
    </div>

  )
}
export default ItemFilter
