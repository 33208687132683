import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4)
    }
  },
  itemTitle: {
    margin: theme.spacing(0, 0, 3),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
    textTransform: 'capitalize'
  },
  itemDate: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(3)
  },
  rowItemCircleWrapper: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  rowItemCircle: {
    width: 112,
    height: 112,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rowItemTitle: {
    fontSize: theme.typography.pxToRem(50),
    color: theme.palette.primary.main,
    lineHeight: 1
  },
  rowItemSubTitle: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[500]
  },
  rowItemCircleHint: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    display: 'inline-flex'
  },
  rowItemCircleWrapperInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))
