import React from 'react'
import PropTypes from 'prop-types'
import {Tabs, Tab} from '@material-ui/core'
import EmergencyContactsTab from './EmergencyContacts'
import PersonalInfo from './PersonalInfo'
import {useStyles} from './Styles'
import Alert from '@material-ui/lab/Alert'
import { FormLoader } from '../../../Loader/FormLoader'
import { FormattedMessage } from 'react-intl'
import { AuthConsumer } from '../../../../AuthContext'
import {ProfileConsumer} from '../../../../ProfileContext'

function TabPanel (props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      className='tab'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (children)}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `patient-profile-tab-${index}`,
    'aria-controls': `patient-profile-tabpanel-${index}`
  }
}

export default function PatientProfileTabs () {
  let classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handlePatientProfileTabsChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.PatientProfileTabs}>
      <Tabs
        value={value}
        onChange={handlePatientProfileTabsChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        scrollButtons="auto"
        aria-label="Patient Profile Tabs"
      >
        <Tab label="Personal Info" {...a11yProps(0)} />
        <Tab label="Emergency contacts" {...a11yProps(1)} />
      </Tabs>
     
      <TabPanel value={value} index={0}>
        <ProfileConsumer>
          {({getUserData, userData,setUserData}) => (
            <PersonalInfo
              loadUserData={getUserData}
              userData={userData}
              setUserData={setUserData}/>
          )}
        </ProfileConsumer>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <ProfileConsumer>
          {({getEmergencyContacts}) => (
            <EmergencyContactsTab loadEmergencyContacts={getEmergencyContacts}/>
          )}
        </ProfileConsumer>
      </TabPanel>

    </div>
  )
}

