import './fonts.css'
import WebFont from 'webfontloader'

WebFont.load({
  google: {
    families: ['Ubuntu:300,400,700', 'sans-serif']
  }
})
export const Avenir = 'Ubuntu'
export const EnglishFont = Avenir
export const ArabicFont = Avenir
