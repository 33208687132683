import React from 'react'

const OthersIcon = () => {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.9 595.3" version="1.1" x="100px" y="100px"  viewBox="0 0 70 97" style={{enableBackground:"new 0 0 70 97"}} >
        <g  fill="#462d7d">
          <path fill="#462d7d" d="M30.2,21l5.8-10.1L24,3.8L6,34.9L30.2,21z"/>
          <path fill="#462d7d" d="M40,34.8l9.4-5.4l-7-12.1L12.1,34.8H40z"/>
          <path fill="#462d7d" d="M15.7,1.8h-14v34.8l14-24.2V1.8z"/>
          <path fill="#462d7d" d="M18.6,37.8h33.1v14H18.6V37.8z"/>
          <path fill="#462d7d" d="M7.7,44.8c0,0.5,0.4,1,1,1s1-0.4,1-1s-0.4-1-1-1S7.7,44.2,7.7,44.8z"/>
          <path fill="#462d7d" d="M8.7,51.8c-3.9,0-7-3.1-7-7c0-3.8,3.1-7,7-7h7v14H8.7z M8.7,48.7c2.1,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9
            s-3.9,1.7-3.9,3.9S6.5,48.7,8.7,48.7z"/>
      </g>
      </svg>
 
  )
}

export default OthersIcon
