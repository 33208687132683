import React from 'react'
import {useStyles} from './Styles'
import {Button} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import {FormattedMessage} from 'react-intl'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import {ReactComponent as Stopwatch} from '../../../../../../images/Icons/stopwatch.svg'
import MinimalImage from '../../../../../../images/SleepIntensityIcons/sleep_intensity_minimal.png'
import MildImage from '../../../../../../images/SleepIntensityIcons/sleep_intensity_mild.png'
import ModerateImage from '../../../../../../images/SleepIntensityIcons/sleep_intensity_moderate.png'
import SevereImage from '../../../../../../images/SleepIntensityIcons/sleep_intensity_severe.png'
import NoneImage from '../../../../../../images/SleepIntensityIcons/sleep_intensity_none.png'

const RowItem = ({ seizureData, handleDeleteItem, handleEditItem }) => {
  const classes = useStyles()

  const { id, day, month, duration, icon, time, type, intensity, intensity_string } = seizureData;


  return (
    <section className={classes.rowItemWrapper}>
      <div className={classes.rowItemContentWrapper}>
          <div className={classes.IconTimeWrapper}>
          <i className={icon} />
          <p className={classes.rowItemTime}>{time}</p>
        </div>
        <div className={classes.rowItemContent}>
          <div className={classes.rowItemSeizureTypeAndDuration}>
            <div className={classes.rowItemDurationWrapper}>
              <Stopwatch style={{width: '17px', height: '17px', marginTop: '1px'}} />
              <p className={classes.rowItemDuration}>{duration}</p>
            </div>
            <div className={classes.rowItemSeizureTypeContainer}>
              <p className={classes.SeizureType}>{type}</p>
              </div>
          </div>
        </div>
        <div className={classes.rowItemIntensity}>
          <div className={classes.IntensityChart}>
              {intensity == 1 ? <img src={MinimalImage} className={classes.intensityImage} /> : intensity == 2 ? <img src={MildImage} className={classes.intensityImage} /> : intensity == 3 ? <img src={ModerateImage} className={classes.intensityImage} /> : intensity == 4 ? <img src={SevereImage} className={classes.intensityImage} /> : <img src={NoneImage} className={classes.intensityImage}/> }
              
            </div>
            <p className={classes.IntensityString} style={{color: (intensity == 1 ? '#3ea883' : (intensity == 2 ? '#f0b530' : (intensity == 3 ? '#ff9034' : (intensity == 4 ? '#f75959' : '#989898'))))}}>{intensity != null ? intensity_string : "N/A"}</p>
        </div>
      </div>
      <div className={classes.actionWrapper}>

        <Button variant='text' className={classes.deleteIcon} onClick={() => handleEditItem(id)}>
          <EditIcon/>
        </Button>
        <Button variant='text' className={classes.deleteIcon} onClick={() => handleDeleteItem(id)}>
          <DeleteIcon/>
        </Button>
      </div>
    </section>
  )
}

export default RowItem
