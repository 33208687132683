import React from 'react'
import PatientProfileTabs from './Tabs'
import { ProfileProvider } from '../../../ProfileContext'
import { AuthConsumer } from '../../../AuthContext'

const PatientProfileContent = () => {
  return (
    <AuthConsumer>
      {({ userInfo }) => (
        !!Object.keys(userInfo).length &&
        <ProfileProvider userId={userInfo.id}>
          <PatientProfileTabs/>
        </ProfileProvider>
      )}
    </AuthConsumer>
  )
}

export default PatientProfileContent
