import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  eBookContent: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 0px 0px 2px ${theme.palette.grey[200]}`
  }
}))
