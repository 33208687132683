import React from 'react'
import { useStyles } from './DBStyles'
import DashboardLeftCell from './DashboardLeftCell'
import DashboardMiddleCell from './DashboardMiddleCell'
import DashboardRightCell from './DashboardRightCell'
import "./styles.scss"

const DashboardContent = ({ getWatchData }) => {
  let classes = useStyles()

  React.useEffect(() => {
    if (!!getWatchData) {
      getWatchData()
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  return (
    <>
      <div className="dashboardContent" >
        <div className="DashboardGrid">
          <DashboardLeftCell />
            <DashboardMiddleCell />
            <DashboardRightCell />
        </div>

      </div>
    </>
  )
}

export default DashboardContent
