import React from 'react'
import PropTypes from 'prop-types'
import {Tabs, Tab} from '@material-ui/core'
import MedicationTab from './Medication'
import {useStyles} from './Styles'
import {ProfileConsumer} from '../../../../ProfileContext'
import UpcomingMedicationsTab from './UpcomingMedications'
import MedicationHistoryTab from './MedicationHistory'

function TabPanel (props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      className='tab'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (children)}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `Medications-tab-${index}`,
    'aria-controls': `Medications-tabpanel-${index}`
  }
}

export default function MedicationsTabs () {
  let classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleMedicationsTabsChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.MedicationsTabs}>
      <Tabs
        value={value}
        onChange={handleMedicationsTabsChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        scrollButtons="auto"
        aria-label="Medications Tabs"
      >
        <Tab label="Medication History" {...a11yProps(0)} />
        <Tab label="Scheduled Medications" {...a11yProps(1)} />
        <Tab label="Customize My Medications" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ProfileConsumer>
          {({getLoadMedicationHistory, handleEditMedicationHistory, getLoadUnscheduledMedicationHistory, handleSaveUnscheduledMedicationHistory, handleDeleteUnscheduledMedicationHistory, getUnscheduledMedications, unscheduledMedications }) => (
            <MedicationHistoryTab
            loadMedicationHistory={getLoadMedicationHistory}
            editMedicationHistory={handleEditMedicationHistory}
            loadUnscheduledMedicationHistory={getLoadUnscheduledMedicationHistory}
            saveUnscheduledMedicationHistory={handleSaveUnscheduledMedicationHistory}
            deleteUnscheduledMedicationHistory={handleDeleteUnscheduledMedicationHistory}
            getUnscheduledMedications={getUnscheduledMedications}
            unscheduledMedications={unscheduledMedications}
            />
          )}
        </ProfileConsumer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProfileConsumer>
          {({getLoadMedicationSchedule}) => (
            <UpcomingMedicationsTab loadMedicationSchedule={getLoadMedicationSchedule}/>
          )}
        </ProfileConsumer>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ProfileConsumer>
          {({getMedications, medications, resetMedicationStatus, getUnscheduledMedications, unscheduledMedications, resetUnscheduledMedicationStatus}) => (
            <MedicationTab
              loadMedications={getMedications}
              medications={medications}
              resetMedicationStatus={resetMedicationStatus}
              getUnscheduledMeds={getUnscheduledMedications}
              unscheduledMeds={unscheduledMedications}
              resetUnscheduledMedicationStatus={resetUnscheduledMedicationStatus}
              />
          )}
        </ProfileConsumer>
      </TabPanel>
    </div>
  )
}
