import { fade, makeStyles } from '@material-ui/core/styles'
import zIndex from '@material-ui/core/styles/zIndex'
import { Block, BorderLeft, Directions } from '@material-ui/icons'

export const useStyles = makeStyles((theme) => ({
  DailySleepStatsPage:{
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    zIndex: 10,
    marginTop: '3px',
    zoom: '90%',
    '@media (max-width: 815px)': {
      zoom: '85%',
    },
  },
  Underline: {
    height: '1px',
    width: '100%',
    backgroundColor: '#462d7d',
    boxShadow: '1px 0px 1px 1px #e6e6e6',
  },
  //header
  DailyStatsHeader: {
    backgroundColor: '#462d7d',
    width: '92.5%',
    position: 'fixed',
    marginTop: "-30px",
    zIndex: 3,
    display: 'flex',
    justifyContent: 'flex-end',
    border: '0px solid transparent',
    borderRadius: '5px 5px 0px 0px',
  },
  BackButton: {
    backgroundColor: '#462d7d',
    border: 'none',
    color: '#FFFFFF',
    marginTop: '12px',
    marginBottom: '8px',
    marginRight: '20px',
  },
  DailyStatsDate: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: '200',
    letterSpacing: '2px',
    fontSize: '18px',
    display: 'inline',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  DailySleepStatsSection: {
    marginTop: '30px',
    display: "flex",
    justifyContent: 'space-evenly',
  },

  DetailStatsContainer: {
    width: '650px',
  },
  DailySleepDuration: {
    textAlign: 'center',
  },

  BasicStatsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 0,
    marginLeft: "50px",
    width: '200px',

  },
  ScoreChartContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px'
  },

  StatHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  HeaderLabels: {
    fontWeight: '200',
    marginBottom: '0px',
    marginLeft: '3px',
    marginTop: '20px',
    color: '#462d7d',
  },
  ChartButton: {
    width: '120px',
    height: '40px',
    borderRadius: '50px',
    border: 'none',
    fontSize: '16px',
    marginRight: '10px',
    marginTop: '5px',
  },
  StatChart: {
    marginTop: "20px"
  },


  SleepDurationLabel: {
    textAlign: 'center',
  },
  StartEndTimes: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  SleepTimeLabel: {
    textAlign: 'center',
    marginLeft: "10px",
    marginRight: "10px"
  },
  SleepTime: {
    textAlign: 'center',
  },

  //table
  NoBorderCells: {
    borderBottom: 'unset',
  },
  SleepAlertTableContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "20px"
  },
  AlertTableBox: {
    width: "100%",
    marginBottom: "50px",
  },
  AlertTable: {
    marginTop: "10px"
  },
  TableTopHead: {
    backgroundColor: "#462d7d",
  },
  TableTopHeadLabel: {
    color: "#FFFFFF",
    fontSize: "17px",
    fontWeight: "600"
  },
  ArrowIcons: {
    fontSize: "2.5rem",
  }
}))
