import React from 'react'
import { useStyles } from './../../DBStyles'

import {ProfileConsumer}  from '../../../../../ProfileContext'
import moment from "moment"
import {ReactComponent as Stopwatch} from '../../../../../images/Icons/stopwatch.svg'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import SeizureIcon from '../../../../../images/Icons/seizure.svg'
import {Link} from 'react-router-dom'
import {NamedRoutes} from '../../../../../routes'




const SeizureFreeDaysForm = ({ loadRecentSeizure, getUserSeizures, seizures }) => {
  

  // Immediately fetches seizure free days data
  React.useEffect(() => {
    if (!!loadRecentSeizure) {
      // loadSeizureFreeDays();
      loadRecentSeizure();
      getUserSeizures();
    }
  },
  []);

  const [selectedDate, setSelectedDate] = React.useState(moment());

  // Fetches seizure free days data every 1 minute
  React.useEffect(() => {
      if (!!loadRecentSeizure) {
        setInterval(() => {
          setSelectedDate(moment());
          // loadSeizureFreeDays();
          loadRecentSeizure();
        }, 60000);
      }
    },
    [])

  let classes = useStyles()

  const [warningLabel, setWarningLabel] = React.useState(false);

  const AddSeizureLink = () => {
    return (
      <>
      {!!seizures &&
        <>
        {seizures.length >= 1 ?
          <section className={classes.NewSeizureLinkSection}>
            <Link to={{pathname: NamedRoutes.account.seizures, state: {editSeizure: true}}}>
              <button className={classes.NewSeizureLink}>
                <span className={classes.NewSeizurePlus}>+</span>Log New Seizure Event
              </button>
            </Link>
          </section>
          :
          <section className={classes.DisabledButtonAndWarningSection}>
            <button
              className={classes.NewSeizureLink}
              style={{opacity: 0.5, cursor: "not-allowed"}}
              onClick={() => {
                setWarningLabel(true);
              }}>
              <span className={classes.NewSeizurePlus}>+</span>Log New Seizure Event
            </button>
            {warningLabel &&
            <p className="addSeizureWarning">No seizure types are available.</p>
            }
          </section>
        }
        </>
      }
      
        
      </>
    )
  }
 
  return (
    <>
    <ProfileConsumer>
      {({  recentSeizure }) => {



        let recentSeizureTime = ""

        let name = ""

        let calcFreeDays = "";

        if (recentSeizure && recentSeizure != undefined) {
          recentSeizureTime = moment(`${recentSeizure.seizure_timestamp}`)
          name = recentSeizure.seizure_custom_name
          calcFreeDays = moment().diff(moment(recentSeizure.seizure_timestamp), 'd');
        }
    

          return (
            <>
              {!recentSeizure ?
              <>
                <div className={classes.DBGridHeader}>
                  <div className={classes.HeaderIcon}>
                    <i className="icon-seizure" />
                  </div>
                  <p className={classes.DBGridHeaderString}>Seizures</p>
                </div>
                <div className={classes.DBNoSeizuresContainer}>
                  <p className={classes.DBNoSeizuresString}>No recent seizures found!</p>
                </div>
                <AddSeizureLink />
              </>
              :
              (
                <>
                <div className={classes.DBGridHeader}>
                  <div className={classes.HeaderIcon}>
                    <i className="icon-seizure" />
                  </div>
                  <p className={classes.DBGridHeaderString}>Seizures</p>
                </div>
                  <div className={classes.DBGridCellTwoFooterBody}>
                    <div style={{width:"100%",flexDirection:"column",justifyContent: "center",alignItems:"center",display: "flex"}}>
                      <div className={classes.DBGridCellTwoSeizureFreeStreak}>{calcFreeDays}</div>
                      <p className={classes.DBGridCellTwoSeizureFreeString}>Seizure Free <span>{calcFreeDays <= 1 ? "Day": "Days"}</span></p>
                      <p className={classes.DBGridCellTwoFreeSinceString}>Since <span>{recentSeizure != undefined ? recentSeizureTime.format("MMMM Do, YYYY"): " "}</span></p>
                    </div>
                  </div>
                  <div className={classes.Divider}></div>
                  <section className={classes.RecentSeizureEventsContainer}>
                    <h3 className={classes.RecentSeizureEventsHeader}>Recent Seizure Event :</h3>
                    <div className={classes.EventsDateContainer}>
                      <div className={classes.RecentSeizureIconDateWrapper}>
                        {recentSeizureTime.format("A") === 'AM' ? <i className="icon-day" /> : <i className="icon-night" />}
                        <p className={classes.RecentSeizureDate}>
                          {recentSeizure != undefined ? recentSeizureTime.format("ddd, MMM Do - hh:mm A") : "Data not available"}
                        </p>
                      </div>
                    </div>

                    
                    <div className={classes.RecentSeizureEventsTypeContainer}>
                      <i className="icon-seizure" />
                      <p className={classes.TypeLabel}>Type</p>
                      <p style={{marginBottom: "7px"}}>:</p>
                      <div className={classes.TypeString}>
                        {recentSeizure != undefined && name != undefined ?
                          <>
                            {name.length > 13 ?
                            <>
                              <p className={classes.TypeString}>
                                {name.slice(0,13)}<span>...</span>
                              </p>
                            </>
                            :
                            <>
                              <p className={classes.TypeString}>
                                {name}
                              </p>
                            </>}
                          </>
                        :
                        <></>
                      }
                      </div>
                    </div>
                    <div className={classes.RecentSeizureEventsDurationContainer}>
                      <Stopwatch style={{width: '17px', height: '17px', marginTop: "2px", marginRight: "7px", gridColumn: "2 / span 1"}} />
                      <p className={classes.DurationLabel}>Duration</p>
                      <p style={{marginBottom: "7px"}}>:</p>
                      <p className={classes.DurationString}>{recentSeizure.duration != null ? recentSeizure.duration + ' sec' : "N/A"}</p>
                    </div>
                  </section>
                  <AddSeizureLink />
                </>
              )}
            </>
          )
      }}
    </ProfileConsumer>
  
    </>
  )
}

export default SeizureFreeDaysForm