import React from 'react'
import {ProfileConsumer}  from '../../../../../ProfileContext'
import SeizureFreeDaysForm from './SeizureFreeDaysForm'


const SeizureFreeDays = () => {
  return (
    <ProfileConsumer>
    {({getSeizureFreeDays, getRecentSeizure, getUserSeizures, seizures}) => (
      <SeizureFreeDaysForm
        loadSeizureFreeDays={getSeizureFreeDays}
        loadRecentSeizure={getRecentSeizure}
        getUserSeizures={getUserSeizures}
        seizures={seizures}
      />
    )}
  </ProfileConsumer>
  )
}

export default SeizureFreeDays