import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { medications } from '../../../utils/pages-meta.json'
import { NamedRoutes } from '../../../routes'
import AccountLayout from '../AccountLayout'
import MedicationsContent from '../../../components/Account/MedicationsContent'

const Medications = () => {
  return (
    <>
      <SEO title={medications.title}
           pathname={window.location.origin + NamedRoutes.account.medications}
           titleTemplate='Novela'
           image={medications.image}
           description={medications.description}
      />
      <AccountLayout content={<MedicationsContent/>}/>
    </>
  )
}

export default Medications
