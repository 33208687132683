import React from 'react'
import {FormattedMessage} from 'react-intl'
import {useStyles} from './Styles'

const StreakItem = ({currentStreak, longestStreak, title}) => {
  let classes = useStyles()

  return (
    <div className={classes.itemWrapper}>
      <h2 className={classes.itemTitle}>{title}</h2>
      <div className={classes.rowItemCircleWrapper}>
        <div className={classes.rowItemCircleWrapperInner}>
          <div className={classes.rowItemCircle}>
            <span className={classes.rowItemTitle}>{currentStreak}</span>
            <span className={classes.rowItemSubTitle}>
            <FormattedMessage id='StreakItem.SubTitle'/>
          </span>
          </div>
          <span className={classes.rowItemCircleHint}><FormattedMessage id='StreakItem.currentStreak'/></span>
        </div>
        <div className={classes.rowItemCircleWrapperInner}>
          <div className={classes.rowItemCircle}>
            <span className={classes.rowItemTitle}>{longestStreak}</span>
            <span className={classes.rowItemSubTitle}>
              <FormattedMessage id='StreakItem.SubTitle'/>
            </span>
          </div>
          <span className={classes.rowItemCircleHint}><FormattedMessage id='StreakItem.longestStreak'/></span>
        </div>
      </div>
    </div>
  )
}

export default StreakItem
