import React from 'react'
import {useFormik} from 'formik'
import {FormattedMessage} from 'react-intl'
import { FormControl, FormHelperText, InputLabel, TextField } from '@material-ui/core'
import {EmergencyContactsFormValidation} from '../../../../../utils/validationSchema'
import {FormLoader} from '../../../../Loader/FormLoader'
import {useStyles} from './Styles'
import PhoneInput from "react-phone-input-2"

const EmergencyContactsForm = ({onSubmit}) => {
  let classes = useStyles()
  const emergencyContactsForm = useFormik({
    initialValues: {
      contact_name: '',
      contact_mobile: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: EmergencyContactsFormValidation,
    onSubmit: ((values, actions) => {
      onSubmit(values, actions)
    })
  })

  return (
    <form onSubmit={emergencyContactsForm.handleSubmit} noValidate autoComplete="off"
          className={classes.EmergencyContactsForm}>
      <FormControl>
        <InputLabel htmlFor="contact_name">
          <FormattedMessage id='emergencyContactsForm.InputLabel.name'/>
        </InputLabel>
        <TextField
          id="contact_name"
          required
          fullWidth
          margin="dense"
          type="text"
          name='contact_name'
          variant="outlined"
          onChange={emergencyContactsForm.handleChange}
          onBlur={emergencyContactsForm.handleBlur}
          value={emergencyContactsForm.values.contact_name}
          helperText={emergencyContactsForm.errors.contact_name && emergencyContactsForm.touched.contact_name && emergencyContactsForm.errors.contact_name}
          error={emergencyContactsForm.errors.contact_name && emergencyContactsForm.touched.contact_name !== undefined}
          inputProps={{
            autoComplete: 'new-name',
            form: {
              autoComplete: 'off'
            }
          }}
        />
      </FormControl>

      <FormControl className={classes.authCardFormControl}>
        <InputLabel htmlFor="contact_mobile">
          <FormattedMessage id='emergencyContactsForm.InputLabel.phone'/>
        </InputLabel>
        <PhoneInput
          value={emergencyContactsForm.values.contact_mobile}
          enableSearch={true}
          country={'us'}
          specialLabel={''}
          inputProps={{
            name: 'contact_mobile',
            required: true
          }}
          onChange={(phone) => {
            emergencyContactsForm.setFieldValue('contact_mobile', phone.toString())
            emergencyContactsForm.handleChange(phone)
          }}
          onBlur={emergencyContactsForm.handleBlur}
          isValid={(value, country) => {
            return (!!value)
          }}
        />
        {emergencyContactsForm.errors.contact_mobile && emergencyContactsForm.touched.contact_mobile &&
        <FormHelperText error={true}>{emergencyContactsForm.errors.contact_mobile}</FormHelperText>
        }
      </FormControl>

      <div className={classes.formActionsWrapper}>
        <button disabled={emergencyContactsForm.isSubmitting || !emergencyContactsForm.isValid}
                className={classes.formActionSubmit}>
          <FormattedMessage id='EmergencyContactsForm.button.submit'/>
        </button>
      </div>
      {emergencyContactsForm.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default EmergencyContactsForm
