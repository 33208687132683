import React from 'react'
import { useStyles } from './Styles'
import { FormattedMessage } from 'react-intl'
import EmergencyContactsForm from './Form'
import List from './List'
import { ProfileConsumer } from '../../../../../ProfileContext'
import Alert from '@material-ui/lab/Alert'
import { SearchLoader } from '../../../../Loader/SearchLoader'
import { FormLoader } from '../../../../Loader/FormLoader'

const EmergencyContactsTab = ({ loadEmergencyContacts }) => {
  let classes = useStyles()
  React.useEffect(() => {
      if (!!loadEmergencyContacts) {
        loadEmergencyContacts()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  return (
    <div className={classes.EmergencyContactsTab}>
      <h1 className={classes.EmergencyContactsFormTitle}><FormattedMessage id='EmergencyContactsForm.title'/></h1>
      <ProfileConsumer>
        {({
            emergencyContactsLoading, emergencyContactsFailure, emergencyContacts,
            handleAddEmergencyContact, emergencyContactsAddSuccess, emergencyContactsAddFailure,
            handleRemoveEmergencyContact, emergencyContactsRemoveLoading, emergencyContactsRemoveSuccess,
            emergencyContactsRemoveFailure, handleSortEmergencyContacts, emergencyContactsSortLoading,
            emergencyContactsSortSuccess, emergencyContactsSortFailure
          }) => (
          <>
            {!!emergencyContactsAddSuccess &&
            <Alert severity="success"><FormattedMessage id='EndPoint.EmergencyContact.Success'/></Alert>}
            {!!emergencyContactsAddFailure &&
            <Alert severity="error"><FormattedMessage id='EndPoint.EmergencyContact.Failure'/></Alert>}
            <EmergencyContactsForm onSubmit={handleAddEmergencyContact}/>

            {!!emergencyContactsFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}


            {!!emergencyContactsRemoveSuccess &&
            <Alert severity="success"><FormattedMessage id='EndPoint.EmergencyContact.Remove.Success'/></Alert>}
            {!!emergencyContactsRemoveFailure &&
            <Alert severity="error"><FormattedMessage id='EndPoint.EmergencyContact.Remove.Failure'/></Alert>}
            {!!emergencyContactsRemoveLoading && <FormLoader loading={true}/>}


            {!!emergencyContactsSortSuccess &&
            <Alert severity="success"><FormattedMessage id='EndPoint.EmergencyContact.Sort.Success'/></Alert>}
            {!!emergencyContactsSortFailure &&
            <Alert severity="error"><FormattedMessage id='EndPoint.EmergencyContact.Sort.Failure'/></Alert>}
            {!!emergencyContactsSortLoading && <FormLoader loading={true}/>}


            {!!emergencyContactsLoading ? <SearchLoader loading={true}/> :
              (!!emergencyContacts && !!emergencyContacts.length &&
                <List data={emergencyContacts} handleDeleteRow={handleRemoveEmergencyContact}
                      handleSort={handleSortEmergencyContacts}/>
                      
              )
            }
          </>
        )}
      </ProfileConsumer>
    </div>
  )
}

export default EmergencyContactsTab
