import { red } from '@material-ui/core/colors'
import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  dayShortcuts: {
    display: 'flex',
    justifyContent: 'center'
  },

  calendarContainer: {
    width: '35%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', paddingTop: 0,
    paddingLeft: '20px',
  },

  itemFilter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiPickersStaticWrapper-staticWrapperRoot': {
    },
    '& .MuiPickersBasePicker-container': {
      position: 'absolute'
    }
  },
  itemFilterButton: {
    minWidth: 200,
    padding: theme.spacing(1),
    backgroundColor: `#f9fafb`,
    border: `1px solid #e5e5e6`,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))
