import { fade, makeStyles } from '@material-ui/core/styles'
import zIndex from '@material-ui/core/styles/zIndex'
import { Block, BorderLeft, Directions } from '@material-ui/icons'

export const useStyles = makeStyles((theme) => ({
  SummaryPage : {
    borderLeft: "3px solid #eeeeee",
    minWidth: "100%",
    padding: 0,
    margin: 0,
    fontFamily: "Ubuntu",
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  WeeklyScores: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 500,
    left: 125,
    overflow: "hidden",
    '@media (max-width: 815px)': {
      top: 60,
      left: 20,
      transform: 'scale(0.85)',
    }
  },
  WeeklyScoresHeader: {
    fontWeight: "400",
    marginBottom: 20,
    marginTop: "20px",
    '@media (max-width:815px)': {
      display: 'none'
    }
  },
  RowWeekList: {
    display: "flex",
    flexDirection: "column",
    marginTop: "-2px",
    backgroundColor: "#f2f2f2",
  },
  RowWeekListPosition: {
    backgroundColor: "#efefef",
    minHeight: "58vw",
    minWidth: "100%"
  },
  WeekList: {
    overflowX: "hidden",
    overflowY: "auto",
  },
  DayList: {
    cursor: "pointer",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    width: 350,
    height: 100,
    borderBottom: "3px solid #E6E6E6",
    borderLeft: "6px solid #E6E6E6",
    borderTop: "3px solid #E6E6E6",
    borderRight: "6px solid #E6E6E6",
    backgroundColor: "#FFFFFF",
    transition: "ease 200ms",
    '&:hover, &:active': {
      backgroundColor: "#fdfcff",
    },
  },
  DateTimeContainer: {
    marginLeft: "12px",
  },
  ScoreCircle: {
    width: 45,
    height: 45,
    borderRadius: 50,
    display: "inline-block",
    fontSize: "21px",
    textAlign: "center",
    color: "#FFFFFF",
    lineHeight: "2.2em",
    fontWeight: "light",
    marginTop: "5px"
  },
  SleepDate: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(22),
    color: "#462d7d",
    margin: 0,
    '@media (max-width: 815px)' : {
      fontSize: theme.typography.pxToRem(19),
    }
  },
  SleepInterval: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    color: "#adadad",
    marginBottom: "-4px",
    marginTop: 2,
  },
  SleepDuration: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    color: "#462d7d",
    margin: 0,
  },
  ScoreContainer: {
    maxWidth: 70,
    minWidth: 70,
    minHeight: 100,
    maxHeight: 100,
    marginRight: "7px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center"
  },
  Score: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    marginRight: 25,
  },
  ScoreCircle: {
    width: 45,
    height: 45,
    borderRadius: 50,
    display: "inline-block",
    fontSize: "21px",
    textAlign: "center",
    color: "#FFFFFF",
    lineHeight: "2.2em",
    fontWeight: "light",
    marginTop: "5px",
    marginBottom: 5,
  },
  ScoreString: {
    marginTop: 0,
    marginBottom: 0,
    textAlign: "center",
    wordWrap: "wrap",
    lineHeight: 1,
    width: 77,
  },
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    display: 'hidden',
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  ItemFilter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
  },
  itemFilterButton: {
    minWidth: 280,
    padding: theme.spacing(1),
    backgroundColor: `#f9fafb`,
    border: `1px solid #e5e5e6`,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nonCurrentMonthDay: {
    color: "#ffffff",
    cursor: "context-menu",
      '&:hover': {
        color: "#ffffff"
      }
  },
}))
