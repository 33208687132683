import React from 'react'
import ProfileServices from '../../../../../Services/Consumers/ProfileServices'

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useStyles } from './Styles'
import { ControlCamera } from '@material-ui/icons';

const SleepAlerts = ({ selectedDailyStats }) => {

  const classes = useStyles()
  const [heartRateUnder35Open, setHeartRateUnder35Open] = React.useState(false);
  const [heartRateOver120Open, setHeartRateOver120Open] = React.useState(false);
  const [heartRateSuddenOpen, setHeartRateSuddenOpen] = React.useState(false);
  const [spoUnder86Open, setSpoUnder86Open] = React.useState(false);
  const [spoUnder90Open, setSpoUnder90Open] = React.useState(false);


  const [highHrAlert, setHighHrAlert] = React.useState([])
  const [lowHrAlert, setLowHrAlert] = React.useState([])
  const [avgHrAlert, setAvgHrAlert] = React.useState([])
  const [immediateSpoAlert, setImmediateSpoAlert] = React.useState([])
  const [longtermSpoAlert, setLongtermSpoAlert] = React.useState([])


  const getSleepAlerts = async () => {

    let userID = localStorage.getItem('PatientID')
    let sleepLogsID = selectedDailyStats.sleep_logs_id

    const response = await ProfileServices.sleepAlerts(userID, sleepLogsID)
    const sleepAlerts = response.data.data

    if (sleepAlerts != null) {
      setLowHrAlert(sleepAlerts.low_hr_alert)
      setAvgHrAlert(sleepAlerts.avg_hr_alert)
      setHighHrAlert(sleepAlerts.high_hr_alert)
      setImmediateSpoAlert(sleepAlerts.immediate_spo2_alert)
      setLongtermSpoAlert(sleepAlerts.longeterm_spo2_alert)
    }
  }

  React.useEffect(() => {
    getSleepAlerts()
    },
  []);


  const formatTime = (time) => {
    let hours24 = parseInt(time.substring(17, 19))
    let hours = ((hours24 + 11) % 12) + 1
    let amPm = hours24 > 11 ? 'PM' : 'AM'
    let minutes = time.substring(20, 22)
    let formattedTime = `${hours}:${minutes} ${amPm}`
    return formattedTime
  }
  
  
  function rows(props){
    return(
      <>
      {
        props.map((row)=>{
          return (
            <>
              <TableRow>
                <TableCell className={classes.NoBorderCells}></TableCell>
                <TableCell className={classes.NoBorderCells}></TableCell>
                <TableCell align="center">{props.indexOf(row) + 1}</TableCell>
                <TableCell align="center">{row.extreame_value != null ? row.extreame_value: "N/A"}</TableCell>
                <TableCell align="center">{row.start_timestamp != null ? formatTime(row.start_timestamp): "N/A"}</TableCell>
                <TableCell align="center" style={{padding: "15px 0"}}>{row.end_timestamp != null ? formatTime(row.end_timestamp): "N/A"}</TableCell>
              </TableRow>
            </>
          )
        })     
      }
      </>      
    )
  }


  function HeartRateUnder35Row(props) {
  return (
    <Box margin={1}>
      <Table size="small" aria-label="details">
        <TableHead>
          <TableRow>
            <TableCell className={classes.NoBorderCells}></TableCell>
            <TableCell className={classes.NoBorderCells}></TableCell>
            <TableCell>#</TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>Lowest Heart Rate</p></TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>Start Time</p></TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>End Time</p></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
            {rows(props.props)}
        </TableBody>
      </Table>
    </Box>
  )}

  function HeartRateOver120Row(props) {
  return (
    <Box margin={1}>

      <Table size="small" aria-label="details">
        <TableHead>
          <TableRow>
            <TableCell className={classes.NoBorderCells}></TableCell>
            <TableCell className={classes.NoBorderCells}></TableCell>
            <TableCell>#</TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>Highest Heart Rate</p></TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>Start Time</p></TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>End Time</p></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows(props.props)}
        </TableBody>
      </Table>
    </Box>
  )}

  function SuddenHeartRateRow(props) {
  return (
    <Box margin={1}>

      <Table size="small" aria-label="details">
        <TableHead>
          <TableRow>
            <TableCell className={classes.NoBorderCells}></TableCell>
            <TableCell className={classes.NoBorderCells}></TableCell>
            <TableCell>#</TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>Irregular Heart Rate</p></TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>Start Time</p></TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>End Time</p></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
            {rows(props.props)}
        </TableBody>
      </Table>
    </Box>
  )}



  function SpoUnder86Row(props) {
  return (
    <Box margin={1}>

      <Table size="small" aria-label="details">
        <TableHead>
          <TableRow>
            <TableCell className={classes.NoBorderCells}></TableCell>
            <TableCell className={classes.NoBorderCells}></TableCell>
            <TableCell>#</TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>Lowest SpO2<br />(Blood Oxygen Level)</p></TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>Start Time</p></TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>End Time</p></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows(props.props)}
        </TableBody>
      </Table>
    </Box>
  )}



  function SpoUnder90Row(props) {
  return (
    <Box margin={1}>

      <Table size="small" aria-label="details">
        <TableHead>
          <TableRow>
            <TableCell className={classes.NoBorderCells}></TableCell>
            <TableCell className={classes.NoBorderCells}></TableCell>
            <TableCell>#</TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>Lowest SpO2<br />(Blood Oxygen Level)</p></TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>Start Time</p></TableCell>
            <TableCell align="center"><p style={{fontWeight: "600", margin: "7px 0"}}>End Time</p></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
            {rows(props.props)}
        </TableBody>
      </Table>
    </Box>
  )}
  

  return (
    <>
    <div className={classes.AlertTableBox}>
      <h2 className={classes.HeaderLabels}>Sleep Alerts</h2>
      <div className={classes.Underline} />
      <TableContainer component={Paper} className={classes.AlertTable}>
        <Table aria-label="heart rate collapsible table">
          <TableHead className={classes.TableTopHead}>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell className={classes.NoBorderCells} align="center" className={classes.TableTopHeadLabel}><p style={{fontSize: "18px", fontWeight: "400", margin: "0", padding: '0px'}}>Type of Alerts</p></TableCell>
              <TableCell className={classes.NoBorderCells} align="center" className={classes.TableTopHeadLabel}><p style={{fontSize: "18px", fontWeight: "400", margin: "0", padding: '0px'}}>Frequency</p></TableCell>
              <TableCell className={classes.NoBorderCells} />
            </TableRow>
          </TableHead>


          <TableBody className={classes.HeartRateUnder35RowBody}>
            <TableRow>
              <TableCell className={classes.NoBorderCells} />
              <TableCell className={classes.NoBorderCells} style={{padding: '0px'}}>
                {lowHrAlert == '' || lowHrAlert == null ?
                <IconButton size="big" disabled="true">
                <KeyboardArrowRightIcon className={classes.ArrowIcons} />
                </IconButton>
                :
                <IconButton aria-label="expand row" size="big" onClick={() => setHeartRateUnder35Open(!heartRateUnder35Open)} >
                  {heartRateUnder35Open ? <KeyboardArrowDownIcon className={classes.ArrowIcons} /> : <KeyboardArrowRightIcon className={classes.ArrowIcons} />}
                </IconButton>
                }
              </TableCell>
              <TableCell className={classes.NoBorderCells} className={classes.NoBorderCells} align="left"><p style={{fontSize: "16px", padding: '0px', margin: 0}}>Low Heart Rate Alert</p></TableCell>
              <TableCell className={classes.NoBorderCells} className={classes.NoBorderCells} align="center"><p style={{fontSize: "16px", margin: 0}}>{lowHrAlert != null ? lowHrAlert.length:<span>0</span>}</p></TableCell>
              <TableCell className={classes.NoBorderCells} />
            </TableRow>

            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={heartRateUnder35Open} timeout="auto" unmountOnExit>
                  <HeartRateUnder35Row props={lowHrAlert}/>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>



          <TableBody className={classes.HeartRateOver120Body}>
            <TableRow>
              <TableCell className={classes.NoBorderCells} />
              <TableCell className={classes.NoBorderCells} style={{padding: '0px'}}>
                {highHrAlert == '' || highHrAlert == null ?
                <IconButton size="big" disabled="true">
                <KeyboardArrowRightIcon className={classes.ArrowIcons} />
                </IconButton>
                :
                <IconButton aria-label="expand row" size="big" onClick={() => setHeartRateOver120Open(!heartRateOver120Open)}>
                  {heartRateOver120Open ? <KeyboardArrowDownIcon className={classes.ArrowIcons} /> : <KeyboardArrowRightIcon className={classes.ArrowIcons} />}
                </IconButton>
                }

              </TableCell>
              <TableCell className={classes.NoBorderCells} className={classes.NoBorderCells} align="left"><p style={{fontSize: "16px", padding: '0px', margin: 0}}>High Heart Rate Alert</p></TableCell>
              <TableCell className={classes.NoBorderCells} align="center"><p style={{fontSize: "16px", margin: 0}}>{highHrAlert != null ? highHrAlert.length:<span>0</span>}</p></TableCell>
              <TableCell className={classes.NoBorderCells} />
            </TableRow>

            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={heartRateOver120Open} timeout="auto" unmountOnExit>
                  <HeartRateOver120Row props={highHrAlert}/>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>



          <TableBody className={classes.HeartRateSuddenChangeBody}>
            <TableRow>
              <TableCell className={classes.NoBorderCells} />
              <TableCell className={classes.NoBorderCells} style={{padding: '0px'}}>
                {avgHrAlert == '' || avgHrAlert == null ?
                <IconButton size="big" disabled="true">
                <KeyboardArrowRightIcon className={classes.ArrowIcons} />
                </IconButton>
                :
                <IconButton aria-label="expand row" size="big" onClick={() => setHeartRateSuddenOpen(!heartRateSuddenOpen)}>
                  {heartRateSuddenOpen ? <KeyboardArrowDownIcon className={classes.ArrowIcons} /> : <KeyboardArrowRightIcon className={classes.ArrowIcons} />}
                </IconButton>
                }
                
              </TableCell>
              <TableCell className={classes.NoBorderCells} className={classes.NoBorderCells} align="left"><p style={{fontSize: "16px", padding: '0px', margin: 0}}>Sudden Heart Rate Change Alert</p></TableCell>
              <TableCell className={classes.NoBorderCells} align="center"><p style={{fontSize: "16px", margin: 0}}>{avgHrAlert != null ? avgHrAlert.length:<span>0</span>}</p></TableCell>
              <TableCell className={classes.NoBorderCells} />
            </TableRow>

            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={heartRateSuddenOpen} timeout="auto" unmountOnExit>
                  <SuddenHeartRateRow props={avgHrAlert}/>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>



          <TableBody className={classes.SpoUnder86Body}>
            <TableRow>
              <TableCell className={classes.NoBorderCells} />
              <TableCell className={classes.NoBorderCells} style={{padding: '0px'}}>
                {immediateSpoAlert == '' || immediateSpoAlert == null ?
                <IconButton size="big" disabled="true">
                <KeyboardArrowRightIcon className={classes.ArrowIcons} />
                </IconButton>
                :
                <IconButton aria-label="expand row" size="big" onClick={() => setSpoUnder86Open(!spoUnder86Open)}>
                  {spoUnder86Open ? <KeyboardArrowDownIcon className={classes.ArrowIcons} /> : <KeyboardArrowRightIcon className={classes.ArrowIcons} />}
                </IconButton>
                }
                

              </TableCell>
              <TableCell className={classes.NoBorderCells} className={classes.NoBorderCells} align="left"><p style={{fontSize: "16px", padding: '0px', margin: 0}}>Extremely Low SpO<span style={{fontSize: "12px"}}>2</span> (Blood Oxygen Level) Level</p></TableCell>
              <TableCell className={classes.NoBorderCells} align="center"><p style={{fontSize: "16px", margin: 0}}>{immediateSpoAlert != null ? immediateSpoAlert.length:<span>0</span>}</p></TableCell>
              <TableCell className={classes.NoBorderCells} />
            </TableRow>

            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={spoUnder86Open} timeout="auto" unmountOnExit>
                  <SpoUnder86Row props={immediateSpoAlert}/>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>



          <TableBody className={classes.SpoUnder90Body}>
            <TableRow>
              <TableCell className={classes.NoBorderCells} />
              <TableCell className={classes.NoBorderCells} style={{padding: '0px'}}>
                {longtermSpoAlert == '' || longtermSpoAlert == null ?
                <IconButton size="big" disabled="true">
                <KeyboardArrowRightIcon className={classes.ArrowIcons} />
                </IconButton>
                :
                <IconButton aria-label="expand row" size="big" onClick={() => setSpoUnder90Open(!spoUnder90Open)}>
                  {spoUnder90Open ? <KeyboardArrowDownIcon className={classes.ArrowIcons} /> : <KeyboardArrowRightIcon className={classes.ArrowIcons} />}
                </IconButton>
                }
                

              </TableCell>
              <TableCell className={classes.NoBorderCells} className={classes.NoBorderCells} align="left"><p style={{fontSize: "16px", padding: '0px', margin: 0}}>Sustained Low SpO<span style={{fontSize: "12px"}}>2</span> (Blood Oxygen Level) Level</p></TableCell>
              <TableCell className={classes.NoBorderCells} align="center"><p style={{fontSize: "16px", margin: 0}}>{longtermSpoAlert != null ? longtermSpoAlert.length:<span>0</span>}</p></TableCell>
              <TableCell className={classes.NoBorderCells} />
            </TableRow>

            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={spoUnder90Open} timeout="auto" unmountOnExit>
                  <SpoUnder90Row props={longtermSpoAlert}/>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>

        </Table>

          



      </TableContainer>
    </div>
      
    </>
  )

}

export default SleepAlerts