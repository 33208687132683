import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  NoSeizureLabelContainer: {
    width: '63%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }, 
  NoSeizureLabel: {
    marginBottom: "81px",
    fontWeight: 400,
    fontSize: '22px',
    fontStyle: 'italic',
    color: '#929292',
  },
  Divider: {
    height: '1px',
    width: '100%',
    backgroundColor: '#e0e0e0',
  },
  rowItemContainer: {
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  },
}))