import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(18),
      color:theme.palette.grey[600]
    }
  },
  itemTitle: {
    margin: theme.spacing(1.5, 0),
    fontWeight:theme.typography.fontWeightLight
  },
  itemDate: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(3)
  }
}))
