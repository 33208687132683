import React, { useRef } from 'react'

import CanvasJSReact from './canvasjs/canvasjs.stock.react'
import ProfileServices from '../../../Services/Consumers/ProfileServices'
import { useStyles } from './Styles'
import { FormLoader } from '../../Loader/FormLoader'

const NightChartsWithDate = ({ userId, nightChartsFilterDate }) => {
  const classes = useStyles()
  const [ringDataFailure, setRingDataFailure] = React.useState(false)
  const [hasData, setHasData] = React.useState(false)
  const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart
  let heartRateDataPoints = [],
    spo2DataPoints = [],
    motionDataPoints = [],
    midNightDate = new Date(nightChartsFilterDate),
    currentDayDate = new Date(),
    rangeChangedTriggered = false
  // midNightDate.setDate(midNightDate.getDate() - 1)
  midNightDate.setHours(0, 0, 0, 0)
  heartRateDataPoints.push({ x: midNightDate.getTime(), y: 0 })
  spo2DataPoints.push({ x: midNightDate.getTime(), y: 0 })
  motionDataPoints.push({ x: midNightDate.getTime(), y: 0 })

  let heartRateChartRef = useRef(null)

  const heartRateContainerProps = {
    width: '100%',
    height: '250px',
    margin: 'auto'
  }
  let heartRateOptions = {
    theme: 'light2',
    rangeChanged: function(e) {
      rangeChangedTriggered = true
    },
    charts: [{
      axisX: {
        crosshair: {
          enabled: true,
          valueFormatString: 'MMM DD, YYYY HH:mm:ss'
        }
      },
      axisY: {
        title: 'Heart Rate'
      },
      toolTip: {
        shared: true
      },
      data: [{
        type: 'spline',
        name: 'Heart Rate',
        lineColor: '#462d7d',
        color: '#462d7d',
        xValueFormatString: 'MMM DD, YYYY HH:mm:ss',
        xValueType: 'dateTime',
        dataPoints: heartRateDataPoints
      }]
    }],
    navigator: {
      slider: {
        minimum: new Date(currentDayDate.getTime())
      }
    },
    rangeSelector: {
      enabled: false
    }
  }

  let chartRefSPO2 = useRef(null)
  const containerPropsSPO2 = {
    width: '100%',
    height: '250px',
    margin: 'auto'
  }

  let spo2Options = {
    theme: 'light2',
    rangeChanged: function(e) {
      rangeChangedTriggered = true
    },
    charts: [{
      axisX: {
        crosshair: {
          enabled: true,
          valueFormatString: 'MMM DD, YYYY HH:mm:ss'
        }
      },
      axisY: {
        title: 'SPO2',
      },
      toolTip: {
        shared: true
      },
      data: [{
        type: 'spline',
        name: 'SPO2',
        lineColor: '#462d7d',
        color: '#462d7d',
        xValueFormatString: 'MMM DD, YYYY HH:mm:ss',
        xValueType: 'dateTime',
        dataPoints: spo2DataPoints
      }]
    }],
    navigator: {
      slider: {
        minimum: new Date(currentDayDate.getTime() - (1 * 2160000))
      }
    },
    rangeSelector: {
      enabled: false
    }
  }
  let chartRefMotion = useRef(null)
  const containerPropsMotion = {
    width: '100%',
    height: '250px',
    margin: 'auto'
  }

  let motionOptions = {
    theme: 'light2',
    rangeChanged: function(e) {
      rangeChangedTriggered = true
    },
    charts: [{
      axisX: {
        crosshair: {
          enabled: true,
          valueFormatString: 'MMM DD, YYYY HH:mm:ss'
        }
      },
      axisY: {
        title: 'Motion',
      },
      toolTip: {
        shared: true
      },
      data: [{
        type: 'spline',
        name: 'Motion',
        lineColor: '#462d7d',
        color: '#462d7d',
        xValueFormatString: 'MMM DD, YYYY HH:mm:ss',
        xValueType: 'dateTime',
        dataPoints: motionDataPoints
      }]
    }],
    navigator: {
      slider: {
        minimum: new Date(currentDayDate.getTime() - (1 * 3600000))
      }
    },
    rangeSelector: {
      enabled: false
    }
  }

  let timeFrom = midNightDate.getTime() / 1000

  const updateChart = (tfrom, reset) => {
    let currentDate = new Date(nightChartsFilterDate)
    currentDate.setHours(23, 59, 59, 999)
    let currentTime = Math.round(currentDate.getTime() / 1000)
    let needUpdate = false
    let data = {
      'timestamp_from': tfrom,
      'timestamp_to': currentTime,
      'patient_id': userId
    }

    ProfileServices.loadRingData(data, null, setRingDataFailure, null).then((result) => {
      if (!!result && !!result.data) {
        if (!((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE)) || !!result.data.exception)) {
          result.data.ring_data.forEach((rData) => {
            if (!!reset) {
              heartRateDataPoints[0] = { x: Date.parse(rData.timestamp), y: rData.heart_rate }
              spo2DataPoints[0] = { x: Date.parse(rData.timestamp), y: rData.spo2 }
              motionDataPoints[0] = { x: Date.parse(rData.timestamp), y: rData.motion || 0 }
              reset = false
            } else {
              heartRateDataPoints.push({ x: Date.parse(rData.timestamp), y: rData.heart_rate })
              spo2DataPoints.push({ x: Date.parse(rData.timestamp), y: rData.spo2 })
              motionDataPoints.push({ x: Date.parse(rData.timestamp), y: rData.motion || 0 })
            }

          })
          needUpdate = true
        }
      }
    }).then(() => {
      if (!!heartRateDataPoints.length && !!spo2DataPoints.length) {
        if (!!heartRateChartRef.current) {
          heartRateChartRef.current.render()
        }

        if (!!chartRefSPO2.current) {
          chartRefSPO2.current.render()
        }
        if (!!chartRefMotion.current) {
          chartRefMotion.current.render()
        }
        needUpdate = false
      }
      setHasData(true)
    })

  }
  updateChart(timeFrom, true)


  return (
    <div>
      <div className={classes.chartWrapper}>
        {!hasData && <FormLoader loading={true}/>}
        <CanvasJSStockChart containerProps={heartRateContainerProps} options={heartRateOptions}
                            onRef={ref => heartRateChartRef.current = ref}
        />
      </div>

      <div className={classes.chartWrapper}>
        {!hasData && <FormLoader loading={true}/>}
        <CanvasJSStockChart containerProps={containerPropsSPO2} options={spo2Options}
                            onRef={ref => chartRefSPO2.current = ref}
        />
      </div>

      <div className={classes.chartWrapper}>
        {!hasData && <FormLoader loading={true}/>}
        <CanvasJSStockChart containerProps={containerPropsMotion} options={motionOptions}
                            onRef={ref => chartRefMotion.current = ref}
        />
      </div>
    </div>

  )
}
export default NightChartsWithDate