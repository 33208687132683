import React from 'react'
import ResetPasswordForm from './Form'
import AuthLogo from '../AuthLogo'
import { AuthConsumer } from '../../../AuthContext'

const ResetPasswordCard = ({code}) => {

  return (
    <>
      <AuthLogo/>
      <AuthConsumer>
        {({ handleResetPasswordSubmit }) => (
          <ResetPasswordForm code={code} onSubmit={handleResetPasswordSubmit}/>
        )}
      </AuthConsumer>
    </>
  )
}

export default ResetPasswordCard
