import { Field, Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import ProfileServices from "../../../../../../Services/Consumers/ProfileServices";
import { LocalStorageService } from "../../../../../../Services/LocalStorageService";
import { HiddenCheckbox, HiddenRadio } from "../../../../../StyledComponents/HiddenCheckbox";
import { StyledInput, StyledTextArea } from "../../../../../StyledComponents/StyledInput";
import { StyledSelect, SelectTheme, } from "../../../../../StyledComponents/StyledSelect";
import { StyledTimePicker, StopwatchIcon, StyledDatePicker } from "../../../../../StyledComponents/StyledTimePicker";
import "./styles.scss";

const SeizureHistoryForm = ({ selectedDate, seizureTypes, seizureEventID, closeForm, editSeizureEvent, eventsUpdated, setEventsUpdated, seizureEventData  }) => {
  const seizureTypeOptions = seizureTypes.map((seizureType) => ({
    value: seizureType.seizure_type_id,
    label: `${seizureType.custom_name}`,
  }));

  const [filteredSeizureTypeID, setFilteredSeizureTypeID] = useState(null);

  useEffect(() => {
    if(seizureTypeOptions.length && seizureEventData) {
      const seizureTypeFiltered = seizureTypeOptions.filter(type => type.label === seizureEventData.seizure_type_custom_name);
      if(seizureTypeFiltered.length){
        setFilteredSeizureTypeID(seizureTypeFiltered[0].value);
      }
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[seizureTypeOptions, seizureEventData])

  const currentTimestamp = moment().utc().format("YYYY-MM-DD HH:mm:ssZ");

  const [triggersList, setTriggersList] = useState([]);

  const intensityOptions = [
    {intensityString: "minimal", intensityValue: 1},
    {intensityString: "mild", intensityValue: 2},
    {intensityString: "moderate", intensityValue: 3},
    {intensityString: "severe", intensityValue: 4}
  ]

  useEffect(() => {
    const getTriggers = async () => {
      const response = await ProfileServices.getSeizuresTriggers();
      const triggersData = response.data.data;
      setTriggersList(triggersData);
    };
    getTriggers();
  }, []);

  const handleSubmit = (formValues, formikActions) => {

    const seizureData = {
      current_timestamp: currentTimestamp,
      device_type: "Browser",
      app_version: null,
      is_ongoing: false,
      possible_triggers: formValues.triggers && formValues.triggers.map((stringID) => parseInt(stringID)),
      seizure_type_id: formValues.seizureTypeID,
      seizure_timestamp: formValues.seizureDate && formValues.seizureTime && `${formValues.seizureDate} ${formValues.seizureTime}`,
      seizure_duration: formValues.seizureDuration,
      seizure_intensity: formValues.seizureIntensity && parseInt(formValues.seizureIntensity),
      comments: formValues.comments,
    };

    editSeizureEvent(seizureEventID, seizureData, formikActions, eventsUpdated, setEventsUpdated);
    closeForm();

  };

  const handleCheckboxChange = (e, props, id) => {
    if(e.target.checked) {
      props.values.triggers.push(id)
    } else if (e.target.checked === false) {
        props.values.triggers.splice(props.values.triggers.indexOf(id), 1)
    }
  }

  const scrollRef = useRef(null);

  const scrollIntoView = () => {
    scrollRef.current.scrollIntoView();
  }


  return (
    <div className="SeizureHistoryForm">

    <div className="formContainer">

      <div className="formTitle">
        <h3>{seizureEventID === "new" ? "Log New Seizure" : "Edit Seizure History"}</h3>
      </div>

      <Formik
        initialValues={{
          seizureTypeID: seizureEventData ? (seizureTypeOptions.filter(type => type.label === seizureEventData.seizure_type_custom_name).length ? seizureTypeOptions.filter(type => type.label === seizureEventData.seizure_type_custom_name)[0].value : null) : null,
          seizureDate: seizureEventData ? moment(seizureEventData.seizure_timestamp).format("YYYY-MM-DD") : moment(selectedDate).format("YYYY-MM-DD"),
          seizureTime: seizureEventData ? moment(seizureEventData.seizure_timestamp).format("HH:mm:ssZ") : moment().format("HH:mm:ssZ"),
          seizureDuration: seizureEventData ? seizureEventData.duration : null,
          seizureIntensity: seizureEventData ? seizureEventData.intensity : null,
          comments: seizureEventData ? seizureEventData.comments : "",
          triggers: seizureEventData ? seizureEventData.possible_triggers : []
        }}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {(props) => {
          return (
          <Form>
            <div className="seizureFormRow">
              <div className="seizureTypeSelect">
                <label className="required">Seizure Type</label>
                <StyledSelect
                  key={JSON.stringify(filteredSeizureTypeID)}
                  isSearchable={false}
                  options={seizureTypeOptions}
                  theme={(theme) => SelectTheme(theme)}
                  defaultValue={seizureEventData && filteredSeizureTypeID ? seizureTypeOptions.filter(item => item.value === filteredSeizureTypeID)[0] : null}
                  onChange={(e) => props.setFieldValue("seizureTypeID", e.value)}
                />
              </div>
              <div className="seizureDurationInput">
                <label className="durationHeader">Duration (seconds)</label>
                <StyledInput
                  name="seizureDuration"
                  onChange={props.handleChange}
                  placeholder="Enter Duration"
                  type="number"
                  min="1"
                  suffix={<StopwatchIcon />}
                  defaultValue={seizureEventData ? seizureEventData.duration : null}
                />
              </div>
            </div>
            <div className="seizureFormRow">

              <div className="datePicker">
                <label className="required">Date</label>
                <StyledDatePicker
                  className="datePickerComponent"
                  defaultValue={moment(selectedDate)}
                  showToday={false}
                  allowClear={false}
                  format={"ddd. MMMM D, yyyy"}
                  onChange={date => props.setFieldValue("seizureDate", date.format("YYYY-MM-DD"))}
                  inputReadOnly={true}
                  disabledDate={date => date > moment()}
                  dropdownAlign={{offset: [-10, -215]}}
                  />
              </div>

              <div className="timePicker">
                <label className="required">Time</label>
                <StyledTimePicker 
                  dropdownAlign={{offset: [-120, -215]}}
                  allowClear={false}
                  inputReadOnly={true}
                  format={"h:mm A"}
                  onChange={time => props.setFieldValue("seizureTime", time.format("HH:mm:ssZ"))}
                  defaultValue={seizureEventData ? moment(seizureEventData.seizure_timestamp, "YYYY-MM-DD HH:mm:ssZ") : moment()}
                />
              </div>
              
            </div>

            <div className="seizureFormRow">
              <div className="intensityContainer">
                <label className="IntensityHeader">Intensity</label>
              <div className="intensityRow">

              {intensityOptions.map(option => (
                <label key={option.intensityValue}>
                  <div className="intensityOption">
                    <Field
                      className="intensityRadio"
                      as={HiddenRadio}
                      name="seizureIntensity"
                      value={option.intensityValue}
                      defaultChecked={seizureEventData && option.intensityValue === seizureEventData.intensity}
                      />
                    <div className={option.intensityString}>
                      <span>
                        {option.intensityValue}
                      </span>
                      <p>
                        {option.intensityString}
                      </p>
                    </div>
                  </div>
                </label>
              ))}
              
              </div>
              </div>
            </div>

            <div className="possibleTriggersContainer">
              <label className="TriggerHeader">Possible Triggers</label>
              <div className="possibleTriggers">
                {triggersList.map((trigger) => {
                  const triggerID = trigger.seizure_trigger_id;
                  return (
                    <div
                    className="triggerOption"
                    key={triggerID}
                    >
                      <label>
                        <Field
                          className="triggerCheckbox"
                          as={HiddenCheckbox}
                          name="triggers"
                          value={triggerID}
                          defaultChecked={seizureEventData && seizureEventData.possible_triggers && seizureEventData.possible_triggers.includes(triggerID)}
                          onChange={(e) => handleCheckboxChange(e, props, triggerID)}
                          />
                        <span>{trigger.seizure_trigger_name}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>

			<div className="seizureFormRow">
        <div className="commentsContainer">
          <label className="durationHeader" ref={scrollRef}>Comments</label>
          <StyledTextArea
            name="comments"
            onChange={props.handleChange}
            autoSize={{minRows: 3}}
            onClick={scrollIntoView}
            defaultValue={seizureEventData && seizureEventData.comments}
          />
        </div>
            </div>
            
            <button disabled={!props.values.seizureTypeID || !props.values.seizureDate || !props.values.seizureTime || !props.values.seizureIntensity} className="saveButton" type="submit">
              Save
            </button>
            <p className="cancel" onClick={closeForm}>
              Cancel
            </p>
          </Form>
        )}}
      </Formik>
    </div>
    </div>

  );
};

export default SeizureHistoryForm;
