import React from 'react'
import {SEO} from '../../../components/Seo/seo'
import {login} from '../../../utils/pages-meta.json'
import LoginCard from '../../../components/Auth/LoginCard'
import {NamedRoutes} from '../../../routes'

const Login = () => {
  return (
    <>
      <SEO title={login.title}
           pathname={window.location.origin + NamedRoutes.auth.login}
           titleTemplate='Novela'
           image={login.image}
           description={login.description}
      />
      <LoginCard/>
    </>
  )
}

export default Login
