import React from 'react'
import {useStyles} from './Styles'
import samplePDF from './After_That_Day_-_digital.pdf'
import SinglePagePDFViewer from './PDF/single-page'

const EBookContent = () => {
  const classes = useStyles()

  return (
    <section className={classes.eBookContent}>
      <SinglePagePDFViewer pdf={samplePDF}/>
    </section>
  )
}

export default EBookContent
