import React from 'react'
import { useStyles } from './Styles'
import { AuthConsumer } from '../../../AuthContext'
import moment from 'moment'
import Media from 'react-media'


const TimeWeather = ({ title }) => {
  let classes = useStyles()
  const [selectedDate, setSelectedDate] = React.useState(moment())
  React.useEffect(() => {
      setInterval(() => {
        setSelectedDate(moment());
      }, 60000);
    }, []);

  return (
        <div className={classes.timWeather} >
          <div className={classes.timWeatherDate}>
            <Media query="(max-width: 815px)">
              {matches => {
                return matches ? selectedDate.format("ddd, MMM DD") : selectedDate.format("dddd, MMM DD");
              }}
            </Media>
            </div>
          <div  className={classes.timWeatherTime}>
              <div className={classes.timWeatherTimeHHMM}>
                {selectedDate.format("hh:mm")}
                <small className={classes.timWeatherTimeA}>{selectedDate.format("A")}</small>
              </div>
          </div>
        </div>
  )
}

export default TimeWeather
