import React from 'react'
import {useStyles} from './Styles'
import {FormattedMessage} from 'react-intl'
// import RowItem from './RowItem/RowItem'
// import voiceRecord from './test-voice.mp3'
import {ProfileProvider} from '../../../ProfileContext'
import {ProfileConsumer} from '../../../ProfileContext'
import VoiceMemosTable from './VoiceMemosTable'

const VoiceMemosContent = () => {
  const classes = useStyles()

  return (
    <section className={classes.voiceMemosContent}>
      <h1 className={classes.voiceMemosContentTitle}><FormattedMessage id='voiceMemos.Content.Title'/></h1>

        <ProfileProvider>
          <ProfileConsumer>
            {({getLoadVoiceMemos, handleGetVoiceMemos}) => (
              <VoiceMemosTable
                loadVoiceMemos={getLoadVoiceMemos} getVoiceMemos={handleGetVoiceMemos}
              />
            )}
          </ProfileConsumer>
        </ProfileProvider>
    </section>
  )
}

export default VoiceMemosContent
