import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  rowItemWrapper: {
    padding: theme.spacing(3, 0, 1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`
    }
  },
  rowItemContentWrapper: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 1 auto'
    }
  },
  rowItemCircleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& i': {
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(30),
      '&.icon-day': {
        color: '#FFC107'
      },
      '&.icon-night': {
        color: theme.palette.primary.main
      }
    }
  },
  rowItemCircle: {
    width: 112,
    height: 112,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rowItemTitle: {
    fontSize: theme.typography.pxToRem(17),
    color: theme.palette.primary.main
  },
  rowItemSubTitle: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey[500]
  },
  rowItemContent: {
    marginLeft: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      width: '100%'
    }
  },
  rowItemAudio: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  rowItemLocation: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[600]
  },
  rowItemNote: {
    padding: theme.spacing(1, 0)
  },
  addNote: {
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      textDecoration: 'underline',
      color: theme.palette.primary.main
    }
  },
  actionWrapper: {},
  deleteIcon: {
    '&.MuiButton-root': {
      color: theme.palette.grey[500],
      marginLeft: theme.spacing(1),
      minWidth: 0,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        right: 0,
        top: 16
      }
    }
  }
}))
