import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  timWeather: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    justifyContent: "start",
    textAlign: "center",
    width: "220px",
    height: "60px",
    overflow: "hidden",
    fontSize: "20px",
    '@media (max-width: 815px)': {
      width: 'auto',
      fontSize: "16px",
    }
  },
  timWeatherDate: {
    color: theme.palette.primary.main,
    padding: "10px",
    paddingBottom: "0px",
    fontSize: "20px",
    fontWeight: 400,
    height: "30px",
    '@media (max-width: 815px)': {
      fontSize: "16px",
    }
  },
  timWeatherTime: {
    padding: "10px",
    paddingTop: "0px",
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.primary.main,
    display: "flex",
  },
  timWeatherTimeHHMM: {
    padding: "5px",
    width: "100%",
  },
  timWeatherTimeA: {
    paddingLeft: "10px",
    fontSize: "15px",
    '@media (max-width: 815px)': {
      fontSize: "13px",
    }
  },
}))