import React from 'react'
import {useStyles} from '../Styles'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import {Grid} from '@material-ui/core'

import {FormattedMessage} from 'react-intl'
import Item from '../Item'
import StreakItem from '../StreakItem'
import moment from 'moment';

const UpcomingMedicationsFrom = ({medicationSchedule}) => {
  let classes = useStyles()
  let list = [];
  let streakList = [];
  // let streakBuffer = {};

  let now = moment().add(-1,'days');
  let medication,medication_enddate;

  function convertUTCToLocal(utcDt, utcDtFormat) {
    var toDt = moment.utc(utcDt, utcDtFormat).toDate();
    return moment(toDt);
  }

  for(let i=0;i<medicationSchedule.length;i++){
      medication = medicationSchedule[i];
      // medication_enddate = moment(`${medication.end_date}`, "YYYY-MM-DD")
      medication_enddate = moment(`${medication.end_date}`, "YYYY-MM-DD")

      if(now < medication_enddate || medicationSchedule[i].ongoing == true ){

        streakList.push({
          title: medicationSchedule[i].medication_name,
          longestStreak:  medicationSchedule[i].longest_streak,
          id:  medicationSchedule[i].id,
          currentStreak: medicationSchedule[i].current_streak
        });

        for(let j=0;j< medicationSchedule[i].reminders.length;j++){
          // medication_enddate = moment(`${ medicationSchedule[i].end_date} ${ medicationSchedule[i].reminders[j].dose_time}`, "YYYY-MM-DD HH:mm:ss")
          medication_enddate = moment(`${ medicationSchedule[i].end_date} ${ medicationSchedule[i].reminders[j].dose_time}`, "YYYY-MM-DD HH:mm:ss");
          list.push({
            id: medicationSchedule[i].reminders[j].reminder_id,
            title: medicationSchedule[i].medication_name,
            date:medication_enddate
          });

          list = list.sort((x, y) => moment(x.date.format('12-12-2012 HH:mm:ss'),"YYYY-MM-DD HH:mm:ss") - moment(y.date.format('12-12-2012 HH:mm:ss'),"YYYY-MM-DD HH:mm:ss"));
        }
      }
  }
  
  //.sort(
  /*  function(x,y){
      return moment(x.date.format('12-12-2012 HH:mm:ss'),"YYYY-MM-DD HH:mm:ss") < moment(y.date.format('12-12-2012 HH:mm:ss'),"YYYY-MM-DD HH:mm:ss")})
      */
  return (
    <div>
       <div className={classes.UpcomingMedicationsTab} >
              <Grid container spacing={0} style={{justifyContent:"space-between"}}>
                <Grid item  md={5} lg={5} className={classes.UpcomingMedicationsTabGridItem}>
                  <h1 className={classes.UpcomingMedicationsTabTitle}>
                    <FormattedMessage id='UpcomingMedicationsTab.title'/>
                  </h1>
                  {list.map((item) => (
                    <Item
                      key={item.id}
                      title={item.title}
                      date={item.date.format('hh:mm A')}
                    />
                  ))}
                </Grid>
                <Grid  item md={5} lg={7} style={{flexGrow:"1",maxWidth:"300px"}}>
                  {streakList.map((item) => (
                    <StreakItem
                      key={item.id}
                      currentStreak={item.currentStreak}
                      longestStreak={item.longestStreak}
                      title={item.title}
                    />
                  ))}
                </Grid>
              </Grid>
            </div>
    </div>
  )
}

export default UpcomingMedicationsFrom
