import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { TextField, InputLabel, FormControl } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { ForgotPasswordFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../Styles'
import { NavLink } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'
import { AuthConsumer } from '../../../AuthContext'

const ForgotPasswordForm = ({ onSubmit }) => {
  let classes = useStyles()
  const forgotPasswordForm = useFormik({
    initialValues: {
      email: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: ForgotPasswordFormValidation,
    onSubmit: ((values, actions) => {
      onSubmit(values, actions)
    })
  })
  return (
    <AuthConsumer>
      {({ forgotPasswordFailureMessage, forgotPasswordFailure, forgotPasswordSuccess }) => (
        <div className={classes.authCard}>
          <h1 className={classes.authCardTitle}><FormattedMessage id='ForgotPasswordForm.title'/></h1>
          <form onSubmit={forgotPasswordForm.handleSubmit} noValidate autoComplete="off" className={classes.authForm}>
            {!!forgotPasswordFailure &&
            <Alert severity="error">
              {!!forgotPasswordFailureMessage ? forgotPasswordFailureMessage :
                <FormattedMessage id='EndPoint.Error'/>}
            </Alert>}
            {!!forgotPasswordSuccess &&
            <Alert severity="success"><FormattedMessage id='EndPoint.ForgotPassword.Success'/></Alert>}

            <FormControl className={classes.authCardFormControl}>
              <InputLabel className={classes.authCardFormLabel} htmlFor="email">
                <FormattedMessage id='SignUpForm.InputLabel.email'/>
              </InputLabel>
              <TextField
                id="email"
                required
                fullWidth
                margin="dense"
                type="email"
                name='email'
                variant="outlined"
                onChange={forgotPasswordForm.handleChange}
                onBlur={forgotPasswordForm.handleBlur}
                value={forgotPasswordForm.values.email}
                helperText={forgotPasswordForm.errors.email && forgotPasswordForm.touched.email && forgotPasswordForm.errors.email}
                error={forgotPasswordForm.errors.email && forgotPasswordForm.touched.email !== undefined}
                inputProps={{
                  autoComplete: 'new-email',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
            <div className={classes.formActionsWrapper}>
              <button disabled={forgotPasswordForm.isSubmitting || !forgotPasswordForm.isValid} className={classes.formActionSubmit}>
                <FormattedMessage id='ForgotPasswordForm.button.submit'/>
              </button>
            </div>
            {forgotPasswordForm.isSubmitting && <FormLoader loading={true}/>}
          </form>
          <p align={'center'}>
            <FormattedMessage id='SignUpForm.footer.text'/> <NavLink to={NamedRoutes.auth.login}><FormattedMessage
            id='SignUpForm.footer.link'/></NavLink>
          </p>
        </div>
      )}
    </AuthConsumer>
  )
}

export default ForgotPasswordForm
