/**
 * General Services class
 */
import RestClient from '../Client/RestClient'
import Configuration from '../Api/Configuration'
import moment from 'moment'
import axios from 'axios'


const { Profile_EndPoints } = Configuration
const { Global_EndPoints } = Configuration

class ProfileServices {

  static loadSeizureFreeDays(patientID, setFormLoading, setFailure, FormikActions) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/seizures/streaks`, setFormLoading, setFailure, FormikActions, true)
  }

  static loadSleepQuality(patientID, currentTime, setFormLoading, setFailure, FormikActions) {
    const response = RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/sleep/quality/details`.replace("${currentTime}", currentTime), {}, { 'currentTime': currentTime }, {}, setFormLoading, setFailure, FormikActions, true)
    return response
  }

  static loadUpcomingMedications(patientID, setFormLoading, setFailure, FormikActions) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/medications/upcoming`, setFormLoading, setFailure, true)
  }

  static getVoiceMemos(patientID, params) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/voice-memos`, { params })
  }

  static loadRecentSeizure(patientID, queryParam, setFormLoading, setFailure) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/seizures/history?start_datetime=2020-01-01&number_of_seizures=1`, setFormLoading, setFailure, true)
  }

  static loadSeizuresHistory(patientID, queryParam, setFormLoading, setFailure) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/seizures/history?${queryParam}`, setFormLoading, setFailure, true)
  }

  static loadMedicationHistory(patientID, queryParam, setFormLoading, setFailure) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/medications/history?${queryParam}`, setFormLoading, setFailure, true)
  }

  static editMedicationHistory(patientID, medicationID, queryParam, setFormLoading, setFailure, FormikActions) {
    return RestClient.putRequest(`${Profile_EndPoints.patient}/${patientID}/medications/history/${medicationID}`, queryParam, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static loadSeizureTypes(patientID, setFormLoading, setFailure, FormikActions) {
    const response = RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/seizures/types`, {}, {}, setFormLoading, setFailure, FormikActions, true)
    return response
  }

  static saveMedication(patientID, medicationId, data, setFormLoading, setFailure, FormikActions) {
    if (!!medicationId && medicationId !== 'new') {
      return RestClient.putRequest(`${Profile_EndPoints.patient}/${patientID}/medications/${medicationId}`, data, {}, setFormLoading, setFailure, FormikActions, true)
    } else {
      return RestClient.postRequest(`${Profile_EndPoints.patient}/${patientID}/medications`, data, {}, setFormLoading, setFailure, FormikActions, true)
    }
  }

  static loadMedications(patientID, setFormLoading, setFailure, FormikActions) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/medications`, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static deleteMedication(patientID, medicationId, setFormLoading, setFailure, FormikActions) {
    return RestClient.deleteRequest(`${Profile_EndPoints.patient}/${patientID}/medications/${medicationId}`, {}, setFormLoading, setFailure, FormikActions, true)
  }


  static loadUnscheduledMedication(patientID, setFormLoading, setFailure, FormikActions) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/medications/unscheduled`, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static getUnscheduledMedicationEventData(patientID, eventID) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/medications/unscheduled/${eventID}`, {}, true)
  }

  static saveUnscheduledMedication(patientID, medicationId, data, setFormLoading, setFailure, FormikActions) {
    if (!!medicationId && medicationId !== 'new') {
      return RestClient.putRequest(`${Profile_EndPoints.patient}/${patientID}/medications/unscheduled/${medicationId}`, data, setFormLoading, setFailure, FormikActions, true)
    } else {
      return RestClient.postRequest(`${Profile_EndPoints.patient}/${patientID}/medications/unscheduled`, data, setFormLoading, setFailure, FormikActions, true)
    }
  }

  static deleteUnscheduledMedication(patientID, medicationID, setFailure, FormikActions) {
    return RestClient.deleteRequest(`${Profile_EndPoints.patient}/${patientID}/medications/unscheduled/${medicationID}`, {}, setFailure, FormikActions, true)
  }


  static loadUnscheduledMedicationHistory(patientID, queryParam, setFormLoading, setFailure, FormikActions) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/medications/unscheduled/history?${queryParam}`, setFormLoading, setFailure, FormikActions, true)
  }

  static saveUnscheduledMedicationHistory(patientID, medicationId, data, setFormLoading, setFailure, FormikActions) {
    if (!!medicationId && medicationId !== 'new') {
      return RestClient.putRequest(`${Profile_EndPoints.patient}/${patientID}/medications/unscheduled/history/${medicationId}`, data, setFormLoading, setFailure, FormikActions, true)
    } else {
      return RestClient.postRequest(`${Profile_EndPoints.patient}/${patientID}/medications/unscheduled/history`, data, setFormLoading, setFailure, FormikActions, true)
    }
  }

  static deleteUnscheduledMedicationHistory(patientID, medicationID, setFailure, FormikActions) {
    return RestClient.deleteRequest(`${Profile_EndPoints.patient}/${patientID}/medications/unscheduled/history/${medicationID}`, {}, setFailure, FormikActions, true)
  }

  static loadMedicationSchedule(email, userID, setFormLoading, setFailure, FormikActions) {
    // return RestClient.postRequest(Profile_EndPoints.loadMedicationSchedule.replace("${email}",email), {"email":email} , {}, setFormLoading, setFailure, FormikActions, true)
    return RestClient.getRequest(Profile_EndPoints.loadMedicationSchedule.replace("${email}", email), { "email": email }, {}, setFormLoading, setFailure, FormikActions, true)
  }



  static async getMedicationsList() {
    const list = await fetch(Global_EndPoints.medicationsList);
    return list;
  }

  static sleepHistory(patientID, startTimestamp, endTimestamp) {
    let params = '?start_timestamp=' + startTimestamp
    if (endTimestamp != "") {
      params += '&end_timestamp=' + endTimestamp
    }
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/sleep/history` + params)
  }

  static sleepStatistics(patientID, sleepLogsID) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/sleep/session/statistics/${sleepLogsID}`)
  }

  static sleepAlerts(patientID, sleepLogsID) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/sleep/session/statistics/alerts/${sleepLogsID}`)
  }

  static getSeizuresTriggers() {
    return RestClient.getRequest(Global_EndPoints.triggersList);
  }

  static editSeizureEvent(eventID, patientID, queryParam, setFailure, FormikActions) {
    if (eventID !== "new") {
      return RestClient.putRequest(`${Profile_EndPoints.patient}/${patientID}/seizures/event/${eventID}`, queryParam, {}, {}, setFailure, FormikActions, true);
    } else {
      return RestClient.postRequest(`${Profile_EndPoints.patient}/${patientID}/seizures/event`, queryParam, {}, {}, setFailure, FormikActions, true);
    }
  }

  static deleteSeizureEvent(patientID, eventID, setLoading, setFailure, FormikActions) {
    return RestClient.deleteRequest(`${Profile_EndPoints.patient}/${patientID}/seizures/event/${eventID}`, setLoading, setFailure, FormikActions, true);
  }

  static getSeizureEventData(patientID, eventID) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/seizures/event/${eventID}`, {}, true)
  }

  static editSeizureType(typeID, patientID, data, setFailure, FormikActions) {
    if (typeID === "new") {
      return RestClient.postRequest(`${Profile_EndPoints.patient}/${patientID}/seizures/types`, data, {}, {}, setFailure, FormikActions, true)
    } else {
      return RestClient.putRequest(`${Profile_EndPoints.patient}/${patientID}/seizures/types/${typeID}`, data, {}, {}, setFailure, FormikActions, true)
    }
  }

  static deleteSeizureType(patientID, typeID, setLoading, setFailure, FormikActions) {
    return RestClient.deleteRequest(`${Profile_EndPoints.patient}/${patientID}/seizures/types/${typeID}`, {}, setLoading, setFailure, FormikActions, true);
  }

  static getIlaeSeizureTypes(){
    return RestClient.getRequest(Profile_EndPoints.ilaeSeizureTypes, {}, true);
  }

  static requestHelp(data, patientID) {
    return RestClient.postRequest(`${Profile_EndPoints.patient}/${patientID}/request-help`, data);
  }

  static getSideEffects(patientID) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/side-effects`)
  }


  static getUserData(patientID, setFormLoading, setFailure, FormikActions) {
    return RestClient.getRequest(`${Profile_EndPoints.patient}/${patientID}/personal-info`, setFormLoading, setFailure, FormikActions, true)
  }


  
  static savePatientProfileInfo(data, setFormLoading, setFailure, FormikActions) {
    let requestData = data;
    requestData.birthday = moment(`${requestData.month}-${requestData.day}-${requestData.year}`, 'MMM-DD-yyyy')
    return RestClient.putRequest(Profile_EndPoints.savePatientProfileInfo, requestData, {}, setFormLoading, setFailure, FormikActions, true)
  }


  static loadEmergencyContacts(email, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(Profile_EndPoints.loadEmergencyContacts, { 'email': email }, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static saveEmergencyContact(data, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(Profile_EndPoints.saveEmergencyContact, data, {}, setFormLoading, setFailure, FormikActions, true)
  }


  static removeEmergencyContact(data, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(Profile_EndPoints.removeEmergencyContact, data, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static sortEmergencyContacts(data, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(Profile_EndPoints.sortEmergencyContacts, data, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static loadEventsLog(email, start_date, end_date, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(Profile_EndPoints.loadEventsLog, {
      'email': email,
      "start_date": start_date,
      "end_date": end_date
    }, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static loadHeartRate(email, timestamp_from, timestamp_to, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(Profile_EndPoints.loadHeartRate, {
      'patient_email': email,
      'timestamp_from': timestamp_from,
      'timestamp_to': timestamp_to
    }, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static loadRingData(data, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(Profile_EndPoints.loadRingData, data, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static sendMedicationNotification(userId, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(Profile_EndPoints.sendMedicationNotification, { 'patient_id': userId }, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static loadSeizures(email, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(Profile_EndPoints.loadSeizures, {
      'email': email
    }, {}, setFormLoading, setFailure, FormikActions, true)
  }

  static saveSeizures(data, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(Profile_EndPoints.saveSeizures, data, {}, setFormLoading, setFailure, FormikActions, true)
  }


}

export default ProfileServices
