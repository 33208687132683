import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  rowItemWrapper: {
    padding: theme.spacing(1, 1, 1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    position: 'relative',
  },
  rowItemContentWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 1 auto'
    },
    '& i': {
      margin: '7px auto',
      fontSize: theme.typography.pxToRem(24),
      '&.icon-day': {
        color: '#FFC107'
      },
      '&.icon-night': {
        color: "#32478e",
      }
    }
  },
  IconTimeWrapper: {
    display: 'flex',
    padding: 0,
    marginBottom: '-10px'
  },
  rowItemTime: {
    fontSize: theme.typography.pxToRem(16),
    color: '#5a5a5a',
    paddingRight: '15px',
    marginTop: '7px',
    width: '85px',
    textAlign: 'right',
  },
  rowItemSeizureTypeAndDuration: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    alignContent: 'center',
    flexWrap: 'wrap',
    margin: 0,
    marginLeft: "10px",
  },
  rowItemSeizureTypeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eeeeee',
    width: '250px',
    borderRadius: "50px",
  },


  SeizureType: {
    textTransform: "capitalize",
    margin: 0,
    padding: '7px 0',
    fontSize: '16px',
    fontWeight: 400,
    color: '#4b257c'
  },
  rowItemContentTitleHint: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(3),
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    '& i': {
      fontSize: theme.typography.pxToRem(34),
      width: 25,
      height: 25,
      marginRight: theme.spacing(1)
    }
  },
  deleteIcon: {
    '&.MuiButton-root': {
      color: theme.palette.grey[500],
      marginLeft: theme.spacing(0),
      minWidth: 0,
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        right: 0,
        top: 16,
        '&:last-of-type': {
          right: 40
        }
      }
    }
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },

  rowItemDurationWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '10px',
    marginTop: 0,
    marginBottom: '4px'
  },
  rowItemDuration: {
    margin: '0 0 0 6px',
    color: '#4b2c7a',
    fontSize: '16px'
  },

  rowItemIntensity: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
    marginLeft: "20px"
  },
  IntensityChart: {
    marginBottom: '0px',
  },
  IntensityString: {
    margin: '0 0 -5px 0',
    fontSize: '13px',
  },

  actionWrapper: {
    margin: '10px 10px 0 0',
  },
  intensityImage: {
    width: "50px",
    
  }
}))
