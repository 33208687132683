import React from 'react'
import {Route, Switch} from 'react-router-dom'
import NoMatch from './pages/NoMatch'
import SignUp from './pages/Auth/SignUp'
import Login from './pages/Auth/Login'
import TokenLogin from './pages/Auth/TokenLogin'
import OrganizationTokenLogin from './pages/Auth/OrganizationTokenLogin'
import EmailVerified from './pages/Auth/EmailVerified'
import Dashboard from './pages/Account/Dashboard'
import PatientProfile from './pages/Account/PatientProfile'
import PrivateRoute from './utils/PrivateRoute'
import AnonymousRoute from './utils/AnonymousRoute'
import ForgotPassword from './pages/Auth/ForgotPassword'
import ResetPassword from './pages/Auth/ResetPassword'
import Seizures from './pages/Account/Seizures'
import Medications from './pages/Account/Medications'
import Sleep from './pages/Account/Sleep'
import SideEffects from './pages/Account/SideEffects'
import VoiceMemos from './pages/Account/VoiceMemos'
import RawData from './pages/Account/RawData'
import EBook from './pages/Account/EBook'
import AnonymousTokenRoute from './utils/AnonymousTokenRoute'

export const NamedRoutes = {
  home: `/`,
  auth: {
    signUp: `/register`,
    login: `/login`,
    tokenLogin: `/login/token/:ring_sn/:verification_token`,
    organizationTokenLogin: `/organization/login/token/:organization_token/:verification_token/:patientID/:organizationID`,
    forgotPassword: `/reset_password`,
    resetPassword: `/reset_password/set_new_password/:code`,
    emailVerified: '/device_verification'
  },
  account: {
    patientProfile: '/account/patient-profile',
    seizures: '/account/seizures',
    medications: '/account/medications',
    sleep: '/account/sleep',
    sideEffects: '/account/side-effects',
    voiceMemos: '/account/voice-memos',
    eBook: '/account/e-book',
    raw: '/account/raw',
  }
}

// @refresh reset

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact path={NamedRoutes.home} component={Dashboard}/>
      <AnonymousRoute exact path={NamedRoutes.auth.signUp} component={SignUp}/>
      <AnonymousRoute exact path={NamedRoutes.auth.login} component={Login}/>
      {/* <AnonymousRoute exact path={NamedRoutes.auth.tokenLogin} component={TokenLogin}/> */}
      <AnonymousTokenRoute exact path={NamedRoutes.auth.tokenLogin} component={TokenLogin}/>
      <AnonymousTokenRoute exact path={NamedRoutes.auth.organizationTokenLogin} component={OrganizationTokenLogin}/>
      <AnonymousRoute exact path={NamedRoutes.auth.forgotPassword} component={ForgotPassword}/>
      <AnonymousRoute exact path={NamedRoutes.auth.resetPassword} component={ResetPassword}/>
      <AnonymousRoute exact path={NamedRoutes.auth.emailVerified} component={EmailVerified}/>
      <PrivateRoute exact path={NamedRoutes.account.patientProfile} component={PatientProfile}/>
      <PrivateRoute exact path={NamedRoutes.account.seizures} component={Seizures}/>
      <PrivateRoute exact path={NamedRoutes.account.medications} component={Medications}/>

      <PrivateRoute exact path={NamedRoutes.account.sleep} component={Sleep}/>

      <PrivateRoute exact path={NamedRoutes.account.sideEffects} component={SideEffects}/>
      <PrivateRoute exact path={NamedRoutes.account.voiceMemos} component={VoiceMemos}/>
      <PrivateRoute exact path={NamedRoutes.account.raw} component={RawData}/>
      <PrivateRoute exact path={NamedRoutes.account.eBook} component={EBook}/>
      <Route component={NoMatch}/>
    </Switch>
  )
}
