import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { rawData } from '../../../utils/pages-meta.json'
import { NamedRoutes } from '../../../routes'
import AccountLayout from '../AccountLayout'
import RawDataContent from '../../../components/Account/RawDataContent'
import { RawDataProvider } from '../../../RawDataContext'

const RawData = () => {
  return (
    <>
      <SEO title={rawData.title}
           pathname={window.location.origin + NamedRoutes.account.rawData}
           titleTemplate='Novela'
           image={rawData.image}
           description={rawData.description}
      />
      <RawDataProvider>
        <AccountLayout content={<RawDataContent/>}/>
      </RawDataProvider>
    </>
  )
}

export default RawData
