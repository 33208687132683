import React from 'react'
import ProfileServices from './Services/Consumers/ProfileServices'
import { LocalStorageService } from './Services/LocalStorageService'
import moment from 'moment'

const RawDataContext = React.createContext({})

const RawDataProvider = ({ children }) => {
  const [authEmail, setAuthEmail] = React.useState(localStorage.getItem('UserEmail'))
  const [eventsLog, setEventsLog] = React.useState([])
  const [eventsLogLoading, setEventsLogLoading] = React.useState(false)
  const [eventsLogFailure, setEventsLogFailure] = React.useState(false)
  const [eventLogDate, setEventLogDate] = React.useState(new Date())

  React.useEffect(() => {
      LocalStorageService.get('UserEmail').then((result) => {
        if (!!result) {
          setAuthEmail(result)
          setEventsLogLoading(true)
          getEventsLog(result, eventLogDate)
        }
      })

      const eventsInterval = setInterval(() => {
        if (!!authEmail) {
          getEventsLog(authEmail, eventLogDate)
        }
      }, 10000)
      return () => clearInterval(eventsInterval)
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventLogDate])


  const getEventsLog = (email, start_date) => {
    if (!!email) {
      ProfileServices.loadEventsLog(email, moment(start_date).format('YYYY-MM-DD'), moment(start_date).format('YYYY-MM-DD'), setEventsLogLoading, setEventsLogFailure, null).then((result) => {
        if ((!!result.response && ![200, 201].includes(result.response.status)) || !result) {
          setEventsLogFailure(true)
        } else {
          if (!!result && !!result.data) {
            if ((!!result.data.RETURN_CODE && ![200, 201].includes(result.data.RETURN_CODE))) {
              setEventsLogFailure(true)
            } else {
              setEventsLog(result.data || [])
            }
          }
        }
      }).then(() => {
        setEventsLogLoading(false)
      })
    }
  }

  return (
    <RawDataContext.Provider value={{
      eventsLog: eventsLog,
      eventsLogLoading: eventsLogLoading,
      eventsLogFailure: eventsLogFailure,
      eventLogDate: eventLogDate,
      setEventLogDate: setEventLogDate
    }}>
      {children}
    </RawDataContext.Provider>
  )
}

const RawDataConsumer = RawDataContext.Consumer

export { RawDataProvider, RawDataConsumer }
