import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { TextField, InputLabel, FormControl, InputAdornment, IconButton } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { LoginFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../Styles'
import { NavLink, Redirect } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'
import { AuthConsumer } from '../../../AuthContext'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const LoginForm = ({ onSubmit }) => {
  let classes = useStyles()
  const [showPassword, setShowPassword] = React.useState(false)
  const handleChangeVisibility = () => {
    setShowPassword(!showPassword)
  }
  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: LoginFormValidation,
    onSubmit: ((values, actions) => {
      onSubmit(values, actions)
    })
  })
  return (
    <AuthConsumer>
      {({ loginEndPointFailure, loginFailure, loginSuccess, loginSuccessRedirect }) => (
        <div className={classes.authCard}>
          <h1 className={classes.authCardTitle}><FormattedMessage id='LoginForm.title'/></h1>
          <form onSubmit={loginForm.handleSubmit} noValidate autoComplete="off" className={classes.authForm}>
            {!!loginEndPointFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}
            {!!loginFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Login.Error'/></Alert>}
            {!!loginSuccess && <Alert severity="success"><FormattedMessage id='EndPoint.Login.Success'/></Alert>}
            {!!loginSuccessRedirect && <Redirect to={{
              pathname: NamedRoutes.home,
              state: { from: NamedRoutes.auth.login }
            }}/>}
            <FormControl className={classes.authCardFormControl}>
              <InputLabel className={classes.authCardFormLabel} htmlFor="email">
                <FormattedMessage id='SignUpForm.InputLabel.email'/>
              </InputLabel>
              <TextField 
                id="email" 
                required
                fullWidth
                margin="dense"
                type="email"
                name='email'
                variant="outlined"
                onChange={loginForm.handleChange}
                onBlur={loginForm.handleBlur}
                value={loginForm.values.email}
                helperText={loginForm.errors.email && loginForm.touched.email && loginForm.errors.email}
                error={loginForm.errors.email && loginForm.touched.email !== undefined}
                inputProps={{
                  autoComplete: 'new-email',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
            <FormControl className={classes.authCardFormControl}>
              <InputLabel className={classes.authCardFormLabel} htmlFor="password">
                <FormattedMessage id='SignUpForm.InputLabel.password'/>
              </InputLabel>
              <TextField
                id='password'
                required
                fullWidth
                margin="dense"
                type={showPassword ? 'text' : 'password'}
                name='password'
                variant="outlined"
                onChange={loginForm.handleChange}
                onBlur={loginForm.handleBlur}
                value={loginForm.values.password}
                helperText={loginForm.errors.password && loginForm.touched.password && loginForm.errors.password}
                error={loginForm.errors.password && loginForm.touched.password !== undefined}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={<FormattedMessage id='Fields.Labels.passwordVisibility'/>}
                        onClick={() => handleChangeVisibility()}
                        onMouseDown={() => handleChangeVisibility()}
                      >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
              <div align={'right'}>
                <NavLink to={NamedRoutes.auth.forgotPassword}><FormattedMessage
                  id='LoginForm.forgotPassword.link'/></NavLink>
              </div>
            </FormControl>
            <div className={classes.formActionsWrapper}>
              <button disabled={loginForm.isSubmitting || !loginForm.isValid} className={classes.formActionSubmit}>
                <FormattedMessage id='LoginForm.button.submit'/>
              </button>
            </div>
            {loginForm.isSubmitting && <FormLoader loading={true}/>}
          </form>
          <p align={'center'}>
            <FormattedMessage id='LoginForm.footer.text'/> <NavLink to={NamedRoutes.auth.signUp}><FormattedMessage
            id='LoginForm.footer.link'/></NavLink>
          </p>
        </div>
      )}
    </AuthConsumer>
  )
}

export default LoginForm
