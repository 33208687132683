import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { TextField, InputLabel, FormControl, FormHelperText, InputAdornment, IconButton } from '@material-ui/core'
import { SignUpFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../Styles'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { NavLink } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'
import Alert from '@material-ui/lab/Alert'
import { AuthConsumer } from '../../../AuthContext'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'


const SignUpForm = ({ onSubmit }) => {
  let classes = useStyles()
  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const handleChangeVisibility = (confirm = false) => {
    if (!!confirm) {
      setShowConfirmPassword(!showConfirmPassword)
    } else {
      setShowPassword(!showPassword)
    }
  }
  const signUpForm = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      emergencyContactName: '',
      emergencyContactMobileNumber: '',
      password: '',
      confirmPassword: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: SignUpFormValidation,
    onSubmit: ((values, actions) => {
      onSubmit(values, actions)
    })
  })

  return (
    <AuthConsumer>
      {({ registerEndPointFailure, registerFailure, registerSuccess }) => (
        <div className={classes.authCard}>
          <h1 className={classes.authCardTitle}><FormattedMessage id='SignUpForm.title'/></h1>
          {!!registerEndPointFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}
          {!!registerFailure && <Alert severity="error"><FormattedMessage id='EndPoint.SignUp.Error'/></Alert>}
          {!!registerSuccess && <Alert severity="success"><FormattedMessage id='EndPoint.SignUp.Success'/></Alert>}
          <form onSubmit={signUpForm.handleSubmit} noValidate autoComplete="off" className={classes.authForm}>
            <FormControl className={classes.authCardFormControl}>
              <InputLabel htmlFor="firstName">
                <FormattedMessage id='SignUpForm.InputLabel.firstName'/>
              </InputLabel>
              <TextField
                id="firstName"
                required
                fullWidth
                margin="dense"
                type="text"
                name='firstName'
                variant="outlined"
                onChange={signUpForm.handleChange}
                onBlur={signUpForm.handleBlur}
                value={signUpForm.values.firstName}
                helperText={signUpForm.errors.firstName && signUpForm.touched.firstName && signUpForm.errors.firstName}
                error={signUpForm.errors.firstName && signUpForm.touched.firstName !== undefined}
                inputProps={{
                  autoComplete: 'new-firstName',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
            <FormControl className={classes.authCardFormControl}>
              <InputLabel htmlFor="lastName">
                <FormattedMessage id='SignUpForm.InputLabel.lastName'/>
              </InputLabel>
              <TextField
                id="lastName"
                required
                fullWidth
                margin="dense"
                type="text"
                name='lastName'
                variant="outlined"
                onChange={signUpForm.handleChange}
                onBlur={signUpForm.handleBlur}
                value={signUpForm.values.lastName}
                helperText={signUpForm.errors.lastName && signUpForm.touched.lastName && signUpForm.errors.lastName}
                error={signUpForm.errors.lastName && signUpForm.touched.lastName !== undefined}
                inputProps={{
                  autoComplete: 'new-lastName',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
            <FormControl className={classes.authCardFormControl}>
              <InputLabel htmlFor="email">
                <FormattedMessage id='SignUpForm.InputLabel.email'/>
              </InputLabel>
              <TextField
                id="email"
                required
                fullWidth
                margin="dense"
                type="email"
                name='email'
                variant="outlined"
                onChange={signUpForm.handleChange}
                onBlur={signUpForm.handleBlur}
                value={signUpForm.values.email}
                helperText={signUpForm.errors.email && signUpForm.touched.email && signUpForm.errors.email}
                error={signUpForm.errors.email && signUpForm.touched.email !== undefined}
                inputProps={{
                  autoComplete: 'new-email',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
            <FormControl className={classes.authCardFormControl}>
              <InputLabel htmlFor="mobileNumber">
                <FormattedMessage id='SignUpForm.InputLabel.mobileNumber'/>
              </InputLabel>
              <PhoneInput
                value={signUpForm.values.mobileNumber}
                enableSearch={true}
                country={'us'}
                specialLabel={''}
                inputProps={{
                  name: 'mobileNumber',
                  required: true
                }}
                onChange={(phone) => {
                  signUpForm.setFieldValue('mobileNumber', phone.toString())
                  signUpForm.handleChange(phone)
                }}
                onBlur={signUpForm.handleBlur}
                isValid={(value, country) => {
                  return (!!value)
                }}
              />
              {signUpForm.errors.mobileNumber && signUpForm.touched.mobileNumber &&
              <FormHelperText error={true}>{signUpForm.errors.mobileNumber}</FormHelperText>
              }
            </FormControl>
            <FormControl className={classes.authCardFormControl}>
              <InputLabel htmlFor="emergencyContactName">
                <FormattedMessage id='SignUpForm.InputLabel.emergencyContactName'/>
              </InputLabel>
              <TextField
                id="emergencyContactName"
                required
                fullWidth
                margin="dense"
                type="text"
                name='emergencyContactName'
                variant="outlined"
                onChange={signUpForm.handleChange}
                onBlur={signUpForm.handleBlur}
                value={signUpForm.values.emergencyContactName}
                helperText={signUpForm.errors.emergencyContactName && signUpForm.touched.emergencyContactName && signUpForm.errors.emergencyContactName}
                error={signUpForm.errors.emergencyContactName && signUpForm.touched.emergencyContactName !== undefined}
                inputProps={{
                  autoComplete: 'new-emergencyContactName',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
            <FormControl className={classes.authCardFormControl}>
              <InputLabel htmlFor="emergencyContactMobileNumber">
                <FormattedMessage id='SignUpForm.InputLabel.emergencyContactMobileNumber'/>
              </InputLabel>
              <PhoneInput
                value={signUpForm.values.emergencyContactMobileNumber}
                enableSearch={true}
                country={'us'}
                specialLabel={''}
                inputProps={{
                  name: 'emergencyContactMobileNumber',
                  required: true
                }}
                onChange={(phone) => {
                  signUpForm.setFieldValue('emergencyContactMobileNumber', phone.toString())
                  signUpForm.handleChange(phone)
                }}
                onBlur={signUpForm.handleBlur}
                isValid={(value, country) => {
                  return (!!value)
                }}
              />
              {signUpForm.errors.emergencyContactMobileNumber && signUpForm.touched.emergencyContactMobileNumber &&
              <FormHelperText error={true}>{signUpForm.errors.emergencyContactMobileNumber}</FormHelperText>
              }
            </FormControl>

            <FormControl className={classes.authCardFormControl}>
              <InputLabel htmlFor="password">
                <FormattedMessage id='SignUpForm.InputLabel.password'/>
              </InputLabel>
              <TextField
                id='password'
                required
                fullWidth
                margin="dense"
                type={showPassword ? 'text' : 'password'}
                name='password'
                variant="outlined"
                onChange={signUpForm.handleChange}
                onBlur={signUpForm.handleBlur}
                value={signUpForm.values.password}
                helperText={signUpForm.errors.password && signUpForm.touched.password && signUpForm.errors.password}
                error={signUpForm.errors.password && signUpForm.touched.password !== undefined}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={<FormattedMessage id='Fields.Labels.passwordVisibility'/>}
                        onClick={() => handleChangeVisibility()}
                        onMouseDown={() => handleChangeVisibility()}
                      >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
            <FormControl className={classes.authCardFormControl}>
              <InputLabel htmlFor="confirmPassword">
                <FormattedMessage id='SignUpForm.InputLabel.confirmPassword'/>
              </InputLabel>
              <TextField
                id='confirmPassword'
                required
                fullWidth
                margin="dense"
                type={showConfirmPassword ? 'text' : 'password'}
                name='confirmPassword'
                variant="outlined"
                onChange={signUpForm.handleChange}
                onBlur={signUpForm.handleBlur}
                value={signUpForm.values.confirmPassword}
                helperText={signUpForm.errors.confirmPassword && signUpForm.touched.confirmPassword && signUpForm.errors.confirmPassword}
                error={signUpForm.errors.confirmPassword && signUpForm.touched.confirmPassword !== undefined}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={<FormattedMessage id='Fields.Labels.passwordVisibility'/>}
                        onClick={() => handleChangeVisibility(true)}
                        onMouseDown={() => handleChangeVisibility(true)}
                      >
                        {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: 'new-confirmPassword',
                  form: {
                    autoComplete: 'off'
                  }
                }}
              />
            </FormControl>
            <div className={classes.formActionsWrapper}>
              <button type="submit" disabled={signUpForm.isSubmitting || !signUpForm.isValid}
                      className={classes.formActionSubmit}>
                <FormattedMessage id='SignUpForm.button.submit'/>
              </button>
            </div>
            {signUpForm.isSubmitting && <FormLoader loading={true}/>}
          </form>
          <p align={'center'}>
            <FormattedMessage id='SignUpForm.footer.text'/> <NavLink to={NamedRoutes.auth.login}><FormattedMessage
            id='SignUpForm.footer.link'/></NavLink>
          </p>
        </div>
      )}
    </AuthConsumer>
  )
}

export default SignUpForm
