import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { dashboard } from '../../../utils/pages-meta.json'
import { NamedRoutes } from '../../../routes'
import AccountLayout from '../AccountLayout'
import DashboardContent from '../../../components/Account/DashboardContent'
import { AuthConsumer } from '../../../AuthContext'

const Dashboard = () => {
  return (
    <>
      <SEO title={dashboard.title}
           pathname={window.location.origin + NamedRoutes.account.dashboard}
           titleTemplate='Novela'
           image={dashboard.image}
           description={dashboard.description}
      />

      <AuthConsumer>
        {({ getWatchData }) => (
          <AccountLayout content={<DashboardContent getWatchData={getWatchData}/>}/>
        )}
      </AuthConsumer>
    </>
  )
}

export default Dashboard
