import React from 'react'
import { useStyles } from './../../DBStyles'
import {Grid,Box} from '@material-ui/core'
import ReactApexChart from 'react-apexcharts'
import ApexCharts from 'apexcharts'
import LazyImage from '../../../../LazyImage'
import {ProfileConsumer}  from '../../../../../ProfileContext'
import moment from "moment"
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import TimerIcon from '@material-ui/icons/Timer';

import HotelIcon from '@material-ui/icons/Hotel';
import {ReactComponent as MoonIcon} from '../../../../../images/Icons/moon.svg'
import ShortSleepSession from '../../../../../images/Icons/short_sleep_session_icon.png'
import SleepIcon from '../../../../../images/Icons/night.svg'

import fairImage from '../../../../../images/fair.png';
import poorImage from '../../../../../images/poor.png';
import goodImage from '../../../../../images/good.png';
import exellentImage from '../../../../../images/exellent.png';
import noDataImage from '../../../../../images/no-data.png';

import fairEmojiImage from '../../../../../images/fair-emoji.png';
import poorEmojiImage from '../../../../../images/poor-emoji.png';
import goodEmojiImage from '../../../../../images/good-emoji.png';
import exellentEmojiImage from '../../../../../images/exellent-emoji.png';
import noDataEmojiImage from '../../../../../images/no-data-emoji.png';

const SleepQualityForm = ({ loadSleepQuality }) => {

  let classes = useStyles()


  const [selectedDate, setSelectedDate] = React.useState(moment());


  // Reloads sleep quality data every minute
  React.useEffect(() => {
    if (!!loadSleepQuality) {
      setInterval(() => {
        setSelectedDate(moment());
        loadSleepQuality();
      }, 60000);
    }
  },
  [])

  // Immediately fetch the sleep quality data on load
  React.useEffect(() => {
    if (!!loadSleepQuality) {
      loadSleepQuality();
    }
  },
  []);

  return (
    <>
    <ProfileConsumer>
      {({  sleepQuality }) => {
      
        let sleepHeader;
        if (sleepQuality.data == null) {
          return (
            <div className={classes.DBGridCellThreeSleepQuality}>
              <div className={classes.DBGridHeader}>
                <div className={classes.HeaderIcon}>
                    <i className="icon-night" />
                  </div>
                <p className={classes.DBGridHeaderString}>Sleep Quality</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{display: "flex", border: "5px solid rgb(217,217,217)", borderRadius: "50%", width: "130px", height: "130px", marginTop: '20px', marginBottom: '25px', justifyContent: 'center', alignItems: 'center'}}>
                  <MoonIcon style={{width: 70}} fill="#32478e"/>
                </div>
                <h2 style={{color: '#32478e', fontSize: 20, fontWeight: 600,marginBottom: 0, marginTop: 5, fontFamily:'Ubuntu', textAlign: 'center'}}>No recent sleep data!</h2>
                <p style={{color: 'rgb(79,80,79)', fontSize: 16, fontWeight: 400, marginRight: 0, marginTop: 5, width: '80%', textAlign: 'center'}}> Please wear your ring to bed and ensure that the nEureka Home Hub is recording.</p>
              </div>
            </div>
          )
        } else if (sleepQuality.data !== undefined) {
          let sleepQualityScore = sleepQuality.data.sleep_quality_score;
          let sleepQualityScoreString = sleepQuality.data.sleep_quality_score_string;
          
          let sleepSessionDate = sleepQuality.data.sleep_session_date;
          let d = new Date(sleepSessionDate)
          let day = d.getDate() + 1
          let month = d.toString().split(' ')[1]
          let displaySleepSessionDate = `${month} ${day}`
          
          let sleepStartTime = sleepQuality.data.sleep_start_time;
          let startHours24 = parseInt(sleepStartTime.substring(0,2))
          let startHours = ((startHours24 + 11) % 12) + 1
          let startAmPm = startHours24 > 11 ? 'PM' : 'AM'
          let startMinutes = sleepStartTime.substring(3,5)
          let displaySleepStartTime = `${startHours}:${startMinutes} ${startAmPm}`
          
          let sleepEndTime = sleepQuality.data.sleep_end_time;
          let endHours24 = parseInt(sleepEndTime.substring(0,2))
          let endHours = ((endHours24 + 11) % 12) + 1
          let endAmPm = endHours24 > 11 ? 'PM' : 'AM'
          let endMinutes = sleepEndTime.substring(3,5)
          let displaySleepEndTime = `${endHours}:${endMinutes} ${endAmPm}`
          
          
          let sleepDuration = sleepQuality.data.sleep_duration_minutes;
          let hours = (sleepDuration / 60)
          let rhours = Math.floor(hours)
          let minutes = (hours - rhours) * 60
          let rminutes = Math.round(minutes)
          let displaySleepDuration = `${rhours} hr ${rminutes} min`
          
          const series = [`${sleepQualityScore}`]
          const options = {
            chart: {
              type: 'radialBar',
              animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 400,
                animateGradually: {
                  enabled: true,
                  delay: 150
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350
                },
              },
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: 65
                },
                dataLabels: {
                  show: false,
                }
              }
            },
            fill: {
              colors: (sleepQualityScore == 100 ? '#007e52' : (sleepQualityScore <= 25 ? '#F75959' : (sleepQualityScore <= 50 ? '#ff9034' : (sleepQualityScore <= 75 ? '#f0b530' : '#3ea883')))),
            },
            stroke: {
              lineCap: 'round',
            },
          }
        
          return (
            <div className={classes.DBGridCellThreeSleepQuality}>
              <div className={classes.DBGridHeader}>
                <div className={classes.HeaderIcon}>
                    <i className="icon-night" />
                  </div>
                <p className={classes.DBGridHeaderString}>Sleep Quality</p>
              </div>

              <div className={classes.DBGridCellThreeSleepQualityColumn} >
                <div>{sleepHeader}</div>
                <div className={classes.sleepQualityScoreChart}>
                  {
                    sleepQuality.data !== undefined && sleepQuality.data.sleep_quality_score == null ?
                      <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                          <img src={ShortSleepSession} style={{width: 80, height: 80, marginTop: '7px'}} alt="icon indicating not enough sleep"/>
                        <h2 style={{color: '#c24d75', fontSize: 18, marginBottom: 0, marginTop: "10px"}}>Sleep session too short!</h2>
                        <p style={{color: '#707070', fontSize: 15, fontWeight: 200, margin: "0 0 7px 0"}}> Try to get more than<br />4 hours of sleep.</p>
                      </div>
                    : 
                      <div style={{marginTop: "-10px"}}>
                        <ReactApexChart options={options} series={series} type="radialBar" height={184} />
                        <p className={classes.sleepQualityScoreChartParagraph} style={{color: (sleepQualityScore == 100 ? '#007e52' : (sleepQualityScore <= 25 ? '#F75959' : (sleepQualityScore <= 50 ? '#ff9034' : (sleepQualityScore <= 75 ? '#f0b530' : '#3ea883')))), fontSize: 40, fontWeight: 500, marginTop: 38}}
                        >{sleepQualityScore}</p>
                        <p className={classes.sleepQualityScoreString} style={{color: (sleepQualityScore == 100 ? '#007e52' : (sleepQualityScore <= 25 ? '#F75959' : (sleepQualityScore <= 50 ? '#ff9034' : (sleepQualityScore <= 75 ? '#f0b530' : '#3ea883')))), fontSize: 22, fontWeight: 400}}
                        >{sleepQualityScoreString}</p>
                      </div>
                  }
                </div>
                <div className={classes.Divider}></div>
                <div className={classes.SleepInformationContainer}>
                  <h3 className={classes.SleepDurationHeader}>Time Asleep :</h3>
                  <p className={classes.SleepDurationString}>{displaySleepDuration}</p>

                  <div className={classes.BedtimeWaketime}>
                    <h4>Sleep Time</h4>
                    <p className={classes.BedtimeWaketimeString}>:</p>
                    <p className={classes.BedtimeWaketimeString}>{displaySleepStartTime}</p>
                  </div>

                  <div className={classes.BedtimeWaketime}>
                    <h4>Wake Time</h4>
                    <p className={classes.BedtimeWaketimeString}>:</p>
                    <p className={classes.BedtimeWaketimeString}>{displaySleepEndTime}</p>
                  </div>
                </div>
                
              
              </div>
        </div>
          )
      }}
    }
    </ProfileConsumer>
  
    </>
  )
}

export default SleepQualityForm