import React, { useEffect, useState } from 'react'
import { useStyles } from './Styles'
import { FormattedMessage } from 'react-intl'
import { ProfileConsumer } from '../../../../../ProfileContext'
import Alert from '@material-ui/lab/Alert'
import { SearchLoader } from '../../../../Loader/SearchLoader'
import { Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { HiddenCheckbox } from '../../../../StyledComponents/HiddenCheckbox'
import ProfileServices from '../../../../../Services/Consumers/ProfileServices'
import moment from 'moment'
import SeizureTypesForm from './SeizureTypesForm/SeizureTypesForm'
import { StyledModal } from '../../../../StyledComponents/StyledModal'

const SeizureTypesTab = ({ loadSeizures, editSeizureType, deleteSeizureType }) => {
  const [triggers, setTriggers] = useState([]);

  const [editSeizure, setEditSeizure] = useState(false);
  const [seizureToEdit, setSeizureToEdit] = useState(null);

  const [seizureToDelete, setSeizureToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isMinTypeModalOpen, setIsMinTypeModalOpen] = useState(false);

  const [isAura, setIsAura] = useState(false);
  const [typesUpdated, setTypesUpdated] = useState(false);

  const [maxTypes, setMaxTypes] = useState(false);
  const [minTypes, setMinTypes] = useState(false);

  const seizureQuestions = {
    nocturnal: "Do these seizures only occur during sleep?",
    aware_unaware: "Are you aware during these seizures?",
    focal_generalized: "Is this seizure focal onset or generalized onset?",
    motor_nonmotor: "Does your body move during these seizures?",
    specific_subtype: "Seizure subtype:"
  }
  
  let classes = useStyles()
  
  React.useEffect(() => {
      if (!!loadSeizures) {
        loadSeizures()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [typesUpdated])

useEffect(() => {
    const getTriggers = async () => {
      const response = await ProfileServices.getSeizuresTriggers();
      const triggersData = response.data.data;
      setTriggers(triggersData);
    };
    getTriggers();
  }, []);

  const getSeizureAnswer = (seizure, question) => {
    if(question === "motor_nonmotor") {
      if(seizure[question].toLowerCase() === "motor") return "Yes"
      else if (seizure[question].toLowerCase() === "not sure") return seizure[question] 
      else if (seizure[question].toLowerCase() === "non motor") return "No"
    } else return seizure[question]
  }

  const handleOpenForm = (seizureTypeID, aura) => {
    setSeizureToEdit(seizureTypeID);
    setEditSeizure(true);
    setIsAura(aura);

  }

  const handleCloseForm = () => {
    setEditSeizure(false);
  }

  const showMinTypeModal = () => {
    setIsMinTypeModalOpen(true);
  }

  const showDeleteModal = seizureObj => {
    setSeizureToDelete(seizureObj);
    setIsDeleteModalOpen(true);
  }

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  }

  const handleDeleteSeizure = () => {
    deleteSeizureType(seizureToDelete.id, typesUpdated, setTypesUpdated);
    closeDeleteModal();
  }

  

  return (
    <div className={classes.SeizureTab}>

      <ProfileConsumer>
        {({ seizuresLoading, seizuresFailure, seizures, seizuresSuccess }) => (
          
          <>
            {!!seizuresFailure && <Alert severity="error"><FormattedMessage id='EndPoint.Error'/></Alert>}
            {!!seizuresSuccess && <Alert severity="success"><FormattedMessage id='EndPoint.Seizures.Success'/></Alert>}
            {!!seizuresLoading ? <SearchLoader loading={true}/> :
              (!!seizures && !!seizures.length ?
                editSeizure ? 
                <>
                  <SeizureTypesForm
                    closeForm={handleCloseForm}
                    seizureID={seizureToEdit}
                    seizureTypes={seizures}
                    triggers={triggers}
                    isAura={isAura}
                    flag={typesUpdated}
                    setFlag={setTypesUpdated}
                    editSeizureType={editSeizureType}
                  />
                </>
                :
              <div className={classes.seizureTypesContainer}>
                <div className="seizureTypesRow seizureTypesHeader">
                  <div className="spaceForArrowIcon"></div>
                  <div className="seizureNameCol">
                    <p>Seizure Name</p>
                  </div>
                  <div className="seizureTypeCol">
                    <p>Type</p>
                  </div>
                  <div className="seizureDurationCol">
                    <p>Typical Duration</p>
                  </div>
                  <div className="seizureFrequencyCol">
                    <p>Typical Frequency</p>
                  </div>
                  <div className="seizureDescCol">
                    <p>Description</p>
                  </div>
                  <div className="actionsCol">
                    <p>Actions</p>
                  </div>
                </div>

                
                

                {setMaxTypes(seizures.length >= 4)}
                {setMinTypes(seizures.length === 1)}

                {seizures && seizures.map(seizure => {
                  
                  const typicalDuration = seizure.typical_duration_seconds;
                  const typeID = seizure.seizure_type_id;
                  return (
                    <div key={typeID} className="seizureFullRow">
                      <label htmlFor={`seizureType${typeID}`} className="seizureTypesRow">
                        <div className="spaceForArrowIcon"></div>
                        <div className="seizureNameCol">
                            <p className="seizureName">
                              {seizure.custom_name ? seizure.custom_name : "--"}
                            </p>
                        </div>
                        <div className="seizureTypeCol">
                          <p>
                            {seizure.is_aura ? "Aura" : "Seizure"}
                          </p>
                        </div>
                        <div className="seizureDurationCol">
                          <p>{typicalDuration ? moment.utc(moment.duration(typicalDuration, "seconds").asMilliseconds()).format(typicalDuration >= 60 ? "m [min] s [sec]" : "s [sec]") : "--"}</p>
                        </div>
                        <div className="seizureFrequencyCol">
                          <p>
                            {seizure.typical_frequency ? seizure.typical_frequency : "--"}
                          </p>
                        </div>
                        <div className="seizureDescCol">
                          <p>{seizure.description ? seizure.description : "--"}</p>
                        </div>
                        <div className="actionsCol">
                          <div className="buttons">
                            <Button variant='text' className={classes.editDeleteIcons} onClick={() => handleOpenForm(typeID, seizure.is_aura)}>
                              <EditIcon className={classes.seizureRowEdit}/>
                            </Button>
                            <Button variant='text' className={classes.editDeleteIcons} onClick={() => minTypes ? showMinTypeModal() : showDeleteModal({id: typeID, name: seizure.custom_name})}>
                              <DeleteIcon className={classes.seizureRowDelete}/>
                            </Button>
                          </div>
                        </div>
                      </label>
                      <HiddenCheckbox id={`seizureType${typeID}`} />
                      <div className="seizureTypesRow seizureDetails">
                        <div className="knownTriggers">
                          <p className="seizureQuestion">Known Triggers:</p>
                          
                            {seizure.known_triggers.length ?
                              triggers.map(trigger => {
                              if (seizure.known_triggers.includes(trigger.seizure_trigger_id)){
                                return (
                                  <li key={seizure.known_triggers.seizure_trigger_id}>
                                    <span className="seizureAnswer">{trigger.seizure_trigger_name}</span>
                                  </li>
                                )
                              } else {
                                return null
                              }
                            })
                          : <span className="seizureAnswer">None</span>}
                        </div>
                        <div className="answeredQuestions">
                          <ul>
                            {Object.keys(seizureQuestions).map((question, index) => {
                              if(seizure[question] !== null){
                                return (
                                  <div key={index}>
                                    <li><p className="seizureQuestion">{seizureQuestions[question]}</p></li>
                                    <p className="seizureAnswer">{getSeizureAnswer(seizure, question)}</p>
                                  </div>
                                )
                              } else return null
                            })}
                          </ul>
                        </div>
                        <div className="fullSeizureDescription" style={{textAlign: (seizure.description == '--' ? 'center': 'left')}}>
                          <p>{seizure.description}</p>
                        </div>
                      </div>
                      <label htmlFor={`seizureType${typeID}`}>
                        <KeyboardArrowRightIcon className="seizureChevron" />
                      </label>
                    </div>
                  )
                })}
              
              <StyledModal
                okButtonProps={{danger: true}}
                cancelButtonProps={{style: {background: "#bdbdbd", color: "white", border: "none"}}}
                okText="DELETE"
                cancelText="CANCEL"
                visible={isDeleteModalOpen}
                onOk={handleDeleteSeizure}
                onCancel={closeDeleteModal}>
                  <p className="mainText">Are you sure you want to delete the seizure type "{seizureToDelete && seizureToDelete.name}"?</p>
                  <span className="note">This action cannot be undone.</span>
              </StyledModal>

              <StyledModal
                visible={isMinTypeModalOpen}
                onOk={() => setIsMinTypeModalOpen(false)}
                onCancel={() => setIsMinTypeModalOpen(false)}
              >
                <p className="mainText">Cannot delete this seizure type.</p>
                <p className="note">You must have at least 1 seizure type listed.</p>
              </StyledModal>
            </div>
            : 
            !seizures && !editSeizure?
            <>
              <div className={classes.NoSeizureWarningLabelContainer}>
                <p className={classes.NoSeizureWarningLabel}>No seizure types are available.
                </p>
                <p className = {classes.NoSeizureWarningLabel}>Please use one of two buttons below to add one.</p>
              </div>
            </>
            : seizures == null && editSeizure ? 
                <>
                  <SeizureTypesForm
                    closeForm={handleCloseForm}
                    seizureID={seizureToEdit}
                    seizureTypes={seizures}
                    triggers={triggers}
                    isAura={isAura}
                    flag={typesUpdated}
                    setFlag={setTypesUpdated}
                    editSeizureType={editSeizureType}
                  />
                </>
                :
                <></>
            )
          }
          </>
        )}
      </ProfileConsumer>
      {!editSeizure && 
        <div className="addButtonsRow">
          <button disabled={maxTypes} className="addButtons" onClick={() => handleOpenForm("new", false)}><span>+</span>Add Seizure Type</button>
          <button disabled={maxTypes} className="addButtons" onClick={() => handleOpenForm("new", true)}><span>+</span>Add Aura Type</button>
        </div>
      }
    </div>
  )
}

export default SeizureTypesTab
