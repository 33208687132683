import React from 'react'

const PoorConcentrationIcon = () => {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.3 101.6" version="1.1" x="100px" y="100px"    style={{enableBackground:"new 0 0 70 97"}} >
      {/* <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 100.3 101.6" style="enable-background:new 0 0 100.3 101.6;"  space="preserve"> */}

      <g>
        <path fill="#462d7d" d="M41.8,52.6l3,2.6l-3.6,1.5l0.3-1.6l-8.5-1c-0.4,4.3-4.1,7.7-8.5,7.7c-4.7,0-8.6-3.8-8.6-8.5s3.8-8.5,8.5-8.5
          s8.5,3.8,8.5,8.5l0,0l8.6,1L41.8,52.6z M17.4,53.4c0,4,3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3s-3.3-7.3-7.3-7.3
          C20.6,46.1,17.4,49.4,17.4,53.4z"/>
        <path fill="#462d7d" d="M81.6,76.7H88c0.6,0,1.2,0.3,1.2,0.9v19.1c0,0.6-0.6,1.2-1.2,1.2h-6.4c-0.6,0-1-0.6-1-1.2v-1.5H62.3v-0.1v-4.2
          h15.6c0.4,0,0.6-0.5,0.6-0.9V77.8c0-0.4-0.3-0.6-0.6-0.6H67.6c0,1.3-0.1,2.5-0.1,3.9c-0.1,1.1-1,2-2.1,2c0,0,0,0-0.1,0h-0.1
          c-1.2-0.1-2.1-1.1-2-2.2c0.1-1.3,0.1-2.3,0.1-3.6h-1.9v0.5c-0.8-1-1.9-1.7-3.3-1.7c-1.8,0-3.4,1.2-3.9,2.9
          c-0.5-1.7-2.1-2.9-3.9-2.9c-1.4,0-2.5,0.7-3.3,1.7v-0.5H13.6c-0.4,0-0.5,0.2-0.5,0.6V90c0,0.4,0.2,0.9,0.5,0.9h32.6V95v0.1H10.7
          v1.5c0,0.6-0.6,1.2-1.2,1.2H3.1c-0.6,0-1-0.6-1-1.2V59.1c0-0.6,0.4-1.1,1-1.1h6.4c0.6,0,1.2,0.5,1.2,1.1v23.5h1.6v-4.7
          c0-0.8,0.5-1.4,1.3-1.4H47v-12l-7.3,2.1l0,0c-0.2,0-0.3,0.1-0.5,0.1h-0.1l0,0c-0.1,0-0.2,0-0.3,0h-0.1c-0.1,0-0.1,0-0.2,0h-0.1
          c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0s-0.2-0.1-0.3-0.2l0,0c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2
          c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1s-0.1-0.1-0.1-0.2l0,0c0-0.1-0.1-0.2-0.1-0.3l0,0l0,0v-0.1V65v-0.1v-0.1v-0.1l0,0v-0.1
          c0,0,0,0,0-0.1v-0.1c0,0,0,0,0-0.1v-0.1c0,0,0,0,0-0.1V64v-0.1v-0.1c0,0,0-0.1,0.1-0.1v-0.1c0,0,0-0.1,0.1-0.1c0,0,0,0,0-0.1
          l0.1-0.1c0,0,0,0,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1l0.1-0.1c0,0,0,0,0.1,0l0.1-0.1l0,0l5.4-3.3l4.2-2.6
          c-0.1,0.5-0.2,0.9-0.2,1.4c0,1.2,0.3,2.2,0.8,3.2l-2.4,1.4l0.4,0.5l2.3-1.4c1.2,1.9,3.3,3.1,5.7,3.1c3.8,0,6.8-3.1,6.8-6.8
          c0-0.1,0-0.2,0-0.3c0.6,0.2,1.2,0.5,1.8,1.1c2.5,2.1,4.1,6.3,4.6,12.5c0.1,1.5,0.2,3.4,0.2,5h10.2c0.8,0,1.4,0.6,1.4,1.4v4.7h1.3
          v-5C80.7,77,81,76.7,81.6,76.7z M63.2,71.8c-0.3-3.9-0.9-6.3-1.7-7.7v12.4h1.9C63.3,74.8,63.3,73.2,63.2,71.8z"/>
        <path fill="#462d7d" d="M53.7,95V80.2c0-1.8-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3V95c0,1.8,1.5,3.3,3.3,3.3S53.7,96.9,53.7,95z"/>
        <path fill="#462d7d" d="M61.4,80.2c0-1.8-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3V95c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3V80.2z"/>
        <ellipse fill="#462d7d" cx="54.2" cy="58.2" rx="6" ry="6"/>
        <path  fill="#462d7d" d="M45.7,49.3l1.2-1.2l0.9,3.8l-3.9-0.8l1.2-1.2L33.3,39.3C30.9,41.6,27.6,43,24,43c-7.5,0-13.6-6.1-13.6-13.6
          S16.5,15.8,24,15.8s13.6,6.1,13.6,13.6c0,3.6-1.4,6.9-3.7,9.3L45.7,49.3z M12.8,30c0,6.4,5.2,11.6,11.6,11.6S36,36.4,36,30
          s-5.2-11.6-11.6-11.6S12.8,23.5,12.8,30z"/>
        <path fill="#462d7d" d="M51.9,5.1c4.9,3.4,6.2,10.1,2.8,15c-1.6,2.3-4,3.9-6.6,4.4L51.3,46l1.2-0.2l-1.1,2.9l-2.1-2.3l1.3-0.3
          l-3.2-21.4c-2.6,0.4-5.3-0.2-7.7-1.8c-4.9-3.4-6.2-10.1-2.8-15C40.2,3,47,1.7,51.9,5.1z M38.3,9.4c-2.9,4.2-1.8,9.9,2.4,12.8
          s9.9,1.8,12.8-2.4S55.3,9.9,51.1,7S41.1,5.2,38.3,9.4z"/>
        <path fill="#462d7d" d="M76.5,38.7c2.8-5.7,9.7-8,15.3-5.2c5.7,2.8,8,9.7,5.2,15.3c-2.8,5.7-9.7,8-15.3,5.2c-2.7-1.3-4.6-3.6-5.6-6.2
          l-12.3,4.9l0.4,1.3l-3.3-0.7l2.1-2.6l0.4,1.3l12.4-5C75,44.3,75.1,41.4,76.5,38.7z M82.2,52.6C87,55,92.9,53,95.3,48.2
          s0.4-10.7-4.4-13.1c-4.8-2.4-10.7-0.4-13.1,4.4S77.3,50.2,82.2,52.6z"/>
        <path fill="#462d7d" d="M82.7,19.2c1.7,6.9-2.4,13.8-9.3,15.6c-3.3,0.8-6.6,0.3-9.3-1.2l-6.9,13.2l1.4,0.8l-3.3,1.7l-0.1-3.7l1.3,0.8
          l6.9-13.2c-2.7-1.7-4.7-4.4-5.5-7.7c-1.7-6.9,2.4-13.8,9.3-15.6C74,8.2,81,12.4,82.7,19.2z M67.5,12c-5.9,1.5-9.4,7.4-7.9,13.3
          s7.4,9.4,13.3,7.9c5.9-1.5,9.4-7.4,7.9-13.3C79.3,14.1,73.4,10.5,67.5,12z"/>
      </g>
      </svg>
  )
}

export default PoorConcentrationIcon
